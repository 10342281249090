export const companyValidator = (companyId: number): string => {
    if (companyId === 0) return 'Firma seçiniz!'
    return '';
}

export const bankValidator = (bankId: number): string => {
    if (bankId === 0) return 'Banka seçiniz!'
    return '';
}

export const bankAccountValidator = (bankAccountId: number): string => {
    if (bankAccountId === 0) return 'Hesap seçiniz!'
    return '';
}

export const userValidator = (userId: number): string => {
    if (userId === 0) return 'Kullanıcı seçiniz!'
    return '';
}

export const descriptionValidator = (description: string): string => {
    if (description === "") return 'Açıklama alanını boş geçemezsiniz!'
    return '';
}

export const senderIBANValidator = (senderIBAN: string): string => {
    {/*if (senderIBAN === "") return 'Gönderen IBAN alanını boş geçemezsiniz!'*/}
    return '';
}

export const senderTaxNumberValidator = (taxNumber: string): string => {
    {/*if (taxNumber === "") return 'Gönderen Vergi Numarası alanını boş geçemezsiniz!'*/}
    return '';
}