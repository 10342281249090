import XHRService from "./xhr.service";

export default interface IBankAccountUserRequest {
    id?: number,
    userId: number,
    companyId: number,
    bankAccountId: number,
    isActive: boolean,
    searchText: string
}


interface IBankAccountUser {
    id: number,
    userId: number,
    companyId: number,
    bankAccountId: number,
    isActive: boolean,
    searchText: string
}

interface IBankAccountUserResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IBankAccountUser
}

interface IBankAccountUserListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        bankAccountUsers: IBankAccountUser[]
    }
}

export default class BankAccountUserService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IBankAccountUserResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/BankAccountUser/CreateBankAccountUser", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IBankAccountUserRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/BankAccountUser/UpdateBankAccountUser", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<IBankAccountUserResponse> {
        const { isSuccess }: any = await this.XHRService.post("/BankAccountUser/DeleteBankAccountUser", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number): Promise<any> {

        const model = {
            searchText,
            companyId: 0,
            bankAccountId: 0,
            userId: 0,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/BankAccountUser/GetBankAccountUsers", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string): Promise<any> {

        const model = {
            searchText,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/BankAccountUser/GetBankAccountUsersExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/BankAccountUser/GetBankAccountUserById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
