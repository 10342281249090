import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router'

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import TableFooter from '@material-ui/core/TableFooter'

import CustomSelect from '../layout/CustomSelect'
import ConfirmDialog from '../layout/Confirm'

import { USER } from '../../routes/paths'

import { locationService, userService } from '../../service'


interface IMultipleUserFormState {
    item: number,
    list: any,
    loading: boolean,
    isActive: boolean,
    isSuccess: boolean,
    userId: number,

    companyList: any,
    companyId: any,
    nameSurname: any,
    emailAddress: any,
    mobilePhoneNumber: any,
    password: any,
    showPassword: boolean,

    //Confirm
    confirmStatus: boolean,
    deleteConfirm: boolean,
    confirmMessage: string,
    confirmTitle: string,
    confirmIcon: string,
    confirmType: string,
    access: string
}

export default class MultpileUserForm extends Component {
    public readonly state: IMultipleUserFormState;
    public readonly props: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            item: 0,
            list: [],
            loading: true,
            isActive: true,
            isSuccess: false,
            userId: 0,

            companyList: [],
            companyId: [0],
            nameSurname: [""],
            emailAddress: [""],
            mobilePhoneNumber: [""],
            password: [""],
            showPassword: false,

            //Confirm
            confirmStatus: false,
            deleteConfirm: false,
            confirmMessage: "Kullanıcı eklemek için erişim yetkiniz bulunmamaktadır?",
            confirmTitle: "Erişim İzniniz Bulunmamaktadır!",
            confirmIcon: "fas fa-exclamation",
            confirmType: "ok",
            access: ""
        }

        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);


    }

    componentWillMount() {
        this.getPermissionControl();
    }



    private async getPermissionControl() {
        const { data } = await userService.permission();
        this.setState({ permissionList: data.unauthorizedPermissions, userId: data.userId });
        this.getCompanies();
    }

    /* EVENT */
    private onChangeNameSurname(key: any, e: ChangeEvent<HTMLInputElement>) {
        let _nameSurname: any = this.state.nameSurname;
        _nameSurname[key] = e.target.value;
        this.setState({ nameSurname: _nameSurname });
    }

    private onChangeEmailAddress(key: any, e: ChangeEvent<HTMLInputElement>) {
        let _emailAddress: any = this.state.emailAddress;
        _emailAddress[key] = e.target.value;
        this.setState({ emailAddress: _emailAddress });
    }

    private onChangePassword(key: any, e: ChangeEvent<HTMLInputElement>) {
        let _password: any = this.state.password;
        _password[key] = e.target.value;
        this.setState({ password: _password });
    }

    private onChangeMobilePhoneNumber(key: any, e: ChangeEvent<HTMLInputElement>) {
        if (Number(e.target.value) || e.target.value == "") {
            let _mobilePhoneNumber: any = this.state.mobilePhoneNumber;
            _mobilePhoneNumber[key] = e.target.value;
            this.setState({ mobilePhoneNumber: _mobilePhoneNumber });
        }
    }

    private onChangeCompany(key: any, companyId: any) {
        let _companyId: any = this.state.companyId;
        _companyId[key] = companyId;
        this.setState({ companyId: _companyId });
    }

    private handleClickShowPassword() {
        this.setState(({ showPassword: !this.state.showPassword }));
    };

    private onConfirmClose() {
        this.setState({ confirmStatus: false });
    }

    private addNewUser() {
        let _nameSurname = this.state.nameSurname;
        _nameSurname.push("");

        let _emailAddress = this.state.emailAddress;
        _emailAddress.push("");

        let _mobilePhoneNumber = this.state.mobilePhoneNumber;
        _mobilePhoneNumber.push("");

        let _password = this.state.password;
        _password.push("");

        let _companyId = this.state.companyId;
        _companyId.push(0);

        this.setState({
            item: this.state.item + 1,
            nameSurname: _nameSurname,
            emailAddress: _emailAddress,
            mobilePhoneNumber: _mobilePhoneNumber,
            password: _password,
            companyId: _companyId
        });
    }


    private async saveUsers() {
        const { item, companyId, nameSurname, emailAddress, mobilePhoneNumber, password, userId } = this.state;

        if (userId === 21) { this.setState({ confirmStatus: true }) }
        else {

            let model: any = [];

            for (let i = 0; i <= item; i++) {
                model.push({
                    'companyId': companyId[i].id,
                    'nameSurname': nameSurname[i],
                    'emailAddress': emailAddress[i],
                    'mobilePhoneNumber': mobilePhoneNumber[i],
                    'password': password[i],
                    'isExcelAndPdfExport': false,
                    'isPosStatement': false,
                    'isAccounting': false,
                    'isReporting': false,
                    'isActive': true
                });
            }

            const { isSuccess } = await userService.createMultiple({ 'createUserRequests': model });
            this.setState({ isSuccess: true });
        }
    }


    /* LISTING */
    private async getCompanies(useDefault: boolean = true) {
        const { data } = await locationService.getCompaniesHQ();

        const state = {
            companyList: data.companies,
            loading: false
        };

        this.setState(state);

    }



    /* RENDERING */
    private renderNameSurname(key: number) {
        const { nameSurname } = this.state;


        return (
            <FormControl margin="none" fullWidth className="input">
                <TextField
                    id="nameSurname"
                    label="Kullanıcı Adı"
                    placeholder="Kullanıcı adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={(e: any) => this.onChangeNameSurname(key, e)}
                    value={nameSurname[key]}
                    name="nameSurname"
                    fullWidth
                    autoComplete="nameSurname"
                />
            </FormControl>
        );
    }

    private renderEmailAddress(key: number) {
        const { emailAddress } = this.state;


        return (
            <FormControl margin="none" fullWidth className="input">
                <TextField
                    type="email"
                    id="emailAddress"
                    label="Eposta Adresi"
                    placeholder="Eposta adresi yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={(e: any) => this.onChangeEmailAddress(key, e)}
                    value={emailAddress[key]}
                    name="emailAddress"
                    fullWidth
                    autoComplete="emailAddress"
                />
            </FormControl>
        );
    }

    private renderPassword(key: number) {
        const { password } = this.state;

        return (
            <FormControl margin="none" fullWidth className="input">
                <TextField
                    id="password"
                    name="password"
                    autoComplete="password"
                    value={password[key]}
                    onChange={(e: any) => this.onChangePassword(key, e)}
                    label="Şifre"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="********"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Şifreyi göster"
                                    onClick={this.handleClickShowPassword}
                                    style={{ 'border': 'none', 'opacity': .2 }}
                                >
                                    {this.state.showPassword ? <Icon className="far fa-eye"></Icon> : <Icon className="far fa-eye-slash"></Icon>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
        );
    }

    private renderMobilePhoneNumber(key: number) {
        const { mobilePhoneNumber } = this.state;


        return (
            <FormControl margin="none" fullWidth className="input">
                <TextField
                    id="mobilePhoneNumber"
                    label="Telefon Numarası"
                    placeholder="Telefon numarası yazınız"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    onChange={(e: any) => this.onChangeMobilePhoneNumber(key, e)}
                    value={mobilePhoneNumber[key] != "" ? mobilePhoneNumber[key] : ""}
                    name="mobilePhoneNumber"
                    autoComplete="mobilePhoneNumber"
                />
            </FormControl>
        );
    }

    private renderCompanies(key: number) {
        const { companyId, companyList } = this.state;
        return (
            <FormControl margin="none" fullWidth variant="outlined" style={{ width: '98%' }} className="input inputSelect">
                <CustomSelect labelName="companyName" value={companyId[key]} valueKey="id" options={companyList} onChange={(e: any) => this.onChangeCompany(key, e)} placeholder="Firma *"></CustomSelect>
            </FormControl >
        );
    }


    private renderRowHeaders() {
        return (
            <TableRow style={{ backgroundColor: '#fdfdfd' }}>
                <TableCell style={{ minWidth: 150 }}>
                    <TableSortLabel>
                        Firma
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                        Adı Soyadı
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                        Eposta Adresi
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                        Telefon Numarası
                    </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: 200, textAlign: 'left' }}>
                    <TableSortLabel>
                        Şifresi
                    </TableSortLabel>
                </TableCell>
            </TableRow >
        );
    }


    private renderRowData() {
        const { item } = this.state;
        let table = [];

        for (let i = 0; i <= item; i++) {
            table.push(
                <TableRow key={i}>
                    <TableCell scope="row" style={{ minWidth: 150 }}>
                        {this.renderCompanies(i)}
                    </TableCell>
                    <TableCell>{this.renderNameSurname(i)}</TableCell>
                    <TableCell>{this.renderEmailAddress(i)}</TableCell>
                    <TableCell>{this.renderMobilePhoneNumber(i)}</TableCell>
                    <TableCell>{this.renderPassword(i)}</TableCell>
                </TableRow>
            );
        }
        return table;
    }

    render() {

        return (
            <Paper>
                <ConfirmDialog open={this.state.confirmStatus} icon={this.state.confirmIcon} confirm={this.state.confirmType} text={this.state.confirmMessage} header={this.state.confirmTitle} onClose={this.onConfirmClose} onApprove={this.onConfirmClose} />

                {this.state.isSuccess && <Redirect to={USER} />}
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ position: 'absolute', backgroundColor: '#408af8', width: 100, right: '2%', marginTop: -40 }}
                    onClick={() => this.saveUsers()}
                    type="submit">
                    <Typography style={{ color: '#fff', fontSize: 14 }}>KAYDET</Typography>
                </Button>
                <div style={{ minWidth: '100%', overflowX: 'auto' }}>

                    <Table>
                        <TableHead>
                            {this.renderRowHeaders()}
                        </TableHead>
                        <TableBody>
                            {this.renderRowData()}
                        </TableBody>
                        <TableFooter>
                            <TableCell colSpan={5}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{ backgroundColor: '#9aafcd', opacity: .5, marginTop: 10 }}
                                    onClick={() => this.addNewUser()}
                                    type="submit">
                                    <Typography style={{ fontSize: 14, color: '#445978', paddingTop: 10, paddingBottom: 10 }}>YENİ KULLANICI +</Typography>
                                </Button>
                            </TableCell>
                        </TableFooter>
                    </Table>
                </div>

            </Paper>
        )
    }
}


