//RolePermission


export const roleValidator = (roleId: number): string => {
    if (roleId === 0) return 'Rol seçiniz!'
    return '';
}

export const permissionValidator = (permissionId: number): string => {
    if (permissionId === 0) return 'İzin seçiniz!'
    return '';
}