//Currency

export const currencyNameValidator = (currencyName: string): string => {
    if (currencyName === "") return 'Para birimi adı alanını boş geçemezsiniz!'
    return '';
}

export const currencyCodeValidator = (currencyCode: string): string => {
    if (currencyCode === "") return 'Para birimi kodu alanını boş geçemezsiniz!'
    return '';
}


