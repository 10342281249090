import XHRService from "./xhr.service";

export default interface IPaymentRequest {
    id?: number;
    paymentName: string;
    totalCompany: number;
    memberModel: string;
    amount: number;
    memberFinisedTime: string;
    searchText: string;
    isActive: boolean;
}


interface IPayment {
    id: number;
    paymentName: string;
    totalCompany: number;
    memberModel: string;
    amount: number;
    memberFinisedTime: string;
    searchText: string;
    isActive: boolean;
}

interface IPaymentResponse {
    isSuccess: boolean,
    data: IPayment
}

interface IPaymentListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        currencies: IPayment[]
    }
}

export default class PaymentService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IPaymentResponse> {
        const { isSuccess }: any = await this.XHRService.post("/Payment/CreatePayment", model).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number): Promise<any> {

        const model = {
            isActive: true,
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/Payment/GetPayments", model).catch(() => ({ isSuccess: false }));
    }
}
