import React, { Component } from 'react'
import Wrapper from '../layout/Wrapper';
import Grid from '@material-ui/core/Grid';
import PosStatementValorDateList from './PosStatementValorDateList';

export default class PosStatementValorDate extends Component {
    public props: any;

    render() {
        return (
            <Wrapper>
                <Grid container id="Breadcrumb">
                    <Grid xs={12}>
                        <ul>
                            <li>Pos Valor Raporu</li>
                            <li>Finans</li>
                            <li>Pos Valor Raporu</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container id="Content">
                    <Grid xs={12}>
                        <PosStatementValorDateList />
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
}
