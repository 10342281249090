import React, { Component } from 'react'
import { Tabs, Tab, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { accountBalancesService } from '../../service';
import { ACCOUNT_DETAIL, BANK_STATEMENT } from '../../routes/paths';

interface IBalanceState {
    tab: number;
    bankList: [],
    companyList: [],
    loading: boolean;
}

interface IBalanceProps {
    currencyId: number;
}

export default class Balance extends Component {
    public readonly state: IBalanceState;
    public readonly props: IBalanceProps;


    constructor(props: any) {
        super(props);
        this.props = props;

        this.state = {
            tab: 0,
            bankList: [],
            companyList: [],
            loading: false
        }

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    componentDidMount() {
        this.getBanks();
    }

    private handleTabChange(_: any, tab: number) {
        this.setState({ tab });
        if (tab === 1) this.getCompanies();
        else this.getBanks();
    }

    private async getBanks() {
        this.setState({ loading: true });
        const response = await accountBalancesService.getBankTotals(this.props.currencyId);
        if (response.isSuccess) this.setState({ bankList: response.data.accountBalanceTotalByBankItem, loading: false });
    }

    private async getCompanies() {
        this.setState({ loading: true });
        const response = await accountBalancesService.getAccountBalanceTotalsByCompany(this.props.currencyId);
        if (response.isSuccess) this.setState({ companyList: response.data.accountBalanceTotalByCompanyItem, loading: false, });
    }

    private renderBanks() {
        const { bankList } = this.state;
        if (Array.isArray(bankList) && bankList.length > 0) return bankList.map((bank: any) => (
            <TableRow key={bank.id}>
                <TableCell>
                    <Link to={`${BANK_STATEMENT(bank.bankId, bank.currencyId)}/`}>{bank.bankName}</Link>
                </TableCell>
                <TableCell>{this.renderAmountConvert(String(bank.balance))}</TableCell>
            </TableRow>
        ));

        return <Typography className="text">BANKALARDA BAKİYE BULUNAMADI</Typography>;

    }

    private renderCompanies() {
        const { companyList } = this.state;
        if (Array.isArray(companyList) && companyList.length > 0) return companyList.map((company: any) => (
            <TableRow key={company.id}>
                <TableCell scope="row">
                    {company.companyName}
                </TableCell>
                <TableCell>{this.renderAmountConvert(String(company.balance))}</TableCell>
            </TableRow>
        ));

        return <Typography className="text">FİRMALARDA BAKİYE BULUNAMADI</Typography>;
    }


    private renderAmountConvert(amount: string) {
        if (amount == "0") {
            return "0,00";
        }

        let p = "";
        let isaretli = ""

        let sayi = amount.split(".");
        let net = sayi[0];
        let ondalik = sayi[1];

        net.indexOf("-") != -1 ? isaretli = "-" : null
        net = net.replace("-", "");

        ondalik == undefined ? ondalik = "00" : null
        ondalik.length == 1 ? ondalik += "0" : null

        let tersSayi = ((net.split("")).reverse()).join("");

        for (let i = 0; i < tersSayi.length; i++) {
            if (i % 3 == 0 && i != 0) p += "."
            p += tersSayi.substr(i, 1);
        }


        return isaretli + (((p.split("")).reverse()).join("")) + "," + ondalik;
    }



    private renderBody() {
        const { loading, tab } = this.state;

        if (loading) return <CircularProgress className="progress" />;
        if (tab === 1) return this.renderCompanies();
        return this.renderBanks();
    }

    render() {
        return (
            <div>
                <Tabs
                    value={this.state.tab}
                    onChange={this.handleTabChange}
                    centered
                    className="tab"
                    indicatorColor="none"
                >
                    <Tab label="Banka" />
                    <Tab label="Firma" />

                </Tabs>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{this.state.tab == 0 ? "Banka" : "Firma"} Adı</TableCell>
                            <TableCell>Tutar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderBody()}
                    </TableBody>
                </Table>
                <Link to={ACCOUNT_DETAIL(this.props.currencyId)} className="detail">Detaylar</Link>
            </div>
        )
    }
}
