import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { districtService, locationService } from '../../service';
import { DISTRICT } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import Error from '../NotFound/NotFound'


import { districtNameValidator, countryValidator, cityValidator } from '../../utils/district.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IDistrictFormState {
    districtName: string;
    districtNameError: string;
    countryList: [],
    countryId: any,
    countryError: string;
    cityList: [],
    cityId: any,
    cityError: string;
    isActive: boolean;
    isSuccess: string,
    isEdit: boolean;
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface IDistrictFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class DistrictForm extends Component {
    public readonly state: IDistrictFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            districtName: "",
            districtNameError: "",
            countryList: [],
            countryId: 0,
            countryError: "",
            cityList: [],
            cityId: 0,
            cityError: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeDistrictName = this.onChangeDistrictName.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.districtId) this.getEditableDistrict();
        else this.getCountries();

        this.form = React.createRef();
    }

    private async getEditableDistrict() {
        const { data, isSuccess, errorCode, errorMessage } = await districtService.getById(this.props.match.params.districtId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getCountries();

            const state = {
                ...data,
                countryId: this.state.countryList.find((item: any) => item.id === data.countryId),
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state });
            await this.getCities(state.countryId.id, true);
            this.setState({ cityId: this.state.cityList.find((item: any) => item.id === data.cityId), loading: false })
        }
    }

    /** EVENT LISTENERS */

    private onChangeDistrictName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ districtName: e.target.value, districtNameError: districtNameValidator(e.currentTarget.value) });
    }

    private onChangeCountry(countryId: any) {
        this.setState({ countryId, countryError: countryValidator(countryId) });
        this.getCities(countryId.id, true);
    }

    private onChangeCity(cityId: any) {
        this.setState({ cityId, cityError: cityValidator(cityId) });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(DISTRICT);
    }

    private async getCountries(useDefault: boolean = true) {
        const { data } = await locationService.getCountries();

        const state = {
            countryId: useDefault ? 0 : this.state.countryId.id,
            countryList: data.countries
        };

        this.setState(state);

        await this.getCities(state.countryId, useDefault);
    }

    private async getCities(countryId: number, useDefault: boolean) {
        const { data } = await locationService.getCities(countryId);

        const state = {
            cityList: data.cities,
            cityId: useDefault ? 0 : this.state.cityId.id,
            loading: false
        };

        this.setState(state);

    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { districtName, countryId, cityId } = this.state;

        //Submit Validation
        if (
            districtNameValidator(districtName) !== "" ||
            countryValidator(countryId) !== "" ||
            cityValidator(cityId) !== ""

        ) {

            this.setState({
                districtNameError: districtNameValidator(districtName),
                countryError: countryValidator(countryId),
                cityError: cityValidator(cityId)
            });
            return false;
        }

        return true;
    }


    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { districtName, countryId, cityId, isActive, isEdit } = this.state;

        if (this.formValidation()) {

            const model = {
                districtName: sqlInjectValidator(districtName),
                countryId: sqlInjectValidator(String(countryId.id)),
                cityId: sqlInjectValidator(String(cityId.id)),
                isActive
            };


            if (isEdit) await this.editDistrict(model);
            else await this.createDistrict(model);
        }
    }

    private async createDistrict(model: any) {
        const data = await districtService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editDistrict(model: any) {
        const data = await districtService.update({ ...model, id: this.props.match.params.districtId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private renderDistrictName() {
        const { districtName, districtNameError } = this.state;


        return (
            <FormControl margin="none" style={{ width: '98%' }} className="input">
                <TextField
                    id="districtName"
                    label="İlçe Adı"
                    placeholder="İlçe adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeDistrictName}
                    value={districtName}
                    name="districtName"
                    autoComplete="districtName"
                    helperText={districtNameError}
                />
            </FormControl>
        );
    }

    private renderCountries() {
        const { countryId, countryList, countryError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="countryName" value={countryId} valueKey="id" options={countryList} onChange={this.onChangeCountry} placeholder="Ülke *"></CustomSelect>
                <span className="errorMessage">{countryError}</span>
            </FormControl >
        );
    }

    private renderCities() {
        const { cityId, cityList, cityError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="cityName" value={cityId} valueKey="id" options={cityList} onChange={this.onChangeCity} placeholder="Şehir *"></CustomSelect>
                <span className="errorMessage">{cityError}</span>
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: DISTRICT, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">İlçe {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan ilçe bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda ilçe ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">İlçe {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderDistrictName()}
                                    {this.renderCountries()}
                                    {this.renderCities()}
                                    {this.renderStatus()}

                                    <Button onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(DistrictForm);
