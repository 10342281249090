import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../layout/Wrapper'
import ConfirmDialog from '../layout/Confirm'

import { USER } from '../../routes/paths'
import { userService } from '../../service'


export interface IPasswordUpdateFormState {
    password: string,
    passwordAgain: string,
    showPassword: boolean,
    loading: boolean,
    isSuccess: boolean;

    //Confirm
    confirmStatus: boolean;
    confirmMessage: string;
    confirmTitle: string;
    confirmIcon: string;
    confirmType: string;
}

export interface IPasswordUpdateFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class PasswordResetForm extends Component {
    public readonly state: IPasswordUpdateFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            password: "",
            passwordAgain: "",
            showPassword: false,
            loading: false,
            isSuccess: false,

            //Confirm
            confirmStatus: false,
            confirmMessage: "Eksik veya hatalı bilgi girdiniz, lütfen bilgilerinizi kontrol ederek yeniden deneyiniz?",
            confirmTitle: "Şifre Güncelleme",
            confirmIcon: "fas fa-lock",
            confirmType: "ok",
        };

        this.cancel = this.cancel.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePasswordAgain = this.onChangePasswordAgain.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.onConfirmApprove = this.onConfirmApprove.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);
    }

    componentWillMount() {
        this.form = React.createRef();
    }

    /** EVENT LISTENERS */

    private onChangePassword(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ password: e.target.value });
    }

    private onChangePasswordAgain(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ passwordAgain: e.target.value });
    }

    handleClickShowPassword() {
        this.setState(state => ({ showPassword: !this.state.showPassword }));
    };

    private onConfirmClose() {
        this.setState({ confirmStatus: false });
    }

    private onConfirmApprove() {
        this.setState({ confirmStatus: false });
        this.state.confirmTitle === "Güncellendi" ? this.setState({ isSuccess: true }) : null
    }

    private async cancel() {
        this.props.history.push(USER);
    }

    private async updatePassword() {
        const { password, passwordAgain } = this.state;

        if (password != passwordAgain) {
            this.setState({ confirmMessage: "Girdiğiniz yeni şifreler birbiri ile uyuşmamaktadır!", confirmTitle: "Uyumsuz Yeni Şifreler", confirmIcon: "fas fa-lock", confirmType: "ok", confirmStatus: true });
        } else if (password.length < 8 || password.length > 16) {
            this.setState({ confirmMessage: "Şifre en az 8 en fazla 16 karakter olabilir.", confirmTitle: "Geçersiz Yeni Şifreler", confirmIcon: "fas fa-lock", confirmType: "ok", confirmStatus: true });
        } else{

            const model = {
                existingPassword: "1",
                id: this.props.match.params.userId,
                password
            };

            const { isSuccess } = await userService.reset({ ...model });

            if (isSuccess) {
                this.setState({ confirmMessage: "Kullanıcı şifresi başarılı bir şekilde güncellendi!", confirmTitle: "Güncellendi", confirmIcon: "fas fa-lock", confirmType: "ok", confirmStatus: true });
            } else {
                this.setState({ confirmMessage: "Eksik veya hatalı bilgi girdiniz, lütfen bilgilerinizi kontrol ederek yeniden deneyiniz?", confirmTitle: "Hatalı İşlem", confirmIcon: "fas fa-lock", confirmType: "ok", confirmStatus: true });
            }
        }
    }

    /** VIEW RENDER */

    private renderPassword() {
        const { password } = this.state;

        return (
            <FormControl margin="none" required className="input" style={{ width: '100%' }}>
                <TextField
                    required
                    id="password"
                    name="password"
                    autoComplete="password"
                    value={password}
                    onChange={this.onChangePassword}
                    label="Yeni Şifreniz"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="Yeni şifrenizi yazınız"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Şifreyi göster"
                                    onClick={this.handleClickShowPassword}
                                    style={{ 'border': 'none', 'opacity': .2 }}
                                >
                                    {this.state.showPassword ? <Icon className="far fa-eye"></Icon> : <Icon className="far fa-eye-slash"></Icon>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
        );
    }

    private renderPasswordAgain() {
        const { passwordAgain } = this.state;

        return (
            <FormControl margin="none" required className="input" style={{ width: '100%' }}>
                <TextField
                    required
                    id="passwordAgain"
                    name="passwordAgain"
                    autoComplete="passwordAgain"
                    value={passwordAgain}
                    onChange={this.onChangePasswordAgain}
                    label="Yeni Şifreniz Tekrar"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="Yeni şifrenizi tekrar yazınız"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Şifreyi göster"
                                    onClick={this.handleClickShowPassword}
                                    style={{ 'border': 'none', 'opacity': .2 }}
                                >
                                    {this.state.showPassword ? <Icon className="far fa-eye"></Icon> : <Icon className="far fa-eye-slash"></Icon>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
        );
    }

    public render() {
        const { loading, confirmStatus, isSuccess } = this.state;

        return (
            <Wrapper>
                {isSuccess && <Redirect to={USER} />}
                <Grid container id="Form">
                    <ConfirmDialog open={confirmStatus} icon={this.state.confirmIcon} confirm={this.state.confirmType} text={this.state.confirmMessage} header={this.state.confirmTitle} onClose={this.onConfirmClose} onApprove={this.onConfirmApprove} />

                    {loading && <CircularProgress className="progress" />}
                    {!loading ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                <Icon className="fas fa-edit"></Icon>
                                <Typography variant="h3">Şifre Güncelleme</Typography>
                                <p>
                                    Bu ekrandan kullanıcı şifresini yeniden belirleyebilirsiniz!
                                </p>
                            </Grid>
                            <Grid item xs={12} md={2} className="form"></Grid>
                            <Grid item xs={12} md={5} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Şifre Güncelleme Formu</Typography>
                                    {this.renderPassword()}
                                    {this.renderPasswordAgain()}

                                    <Button type="submit" onClick={this.updatePassword}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : null}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(PasswordResetForm);
