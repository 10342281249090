import moment from 'moment'
import XHRService from './xhr.service'

export default class AccountBalancesService {
  private readonly XHRService: XHRService

  constructor(xhrService: XHRService) {
    this.XHRService = xhrService
  }

  public async getTotals(search: any = null): Promise<any> {
    const model = {
      companyId: 1,
      clientType: 'WEB',
      isActive: true,
      pagingParameters: {
        rowsPerPage: 50,
        pageNumber: 0,
      },
    }

    return await this.XHRService.post(
      '/AccountBalance/GetAccountBalanceTotals',
      model
    ).catch(() => ({ isSuccess: false }))
  }

  public async getAccountBalanceTotals(currencyId: number): Promise<any> {
    const model = {
      companyId: 1,
      currencyId,
      clientType: 'WEB',
      isActive: true,
      pagingParameters: {
        rowsPerPage: 50,
        pageNumber: 0,
      },
    }

    return await this.XHRService.post(
      '/AccountBalance/GetAccountBalanceTotals',
      model
    ).catch(() => ({ isSuccess: false }))
  }

  public async getBankTotals(currencyId?: number): Promise<any> {
    const model = {
      companyId: 1,
      bankId: 0,
      currencyId,
      clientType: 'WEB',
      isActive: true,
      pagingParameters: {
        rowsPerPage: 50,
        pageNumber: 0,
      },
    }

    return await this.XHRService.post(
      '/AccountBalance/GetAccountBalanceTotalsByBank',
      model
    ).catch(() => ({ isSuccess: false }))
  }

  public async getById(currencyId: number): Promise<any> {
    return await this.XHRService.get(
      `/AccountBalance/GetAccountBalanceTotals?currencyId=${currencyId}`
    ).catch(() => ({ isSuccess: false }))
  }

  public async getAccountBalanceTotalsByCompany(
    currencyId: number
  ): Promise<any> {
    const model = {
      bankId: 0,
      bankAccountId: 0,
      currencyId,
      balance: 0,
      clientType: 'WEB',
      isActive: true,
      searchText: '',
      pagingParameters: {
        rowsPerPage: 999,
        pageNumber: 0,
        orderBy: 0,
      },
    }
    //return await this.XHRService.post("/AccountBalance/GetAccountBalanceTotalsByCompany", { currencyId }).catch(() => ({ isSuccess: false }));
    return await this.XHRService.post(
      '/AccountBalance/GetAccountBalanceTotalsByCompany',
      model
    ).catch(() => ({ isSuccess: false }))
  }

  public async getAccountBalanceTotalsByBank(currencyId: number): Promise<any> {
    const model = {
      bankId: 0,
      bankAccountId: 0,
      currencyId,
      balance: 0,
      clientType: 'WEB',
      isActive: true,
      searchText: '',
      pagingParameters: {
        rowsPerPage: 999,
        pageNumber: 0,
        orderBy: 0,
      },
    }
    //return await this.XHRService.post("/AccountBalance/GetAccountBalanceTotalsByBank", { currencyId }).catch(() => ({ isSuccess: false }));
    return await this.XHRService.post(
      '/AccountBalance/GetAccountBalanceTotalsByBank',
      model
    ).catch(() => ({ isSuccess: false }))
  }
  public async getAccountBalances(currencyId: number): Promise<any> {
    const model = {
      bankId: 0,
      bankAccountId: 0,
      currencyId,
      balance: 0,
      clientType: 'WEB',
      isActive: true,
      searchText: '',
      pagingParameters: {
        rowsPerPage: 999,
        pageNumber: 0,
        orderBy: 0,
      },
    }
    return await this.XHRService.post(
      '/AccountBalance/GetAccountBalances',
      model
    ).catch(() => ({ isSuccess: false }))
  }

  public async getAccountBalancesByCompanyId(
    currencyId: number,
    companyId: number
  ): Promise<any> {
    const model = {
      companyId,
      bankId: 0,
      bankAccountId: 0,
      currencyId,
      balance: 0,
      clientType: 'WEB',
      isActive: true,
      searchText: '',
      pagingParameters: {
        rowsPerPage: 999,
        pageNumber: 0,
        orderBy: 0,
      },
    }
    return await this.XHRService.post(
      '/AccountBalance/GetAccountBalances',
      model
    ).catch(() => ({ isSuccess: false }))
  }

  public async get(
    unixStartDate?: string,
    unixEndtDate?: string,
    currencyId?: number,
    bankId?: number,
    accountId?: number,
    searchText?: string,
    clientType?: string,
    iban?: string,
    rowsPerPage?: number,
    pageNumber?: number,
    orderBy?: number
  ): Promise<any> {
    const model = {
      currencyId: accountId !== 0 ? 0 : currencyId,
      bankId: accountId !== 0 ? 0 : bankId,
      bankAccountId: accountId,
      startDate: unixStartDate,
      endDate: unixEndtDate,
      iban,
      description: null,
      clientType: 'WEB',
      isActive: true,
      searchText,
      pagingParameters: {
        rowsPerPage,
        pageNumber,
        orderBy,
      },
    }

    //console.log(model)

    return await this.XHRService.post(
      '/AccountBalance/GetAccountBalanceHistories',
      model
    ).catch(() => ({ isSuccess: false }))
  }

  public async getAccountBalanceHistoriesReport(
    unixStartDate?: string,
    unixEndtDate?: string,
    currencyId?: number,
    bankId?: number,
    accountId?: number
  ): Promise<any> {
    const model = {
      currencyId: 0,
      bankId: 0,
      bankAccountId: 0,
      balance: 0,
      startDate: moment().subtract(1, 'days').format('DD.MM.YYYY'),
      endDate: moment().format('DD.MM.YYYY'),
      clientType: 'WEB',
      isLicenseDifferent: false,
      isLicenseDifferentReverse: false,
      isJustStatement: false,
      isJustWebUser: false,
      isActive: true,
      searchText: '',
      pagingParameters: {
        rowsPerPage: 9999,
        pageNumber: 0,
        orderBy: 0,
      },
    }

    //console.log(model)

    return await this.XHRService.post(
      '/AccountBalance/GetAccountBalanceHistoriesReport',
      model
    ).catch(() => ({ isSuccess: false }))
  }

  public async download(
    startDate?: string,
    endDate?: string,
    currencyId?: number,
    bankId?: number,
    accountId?: number,
    searchText?: string,
    iban?: string
  ): Promise<any> {
    const model = {
      currencyId: accountId !== 0 ? 0 : currencyId,
      bankId: accountId !== 0 ? 0 : bankId,
      startDate,
      endDate,
      bankAccountId: accountId,
      searchText,
      iban,
      clientType: 'WEB',
      isActive: true,
      pagingParameters: {
        rowsPerPage: 9999,
      },
    }

    return await this.XHRService.export(
      '/AccountBalance/GetAccountBalanceHistoriesExcel',
      model
    )
  }
}
