//Language

export const languageNameValidator = (languageName: string): string => {
    if (languageName === "") return 'Dil adı alanını boş geçemezsiniz!'
    return '';
}

export const languageCodeValidator = (languageCode: string): string => {
    if (languageCode === "") return 'Dil kodu alanını boş geçemezsiniz!'
    return '';
}

