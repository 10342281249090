import XHRService from "./xhr.service";

export default interface IBankBranchRequest {
    id?: number,
    bankBranchName: string,
    bankBranchCode: string,
    bankId: number,
    countryId: number,
    cityId: number,
    districtId: number,
    address: string,
    phoneNumber: string,
    description: string,
    isActive: boolean
}


interface IBankBranch {
    id: number,
    bankBranchName: string,
    bankBranchCode: string,
    bankId: number,
    countryId: number,
    cityId: number,
    districtId: number,
    address: string,
    phoneNumber: string,
    description: string,
    isActive: boolean
}

interface IBankBranchResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IBankBranch
}

interface IBankBranchListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        bankBranches: IBankBranch[]
    }
}

export default class BankBranchService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IBankBranchResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/BankBranch/CreateBankBranch", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IBankBranchRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/BankBranch/UpdateBankBranch", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<IBankBranchResponse> {
        const { isSuccess }: any = await this.XHRService.post("/BankBranch/DeleteBankBranch", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            bankId: 0,
            countryId: 0,
            cityId: 0,
            districtId: 0,
            isActive,
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/BankBranch/GetBankBranches", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/BankBranch/GetBankBranchesExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/BankBranch/GetBankBranchById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
