import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

if (isMobile.iOS()) {
    window.location.replace('https://apps.apple.com/us/app/finstant/id1476070573');
} else if (isMobile.Android()) {
    window.location.replace('https://apps.apple.com/us/app/finstant/id1476070573');
} else {
    ReactDOM.render(<App />, document.getElementById('root'));
}