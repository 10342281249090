//Validation Methods
function emailValidation(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

//Remember


export const emailValidator = (email: string): string => {

    if (email.length < 6 || email.length > 25) return 'Kullanici adi en az 6 en fazla 25 karakter olabilir!'
    if (!emailValidation(email)) return 'Geçersiz bir E-posta Adresi girdiniz!'
    return '';
}