import XHRService from "./xhr.service";

export default interface IResourceTypeRequest {
    id?: number;
    resourceTypeName: string;
    isActive: boolean;
}


interface IResourceType {
    id: number;
    resourceTypeName: string;
    isActive: boolean;
}

interface IResourceTypeResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IResourceType
}

interface IResourceTypeListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        resourceTypes: IResourceType[]
    }
}

export default class ResourceTypeService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IResourceTypeResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/ResourceType/CreateResourceType", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IResourceTypeRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/ResourceType/UpdateResourceType", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorMessage, errorCode };
        return data;
    }

    public async delete(id: number): Promise<IResourceTypeResponse> {
        const { isSuccess }: any = await this.XHRService.post("/ResourceType/DeleteResourceType", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/ResourceType/GetResourceTypes", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/ResourceType/GetResourceTypesExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/ResourceType/GetResourceTypeById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
