import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { countryService } from '../../service';
import { COUNTRY } from '../../routes/paths';

import Error from '../NotFound/NotFound'

import { countryCodeValidator, countryNameValidator, countryPhoneCodeValidator } from '../../utils/country.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface ICountryFormState {
    countryName: string,
    countryNameError: string,
    countryCode: string,
    countryCodeError: string,
    countryPhoneCode: number,
    countryPhoneCodeError: string,
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface ICountryFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class CountryForm extends Component {
    public readonly state: ICountryFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            countryName: "",
            countryNameError: "",
            countryCode: "",
            countryCodeError: "",
            isActive: true,
            countryPhoneCode: 0,
            countryPhoneCodeError: "",
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeCountryName = this.onChangeCountryName.bind(this);
        this.onChangeCountryCode = this.onChangeCountryCode.bind(this);
        this.onChangeCountryPhoneCode = this.onChangeCountryPhoneCode.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }


    componentWillMount() {
        if (this.props.match.params.countryId) this.getEditableCountry();
        else this.setState({ loading: false });

        this.form = React.createRef();
    }

    private async getEditableCountry() {
        const { data, isSuccess, errorCode, errorMessage } = await countryService.getById(this.props.match.params.countryId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            const state = {
                ...data,
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });
        }
    }

    /** EVENT LISTENERS */

    private onChangeCountryName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ countryName: e.target.value, countryNameError: countryNameValidator(e.currentTarget.value) });
    }

    private onChangeCountryCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ countryCode: e.target.value, countryCodeError: countryCodeValidator(e.currentTarget.value) });
    }

    private onChangeCountryPhoneCode(e: ChangeEvent<HTMLInputElement>) {
        if (Number(e.target.value) || e.target.value == "") {
            this.setState({ countryPhoneCode: Number(e.target.value), countryPhoneCodeError: countryPhoneCodeValidator(String(e.target.value)) });
        }
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(COUNTRY);
    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { countryCode, countryName, countryPhoneCode } = this.state;

        //Submit Validation
        if (
            countryCodeValidator(countryCode) !== "" ||
            countryPhoneCodeValidator(String(countryPhoneCode)) !== "" ||
            countryNameValidator(countryName) !== ""

        ) {

            this.setState({
                countryCodeError: countryCodeValidator(countryCode),
                countryPhoneCodeError: countryPhoneCodeValidator(String(countryPhoneCode)),
                countryNameError: countryNameValidator(countryName)
            });
            return false;
        }

        return true;
    }


    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { countryName, countryCode, countryPhoneCode, isActive, isEdit } = this.state;

        if (this.formValidation()) {

            const model = {
                countryName: sqlInjectValidator(countryName),
                countryCode: sqlInjectValidator(countryCode),
                countryPhoneCode: sqlInjectValidator(String(countryPhoneCode)),
                isActive
            };


            if (isEdit) await this.editCountry(model);
            else await this.createCountry(model);
        }
    }

    private async createCountry(model: any) {
        const data = await countryService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editCountry(model: any) {
        const data = await countryService.update({ ...model, id: this.props.match.params.countryId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private renderCountryName() {
        const { countryName, countryNameError } = this.state;


        return (
            <FormControl margin="none" style={{ width: '98%' }} className="input">
                <TextField
                    id="countryName"
                    label="Ülke Adı"
                    placeholder="Ülke adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCountryName}
                    value={countryName}
                    name="countryName"
                    autoComplete="countryName"
                    helperText={countryNameError}
                />
            </FormControl>
        );
    }

    private renderCountryCode() {
        const { countryCode, countryCodeError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="countryCode"
                    label="Ülke Kodu"
                    placeholder="Ülke kodu yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCountryCode}
                    value={countryCode}
                    name="countryCode"
                    autoComplete="countryCode"
                    helperText={countryCodeError}
                />
            </FormControl>
        );
    }

    private renderCountryPhoneCode() {
        const { countryPhoneCode, countryPhoneCodeError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="countryPhoneCode"
                    label="Ülke Telefon Kodu"
                    placeholder="Ülke Telefon Kodu yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCountryPhoneCode}
                    value={countryPhoneCode != 0 ? countryPhoneCode : ""}
                    name="countryPhoneCode"
                    autoComplete="countryPhoneCode"
                    helperText={countryPhoneCodeError}
                />
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: COUNTRY, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Ülke {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan ülke bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda ülke ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Ülke {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderCountryName()}
                                    {this.renderCountryCode()}
                                    {this.renderCountryPhoneCode()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(CountryForm);
