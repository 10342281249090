import XHRService from "./xhr.service";

export default interface IBankFTPCredentialRequest {
    id?: number,
    bankId: number,
    bankAccountId: number,
    userName: string,
    password: string,
    posMerchantCode: string,
    companyId: number,
    isActive: boolean
}


interface IBankFTPCredential {
    id: number,
    bankId: number,
    bankAccountId: number,
    userName: string,
    password: string,
    posMerchantCode: string,
    companyId: number,
    isActive: boolean
}

interface IBankFTPCredentialResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IBankFTPCredential
}

interface IBankFTPCredentialListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        bankFTPCredentials: IBankFTPCredential[]
    }
}

export default class BankFTPCredentialService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IBankFTPCredentialResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/BankFTPCredential/CreateBankFTPCredential", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IBankFTPCredentialRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/BankFTPCredential/UpdateBankFTPCredential", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<IBankFTPCredentialResponse> {
        const { isSuccess }: any = await this.XHRService.post("/BankFTPCredential/DeleteBankFTPCredential", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            bankId: 0,
            bankAccountId: 0,
            userName: null,
            password: null,
            posMerchantCode: null,
            companyId: 0,
            isActive,
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/BankFTPCredential/GetBankFTPCredentials", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/BankFTPCredential/GetBankFTPCredentialsExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/BankFTPCredential/GetBankFTPCredentialById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
