import React, { Component } from 'react'
import RegisterForm from './RegisterForm';

export default class Register extends Component {
    public readonly props: any;

    constructor(props: any){
        super(props);

        this.props = props;
    }

    render() {
        let params = this.props.location.search.replace("?","").split("=");

        return (<RegisterForm params={params[0] === "Token" ? params[1] : null} />)
    }
}
