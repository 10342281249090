//Validation Methods
function emailValidation(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

//Login


export const passwordValidator = (password: string): string => {
    if (password.length < 6 || password.length > 16) return 'Sifre en az 6 en fazla 16 karakter olabilir.'
    return '';
}

export const passwordAgainValidator = (passwordAgain: string): string => {
    if (passwordAgain != passwordAgain) return 'Girdiğiniz şifreler birbiri ile uyuşmuyor.'
    return '';
}


export const emailValidator = (email: string): string => {

    if (email.length < 6) return 'Kullanici adi en az 6 karakter olabilir!'
    if (!emailValidation(email)) return 'Geçersiz bir E-posta Adresi girdiniz!'
    return '';
}