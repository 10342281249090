import XHRService from './xhr.service';
import UserService from './user.service';
import CompanyService from './company.service';
import BankService from './bank.service';
import BankBranchService from './bank.branch.service';
import BankAccountService from './bank.account.service';
import BankCredentialService from './bank.credential.service';
import BankFTPCredentialService from './bank.ftpcredential.service';
import CountryService from './country.service';
import CityService from './city.service';
import DistrictService from './district.service';
import CurrencyService from './currency.service';
import PermissionService from './permission.service';
import RoleService from './role.service';
import RegisterService from './register.service';
import { StorageTypes, StorageService, CookieService } from './storage.service';
import LocationService from './location.service';
import AccountStatementsService from './account.statement.service';
import PosStatementsService from './pos.statement.service';
import AccountBalancesService from './account.balances.service';
import LanguageService from './language.service';
import ResourceTypeService from './resource.type.service';
import ResourceService from './resource.service';
import PaymentService from './payment.service';
import BankAccountUserService from './bank.account.user.service';
import CompanyUserService from './company.user.service';
import UserPermissionService from './user.permission.service';
import RolePermissionService from './role.permission.service';
import NotificationService from './notification.service';
import InvoiceService from './invoice.service';
import PartnerService from './partner.service';

const xhrService = new XHRService();
const userService = new UserService(xhrService);
const companyService = new CompanyService(xhrService);
const bankService = new BankService(xhrService);
const bankBranchService = new BankBranchService(xhrService);
const bankAccountService = new BankAccountService(xhrService);
const bankCredentialService = new BankCredentialService(xhrService);
const bankFTPCredentialService = new BankFTPCredentialService(xhrService);
const countryService = new CountryService(xhrService);
const cityService = new CityService(xhrService);
const districtService = new DistrictService(xhrService);
const currencyService = new CurrencyService(xhrService);
const permissionService = new PermissionService(xhrService);
const roleService = new RoleService(xhrService);
const registerService = new RegisterService(xhrService);
const locationService = new LocationService(xhrService);
const accountStatementsService = new AccountStatementsService(xhrService);
const posStatementsService = new PosStatementsService(xhrService);
const accountBalancesService = new AccountBalancesService(xhrService);
const languageService = new LanguageService(xhrService);
const resourceTypeService = new ResourceTypeService(xhrService);
const resourceService = new ResourceService(xhrService);
const paymentService = new PaymentService(xhrService);
const bankAccountUserService = new BankAccountUserService(xhrService);
const companyUserService = new CompanyUserService(xhrService);
const userPermissionService = new UserPermissionService(xhrService);
const rolePermissionService = new RolePermissionService(xhrService);
const notificationService = new NotificationService(xhrService);
const invoiceService = new InvoiceService(xhrService);
const partnerService = new PartnerService(xhrService);


const localStorageService = new StorageService(StorageTypes.LocaleStorage);
const sessionStorageService = new StorageService(StorageTypes.LocaleStorage);
const cookieService = new CookieService();


export {
    xhrService,
    userService,
    companyService,
    bankService,
    bankBranchService,
    bankAccountService,
    bankCredentialService,
    bankFTPCredentialService,
    countryService,
    cityService,
    districtService,
    currencyService,
    permissionService,
    roleService,
    registerService,
    localStorageService,
    sessionStorageService,
    cookieService,
    accountStatementsService,
    posStatementsService,
    locationService,
    accountBalancesService,
    languageService,
    resourceTypeService,
    resourceService,
    paymentService,
    bankAccountUserService,
    companyUserService,
    userPermissionService,
    rolePermissionService,
    notificationService,
    invoiceService,
    partnerService,
}
