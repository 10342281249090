export type CurrencyId =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 24
export type CurrencySymbol = '₺' | '£' | '$' | '€' | ''
export type CurrencyCode =
  | 'TRY'
  | 'GBP'
  | 'USD'
  | 'EUR'
  | 'AUD'
  | 'CAD'
  | 'RUB'
  | 'CHF'
  | 'NOK'
  | 'SEK'
  | 'DKK'
  | 'JPY'
  | 'KWD'
  | 'BGN'
  | 'RON'
  | 'CNY'
  | 'PKR'
  | 'QAR'
  | 'SAR'
  | 'IRR'
  | 'AED'

export const currencyList: {
  id: CurrencyId
  symbol: CurrencySymbol
  code: CurrencyCode
}[] = [
  { id: 1, symbol: '₺', code: 'TRY' },
  { id: 2, symbol: '£', code: 'GBP' },
  { id: 3, symbol: '$', code: 'USD' },
  { id: 4, symbol: '€', code: 'EUR' },
  { id: 5, symbol: '', code: 'AUD' },
  { id: 6, symbol: '', code: 'CAD' },
  { id: 7, symbol: '', code: 'RUB' },
  { id: 8, symbol: '', code: 'CHF' },
  { id: 9, symbol: '', code: 'NOK' },
  { id: 10, symbol: '', code: 'SEK' },
  { id: 11, symbol: '', code: 'DKK' },
  { id: 12, symbol: '', code: 'JPY' },
  { id: 13, symbol: '', code: 'KWD' },
  { id: 14, symbol: '', code: 'BGN' },
  { id: 15, symbol: '', code: 'RON' },
  { id: 16, symbol: '', code: 'CNY' },
  { id: 17, symbol: '', code: 'PKR' },
  { id: 18, symbol: '', code: 'QAR' },
  { id: 19, symbol: '', code: 'SAR' },
  { id: 20, symbol: '', code: 'IRR' },
  { id: 24, symbol: '', code: 'AED' },
]

export const priceFormatter = (
  price: number,
  currencyId: CurrencyId = 1,
  withNoSymbol = false
): string => {
  const currentItem = currencyList.find((item) => item.id === currencyId)!
  const currencySymbol: CurrencySymbol | CurrencyCode =
    currentItem.symbol || currentItem.code

  let [flatPart, decimalPart] = `${Number(price).toFixed(2)}`.split('.')
  const reversedPrice = flatPart.split('').reverse()

  if (!decimalPart) decimalPart = '00'
  if (decimalPart.length === 1) decimalPart = `${decimalPart}0`

  const withThousand = reversedPrice
    .map((number, index) => {
      const order = index + 1

      // we don't want to add "." when it's last number of the flatPart.
      if (order % 3 === 0 && order !== flatPart.length) {
        return `.${number}`
      } else {
        return number
      }
    })
    .reverse()
    .join('')

  if (withNoSymbol) {
    return `${withThousand},${decimalPart}`
  }

  return `${withThousand},${decimalPart} ${currencySymbol}`
}
