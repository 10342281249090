import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { currencyService } from '../../service';
import { CURRENCY } from '../../routes/paths';
import { Typography } from '@material-ui/core';

import Error from '../NotFound/NotFound'


import { currencyCodeValidator, currencyNameValidator } from '../../utils/currency.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface ICurrencyFormState {
    currencyName: string,
    currencyNameError: string,
    currencyCode: string,
    currencyCodeError: string,
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface ICurrencyFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class CurrencyForm extends Component {
    public readonly state: ICurrencyFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            currencyName: "",
            currencyNameError: "",
            currencyCode: "",
            currencyCodeError: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeCurrencyName = this.onChangeCurrencyName.bind(this);
        this.onChangeCurrencyCode = this.onChangeCurrencyCode.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }


    componentWillMount() {
        if (this.props.match.params.currencyId) this.getEditableCurrency();
        else this.setState({ loading: false });

        this.form = React.createRef();
    }

    private async getEditableCurrency() {
        const { data, isSuccess, errorCode, errorMessage } = await currencyService.getById(this.props.match.params.currencyId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            const state = {
                ...data,
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });
        }
    }

    /** EVENT LISTENERS */

    private onChangeCurrencyName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ currencyName: e.target.value, currencyNameError: currencyNameValidator(e.currentTarget.value) });
    }

    private onChangeCurrencyCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ currencyCode: e.target.value, currencyCodeError: currencyCodeValidator(e.target.value) });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(CURRENCY);
    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { currencyCode, currencyName } = this.state;

        //Submit Validation
        if (
            currencyNameValidator(currencyName) !== "" ||
            currencyCodeValidator(currencyCode) !== ""

        ) {

            this.setState({
                currencyNameError: currencyNameValidator(currencyName),
                currencyCodeError: currencyCodeValidator(currencyCode)
            });
            return false;
        }

        return true;
    }


    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { currencyName, currencyCode, isActive, isEdit } = this.state;

        const model = {
            currencyName: sqlInjectValidator(currencyName),
            currencyCode: sqlInjectValidator(currencyCode),
            isActive
        };

        if (this.formValidation()) {
            if (isEdit) await this.editCurrency(model);
            else await this.createCurrency(model);
        }
    }

    private async createCurrency(model: any) {
        const data = await currencyService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editCurrency(model: any) {
        const data = await currencyService.update({ ...model, id: this.props.match.params.currencyId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private renderCurrencyName() {
        const { currencyName, currencyNameError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="currencyName"
                    label="Para Birimi Adı"
                    placeholder="Para Birimi adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCurrencyName}
                    value={currencyName}
                    name="currencyName"
                    autoComplete="currencyName"
                    helperText={currencyNameError}
                />
            </FormControl>
        );
    }

    private renderCurrencyCode() {
        const { currencyCode, currencyCodeError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="currencyCode"
                    label="Para Birimi Kodu"
                    placeholder="Para Birimi kodu yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCurrencyCode}
                    value={currencyCode}
                    name="currencyCode"
                    autoComplete="currencyCode"
                    helperText={currencyCodeError}
                />
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage, isEdit } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: CURRENCY, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Para Birimi {isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {isEdit ? "Bu ekrandan para birimi bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda para birimi ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Para Birimi {isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderCurrencyName()}
                                    {this.renderCurrencyCode()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(CurrencyForm);
