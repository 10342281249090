import XHRService from "./xhr.service";
import { cookieService } from './';

export default interface IUserRequest {
    id?: number;
    companyId: string;
    nameSurname: string;
    emailAddress: string;
    password: string;
    mobilePhoneNumber: string;
    isActive: boolean;
}

interface IUser {
    id: number;
    companyId: string;
    companyName: string;
    nameSurname: string;
    emailAddress: string;
    password: string;
    mobilePhoneNumber: string;
    isActive: boolean;
}

interface IUserResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IUser
}

interface IUserListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        users: IUser[]
    }
}

interface IUserRegistrationComplate{
    nameSurname: string,
    emailAddress: string,
    password: string,
    mobilePhoneNumber: string,
    isKullaniciSozlesmesiAccepted: boolean,
    isAydinlatmaMetniAccepted: boolean,
    isAcikRizaMetniAccepted: boolean,
    token: string
  }

export default class UserService {
    private readonly XHRService: XHRService;
    public readonly COOKIE_NAME = 'USER_TOKEN';
    public readonly COOKIE_USERNAME = 'USER_NAME';
    public readonly COOKIE_USEREMAIL = 'USER_EMAIL';
    public readonly LANGUAGE_NAME = 'LANGUAGE_TOKEN';

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async login(user: { emailAddress: string, password: string, clientType: string, partnerCompanyCode: string }): Promise<{ isSuccess: boolean }> {
        const { token, isSuccess }: any = await this.XHRService.post("/login/LoginUser", user).catch(() => ({ isSuccess: false }));
        if (isSuccess) {
            cookieService.setItem(this.COOKIE_NAME, token);
            const {data} = await this.permission();
            cookieService.setItem(this.COOKIE_USERNAME, data.nameSurname);
            cookieService.setItem(this.COOKIE_USEREMAIL, user.emailAddress);
        }
        return { isSuccess };
    }

    public async permission(): Promise<any> {
        return await this.XHRService.get("/Login/GetUserDetails").catch(() => ({ isSuccess: false }));
    }

    public async create(model: any): Promise<IUserResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/User/CreateUser", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorMessage, errorCode };
        return data;
    }

    public async createMultiple(model: any): Promise<IUserResponse> {
        const { isSuccess }: any = await this.XHRService.post("/User/CreateMultipleUser", model).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async update(model: IUserRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/User/UpdateUser", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async reset(model: any): Promise<any> {
        return await this.XHRService.post("/Login/UpdatePassword", model).catch((e) => ({ isSuccess: false }));
    }

    public async delete(id: number): Promise<IUserResponse> {
        const { isSuccess }: any = await this.XHRService.post("/User/DeleteUser", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            companyId: 0,
            password: null,
            isActive,
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/User/GetUsers", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/User/GetUsersExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/User/GetUserById?id=${id}`).catch(() => ({ isSuccess: false }));
    }

    public async getUserByToken(token: string): Promise<any> {
        return await this.XHRService.get(`/User/GetUserByToken?Token=${token}`).catch(() => ({ isSuccess: false }));
    }

    public async registrationComplate(model: IUserRegistrationComplate): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/User/RegistrationComplete", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorMessage, errorCode };
        return data;
    }

    public getToken() {
        return `Bearer ${cookieService.getItem(this.COOKIE_NAME)}`;
    }

    public token() {
        return cookieService.getItem(this.COOKIE_NAME);
    }

    public logout() {
        cookieService.removeItem(this.COOKIE_NAME);
    }

    public isAuth() {
        return !!cookieService.getItem(this.COOKIE_NAME);
    }

    public isLanguage() {
        return !!cookieService.getItem(this.LANGUAGE_NAME);
    }
}
