import XHRService from "./xhr.service";

export default interface ICityRequest {
    id?: number;
    cityName: string;
    countryId: number;
    isActive: boolean;
}


interface ICity {
    id: number;
    cityName: string;
    countryId: number;
    isActive: boolean;
}

interface ICityResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: ICity
}

interface ICityListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        companies: ICity[]
    }
}

export default class CityService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<ICityResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/City/CreateCity", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: ICityRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/City/UpdateCity", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<ICityResponse> {
        const { isSuccess }: any = await this.XHRService.post("/City/DeleteCity", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/City/GetCitiesExcel", model);
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            countryId: 0,
            isActive,
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/City/GetCities", model).catch(() => ({ isSuccess: false }));
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/City/GetCityById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
