import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../layout/Wrapper'
import moment from 'moment'

import { Snackbar } from '@material/react-snackbar'
import '@material/react-snackbar/dist/snackbar.css'

import { companyService, locationService } from '../../service'
import { COMPANY } from '../../routes/paths'
import CustomSelect from '../layout/CustomSelect'

import {
  companyNameValidator,
  vatNumberValidator,
  employeeValidator,
  countryValidator,
  cityValidator,
  districtValidator,
  addressValidator,
} from '../../utils/company.validators'
import { sqlInjectValidator } from '../../utils/sql.inject.validators'

export interface ICompanyFormState {
  companyName: string
  companyNameError: string
  vatNumber: string
  vatNumberError: string
  employee: any
  employeeNumber: string
  employeeError: string
  address: string
  addressError: string
  isActive: boolean
  countryList: []
  countryId: any
  countryError: string
  cityList: []
  cityId: any
  cityError: any
  districtList: []
  districtId: any
  districtError: string
  isEdit: boolean
  loading: boolean
  isSuccess: string
  errorCode: string
  errorMessage: string
}

const styles = (theme: any) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
})

export interface ICompanyFormProps {
  classes: any
}

class CompanyForm extends Component {
  public readonly state: ICompanyFormState
  public readonly props: any
  private form: any

  constructor(props: any) {
    super(props)

    this.props = props

    this.state = {
      companyName: '',
      companyNameError: '',
      vatNumber: '',
      vatNumberError: '',
      employee: [
        {
          employeeNumber: '1-5 Kişi',
        },
        {
          employeeNumber: '5-10 Kişi',
        },
        {
          employeeNumber: '10-25 Kişi',
        },
        {
          employeeNumber: '25-100 Kişi',
        },
        {
          employeeNumber: '100+ Kişi',
        },
      ],
      employeeNumber: '',
      employeeError: '',
      address: '',
      addressError: '',
      isActive: true,
      countryList: [],
      countryId: 0,
      countryError: '',
      cityList: [],
      cityId: 0,
      cityError: '',
      districtList: [],
      districtId: 0,
      districtError: '',
      isEdit: false,
      loading: true,
      isSuccess: '',
      errorCode: '',
      errorMessage: '',
    }

    this.onChangeCompanyName = this.onChangeCompanyName.bind(this)
    this.onChangeVatNumber = this.onChangeVatNumber.bind(this)
    this.onChangeEmployee = this.onChangeEmployee.bind(this)
    this.onChangeCountry = this.onChangeCountry.bind(this)
    this.onChangeCity = this.onChangeCity.bind(this)
    this.onChangeDistrict = this.onChangeDistrict.bind(this)
    this.onChangeAddress = this.onChangeAddress.bind(this)
    this.onChangeStatus = this.onChangeStatus.bind(this)
    this.cancel = this.cancel.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
  }

  componentWillMount() {
    if (this.props.match.params.companyId) this.getEditableCompany()
    else {
      this.getCountries(0)
      this.setState({ loading: false })
    }

    this.form = React.createRef()
  }

  private async getEditableCompany() {
    const { data } = await companyService.getById(
      this.props.match.params.companyId
    )

    await this.getCountries(data.countryId)
    const state = {
      ...data,
      isEdit: true,
      expireDate: moment(data.expireDate).format('YYYY-MM-DD'),
      countryId: this.state.countryList.find(
        (item: any) => item.id === data.countryId
      ),
      cityId: this.state.cityList.find((item: any) => item.id === data.cityId),
    }

    this.setState({ ...state, loading: false })
    if (data.cityId) {
      await this.getDistricts(state.countryId.id, state.cityId.id)
      this.setState({
        districtId: this.state.districtList.find(
          (item: any) => item.id === data.districtId
        ),
        loading: false,
      })
    }
  }

  /** EVENT LISTENERS */

  private onChangeCompanyName(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      companyName: e.currentTarget.value,
      companyNameError: companyNameValidator(e.currentTarget.value),
    })
  }

  private onChangeVatNumber(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      vatNumber: e.currentTarget.value,
      vatNumberError: vatNumberValidator(e.currentTarget.value),
    })
  }

  private onChangeEmployee(val: any) {
    if (val) {
      this.setState({
        employeeNumber: val.employeeNumber,
        employeeError: employeeValidator(val),
      })
    }
  }

  private onChangeCountry(countryId: any) {
    this.setState({
      countryId,
      countryError: countryValidator(countryId),
      cityId: 0,
      districtId: 0,
    })
    this.getCities(countryId.id)
  }

  private onChangeCity(cityId: any) {
    this.setState({ cityId, cityError: cityValidator(cityId), districtId: 0 })
    this.getDistricts(this.state.countryId.id, cityId.id)
  }

  private onChangeDistrict(districtId: any) {
    this.setState({ districtId, districtError: districtValidator(districtId) })
  }

  private onChangeAddress(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      address: e.currentTarget.value,
      addressError: addressValidator(e.currentTarget.value),
    })
  }

  private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
    this.setState({ isActive: e.currentTarget.checked })
  }

  /** VIEW RENDER */

  private async cancel() {
    this.props.history.push(COMPANY)
  }

  private async getCountries(countryId?: number) {
    const { data } = await locationService.getCountries()

    const state = {
      countryId:
        this.state.countryId === 0 ? countryId : this.state.countryId.id,
      countryList: data.countries,
      loading: false,
    }

    this.setState(state)

    if (this.props.match.params.companyId) await this.getCities(state.countryId)
  }

  private async getCities(countryId: number) {
    const { data } = await locationService.getCities(countryId)

    const state = {
      cityList: data.cities,
      cityId: this.state.cityId === 0 ? 0 : this.state.cityId.id,
    }

    this.setState(state)

    if (this.props.match.params.companyId)
      await this.getDistricts(countryId, state.cityId)
  }

  private async getDistricts(countryId: number, cityId: number) {
    const { data } = await locationService.getDistricts(countryId, cityId)

    const state = {
      districtList: data.districts,
      districtId: this.state.districtId === 0 ? 0 : this.state.districtId.id,
    }

    this.setState(state)
  }

  private getErrorMessage() {
    const { errorCode, errorMessage } = this.state
    return this.state.errorMessage !== '' ? (
      <Redirect
        to={{
          pathname: COMPANY,
          state: { errorMessage: this.state.errorMessage },
        }}
      />
    ) : null
  }

  private formValidation() {
    const {
      companyName,
      vatNumber,
      address,
      employeeNumber,
      countryId,
      cityId,
      districtId,
    } = this.state

    if (
      companyNameValidator(companyName) !== '' ||
      vatNumberValidator(vatNumber) !== '' ||
      employeeValidator(employeeNumber) !== '' ||
      countryValidator(countryId) !== '' ||
      cityValidator(cityId) !== '' ||
      districtValidator(districtId) !== '' ||
      addressValidator(address) !== '' /*||
            partnerValidator(partnerId) !== ""*/
    ) {
      this.setState({
        companyNameError: companyNameValidator(companyName),
        vatNumberError: vatNumberValidator(vatNumber),
        employeeError: employeeValidator(employeeNumber),
        countryError: countryValidator(countryId),
        cityError: cityValidator(cityId),
        districtError: districtValidator(districtId),
        addressError: addressValidator(address) /*,
                partnerError: partnerValidator(partnerId)*/,
      })
      return false
    }

    return true
  }

  private async saveChanges() {
    this.setState({ isSuccess: '', errorCode: '', errorMessage: '' })

    const {
      companyName,
      vatNumber,
      address,
      employeeNumber,
      countryId,
      cityId,
      districtId,
      isActive,
      isEdit,
    } = this.state

    if (this.formValidation()) {
      const model = {
        companyName: sqlInjectValidator(companyName),
        vatNumber: sqlInjectValidator(vatNumber),
        employeeNumber: sqlInjectValidator(employeeNumber),
        countryId: sqlInjectValidator(String(countryId.id)),
        cityId: sqlInjectValidator(String(cityId.id)),
        districtId: sqlInjectValidator(String(districtId.id)),
        address: sqlInjectValidator(address),
        isActive,
      }

      if (isEdit) await this.editCompany(model)
      else await this.createCompany(model)
    }
  }

  private async createCompany(model: any) {
    const data = await companyService.create(model)
    this.setState({
      isSuccess: data.isSuccess ? 'success' : 'error',
      errorCode: data.errorCode,
      errorMessage: data.errorMessage,
    })
  }

  private async editCompany(model: any) {
    const data = await companyService.update({
      ...model,
      id: this.props.match.params.companyId,
    })
    this.setState({
      isSuccess: data.isSuccess ? 'success' : 'error',
      errorCode: data.errorCode,
      errorMessage: data.errorMessage,
    })
  }

  private renderCompanyName() {
    const { companyName, companyNameError } = this.state

    return (
      <FormControl margin='none' className='input'>
        <TextField
          id='companyName'
          label='Firma Adı'
          placeholder='Firma Adı yazınız'
          margin='normal'
          variant='outlined'
          onChange={this.onChangeCompanyName}
          value={companyName}
          name='companyName'
          autoComplete='companyName'
          helperText={companyNameError}
        />
      </FormControl>
    )
  }
  private renderVatNumber() {
    const { vatNumber, vatNumberError } = this.state

    return (
      <FormControl margin='none' className='input'>
        <TextField
          id='vatnumber'
          label='Vergi Numarası'
          placeholder='Vergi Numarası yazınız'
          margin='normal'
          variant='outlined'
          onChange={this.onChangeVatNumber}
          value={vatNumber}
          name='vatnumber'
          autoComplete='vatnumber'
          helperText={vatNumberError}
        />
      </FormControl>
    )
  }

  private renderEmployeeLimit() {
    const { employeeNumber, employee, employeeError } = this.state
    return (
      <FormControl
        margin='none'
        variant='outlined'
        className='input inputSelect'
      >
        <CustomSelect
          labelName='employeeNumber'
          valueKey='employeeNumber'
          options={employee}
          onChange={this.onChangeEmployee}
          placeholder={
            employeeNumber === '' ? 'Çalışan Sayısı' : employeeNumber
          }
        ></CustomSelect>
        <span className='errorMessage'>{employeeError}</span>
      </FormControl>
    )
  }

  private renderCountries() {
    const { countryId, countryList, countryError } = this.state

    return (
      <FormControl
        margin='none'
        variant='outlined'
        className='input inputSelect'
      >
        <CustomSelect
          labelName='countryName'
          value={countryId}
          valueKey='id'
          options={countryList}
          onChange={this.onChangeCountry}
          placeholder='Ülke *'
        ></CustomSelect>
        <span className='errorMessage'>{countryError}</span>
      </FormControl>
    )
  }

  private renderCities() {
    const { cityId, cityList, cityError, countryId } = this.state

    return (
      <FormControl
        margin='none'
        variant='outlined'
        className='input inputSelect'
      >
        <CustomSelect
          labelName='cityName'
          value={cityId}
          valueKey='id'
          options={cityList}
          onChange={this.onChangeCity}
          placeholder='Şehir *'
        ></CustomSelect>
        <span className='errorMessage'>{cityError}</span>
      </FormControl>
    )
  }

  private renderDistricts() {
    const { districtId, districtList, districtError } = this.state

    return (
      <FormControl
        margin='none'
        variant='outlined'
        className='input inputSelect'
      >
        <CustomSelect
          labelName='districtName'
          value={districtId}
          valueKey='id'
          options={districtList}
          onChange={this.onChangeDistrict}
          placeholder='İlçe *'
        ></CustomSelect>
        <span className='errorMessage'>{districtError}</span>
      </FormControl>
    )
  }

  private renderAddress() {
    const { address, addressError } = this.state

    return (
      <FormControl margin='none' style={{ width: '98%', marginLeft: '1%' }}>
        <TextField
          id='address'
          label='Adres'
          placeholder='Adres yazınız'
          margin='normal'
          variant='outlined'
          multiline
          onChange={this.onChangeAddress}
          value={address}
          name='address'
          autoComplete='address'
          rows={6}
          helperText={addressError}
          className='multiInput'
        />
      </FormControl>
    )
  }

  private renderStatus() {
    const { isActive } = this.state

    return (
      <FormControlLabel
        control={
          <Switch
            value='isActive'
            className={isActive ? 'select active' : 'select passive'}
            onChange={this.onChangeStatus}
            checked={isActive}
          />
        }
        label={isActive ? 'Aktif' : 'Pasif'}
      />
    )
  }

  private renderMessage() {
    return (
      <Snackbar
        message={
          this.state.errorMessage +
          ' (Error Code : ' +
          this.state.errorCode +
          ')'
        }
        timeoutMs={5000}
        className='messageBoxError'
      />
    )
  }

  public render() {
    const { loading, isSuccess, errorCode, errorMessage } = this.state
    if (isSuccess === 'error') this.renderMessage()

    return (
      <Wrapper>
        {isSuccess === 'success' ? (
          <Redirect
            to={{ pathname: COMPANY, state: { errorMessage: errorMessage } }}
          />
        ) : null}
        <Grid container id='Form'>
          {loading && <CircularProgress className='progress' />}
          {!loading && errorCode === '' ? (
            <>
              <Grid item xs={12} md={3} className='sidebar'>
                {this.state.isEdit ? (
                  <Icon className='fas fa-edit'></Icon>
                ) : (
                  <Icon className='fas fa-plus'></Icon>
                )}
                <Typography variant='h3'>
                  Firma {this.state.isEdit ? 'Düzenle' : 'Ekle'}
                </Typography>
                <p>
                  {this.state.isEdit
                    ? 'Bu ekrandan firma bilgilerini düzenleyebilirsiniz.'
                    : 'Bu ekrandan sınırsız sayıda firma ekleme özgürlüğüne sahipsiniz.'}
                </p>
              </Grid>
              <Grid item xs={12} md={9} className='form'>
                <form ref={this.form} onSubmit={(e) => e.preventDefault()}>
                  <Typography variant='h2'>
                    Firma {this.state.isEdit ? 'Düzenleme' : 'Ekleme'} Formu
                  </Typography>
                  {this.renderCompanyName()}
                  {this.renderVatNumber()}
                  {this.renderEmployeeLimit()}
                  {this.renderCountries()}
                  {this.renderCities()}
                  {this.renderDistricts()}
                  {this.renderAddress()}

                  {this.renderStatus()}

                  <Button
                    type='submit'
                    id='createButton'
                    onClick={this.saveChanges}
                  >
                    KAYDET
                  </Button>
                  <Button onClick={this.cancel}>VAZGEÇ</Button>
                </form>
              </Grid>
            </>
          ) : (
            this.getErrorMessage()
          )}
        </Grid>
      </Wrapper>
    )
  }
}
export default withStyles(styles)(CompanyForm)
