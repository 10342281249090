import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { GridSize } from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Wrapper from '../layout/Wrapper'
import moment from 'moment'
import 'moment/locale/tr'
import { accountBalancesService } from '../../service'
import {
  CurrencyCode,
  CurrencyId,
  priceFormatter,
} from '../../helpers/priceFormatter'
import { CircularProgress } from '@material-ui/core'

moment.locale('tr')

interface BalanceHistory {
  companyName: string
  companyId: number
  balance: number
  bankAccountCode: string
  bankAccountId: number
  bankAccountName: string
  bankAccountNo: string
  bankBranchCode: string
  bankId: number
  bankName: string
  createDateTime: string
  currencyCode: CurrencyCode
  currencyId: CurrencyId
  currencyName: string
  iban: string
  id: number
  isActive: boolean
}

interface BalanceHistoryResponse {
  accountBalanceHistories: BalanceHistory[]
}

interface BalanceHistoryForList {
  companyId: number
  companyName: string
  list: BalanceHistory[]
}

const headCommon = {
  padding: '8px 0',
  fontWeight: 600,
  fontSize: '13.5px',
}

const midHeadingCommon = {
  ...headCommon,
  fontSize: '14px',
}

const classes: { [key: string]: React.CSSProperties } = {
  bankcard: {
    margin: '1%',
    width: '23%',
  },
  accordionDetail: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  borderBottom: {
    borderBottom: '1px solid #888',
  },
  dateContainer: {
    borderBottom: '1px solid #888',
    fontWeight: 600,
  },
  heading: {
    fontSize: 14,
  },
  head: {
    ...headCommon,
  },
  headFirst: {
    ...headCommon,
    paddingLeft: '8px',
  },
  currencyTotal: {
    fontWeight: 600,
    fontStyle: 'italic',
    padding: '8px 0',
  },
  midHeadingCenter: {
    ...midHeadingCommon,
    textAlign: 'center',
    background: '#99bef8',
    color: '#444',
  },
  midHeading: {
    ...midHeadingCommon,
    paddingLeft: '8px',
    background: '#f3f3f3',
  },
  normalCell: {
    fontWeight: 500,
    padding: '8px 0',
  },
  cellFirst: {
    fontWeight: 500,
    padding: '8px 0 8px 8px',
  },
}

export interface ICompanyFormState {
  loading: boolean
  balanceHistoryList: BalanceHistoryForList[]
  createDateTimeList: string[]
  expandedCompany: number
}

export interface ICompanyFormProps {
  classes: any
}

class BalanceAnalysis extends Component {
  public readonly state: ICompanyFormState
  public readonly props: any

  constructor(props: any) {
    super(props)

    this.props = props

    this.state = {
      loading: false,
      balanceHistoryList: [],
      createDateTimeList: [],
      expandedCompany: 0,
    }

    this.getBalanceHistory = this.getBalanceHistory.bind(this)
  }

  componentWillMount() {
    this.getBalanceHistory()
  }

  seperateByKey<T extends keyof BalanceHistory, K extends keyof BalanceHistory>(
    list: BalanceHistory[],
    idKey: T,
    nameKey: K
  ) {
    const transformedList = list.reduce((acc: any, curr) => {
      const idKeyValue = curr[idKey]
      const nameKeyValue = curr[nameKey]

      const isInList = acc.find((item: any) => item[idKey] === idKeyValue)

      if (!isInList) {
        acc = [
          ...acc,
          { [idKey]: idKeyValue, [nameKey]: nameKeyValue, list: [curr] },
        ]
      } else {
        acc = acc.map((item: any) =>
          item[idKey] === idKeyValue
            ? { ...item, list: [...item.list, curr] }
            : item
        )
      }

      return acc
    }, [])

    return transformedList
  }

  getUniqueDateTimeList(data: BalanceHistory[]) {
    const uniqueDateList = data
      .reduce((acc: string[], curr) => {
        const currentDateTime = curr.createDateTime.split(' ')[0]

        const isInList = acc.includes(currentDateTime)

        if (!isInList) {
          acc = [...acc, currentDateTime]
        }

        return acc
      }, [])
      .sort((a, b) => moment(b).valueOf() - moment(a).valueOf())

    return uniqueDateList
  }

  getGridColList(length: number): GridSize[] {
    if (length === 4) {
      return [4, 2, 2, 2, 2]
    }
    if (length > 5) {
      const allAuto = Array.from({ length: length + 1 }, () => 'auto' as 'auto')
      return allAuto
    }

    const defaultValue = Array.from({ length: length + 1 }, () => {
      return Math.floor(12 / (length + 1))
    }) as GridSize[]

    return defaultValue
  }

  sortWithDateTime(data: BalanceHistory[]) {
    const listByAccount = this.seperateByKey(
      data,
      'bankAccountId',
      'bankAccountName'
    ) as {
      bankAccountId: number
      bankAccountName: string
      list: BalanceHistory[]
    }[]

    const { createDateTimeList } = this.state

    const transformedList = listByAccount.map((item) => {
      const { list } = item

      const newList = Array.from(createDateTimeList, (v, i) => {
        const accountWithThisDateTime = list.find(
          (item) => item.createDateTime.split(' ')[0] === v
        )

        if (accountWithThisDateTime) {
          return { createDateTime: v, balance: accountWithThisDateTime.balance }
        } else {
          return { createDateTime: v, balance: 0 }
        }
      }).sort(
        (a, b) =>
          moment(b.createDateTime).valueOf() -
          moment(a.createDateTime).valueOf()
      )

      return { ...item, list: newList }
    })

    return transformedList
  }

  getTotalsForDate(data: BalanceHistory[]) {
    const { createDateTimeList } = this.state

    const totalsForDate = Array.from(createDateTimeList, (v, i) => {
      const total = data.reduce((acc, curr) => {
        if (curr.createDateTime.split(' ')[0] === v) {
          acc += curr.balance
        }

        return acc
      }, 0)

      return { createDateTime: v, total }
    }).sort(
      (a, b) =>
        moment(b.createDateTime).valueOf() - moment(a.createDateTime).valueOf()
    )

    return totalsForDate
  }

  async getBalanceHistory() {
    this.setState({ loading: true })

    const data = (
      await accountBalancesService.getAccountBalanceHistoriesReport()
    ).data as BalanceHistoryResponse

    // console.log('data', data)

    const balanceHistoryList = this.seperateByKey(
      data.accountBalanceHistories,
      'companyId',
      'companyName'
    )

    const createDateTimeList = this.getUniqueDateTimeList(
      data.accountBalanceHistories
    )

    this.setState({
      loading: false,
      balanceHistoryList,
      createDateTimeList,
      expandedCompany: balanceHistoryList[0] && balanceHistoryList[0].companyId,
    })
  }

  renderDatesRow() {
    const { createDateTimeList } = this.state

    const colList = this.getGridColList(createDateTimeList.length)!

    return (
      <Grid container style={classes.dateContainer}>
        <Grid item xs={colList[0]} />
        {createDateTimeList.map((date, i) => {
          return (
            <Grid item xs={colList[i + 1]}>
              <div style={classes.head}>{date}</div>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  renderExpansionPanels() {
    const { expandedCompany, balanceHistoryList, createDateTimeList } =
      this.state

    // console.log('balanceHistoryList', balanceHistoryList)

    const colList = this.getGridColList(createDateTimeList.length)!

    return balanceHistoryList.map(({ companyId, companyName, list }) => {
      return (
        <ExpansionPanel
          expanded={expandedCompany === companyId}
          key={companyId}
          onChange={() => this.setState({ expandedCompany: companyId })}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={classes.heading}>{companyName}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={classes.accordionDetail}>
            {this.renderDatesRow()}
            {this.seperateByKey(list, 'bankId', 'bankName').map(
              ({ bankName, bankId, list }: any) => {
                return (
                  <>
                    <Grid container style={classes.borderBottom}>
                      <Grid item xs={12}>
                        <div style={classes.midHeadingCenter}>{bankName}</div>
                      </Grid>
                    </Grid>

                    {this.seperateByKey(list, 'currencyId', 'currencyCode').map(
                      ({ currencyId, currencyCode, list }: any) => {
                        // console.log('listByCurrency', list)

                        const listByDateTime = this.sortWithDateTime(list)

                        const totalsForDate = this.getTotalsForDate(list)
                        return (
                          <>
                            <Grid container style={classes.borderBottom}>
                              <Grid item xs={12}>
                                <div style={classes.midHeading}>
                                  {currencyCode}
                                </div>
                              </Grid>
                            </Grid>

                            <Grid container style={classes.borderBottom}>
                              {/* Olmayan dateTime'lar da üretilecek şekilde listByCurrency listesi güncellenecek. */}
                              {listByDateTime.map(
                                ({ bankAccountName, bankAccountId, list }) => {
                                  return (
                                    <>
                                      <Grid item xs={colList[0]}>
                                        <div style={classes.cellFirst}>
                                          {bankAccountName}
                                        </div>
                                      </Grid>
                                      {list.map(
                                        ({ createDateTime, balance }, i) => {
                                          return (
                                            <Grid item xs={colList[i + 1]}>
                                              <div style={classes.normalCell}>
                                                {balance === 0
                                                  ? '-'
                                                  : priceFormatter(
                                                      balance,
                                                      currencyId
                                                    )}
                                              </div>
                                            </Grid>
                                          )
                                        }
                                      )}
                                    </>
                                  )
                                }
                              )}
                            </Grid>
                            <Grid container style={classes.borderBottom}>
                              <Grid item xs={colList[0]}>
                                <div style={classes.headFirst}>Toplam</div>
                              </Grid>
                              {totalsForDate.map(({ total }, i) => {
                                return (
                                  <Grid item xs={colList[i + 1]}>
                                    <div style={classes.currencyTotal}>
                                      {total === 0
                                        ? '-'
                                        : priceFormatter(total, currencyId)}
                                    </div>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          </>
                        )
                      }
                    )}
                  </>
                )
              }
            )}

            {/* Şirketlerdeki Toplam Bakiye */}
            <Grid container style={{ height: 36 }} />
            {this.renderDatesRow()}
            <Grid container style={classes.borderBottom}>
              <Grid item xs={12}>
                <div
                  style={{ ...classes.midHeadingCenter, background: '#aab0f5' }}
                >
                  Toplam Bakiye
                </div>
              </Grid>
            </Grid>
            {this.seperateByKey(list, 'currencyId', 'currencyCode').map(
              ({ currencyId, currencyCode, list }: any) => {
                const totalsForDate = this.getTotalsForDate(list)

                return (
                  <Grid container style={classes.borderBottom}>
                    <Grid item xs={colList[0]}>
                      <div
                        style={classes.headFirst}
                      >{`Toplam ${currencyCode}`}</div>
                    </Grid>
                    {totalsForDate.map(({ total }, i) => {
                      return (
                        <Grid item xs={colList[i + 1]}>
                          <div style={classes.currencyTotal}>
                            {total === 0
                              ? '-'
                              : priceFormatter(total, currencyId)}
                          </div>
                        </Grid>
                      )
                    })}
                  </Grid>
                )
              }
            )}

            {/* Şirketlerdeki Toplam Bakiye Değişimi */}
            <Grid container style={classes.borderBottom}>
              <Grid item xs={12}>
                <div
                  style={{ ...classes.midHeadingCenter, background: '#aab0f5' }}
                >
                  Toplam Bakiye Değişimi
                </div>
              </Grid>
            </Grid>
            {this.seperateByKey(list, 'currencyId', 'currencyCode').map(
              ({ currencyId, currencyCode, list }: any) => {
                const totalsForDate = this.getTotalsForDate(list)
                const listLength = totalsForDate.length

                const differencesByDayBefore = Array.from(
                  { length: listLength },
                  (v, i) => {
                    if (i === listLength - 1) {
                      return 0
                    } else {
                      return totalsForDate[i].total - totalsForDate[i + 1].total
                    }
                  }
                )
                return (
                  <Grid container style={classes.borderBottom}>
                    <Grid item xs={colList[0]}>
                      <div
                        style={classes.headFirst}
                      >{`Toplam ${currencyCode}`}</div>
                    </Grid>
                    {differencesByDayBefore.map((difference, i) => {
                      return (
                        <Grid item xs={colList[i + 1]}>
                          <div style={classes.currencyTotal}>
                            {difference === 0
                              ? '-'
                              : priceFormatter(difference, currencyId)}
                          </div>
                        </Grid>
                      )
                    })}
                  </Grid>
                )
              }
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
    })
  }

  render() {
    return (
      <Wrapper>
        {this.state.loading ? (
          <CircularProgress className='progress' />
        ) : (
          <div id='Accordion'>
            <Typography variant='h5' component='h5'>
              Şirketlere Göre Bakiye Analizleri
            </Typography>
            {this.renderExpansionPanels()}
          </div>
        )}
      </Wrapper>
    )
  }
}

export default BalanceAnalysis
