import React from "react";
import { withStyles } from '@material-ui/core/styles'

const styles: any = (theme: any) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
});


const Wrapper = (props: any) => (
    <>
        <div className={props.classes.content}>
            <div className={props.classes.appBarSpacer}></div>
            {props.children}
        </div>
    </>
);

export default withStyles(styles, { withTheme: true })(Wrapper);