import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { languageService } from '../../service';
import { LANGUAGE } from '../../routes/paths';

import Error from '../NotFound/NotFound'


import { languageCodeValidator, languageNameValidator } from '../../utils/language.validators'
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface ILanguageFormState {
    languageName: string;
    languageNameError: string;
    languageCode: string;
    languageCodeError: string;
    isActive: boolean;
    isSuccess: string,
    isEdit: boolean;
    loading: boolean;
    errorCode: string,
    errorMessage: string
}

export interface ILanguageFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class LanguageForm extends Component {
    public readonly state: ILanguageFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            languageName: "",
            languageNameError: "",
            languageCode: "",
            languageCodeError: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeLanguageName = this.onChangeLanguageName.bind(this);
        this.onChangeLanguageCode = this.onChangeLanguageCode.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }


    componentWillMount() {
        if (this.props.match.params.languageId) this.getEditableLanguage();
        else this.setState({ loading: false });

        this.form = React.createRef();
    }

    private async getEditableLanguage() {
        const { data, isSuccess, errorCode, errorMessage } = await languageService.getById(this.props.match.params.languageId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            const state = {
                ...data,
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });
        }
    }

    /** EVENT LISTENERS */

    private onChangeLanguageName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ languageName: e.target.value, languageNameError: languageNameValidator(e.currentTarget.value) });
    }

    private onChangeLanguageCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ languageCode: e.target.value, languageCodeError: languageCodeValidator(e.currentTarget.value) });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(LANGUAGE);
    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { languageCode, languageName } = this.state;

        //Submit Validation
        if (
            languageCodeValidator(languageCode) !== "" ||
            languageNameValidator(languageName) !== ""

        ) {

            this.setState({
                languageCodeError: languageCodeValidator(languageCode),
                languageNameError: languageNameValidator(languageName)
            });
            return false;
        }

        return true;
    }


    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { languageName, languageCode, isActive, isEdit } = this.state;

        if (this.formValidation()) {

            const model = {
                languageName: sqlInjectValidator(languageName),
                languageCode: sqlInjectValidator(languageCode),
                isActive
            };

            if (isEdit) await this.editLanguage(model);
            else await this.createLanguage(model);
        }
    }

    private async createLanguage(model: any) {
        const data = await languageService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editLanguage(model: any) {
        const data = await languageService.update({ ...model, id: this.props.match.params.languageId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private renderLanguageName() {
        const { languageName, languageNameError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="languageName"
                    label="Dil Adı"
                    placeholder="Dil adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeLanguageName}
                    value={languageName}
                    name="languageName"
                    autoComplete="languageName"
                    helperText={languageNameError}
                />
            </FormControl>
        );
    }

    private renderLanguageCode() {
        const { languageCode, languageCodeError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="languageCode"
                    label="Dil Kodu"
                    placeholder="Dil kodu yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeLanguageCode}
                    value={languageCode}
                    name="languageCode"
                    autoComplete="languageCode"
                    helperText={languageCodeError}
                />
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage, isEdit } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: LANGUAGE, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}

                                <Typography variant="h3">Dil {isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {isEdit ? "Bu ekrandan dil bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda dil ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Dil {isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderLanguageName()}
                                    {this.renderLanguageCode()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(LanguageForm);
