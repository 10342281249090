import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import Wrapper from '../layout/Wrapper'
import moment from 'moment'
import 'moment/locale/tr'

import { Snackbar } from '@material/react-snackbar'
import '@material/react-snackbar/dist/snackbar.css'

import { COMPANY } from '../../routes/paths'
import CustomSelect from '../layout/CustomSelect'

import {
  employeeValidator,
  hhsValidator,
  consentDateValidator,
} from '../../utils/company.validators'
import { Divider, FormControlLabel, Tooltip } from '@material-ui/core'

moment.locale('tr')

const dummyAccountData = [
  { account: 'Vadesiz TL Hesap 1', iban: 'TR08 0006 0000 0000 0000 0000 11' },
  { account: 'Vadesiz TL Hesap 2', iban: 'TR08 0006 0000 0000 0000 0000 12' },
]

const sharedKnowledges = [
  { name: 'Temel Hesap Bilgisi', info: 'Açıklama metni buraya yazılacak...' },
  { name: 'Detaylı Hesap Bilgisi', info: 'Açıklama metni buraya yazılacak...' },
  {
    name: 'Temel Hesap Hareketleri',
    info: 'Açıklama metni buraya yazılacak...',
  },
  {
    name: 'Detaylı Hesap Hareketleri',
    info: 'Açıklama metni buraya yazılacak...',
  },
  { name: 'Hesap Bakiyesi', info: 'Açıklama metni buraya yazılacak...' },
]

// maksimum date olarak 6 ay sonrasından iki gün öncesini seçiyoruz.
const d = new Date()
d.setMonth(d.getMonth() + 6)
d.setDate(d.getDate() - 2)
const maxDate = d.toLocaleDateString('en-CA')

export interface ICompanyFormState {
  loading: boolean
  isSuccess: string
  errorCode: string
  errorMessage: string
  process: 'initial' | 'granted'
  hhsList: any
  hhsName: string
  hhsError: string
  consentDate: any
  consentDateError: string
}

export interface ICompanyFormProps {
  classes: any
}

export default class ConsentAgreementForm extends Component {
  public readonly state: ICompanyFormState
  public readonly props: any

  constructor(props: any) {
    super(props)

    this.props = props

    this.state = {
      loading: false,
      isSuccess: '',
      errorCode: '',
      errorMessage: '',
      process: 'initial',
      hhsList: [
        {
          name: 'HHS1',
        },
        {
          name: 'HHS2',
        },
        {
          name: 'HHS3',
        },
      ],
      hhsName: '',
      hhsError: '',
      consentDate: maxDate,
      consentDateError: '',
    }

    this.cancel = this.cancel.bind(this)
    this.grantPermission = this.grantPermission.bind(this)
    this.onChangeHHS = this.onChangeHHS.bind(this)
    this.onChangeConsentDate = this.onChangeConsentDate.bind(this)
  }

  componentWillMount() {
    this.props.match.params.companyId
  }

  /** VIEW RENDER */
  async cancel() {
    this.props.history.push(COMPANY)
  }

  formValidation() {
    const { hhsName, consentDate } = this.state

    if (
      hhsValidator(hhsName) !== '' ||
      consentDateValidator(consentDate) !== ''
    ) {
      this.setState({
        hhsError: hhsValidator(hhsName),
        consentDateError: consentDateValidator(consentDate),
      })
      return false
    }

    return true
  }

  async grantPermission() {
    // this.setState({ isSuccess: '', errorCode: '', errorMessage: '' })

    // Burada backend'e istek atılıp, success true gelirse popup açtırılacak.

    if (this.formValidation()) {
      console.log('granted permission')
      this.setState({ process: 'granted' })
    }
  }

  onChangeHHS(val: any) {
    if (val) {
      this.setState({
        hhsName: val.name,
        hhsError: employeeValidator(val.name),
      })
    }
  }

  renderHHS() {
    const { hhsList, hhsName, hhsError } = this.state
    return (
      <FormControl
        margin='none'
        variant='outlined'
        className='input inputSelect'
      >
        <CustomSelect
          labelName='name'
          valueKey='name'
          // value={this.state.hhsName}
          options={hhsList}
          onChange={this.onChangeHHS}
          placeholder={'HHS Seç'}
        ></CustomSelect>
        <span className='errorMessage'>{hhsError}</span>
      </FormControl>
    )
  }

  onChangeConsentDate(val: any) {
    if (val) {
      this.setState({
        consentDate: val,
      })
    }
  }

  renderConsentDate() {
    const { consentDateError } = this.state

    return (
      <FormControl
        margin='none'
        variant='outlined'
        className='input inputSelect'
      >
        <input
          value={this.state.consentDate}
          className='searchSelect'
          type='date'
          onChange={(e) => this.onChangeConsentDate(e.currentTarget.value)}
          max={maxDate}
        />
        <span className='errorMessage'>{consentDateError}</span>
      </FormControl>
    )
  }

  renderMessage() {
    return (
      <Snackbar
        message={
          this.state.errorMessage +
          ' (Error Code : ' +
          this.state.errorCode +
          ')'
        }
        timeoutMs={5000}
        className='messageBoxError'
      />
    )
  }

  renderSharedKnowledge() {
    return (
      <div style={{ display: 'grid', gap: '14px' }}>
        {sharedKnowledges.map(({ name, info }) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <div>
                <Checkbox checked disabled value={name} />
                <span style={{ fontSize: 13.5 }}>{name}</span>
              </div>
              <Tooltip
                title={<p style={{ color: '#fff' }}>{info}</p>}
                placement='top'
              >
                <Icon className='fas fa-info-circle'></Icon>
              </Tooltip>
            </div>
          )
        })}
      </div>
    )
  }

  renderInitial() {
    return (
      <>
        <Typography variant='h2'>HBHS</Typography>
        <div style={{ marginBottom: '28px' }}>
          <p>
            Hesap bilgisi erişim izni vermek için hesabınızın bulunduğu HHS'yi
            seçiniz:
          </p>
          {this.renderHHS()}
          <p>
            Bu hizmeti sunabilmemiz için, seçtiğiniz HHS'deki hesaplara ait
            aşağıdaki bilgilere erişmemiz için izin vermeniz gerekmektedir.
          </p>
        </div>
        <Divider />
        <div style={{ margin: '28px 0 28px' }}>
          <p>Aşağıdaki tarihe kadar Hesap Bilgi(leri)nize erişeceğiz:</p>
          {this.renderConsentDate()}
          <p>
            Tarih otomatik olarak 6 ay sonrasını belirlemektedir. Dilerseniz
            farklı bir tarih seçmek için tıklayınız.
          </p>
        </div>
        <Divider />
        <p style={{ fontWeight: 'bold', margin: '28px 0 20px' }}>
          Paylaşmaya izin vereceğiniz bilgiler.
        </p>
        {this.renderSharedKnowledge()}
        {/* Buttons */}
        <Grid item xs={12}>
          <Button
            type='submit'
            id='createButton'
            onClick={this.grantPermission}
          >
            DEVAM ET
          </Button>
          <Button onClick={this.cancel}>VAZGEÇ</Button>
        </Grid>
      </>
    )
  }

  renderGranted() {
    return (
      <div>
        <Typography variant='h2'>HBHS</Typography>
        <p style={{ marginBottom: '20px', fontSize: '15px' }}>
          Teşekkürler <br />
          HHS'de seçtiğiniz hesaplar için aşağıdaki bilgilere erişme iznini
          başarıyla aldık!
        </p>
        <Divider />
        <div style={{ margin: '20px 0 24px' }}>
          <p style={{ fontWeight: 500, fontSize: '15px' }}>HHS1</p>
          {dummyAccountData.map(({ account, iban }, i) => (
            <div key={i}>
              <p style={{ fontWeight: 500, fontSize: '14px' }}>
                Hesap: {account}
              </p>
              <p style={{ fontWeight: 500, fontSize: '14px' }}>{iban}</p>
            </div>
          ))}
        </div>
        <Divider />
        <div style={{ margin: '24px 0 20px' }}>
          <p
            style={{ fontWeight: 500, marginBottom: '16px', fontSize: '14px' }}
          >
            Paylaşmaya izin verdiğiniz bilgiler:{' '}
          </p>
          {this.renderSharedKnowledge()}
        </div>
        <Divider />
        <div style={{ marginTop: '24px' }}>
          <p style={{ fontSize: '14.5px' }}>
            Aşağıdaki tarihe kadar Hesap Bilgi(leri)nize erişeceğiz:
          </p>
          <p style={{ fontSize: '14.5px', fontWeight: 500 }}>
            {moment(this.state.consentDate).format('D MMMM YYYY dddd')}
          </p>
        </div>
        <Grid item xs={12}>
          <Button type='submit' onClick={this.cancel}>
            Hesap Bilgilerim
          </Button>
        </Grid>
      </div>
    )
  }

  render() {
    const { loading, isSuccess, process } = this.state
    if (isSuccess === 'error') this.renderMessage()

    return (
      <Wrapper>
        <Grid container id='Form'>
          <Grid item xs={12} md={3} className='sidebar'>
            <Icon className='fas fa-edit'></Icon>
            {/* <Icon className='fas fa-plus'></Icon> */}
            <Typography variant='h3'>Müşteri Rızası Tesisi</Typography>
            <p>
              Bu ekrandan bize vereceğiniz hesap bilgilerinizle ilgili
              ayrıntıları belirleyebilirsiniz.
            </p>
          </Grid>
          <Grid item xs={12} md={9} className='form'>
            {loading ? (
              <CircularProgress className='progress' />
            ) : (
              <form onSubmit={(e) => e.preventDefault()}>
                {process === 'initial'
                  ? this.renderInitial()
                  : this.renderGranted()}
              </form>
            )}
          </Grid>
        </Grid>
      </Wrapper>
    )
  }
}
