import XHRService from "./xhr.service";

export default interface INotificationRequest {
    id?: number,
    processTypeCode: string,
    description: string,
    cardType: string,
    accountCode: string,
    accountName: string,
    isActive: boolean
}


interface INotification {
    id: number,
    processTypeCode: string,
    description: string,
    cardType: string,
    accountCode: string,
    accountName: string,
    isActive: boolean
}

interface INotificationResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: INotification
}

interface INotificationListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        notifications: INotification[]
    }
}

export default class NotificationService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<INotificationResponse> {
        const { isSuccess, errorCode, errorMessage  }: any = await this.XHRService.post("/Notification/CreateNotification", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: INotificationRequest): Promise<any> {
        //console.log(model);
        return await this.XHRService.post("/Notification/UpdateNotification", model).catch((e) => ({ isSuccess: false }));
    }

    public async delete(id: number): Promise<INotificationResponse> {
        const { isSuccess }: any = await this.XHRService.post("/Notification/DeleteNotification", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/Notification/GetNotifications", model).catch(() => ({ isSuccess: false }));
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/Notification/GetNotificationById?id=${id}`).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/Notification/GetNotificationsExcel", model);
    }
}
