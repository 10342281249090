import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { userPermissionService, locationService } from '../../service';
import { USERPERMISSION } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import Error from '../NotFound/NotFound'


import { companyValidator, permissionValidator, userValidator } from '../../utils/userpermission.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IUserPermissionFormState {
    companyList: [],
    companyId: any,
    companyError: string,
    permissionList: [],
    permissionId: any,
    permissionError: string,
    userList: [],
    userId: any,
    userError: string,
    searchText: string,
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface IUSerPermissionFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class UserPermissionForm extends Component {
    public readonly state: IUserPermissionFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            companyList: [],
            companyId: 0,
            companyError: "",
            permissionList: [],
            permissionId: 0,
            permissionError: "",
            userList: [],
            userId: 0,
            userError: "",
            searchText: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeCompany = this.onChangeCompany.bind(this);
        this.onChangePermission = this.onChangePermission.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.userPermissionId) this.getEditableUserPermission();
        else { this.getPermissions(); this.getCompanies(); this.getUsers(); this.setState({ loading: false }); }
        this.form = React.createRef();
    }

    private async getEditableUserPermission() {
        const { data, isSuccess, errorCode, errorMessage } = await userPermissionService.getById(this.props.match.params.userPermissionId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getCompanies();
            await this.getPermissions();
            await this.getUsers();
            const state = {
                ...data,
                permissionId: this.state.permissionList.find((item: any) => item.id === data.permissionId),
                companyId: this.state.companyList.find((item: any) => item.id === data.companyId),
                userId: this.state.userList.find((item: any) => item.id === data.userId),
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });
        }
    }

    /** EVENT LISTENERS */

    private onChangePermission(permissionId: any) {
        this.setState({ permissionId, permissionError: permissionValidator(permissionId) });
    }

    private onChangeCompany(companyId: any) {
        this.setState({ companyId, companyError: companyValidator(companyId) });
    }

    private onChangeUser(userId: any) {
        this.setState({ userId, userError: userValidator(userId) });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(USERPERMISSION);
    }

    private async getPermissions() {
        const { data } = await locationService.getPermissions();

        const state = {
            permissionId: this.state.permissionId === 0 ? 0 : this.state.permissionId.id,
            permissionList: data.permissions
        };

        this.setState(state);
    }



    private async getUsers() {
        const { data } = await locationService.getUsers();

        const state = {
            userId: this.state.userId === 0 ? 0 : this.state.userId.id,
            userList: data.users
        };

        this.setState(state);
    }

    private async getCompanies() {
        const { data } = await locationService.getCompanies();

        const state = {
            companyId: this.state.companyId === 0 ? 0 : this.state.companyId.id,
            companyList: data.companies
        };

        this.setState(state);
    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { userId, companyId, permissionId } = this.state;

        //Submit Validation
        if (
            userValidator(userId) !== "" ||
            companyValidator(companyId) !== "" ||
            permissionValidator(permissionId) !== ""

        ) {

            this.setState({
                userError: userValidator(userId),
                companyError: companyValidator(companyId),
                permissionError: permissionValidator(permissionId)
            });
            return false;
        }

        return true;
    }


    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { permissionId, companyId, userId, isActive, isEdit } = this.state;

        const model = {
            permissionId: sqlInjectValidator(String(permissionId.id)),
            companyId: sqlInjectValidator(String(companyId.id)),
            userId: sqlInjectValidator(String(userId.id)),
            isActive
        };

        if (this.formValidation()) {
            if (isEdit) await this.editUserPermission(model);
            else await this.createUserPermission(model);
        }
    }

    private async createUserPermission(model: any) {
        const data = await userPermissionService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editUserPermission(model: any) {
        const data = await userPermissionService.update({ ...model, id: this.props.match.params.userPermissionId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }



    private renderCompanies() {
        const { companyId, companyList, companyError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="companyName" value={companyId} valueKey="id" options={companyList} onChange={this.onChangeCompany} placeholder="Firma *"></CustomSelect>
                <span className="errorMessage">{companyError}</span>
            </FormControl >
        );
    }


    private renderPermissions() {
        const { permissionId, permissionList, permissionError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="permissionKey" value={permissionId} valueKey="id" options={permissionList} onChange={this.onChangePermission} placeholder="İzin Anahtarı *"></CustomSelect>
                <span className="errorMessage">{permissionError}</span>
            </FormControl>
        );
    }


    private renderUsers() {
        const { userId, userList, userError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" style={{ width: '98%' }} className="input inputSelect">
                <CustomSelect labelName="nameSurname" value={userId} valueKey="id" options={userList} onChange={this.onChangeUser} placeholder="Kullanıcı *"></CustomSelect>
                <span className="errorMessage">{userError}</span>
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: USERPERMISSION, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Kullanıcı İzni {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan kullanıcı izinlerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda kullanıcı izinleri ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Kullanıcı İzni {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderPermissions()}
                                    {this.renderCompanies()}
                                    {this.renderUsers()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(UserPermissionForm);
