import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { bankAccountService, locationService } from '../../service';
import { BANKACCOUNT } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import Error from '../NotFound/NotFound'

import { bankAccountCodeValidator, bankAccountNameValidator, bankBranchCodeValidator, ibanValidator, companyValidator, bankValidator, currencyValidator } from '../../utils/bankaccount.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IBankAccountFormState {
    bankAccountName: string;
    bankAccountNameError: string;
    iban: string;
    ibanError: string;
    posCompanyCode: string;
    bankBranchCode: string;
    bankBranchCodeError: string;
    bankAccountCode: string;
    bankAccountCodeError: string;
    companyList: [],
    companyId: any,
    companyError: string;
    bankList: [],
    bankId: any,
    bankError: string;
    currencyList: [],
    currencyId: any,
    currencyError: string;
    description: string,
    searchText: string,
    isActive: boolean,
    isValorAccount: boolean,
    clientType:string,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface IBankAccountFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class BankAccountForm extends Component {
    public readonly state: IBankAccountFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            bankAccountName: "",
            bankAccountNameError: "",
            iban: "",
            ibanError: "",            
            posCompanyCode: "",
            bankBranchCode: "",
            bankBranchCodeError: "",
            bankAccountCode: "",
            bankAccountCodeError: "",
            companyList: [],
            companyId: 0,
            companyError: "",
            bankList: [],
            bankId: 0,
            bankError: "",
            currencyList: [],
            currencyId: 0,
            currencyError: "",
            description: "",
            searchText: "",
            isActive: true,
            isValorAccount: false,
            clientType:"WEB",
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeBankAccountName = this.onChangeBankAccountName.bind(this);
        this.onChangeIBAN = this.onChangeIBAN.bind(this);
        this.onChangeBankAccountCode = this.onChangeBankAccountCode.bind(this);
        this.onChangeBankBranchCode = this.onChangeBankBranchCode.bind(this);
        this.onChangeCompany = this.onChangeCompany.bind(this);
        this.onChangeBank = this.onChangeBank.bind(this);
        this.onChangeCurrency = this.onChangeCurrency.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangeIsValorAccountStatus = this.onChangeIsValorAccountStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.bankAccountId) this.getEditableBankAccount();
        else { this.getBanks(); this.getCompanies(); this.getCurrencies(); this.setState({ loading: false }); }
        this.form = React.createRef();
    }

    private async getEditableBankAccount() {
        const { data, isSuccess, errorCode, errorMessage } = await bankAccountService.getById(this.props.match.params.bankAccountId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getBanks();
            await this.getCompanies();
            await this.getCurrencies();

            const state = {
                ...data,
                bankId: this.state.bankList.find((item: any) => item.id === data.bankId),
                companyId: this.state.companyList.find((item: any) => item.id === data.companyId),
                currencyId: this.state.currencyList.find((item: any) => item.id === data.currencyId),
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });   
        }
    }

    /** EVENT LISTENERS */

    private onChangeBankAccountName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ bankAccountName: e.target.value, bankAccountNameError: bankAccountNameValidator(e.currentTarget.value) });
    }

    private onChangeIBAN(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ iban: e.target.value, ibanError: ibanValidator(e.currentTarget.value) });
    }

    private onChangeBankAccountCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ bankAccountCode: e.target.value, bankAccountCodeError: bankAccountCodeValidator(e.currentTarget.value) });
    }

    private onChangeBankBranchCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ bankBranchCode: e.target.value, bankBranchCodeError: bankBranchCodeValidator(e.currentTarget.value) });
    }

    private onChangeCompany(companyId: any) {
        this.setState({ companyId, companyError: companyValidator(companyId) });
    }

    private onChangeBank(bankId: any) {
        this.setState({ bankId, bankError: bankValidator(bankId) });
    }

    private onChangeCurrency(currencyId: any) {
        this.setState({ currencyId, currencyError: currencyValidator(currencyId) });
    }

    private onChangeDescription(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ description: e.target.value });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    private onChangeIsValorAccountStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isValorAccount: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(BANKACCOUNT);
    }

    private async getBanks() {
        const { data } = await locationService.getBanks();

        const state = {
            bankId: this.state.bankId === 0 ? 0 : this.state.bankId.id,
            bankList: data.banks
        };

        this.setState(state);
    }
    
    private async getCurrencies() {
        const { data } = await locationService.getCurrencies();

        const state = {
            currencyId: this.state.currencyId === 0 ? 0 : this.state.currencyId.id,
            currencyList: data.currencies
        };

        this.setState(state);
    }

    private async getCompanies() {
        const { data } = await locationService.getCompanies();

        const state = {
            companyId: this.state.companyId === 0 ? 0 : this.state.companyId.id,
            companyList: data.companies
        };

        this.setState(state);
    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            this.state.errorMessage !== "" ? <Redirect to={{ pathname: BANKACCOUNT, state: { errorMessage: this.state.errorMessage } }} /> : null
        );
    }

    private formValidation() {
        const { bankAccountCode, bankAccountName, bankBranchCode, iban, companyId, bankId, currencyId } = this.state;

        if (
            bankAccountCodeValidator(bankAccountCode) !== "" ||
            bankAccountNameValidator(bankAccountName) !== "" ||
            bankBranchCodeValidator(bankBranchCode) !== "" ||
            ibanValidator(iban) !== "" ||
            companyValidator(companyId) !== "" ||
            bankValidator(bankId) !== "" ||
            currencyValidator(currencyId) !== ""

        ) {

            this.setState({
                bankAccountCodeError: bankAccountCodeValidator(bankAccountCode),
                bankAccountNameError: bankAccountNameValidator(bankAccountName),
                bankBranchCodeError: bankBranchCodeValidator(bankBranchCode),
                ibanError: ibanValidator(iban),
                companyError: companyValidator(companyId),
                bankError: bankValidator(bankId),
                currencyError: currencyValidator(currencyId)
            });
            return false;
        }

        return true;
    }

    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { bankAccountName, iban, bankAccountCode, bankBranchCode, companyId, bankId, currencyId, description, posCompanyCode, isActive, isValorAccount, clientType, isEdit } = this.state;

        if (this.formValidation()) {

            const model = {
                bankAccountName: sqlInjectValidator(bankAccountName),
                iban: sqlInjectValidator(iban),
                bankAccountCode: sqlInjectValidator(bankAccountCode),
                bankBranchCode: sqlInjectValidator(bankBranchCode),
                companyId: sqlInjectValidator(String(companyId.id)),
                bankId: sqlInjectValidator(String(bankId.id)),
                currencyId: sqlInjectValidator(String(currencyId.id)),
                description: sqlInjectValidator(description),
                posCompanyCode: sqlInjectValidator(posCompanyCode),
                isActive,
                isValorAccount,
                clientType
            };

            if (isEdit) await this.editBankAccount(model);
            else await this.createBankAccount(model);
        }
    }

    private async createBankAccount(model: any) {
        const data = await bankAccountService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editBankAccount(model: any) {
        const data = await bankAccountService.update({ ...model, id: this.props.match.params.bankAccountId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private renderBankAccountName() {
        const { bankAccountName, bankAccountNameError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="bankAccountName"
                    label="Hesap Adı"
                    placeholder="Hesap adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeBankAccountName}
                    value={bankAccountName}
                    name="bankAccountName"
                    autoComplete="bankAccountName"
                    helperText={bankAccountNameError}
                />
            </FormControl>
        );
    }

    private renderIBAN() {
        const { iban, ibanError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="iban"
                    label="IBAN"
                    placeholder="IBAN yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeIBAN}
                    value={iban}
                    name="iban"
                    autoComplete="iban"
                    helperText={ibanError}
                />
            </FormControl>
        );
    }

    private renderBankAccountCode() {
        const { bankAccountCode, bankAccountCodeError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="bankAccountCode"
                    label="Hesap Numarası"
                    placeholder="Hesap Numarası yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeBankAccountCode}
                    value={bankAccountCode}
                    name="bankAccountCode"
                    autoComplete="bankAccountCode"
                    helperText={bankAccountCodeError}
                />
            </FormControl>
        );
    }

    private renderBankBranchCode() {
        const { bankBranchCode, bankBranchCodeError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="bankBranchCode"
                    label="Şube Kodu"
                    placeholder="Şube kodu yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeBankBranchCode}
                    value={bankBranchCode}
                    name="bankBranchCode"
                    autoComplete="bankBranchCode"
                    helperText={bankBranchCodeError}
                />
            </FormControl>
        );
    }

    private renderDescription() {
        const { description } = this.state;


        return (
            <FormControl margin="none" className="input" style={{ width: '98%', height: 'auto' }}>
                <TextField
                    multiline
                    id="description"
                    label="Açıklama"
                    placeholder="Açıklama yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeDescription}
                    value={description}
                    name="description"
                    rows={6}
                    autoComplete="description"
                    className="multiInput"
                />
            </FormControl>
        );
    }    

    private renderCompanies() {
        const { companyId, companyList, companyError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="companyName" value={companyId} valueKey="id" options={companyList} onChange={this.onChangeCompany} placeholder="Firma *"></CustomSelect>
                <span className="errorMessage">{companyError}</span>
            </FormControl >
        );
    }


    private renderBanks() {
        const { bankId, bankList, bankError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="bankName" value={bankId} valueKey="id" options={bankList} onChange={this.onChangeBank} placeholder="Banka *"></CustomSelect>
                <span className="errorMessage">{bankError}</span>
            </FormControl>
        );
    }   

    private renderCurrencies() {
        const { currencyId, currencyList, currencyError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="currencyName" value={currencyId} valueKey="id" options={currencyList} onChange={this.onChangeCurrency} placeholder="Para Birimi *"></CustomSelect>
                <span className="errorMessage">{currencyError}</span>
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderIsValorAccount() {
        const { isValorAccount } = this.state;

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        value={isValorAccount}
                        color="primary"
                        onChange={this.onChangeIsValorAccountStatus}
                        checked={isValorAccount}
                    />
                }
                className="checkbox"
                label="Pos Valor Hesabı"
            />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }



    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: BANKACCOUNT, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Hesap {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan hesap bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda hesap ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Hesap {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderBankAccountName()}
                                    {this.renderIBAN()}
                                    {this.renderBankBranchCode()}
                                    {this.renderBankAccountCode()}
                                    {this.renderCompanies()}
                                    {this.renderBanks()}
                                    {this.renderCurrencies()}
                                    {this.renderDescription()}
                                    {this.renderStatus()}
                                    {this.renderIsValorAccount()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(BankAccountForm);
