import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { rolePermissionService, locationService } from '../../service';
import { ROLEPERMISSION } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import Error from '../NotFound/NotFound'


import { roleValidator, permissionValidator } from '../../utils/rolepermission.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IRolePermissionFormState {
    roleList: [],
    roleId: any,
    roleError: string;
    permissionList: [],
    permissionId: any,
    permissionError: string,
    searchText: string,
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface IRolePermissionFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class RolePermissionForm extends Component {
    public readonly state: IRolePermissionFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            roleList: [],
            roleId: 0,
            roleError: "",
            permissionList: [],
            permissionId: 0,
            permissionError: "",
            searchText: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeRole = this.onChangeRole.bind(this);
        this.onChangePermission = this.onChangePermission.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.rolePermissionId) this.getEditableRolePermission();
        else { this.getPermissions(); this.getRoles(); this.setState({ loading: false }); }
        this.form = React.createRef();
    }

    private async getEditableRolePermission() {
        const { data, isSuccess, errorCode, errorMessage } = await rolePermissionService.getById(this.props.match.params.rolePermissionId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getRoles();
            await this.getPermissions();

            const state = {
                ...data,
                permissionId: this.state.permissionList.find((item: any) => item.id === data.permissionId),
                roleId: this.state.roleList.find((item: any) => item.id === data.roleId),
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });
        }
    }

    /** EVENT LISTENERS */

    private onChangePermission(permissionId: any) {
        this.setState({ permissionId, permissionError: permissionValidator(permissionId) });
    }

    private onChangeRole(roleId: any) {
        this.setState({ roleId, roleError: roleValidator(roleId) });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(ROLEPERMISSION);
    }

    private async getRoles() {
        const { data } = await locationService.getRoles();

        const state = {
            roleId: this.state.roleId === 0 ? 0 : this.state.roleId.id,
            roleList: data.roles
        };

        this.setState(state);
    }

    private async getPermissions(useDefault: boolean = true) {
        const { data } = await locationService.getPermissions();

        const state = {
            permissionId: this.state.permissionId === 0 ? 0 : this.state.permissionId.id,
            permissionList: data.permissions
        };

        this.setState(state);
    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { roleId, permissionId } = this.state;

        //Submit Validation
        if (
            roleValidator(roleId) !== "" ||
            permissionValidator(permissionId) !== ""

        ) {

            this.setState({
                roleError: roleValidator(roleId),
                permissionError: permissionValidator(permissionId)
            });
            return false;
        }

        return true;
    }


    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { permissionId, roleId, isActive, isEdit } = this.state;

        const model = {
            permissionId: sqlInjectValidator(String(permissionId.id)),
            roleId: sqlInjectValidator(String(roleId.id)),
            isActive
        };

        if (this.formValidation()) {
            if (isEdit) await this.editRolePermission(model);
            else await this.createRolePermission(model);
        }
    }

    private async createRolePermission(model: any) {
        const data = await rolePermissionService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editRolePermission(model: any) {
        const data = await rolePermissionService.update({ ...model, id: this.props.match.params.rolePermissionId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }


    private renderRoles() {
        const { roleId, roleList, roleError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="roleName" value={roleId} valueKey="id" options={roleList} onChange={this.onChangeRole} placeholder="Rol *"></CustomSelect>
                <span className="errorMessage">{roleError}</span>
            </FormControl >
        );
    }


    private renderPermissions() {
        const { permissionId, permissionList, permissionError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="permissionKey" value={permissionId} valueKey="id" options={permissionList} onChange={this.onChangePermission} placeholder="İzin Anahtarı *"></CustomSelect>
                <span className="errorMessage">{permissionError}</span>
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }


    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: ROLEPERMISSION, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Kullanıcı İzni {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan kullanıcı izinlerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda kullanıcı izinleri ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Kullanıcı İzni {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderPermissions()}
                                    {this.renderRoles()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(RolePermissionForm);
