import { Route, Redirect } from "react-router";
import { userService, cookieService } from "../service";
import React, { PureComponent } from "react";

export default class ProtectedRoute extends PureComponent<any> {

    public readonly LANGUAGE_NAME = 'LANGUAGE_TOKEN';

    render() {
        const { component: Component, ...rest }: any = this.props;
        userService.isLanguage() ? null : cookieService.setItem(this.LANGUAGE_NAME, "TR");

        return (
            <Route
                {...rest}
                render={(props) => {
                    return userService.isAuth() ? <Component {...props} /> : <Redirect to="/login" />;
                }}
            />
        );
    }
}
