import XHRService from "./xhr.service";

export default interface IDistrictRequest {
    id?: number;
    districtName: string;
    countryId: number;
    cityId: number;
    isActive: boolean;
}


interface IDistrict {
    id: number;
    districtName: string;
    countryId: number;
    cityId: number;
    isActive: boolean;
}

interface IDistrictResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IDistrict
}

interface IDistrictListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        districts: IDistrict[]
    }
}

export default class DistrictService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IDistrictResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/District/CreateDistrict", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IDistrictRequest): Promise<any> {
        return await this.XHRService.post("/District/UpdateDistrict", model).catch((e) => ({ isSuccess: false }));
    }

    public async delete(id: number): Promise<IDistrictResponse> {
        const { isSuccess }: any = await this.XHRService.post("/District/DeleteDistrict", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            countryId: 0,
            cityId: 0,
            isActive,
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/District/GetDistricts", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/District/GetDistrictsExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/District/GetDistrictById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
