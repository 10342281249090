import React, { Component } from 'react'
import Wrapper from '../layout/Wrapper';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Typography from '@material-ui/core/Typography/Typography';
import Tab from '@material-ui/core/Tab/Tab';
import BalanceChart from './BalanceChart';
import Paper from '@material-ui/core/Paper/Paper';


export default class Reports extends Component {
    public state: any;

    constructor(props: any) {
        super(props);
        this.state = {
            tabValue: 0
        }

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    private handleTabChange(_: any, tabValue: any) {
        this.setState({ tabValue });
    }

    private renderCharts() {
        const { tabValue } = this.state;
        if (tabValue === 0) return <BalanceChart key={1} name="BAKİYE RAPORU" currencyId={1} />;
        else if (tabValue === 1) return <BalanceChart key={2} name="HESAP HAREKETLERİ RAPORU" currencyId={3} />;
        else if (tabValue === 2) return <BalanceChart key={3} name="POS HAREKETLERİ RAPORU" currencyId={2} />;
    }

    render() {
        return (
            <Wrapper>
                <Paper id="Chart">
                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="TÜRK LİRASI" />
                        <Tab label="ABD DOLARI" />
                        <Tab label="İNGİLİZ STERLİNİ" />
                    </Tabs>

                    {this.renderCharts()}
                </Paper>

            </Wrapper>
        )
    }
}
