//Invoice

export const invoiceNameValidator = (invoiceName: string): string => {
    if (invoiceName === "") return 'Fatura adı alanını boş geçemezsiniz!'
    return '';
}

export const companyValidator = (companyId: number): string => {
    if (companyId === 0) return 'Firma seçiniz!'
    return '';
}

export const createDateTimeValidator = (createDateTime: string): string => {
    if (createDateTime === '0000-00-00') return 'Lütfen bir tarih seçiniz!'
    return '';
}

export const dueDateTimeValidator = (dueDateTime: string): string => {
    if (dueDateTime === '0000-00-00') return 'Lütfen bir tarih seçiniz!'
    return '';
}
