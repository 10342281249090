export const sqlInjectValidator = (text: string): any => {

    const sqlInject = [
        ' or ',
        ' and ',
        ' OR ',
        ' AND ',
        'where',
        'WHERE',
        'sql',
        'SQL',
        'CREATE',
        'create',
        'DELETE',
        'delete',
        'update',
        'UPDATE',
        '\'',
        '*',
        ' from ',
        ' FROM ',
        '"',
        '%',
        ' join ',
        ' JOIN ',
        ' inner ',
        ' INNER ',
        ' count ',
        ' COUNT ',
        ' like ',
        ' LIKE ',
        '=',
        ' limit ',
        ' LIMIT ',
        ' id ',
        ' ID ',
        'password',
        'PASSWORD',
        'select',
        'SELECT'
    ];

    sqlInject.forEach(item => {
        if (text !== null) {
            text = text.replace(item, '');    
        } 
    });

    return text;

}