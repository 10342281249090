import XHRService from "./xhr.service";

export default interface ICompanyUserRequest {
    id?: number,
    userId: number,
    companyId: number,
    isActive: boolean,
    searchText: string
}


interface ICompanyUser {
    id: number,
    userId: number,
    companyId: number,
    isActive: boolean,
    searchText: string
}

interface ICompanyUserResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: ICompanyUser
}

interface ICompanyUserListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        companyUsers: ICompanyUser[]
    }
}

export default class CompanyUserService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<ICompanyUserResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/CompanyUser/CreateCompanyUser", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: ICompanyUserRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/CompanyUser/UpdateCompanyUser", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<ICompanyUserResponse> {
        const { isSuccess }: any = await this.XHRService.post("/CompanyUser/DeleteCompanyUser", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number): Promise<any> {
        const model = {
            searchText,
            companyId: 0,
            userId: 0,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/CompanyUser/GetCompanyUsers", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string): Promise<any> {

        const model = {
            searchText,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/CompanyUser/GetCompanyUsersExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/CompanyUser/GetCompanyUserById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
