//Partner
function emailValidation(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const partnerNameValidator = (partnerName: string): string => {
    if (partnerName === "") return 'İş Ortağı adı alanını boş geçemezsiniz!'
    else if (partnerName.length < 6) return 'İş Ortağı adı en az 6 karakter olabilir!'
    return '';
}

export const contactPersonValidator = (contactPerson: string): string => {
    if (contactPerson === "") return 'İrtibat Kişi adı alanını boş geçemezsiniz!'
    else if (contactPerson.length < 6) return 'İrtibat Kişi adı en az 6 karakter olabilir!'
    return '';
}

export const emailAddressValidator = (emailAddress: string): string => {
    if (emailAddress === "") return 'Mail alanını boş geçemezsiniz!'
    else if (emailAddress.length < 6) return 'Kullanici adi en az 6 karakter olabilir!'
    else if (!emailValidation(emailAddress)) return 'Geçersiz bir E-posta Adresi girdiniz!'
    return '';
}

export const mobilePhoneNumberValidator = (mobilePhoneNumber: string): string => {
    if (mobilePhoneNumber === "") return 'Cep telefonu alanını boş geçemezsiniz!'
    else if (mobilePhoneNumber.length < 10 || mobilePhoneNumber.length > 10) return 'Telefon numarası 10 karakter olmalıdır, başında 0 kullanmayın!'
    return '';
}

export const countryValidator = (countryId: number): string => {
    if (countryId === 0) return 'Ülke seçiniz!'
    return '';
}

export const cityValidator = (cityId: number): string => {
    if (cityId === 0) return 'Şehir seçiniz!'
    return '';
}

export const districtValidator = (districtId: number): string => {
    if (districtId === 0) return 'İlçe seçiniz!'
    return '';
}

export const addressValidator = (address: string): string => {
    if (address === "") return 'Adres alanını boş geçemezsiniz!'
    else if (address.length < 15) return 'Adres alanı en az 15 karakter olabilir!'
    return '';
}

export const userValidator = (userId: number): string => {
    if (userId === 0) return 'Kullanıcı seçiniz!'
    return '';
}