import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { cityService, locationService } from '../../service';
import { CITY } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect'

import Error from '../NotFound/NotFound'


import { cityNameValidator, countryValidator } from '../../utils/city.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface ICityFormState {
    cityName: string;
    cityNameError: string;
    countryList: [],
    countryId: any,
    countryError: string;
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface ICityFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class CityForm extends Component {
    public readonly state: ICityFormState;
    public readonly props: any;
    private form: any;


    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            cityName: "",
            cityNameError: "",
            isActive: true,
            countryList: [],
            countryId: 0,
            countryError: "",
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeCityName = this.onChangeCityName.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.cityId) this.getEditableCity();
        else this.getCountries()

        this.form = React.createRef();
    }

    private async getEditableCity() {
        const { data, isSuccess, errorCode, errorMessage } = await cityService.getById(this.props.match.params.cityId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getCountries();
            const state = {
                ...data,
                countryId: this.state.countryList.find((item: any) => item.id === data.countryId),
                isEdit: true
            }

            if (isSuccess) this.setState({ ...state, loading: false });
        }
    }

    /** EVENT LISTENERS */

    private onChangeCityName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ cityName: e.target.value, cityNameError: cityNameValidator(e.currentTarget.value) });
    }

    private onChangeCountry(countryId: any) {
        this.setState({ countryId, countryError: countryValidator(countryId) });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(CITY);
    }

    private async getCountries(useDefault: boolean = true) {
        const { data } = await locationService.getCountries();

        const state = {
            countryId: useDefault ? 0 : this.state.countryId.id,
            countryList: data.countries,
            loading: false
        };

        this.setState(state);

    }


    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { cityName, countryId } = this.state;

        //Submit Validation
        if (
            cityNameValidator(cityName) !== "" ||
            countryValidator(countryId) !== ""

        ) {

            this.setState({
                cityNameError: cityNameValidator(cityName),
                countryError: countryValidator(countryId)
            });
            return false;
        }

        return true;
    }

    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { cityName, countryId, isActive, isEdit } = this.state;

        const model = {
            cityName: sqlInjectValidator(cityName),
            countryId: sqlInjectValidator(String(countryId.id)),
            isActive
        };

        if (this.formValidation()) {
            if (isEdit) await this.editCity(model);
            else await this.createCity(model);
        }
    }

    private async createCity(model: any) {
        const data = await cityService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editCity(model: any) {
        const data = await cityService.update({ ...model, id: this.props.match.params.cityId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private renderCityName() {
        const { cityName, cityNameError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="cityName"
                    label="Şehir Adı"
                    placeholder="Şehir adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCityName}
                    value={cityName}
                    name="cityName"
                    autoComplete="cityName"
                    helperText={cityNameError}
                />
            </FormControl>
        );
    }


    private renderCountries() {
        const { countryId, countryList, countryError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="countryName" value={countryId} valueKey="id" options={countryList} onChange={this.onChangeCountry} placeholder="Ülke *"></CustomSelect>
                <span className="errorMessage">{countryError}</span>
            </FormControl >
        );
    }


    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: CITY, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Şehir {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan şehir bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda şehir ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Şehir {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderCityName()}
                                    {this.renderCountries()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(CityForm);
