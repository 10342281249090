export const LOGIN = '/login'
export const REGISTER = '/register'
export const REMEMBER = '/remember'
export const COCKPIT = '/cockpit'
export const DOCUMENTATION = '/documentation'
export const ACCOUNTSTATEMENT = '/accountStatement'
export const POSSTATEMENT = '/posStatement'
export const ACCOUNTBALANCEHISTORY = '/balance-history'
export const VALORDATE = '/valor-date'
export const HOME = COCKPIT

export const REPORTS = '/reports'
export const BALANCEANALYSIS = '/balanceAnalysis'
export const NOTFOUND = '/404'

export const ACCOUNT_STATEMENT = (accountId: string) =>
  `/accountStatement/${accountId}`
export const BANK_STATEMENT = (bankId: string, currencyId: string) =>
  `/bankStatement/${bankId}/${currencyId}`
export const ACCOUNT = '/bank-account'
export const ACCOUNT_DETAIL = (currencyId: number | string) =>
  `/account-detail/${currencyId}`

export const COMPANY = '/company-list'
export const ADD_NEW_COMPANY = '/new-company'
export const EDIT_COMPANY = (companyId: number | string) =>
  `/company/${companyId}`

export const EDIT_CONSENT_AGREEMENT = (companyId: number | string) =>
  `/consentAgreement/${companyId}`
export const EDIT_CONSENT_RENEWAL = (companyId: number | string) =>
  `/consentRenewal/${companyId}`
export const EDIT_CONSENT_CANCEL = (companyId: number | string) =>
  `/consentCancel/${companyId}`

export const BANK = '/bank-list'
export const ADD_NEW_BANK = '/new-bank'
export const EDIT_BANK = (bankId: number | string) => `/bank/${bankId}`

export const BANKBRANCH = '/bankBranch-list'
export const ADD_NEW_BANKBRANCH = '/new-bankBranch'
export const EDIT_BANKBRANCH = (bankBranchId: number | string) =>
  `/bankBranch/${bankBranchId}`

export const BANKACCOUNT = '/bankAccount-list'
export const ADD_NEW_BANKACCOUNT = '/new-bankAccount'
export const EDIT_BANKACCOUNT = (bankAccountId: number | string) =>
  `/bankAccount/${bankAccountId}`

export const BANKACCOUNTUSER = '/bankAccountUser-list'
export const ADD_NEW_BANKACCOUNTUSER = '/new-bankAccountUser'
export const EDIT_BANKACCOUNTUSER = (bankAccountUserId: number | string) =>
  `/bankAccountUser/${bankAccountUserId}`

export const COMPANYUSER = '/companyUser-list'
export const ADD_NEW_COMPANYUSER = '/new-companyUser'
export const EDIT_COMPANYUSER = (companyUserId: number | string) =>
  `/companyUser/${companyUserId}`

export const BANKCREDENTIAL = '/bankCredential-list'
export const ADD_NEW_BANKCREDENTIAL = '/new-bankCredential'
export const EDIT_BANKCREDENTIAL = (bankCredentialId: number | string) =>
  `/bankCredential/${bankCredentialId}`

export const BANKFTPCREDENTIAL = '/bankFTPCredential-list'
export const ADD_NEW_BANKFTPCREDENTIAL = '/new-bankFTPCredential'
export const EDIT_BANKFTPCREDENTIAL = (bankFTPCredentialId: number | string) =>
  `/bankFTPCredential/${bankFTPCredentialId}`

export const NOTIFICATION = '/notification-list'
export const ADD_NEW_NOTIFICATION = '/new-notification'
export const EDIT_NOTIFICATION = (notificationId: number | string) =>
  `/notification/${notificationId}`

export const COUNTRY = '/country-list'
export const ADD_NEW_COUNTRY = '/new-country'
export const EDIT_COUNTRY = (countryId: number | string) =>
  `/country/${countryId}`

export const CITY = '/city-list'
export const ADD_NEW_CITY = '/new-city'
export const EDIT_CITY = (cityId: number | string) => `/city/${cityId}`

export const DISTRICT = '/district-list'
export const ADD_NEW_DISTRICT = '/new-district'
export const EDIT_DISTRICT = (districtId: number | string) =>
  `/district/${districtId}`

export const CURRENCY = '/currency-list'
export const ADD_NEW_CURRENCY = '/new-currency'
export const EDIT_CURRENCY = (currencyId: number | string) =>
  `/currency/${currencyId}`

export const USER = '/user-list'
export const ADD_NEW_USER = '/new-user'
export const ADD_MULTI_USER = '/new-multi-user'
export const EDIT_USER = (userId: number | string) => `/user/${userId}`
export const PASSWORD_UPDATE = (userId: number | string) =>
  `/user/password-update/${userId}`
export const PROFILE = '/profile/'
export const PASSWORD_RESET = '/password-reset'

export const PERMISSION = '/permission-list'
export const ADD_NEW_PERMISSION = '/new-permission'
export const EDIT_PERMISSION = (permissionId: number | string) =>
  `/permission/${permissionId}`

export const PARTNER = '/partner-list'
export const ADD_NEW_PARTNER = '/new-partner'
export const EDIT_PARTNER = (partnerId: number | string) =>
  `/partner/${partnerId}`

export const USERPERMISSION = '/userPermission-list'
export const ADD_NEW_USERPERMISSION = '/new-userPermission'
export const EDIT_USERPERMISSION = (userPermissionId: number | string) =>
  `/userPermission/${userPermissionId}`

export const ROLEPERMISSION = '/rolePermission-list'
export const ADD_NEW_ROLEPERMISSION = '/new-rolePermission'
export const EDIT_ROLEPERMISSION = (rolePermissionId: number | string) =>
  `/rolePermission/${rolePermissionId}`

export const ROLE = '/role-list'
export const ADD_NEW_ROLE = '/new-role'
export const EDIT_ROLE = (roleId: number | string) => `/role/${roleId}`

export const LANGUAGE = '/language-list'
export const ADD_NEW_LANGUAGE = '/new-language'
export const EDIT_LANGUAGE = (languageId: number | string) =>
  `/language/${languageId}`

export const RESOURCETYPE = '/resourceType-list'
export const ADD_NEW_RESOURCETYPE = '/new-resourceType'
export const EDIT_RESOURCETYPE = (resourceTypeId: number | string) =>
  `/resourceType/${resourceTypeId}`

export const RESOURCE = '/resource-list'
export const ADD_NEW_RESOURCE = '/new-resource'
export const EDIT_RESOURCE = (resourceId: number | string) =>
  `/resource/${resourceId}`

export const PAYMENT = '/payment-list'
export const ADD_NEW_PAYMENT = '/new-payment'

export const INVOICE = '/invoice-list'
export const ADD_NEW_INVOICE = '/new-invoice'
export const EDIT_INVOICE = (invoiceId: number | string) =>
  `/invoice/${invoiceId}`
