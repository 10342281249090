import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Wrapper from '../layout/Wrapper'
import MultipleUserForm from './MultipleUserForm'

interface IUserState {

}

export default class User extends Component {
    public readonly state: IUserState;
    constructor(props: any) {
        super(props);

        this.state = {

        }

    }

    render() {
        return (
            <Wrapper>
                <Grid container id="Breadcrumb">
                    <Grid item xs={12}>
                        <ul>
                            <li>Kullanıcı</li>
                            <li>Yönetim</li>
                            <li>Kullanıcı İşlemleri</li>
                            <li>Çoklu Kullanıcı Ekleme</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container id="Content">
                    <Grid item xs={12}>
                        <MultipleUserForm />
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
}
