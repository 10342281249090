export enum StorageTypes {
    LocaleStorage = "localStorage",
    SessionStorage = "sessionStorage"
}

export class StorageService {
    public readonly storageType: StorageTypes;

    constructor(storageType: StorageTypes) {
        this.storageType = storageType;
    }

    public removeItem(key: string): void {
        window[this.storageType].removeItem(key);
    }

    public getItem<T>(key: string): T | null {
        const data: string | null = window[this.storageType].getItem(key);
        if (data) return JSON.parse(data);
        return null;
    }

    public setItem(key: string, value: any): void {
        const data: string = JSON.stringify(value);
        window[this.storageType].setItem(key, data);
    }
}

export class CookieService {

    getItem(email: string) {
        var result = new RegExp('(?:^|; )' + encodeURIComponent(email) + '=([^;]*)').exec(document.cookie);
        return result ? result[1] : null;
    }

    setItem(email: string, value: string, days?: number) {
        if (!days) {
            days = 1;
        }

        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

        var expires = "; expires=" + date.toUTCString();

        document.cookie = email + "=" + value + expires + "; path=/";
    }

    removeItem(email: string) {
        this.setItem(email, "", -1);
    }

    resourceItem(languageCode: string) {
        var result = new RegExp('(?:^|; )' + encodeURIComponent(languageCode) + '=([^;]*)').exec(document.cookie);
        return result ? result[1] : null;
    }

}
