import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { bankService, locationService } from '../../service'
import { BANK } from '../../routes/paths'
import Wrapper from '../layout/Wrapper'
import CustomSelect from '../layout/CustomSelect'

import Error from '../NotFound/NotFound'

import { bankNameValidator, countryValidator, bankCodeValidator, webSiteValidator } from '../../utils/bank.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';


export interface IBankFormState {
    bankName: string,
    bankNameError: string,
    bankCode: string,
    bankCodeError: string,
    countryList: [],
    countryId: any,
    countryError: string,
    webSite: string,
    webSiteError: string,
    ftpAddress: string,
    ftpAddressError: string,
    ftpPort: string,
    ftpPortError: string,
    ftpRemoteDirectory: string,
    ftpRemoteDirectoryError: string,
    ftpLocalDirectory: string,
    ftpLocalDirectoryError: string,
    description: string,
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface IBankFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class BankForm extends Component {
    public readonly state: IBankFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            bankName: "",
            bankNameError: "",
            bankCode: "",
            bankCodeError: "",
            countryList: [],
            countryId: 0,
            countryError: "",
            webSite: "",
            webSiteError: "",
            ftpAddress: "",
            ftpAddressError: "",
            ftpPort: "",
            ftpPortError: "",
            ftpRemoteDirectory: "",
            ftpRemoteDirectoryError: "",
            ftpLocalDirectory: "",
            ftpLocalDirectoryError: "",
            description: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeBankName = this.onChangeBankName.bind(this);
        this.onChangeBankCode = this.onChangeBankCode.bind(this);
        this.onChangeCountries = this.onChangeCountries.bind(this);
        this.onChangeWebSite = this.onChangeWebSite.bind(this);
        this.onChangeFTPAddress = this.onChangeFTPAddress.bind(this);
        this.onChangeFTPPort = this.onChangeFTPPort.bind(this);
        this.onChangeFTPRemoteDirectory = this.onChangeFTPRemoteDirectory.bind(this);
        this.onChangeFTPLocalDirectory = this.onChangeFTPLocalDirectory.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }


    componentWillMount() {
        if (this.props.match.params.bankId) this.getEditableBank();
        else this.getCountries()

        this.form = React.createRef();
    }

    private async getEditableBank() {
        const { data, isSuccess, errorCode, errorMessage } = await bankService.getById(this.props.match.params.bankId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getCountries();
            const state = {
                ...data,
                countryId: this.state.countryList.find((item: any) => item.id === data.countryId),
                isEdit: true
            }

            if (isSuccess) this.setState({ ...state, loading: false });
        }


    }

    /** EVENT LISTENERS */

    private onChangeBankName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ bankName: e.target.value, bankNameError: bankNameValidator(e.currentTarget.value) });
    }

    private onChangeBankCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ bankCode: e.target.value, bankCodeError: bankCodeValidator(e.currentTarget.value) });
    }

    private onChangeCountries(countryId: any) {
        this.setState({ countryId, countryError: countryValidator(countryId) });;
    }

    private onChangeWebSite(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ webSite: e.target.value, webSiteError: webSiteValidator(e.currentTarget.value) });
    }

    private onChangeFTPAddress(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ ftpAddress: e.target.value });
    }

    private onChangeFTPPort(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ ftpPort: e.target.value });
    }

    private onChangeFTPRemoteDirectory(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ ftpRemoteDirectory: e.target.value });
    }

    private onChangeFTPLocalDirectory(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ ftpLocalDirectory: e.target.value });
    }

    private onChangeDescription(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ description: e.target.value });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(BANK);
    }

    private async getCountries() {
        const { data } = await locationService.getCountries();

        const state = {
            countryId: this.state.countryId === 0 ? 0 : this.state.countryId.id,
            countryList: data.countries,
            loading: false
        };

        this.setState(state);

    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { bankName, bankCode, countryId, webSite, description, isActive, isEdit } = this.state;

        //Submit Validation
        if (
            bankNameValidator(bankName) !== "" ||
            bankCodeValidator(bankCode) !== "" ||
            countryValidator(countryId) !== "" ||
            webSiteValidator(webSite) !== ""

        ) {

            this.setState({
                bankNameError: bankNameValidator(bankName),
                bankCodeError: bankCodeValidator(bankCode),
                countryError: countryValidator(countryId),
                webSiteError: webSiteValidator(webSite)
            });
            return false;
        }

        return true;
    }
    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { bankName, bankCode, countryId, webSite, ftpAddress, ftpPort, ftpRemoteDirectory, ftpLocalDirectory, description, isActive, isEdit } = this.state;

        if (this.formValidation()) {

            const model = {
                bankName: sqlInjectValidator(bankName),
                bankCode: sqlInjectValidator(bankCode),
                countryId: sqlInjectValidator(String(countryId.id)),
                webSite: sqlInjectValidator(webSite),
                ftpAddress: sqlInjectValidator(ftpAddress),
                ftpPort: sqlInjectValidator(ftpPort),                
                ftpRemoteDirectory: sqlInjectValidator(ftpRemoteDirectory),   
                ftpLocalDirectory: sqlInjectValidator(ftpLocalDirectory),   
                description: sqlInjectValidator(description),
                isActive
            };

            if (isEdit) await this.editBank(model);
            else await this.createBank(model);
        }
    }

    private async createBank(model: any) {
        const data = await bankService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editBank(model: any) {
        const data = await bankService.update({ ...model, id: this.props.match.params.bankId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private renderBankName() {
        const { bankName, bankNameError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="bankName"
                    label="Banka Adı"
                    placeholder="Banka adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeBankName}
                    value={bankName}
                    name="bankName"
                    autoComplete="bankName"
                    helperText={bankNameError}
                />
            </FormControl>
        );
    }

    private renderBankCode() {
        const { bankCode, bankCodeError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="bankCode"
                    label="Banka Kodu"
                    placeholder="Banka kodu yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeBankCode}
                    value={bankCode}
                    name="bankCode"
                    autoComplete="bankCode"
                    helperText={bankCodeError}
                />
            </FormControl>
        );
    }

    private renderCountries() {
        const { countryId, countryList, countryError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="countryName" value={countryId} valueKey="id" options={countryList} onChange={this.onChangeCountries} placeholder="Ülke *"></CustomSelect>
                <span className="errorMessage">{countryError}</span>
            </FormControl >
        );
    }


    private renderWebSite() {
        const { webSite, webSiteError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="webSite"
                    label="Website"
                    placeholder="Website yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeWebSite}
                    value={webSite}
                    name="webSite"
                    autoComplete="webSite"
                    helperText={webSiteError}
                />
            </FormControl>
        );
    }

    private renderFTPAddress() {
        const { ftpAddress, ftpAddressError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="ftpAddress"
                    label="ftpAddress"
                    placeholder="ftpAddress yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeFTPAddress}
                    value={ftpAddress}
                    name="ftpAddress"
                    autoComplete="ftpAddress"
                    helperText={ftpAddressError}
                />
            </FormControl>
        );
    }

    private renderFTPPort() {
        const { ftpPort, ftpPortError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="ftpPort"
                    label="ftpPort"
                    placeholder="ftpPort yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeFTPPort}
                    value={ftpPort}
                    name="ftpPort"
                    autoComplete="ftpPort"
                    helperText={ftpPortError}
                />
            </FormControl>
        );
    }

    private renderFTPRemoteDirectory() {
        const { ftpRemoteDirectory, ftpRemoteDirectoryError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="ftpRemoteDirectory"
                    label="ftpRemoteDirectory"
                    placeholder="ftpRemoteDirectory yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeFTPRemoteDirectory}
                    value={ftpRemoteDirectory}
                    name="ftpRemoteDirectory"
                    autoComplete="ftpRemoteDirectory"
                    helperText={ftpRemoteDirectoryError}
                />
            </FormControl>
        );
    }

    private renderFTPLocalDirectory() {
        const { ftpLocalDirectory, ftpLocalDirectoryError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="ftpLocalDirectory"
                    label="ftpLocalDirectory"
                    placeholder="ftpLocalDirectory yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeFTPLocalDirectory}
                    value={ftpLocalDirectory}
                    name="ftpLocalDirectory"
                    autoComplete="ftpLocalDirectory"
                    helperText={ftpLocalDirectoryError}
                />
            </FormControl>
        );
    }

    private renderDescription() {
        const { description } = this.state;


        return (
            <FormControl margin="none" style={{ width: '98%', height: 'auto' }} className="input">
                <TextField
                    id="description"
                    label="Açıklama"
                    placeholder="Açıklama yazınız"
                    margin="normal"
                    variant="outlined"
                    multiline
                    onChange={this.onChangeDescription}
                    value={description}
                    name="description"
                    autoComplete="description"
                    rows={6}
                />
            </FormControl>
        );
    }


    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: BANK, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">
                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Banka {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan banka bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda banka ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Banka {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderBankName()}
                                    {this.renderBankCode()}
                                    {this.renderCountries()}
                                    {this.renderWebSite()}
                                    {this.renderFTPAddress()}
                                    {this.renderFTPPort()}
                                    {this.renderFTPRemoteDirectory()}
                                    {this.renderFTPLocalDirectory()}
                                    {this.renderDescription()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(BankForm);
