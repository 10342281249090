import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { resourceTypeService } from '../../service';
import { RESOURCETYPE } from '../../routes/paths';

import Error from '../NotFound/NotFound'


import { resourceTypeNameValidator } from '../../utils/resourcetype.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IResourceTypeFormState {
    resourceTypeName: string;
    resourceTypeNameError: string,
    isActive: boolean;
    isSuccess: string,
    isEdit: boolean;
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface IResourceTypeFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class ResourceTypeForm extends Component {
    public readonly state: IResourceTypeFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            resourceTypeName: "",
            resourceTypeNameError: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeResourceTypeName = this.onChangeResourceTypeName.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }


    componentWillMount() {
        if (this.props.match.params.resourceTypeId) this.getEditableResourceType();
        else this.setState({ loading: false });
    }

    private async getEditableResourceType() {
        const { data, isSuccess, errorCode, errorMessage } = await resourceTypeService.getById(this.props.match.params.resourceTypeId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            const state = {
                ...data,
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });
        }
    }

    /** EVENT LISTENERS */

    private onChangeResourceTypeName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ resourceTypeName: e.target.value, resourceTypeError: resourceTypeNameValidator(e.currentTarget.value) });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(RESOURCETYPE);
    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { resourceTypeName } = this.state;

        //Submit Validation
        if (
            resourceTypeNameValidator(resourceTypeName) !== ""

        ) {

            this.setState({
                resourceTypeNameError: resourceTypeNameValidator(resourceTypeName)
            });
            return false;
        }

        return true;
    }

    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { resourceTypeName, isActive, isEdit } = this.state;

        const model = {
            resourceTypeName: sqlInjectValidator(resourceTypeName),
            isActive
        };

        if (this.formValidation()) {
            if (isEdit) await this.editResourceType(model);
            else await this.createResourceType(model);
        }
    }

    private async createResourceType(model: any) {
        const data = await resourceTypeService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editResourceType(model: any) {
        const data = await resourceTypeService.update({ ...model, id: this.props.match.params.resourceTypeId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private renderResourceTypeyName() {
        const { resourceTypeName, resourceTypeNameError } = this.state;


        return (
            <FormControl margin="none" style={{ width: '98%' }} className="input">
                <TextField
                    id="resourceTypeName"
                    label="Kaynak Tipi Adı"
                    placeholder="Kaynak Tpi adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeResourceTypeName}
                    value={resourceTypeName}
                    name="resourceTypeName"
                    autoComplete="resourceTypeName"
                    helperText={resourceTypeNameError}
                />
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: RESOURCETYPE, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Kaynak Tipi {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan kaynak tipi bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda kaynak tipi ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Kaynak Tipi {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderResourceTypeyName()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(ResourceTypeForm);
