import React, { Component, ChangeEvent } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid"
import Icon from "@material-ui/core/Icon"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import FormControl from "@material-ui/core/FormControl"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { cookieService, resourceService, posStatementsService, userService } from '../../service';

interface IPosStatementValorDateState {
    list: [],
    loading: boolean,
    focusedInput: any,
    resources: any,
    searchText: string,
    totalCount: number,
    searchTotalCount: number,
    sortType: string,
    currentPage: number,
    selectedRow: number,
    orderBy: number
}


export default class PosStatementValorDate extends Component {
    public readonly state: IPosStatementValorDateState;
    public props: any;

    public readonly LANGUAGE_NAME = 'LANGUAGE_TOKEN';

    constructor(props: any) {
        super(props);
        this.props = props;

        this.state = {
            list: [],
            loading: false,
            focusedInput: null,
            resources: [],
            searchText: "",
            totalCount: 0,
            searchTotalCount: 0,
            selectedRow: 25,
            currentPage: 0,
            sortType: "asc",
            orderBy: 0
        }

        this.getData = this.getData.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
    }

    componentWillMount() {
        this.getResources();
        this.getData();
    }


    private async getResources() {
        const resourceLanguage: any = cookieService.resourceItem(this.LANGUAGE_NAME);
        const { data } = await resourceService.language(resourceLanguage);
        var resources: any = {};
        data.resources.forEach((item: any) => {
            resources[item.resourceKey] = item.value;
        });

        this.setState({ resources: resources });
    }


    private async getData() {
        const { searchText, currentPage, orderBy, selectedRow } = this.state;

        const model = {
            searchText,
            pagingParameters: {
                rowsPerPage: selectedRow,
                pageNumber: currentPage,
                orderBy,
            }
        }

        const { data } = await posStatementsService.valorDate(model);
        this.setState({ list: data.posStatementTotalByValorDateItem, totalCount: data.totalCount, searchTotalCount: data.searchTotalCount, loading: false });
    }


    private async getExportExcel() {
        return await posStatementsService.valorDateDownload({ searchText: this.state.searchText, pagingParameters: { rowsPerPage: 99999 } });
    }



    private handleSearch({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        this.setState({ searchText: value });
        this.getData();
    }

    private handleChangePage(_: any, page: number) {
        this.setState({ currentPage: page, loading: true });
        this.getData();
    }

    private handleSort(orderBy: number) {
        this.setState({ orderBy, loading: true });
        this.getData();
    }

    private async handleRowsPerPageChange({ target: { value } }: any) {
        const selectedRow = Number(value);
        await this.setState({ selectedRow, loading: true });
        await this.getData();
    }


    private renderSearch() {
        const { searchText } = this.state
        return (
            <FormControl margin="none" fullWidth className="input searchBox" required>
                <TextField
                    id="search"
                    placeholder="Arama"
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleSearch}
                    value={searchText}
                    name="search"
                    autoComplete="search"
                    style={{ marginRight: -40, marginTop: 20 }}
                />
            </FormControl>
        );
    }

    private renderPagination() {
        const { searchTotalCount, selectedRow, currentPage } = this.state;
        let pageCount = 1;
        let pages = [];
        searchTotalCount <= selectedRow ? pageCount = 1 : pageCount = (searchTotalCount / selectedRow)

        for (let i = 1; i <= Math.ceil(pageCount); i++) {
            if (currentPage + 1 < i + 3 && currentPage + 1 > i - 3) {
                pages[i - 1] = i;
            }
        }

        const listItems = pages.map((page) =>
            <>
                {currentPage + 1 === page ? <a onClick={() => this.handleChangePage(null, page - 1)} style={{ color: '#408af8' }}>{page}</a> : <a onClick={() => this.handleChangePage(null, page - 1)}>{page}</a>}
            </>
        );

        return listItems;
    }



    private renderAmountConvert(amount: string) {
        if (amount == "0") {
            return "0,00";
        }

        if (amount.indexOf(".") === -1) {
            return amount + ",00";
        }

        let p = "";
        let isaretli = ""

        let sayi = amount.split(".");
        let net = sayi[0];
        let ondalik = sayi[1];

        net.indexOf("-") != -1 ? isaretli = "-" : null
        net = net.replace("-", "");

        ondalik == undefined ? ondalik = "00" : null
        ondalik.length == 1 ? ondalik += "0" : null

        let tersSayi = ((net.split("")).reverse()).join("");

        for (let i = 0; i < tersSayi.length; i++) {
            if (i % 3 == 0 && i != 0) p += "."
            p += tersSayi.substr(i, 1);
        }


        return isaretli + (((p.split("")).reverse()).join("")) + "," + ondalik;
    }


    private renderRowHeaders() {
        return (
            <TableRow>
                <TableCell>
                    <TableSortLabel>
                        Üye İşyeri Adı
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                        Üye İşyeri Numarası
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                        Valor Tarihi
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                        Banka
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                        IBAN
                    </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: 200 }}>
                    <TableSortLabel>
                        Net Tutar
                    </TableSortLabel>
                </TableCell>
            </TableRow >
        );
    }

    private renderTable() {
        const { list, searchText, totalCount, searchTotalCount, selectedRow, currentPage, resources, loading } = this.state;
        return (

            <Grid container>
                <Grid item xs={8}>

                    <TablePagination
                        id="TopPagination"
                        rowsPerPageOptions={[25, 50, 100, 250]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={selectedRow}
                        onChangeRowsPerPage={this.handleRowsPerPageChange}
                        page={currentPage}
                        labelDisplayedRows={() => false}
                        labelRowsPerPage={resources.LabelViewData}
                        onChangePage={() => false}
                    />

                </Grid>
                <Grid item xs={2}>

                </Grid>
                <Grid item xs={2}>

                    <div className="contentHeader" style={{ border: 'none' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            className="actionsButton"
                            fullWidth
                            type="submit">
                            EYLEMLER <Icon className="fas fa-ellipsis-v"></Icon>
                            <ul className="actions">
                                <h6>HIZLI EYLEMLER</h6>
                                <li onClick={() => this.getExportExcel()}><Icon className="far fa-file-excel"></Icon> Excel'e aktar</li>
                            </ul>
                        </Button>
                    </div>

                </Grid>

                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            {this.renderRowHeaders()}
                        </TableHead>
                        <TableBody>
                            {list.map((valorDate: any) => (
                                <TableRow key={valorDate.id}>
                                    <TableCell scope="row">{valorDate.isYeriAdi}</TableCell>                                    
                                    <TableCell>{valorDate.isyeri}</TableCell>
                                    <TableCell>{valorDate.valorTarihi.substring(0, 10)}</TableCell>
                                    <TableCell>{valorDate.bankName}</TableCell>
                                    <TableCell>{valorDate.iban}</TableCell>
                                    <TableCell>{this.renderAmountConvert(String(valorDate.netTutar))} {valorDate.currencyCode}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
                <TablePagination
                    id="Pagination"
                    rowsPerPageOptions={[0]}
                    component="div"
                    count={searchTotalCount}
                    rowsPerPage={selectedRow}
                    onChangeRowsPerPage={this.handleRowsPerPageChange}
                    page={currentPage}
                    labelDisplayedRows={({ from, to, count }) => `${count} ${resources.LabelPagination2} ${from} - ${to} ${resources.LabelPagination3}.`}
                    labelRowsPerPage="Görüntülenen Kayıt"
                    backIconButtonProps={{
                        'aria-label': 'Önceki Sayfa',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Sonraki Sayfa',
                    }}
                    onChangePage={this.handleChangePage}
                />
                <span className="total">{"(" + totalCount + " " + resources.LabelPagination1 + ")"}</span>
                <div id="PageList" style={{ position: 'relative', left: '60%' }}>
                    {currentPage > 0 ? <a onClick={() => this.handleChangePage(null, this.state.currentPage - 1)}>{resources.LabelPrevious}</a> : null}
                    {currentPage > 3 ? <><a onClick={() => this.handleChangePage(null, 0)}>1</a><a >...</a></> : null}

                    {loading === false ? this.renderPagination() : null}

                    {(currentPage + 1) < (Math.floor((searchTotalCount / selectedRow)) - 2) ? <a >...</a> : null}

                    {(currentPage) + 1 < (Math.ceil((searchTotalCount / selectedRow)) - 2) ? <a onClick={() => this.handleChangePage(null, Math.ceil((searchTotalCount / selectedRow) - 1))}>{Math.ceil((searchTotalCount / selectedRow))}</a> : null}
                    {(currentPage + 1) < (Math.ceil(searchTotalCount / selectedRow)) ? <a onClick={() => this.handleChangePage(null, this.state.currentPage + 1)}> {resources.LabelNext}</a> : null}
                </div>

            </Grid>
        )
    }

    render() {
        return (
            <Paper>
                {this.state.loading ? <CircularProgress className="progress" /> : this.renderTable()}
            </Paper>
        )
    }
}
