//Country

export const countryNameValidator = (countryName: string): string => {
    if (countryName === "") return 'Ülke adı alanını boş geçemezsiniz!'
    return '';
}

export const countryCodeValidator = (countryCode: string): string => {
    if (countryCode === "") return 'Ülke kodu alanını boş geçemezsiniz!'
    return '';
}

export const countryPhoneCodeValidator = (countryPhoneCode: string): string => {
    if (countryPhoneCode === "0") return 'Ülke telefon kodu alanını boş geçemezsiniz!'
    return '';
}

