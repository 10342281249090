import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { userService, locationService } from '../../service'
import { USER } from '../../routes/paths'
import CustomSelect from '../layout/CustomSelect'

import Error from '../NotFound/NotFound'

import { companyValidator, nameSurnameValidator, emailAddressValidator, passwordValidator, mobilePhoneNumberValidator } from '../../utils/user.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IUserFormState {
    companyId: any;
    companyList: [];
    companyError: string;
    nameSurname: string;
    nameSurnameError: string;
    emailAddress: string;
    emailAddressError: string;
    password: string;
    passwordError: string;
    mobilePhoneNumber: string;
    mobilePhoneNumberError: string;
    isActive: boolean;
    isSuccess: string,
    isEdit: boolean;
    showPassword: boolean;
    loading: boolean;
    isPosStatement: boolean;
    isAccounting: boolean;
    isReporting: boolean;
    isJustDebit: boolean;
    isJustStatement: boolean;
    isJustWebUser: boolean;
    isExcelAndPdfExport: boolean;
    errorCode: string;
    errorMessage: string;
}

export interface IUserFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class UserForm extends Component {
    public readonly state: IUserFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            companyId: 0,
            companyList: [],
            companyError: "",
            nameSurname: "",
            nameSurnameError: "",
            emailAddress: "",
            emailAddressError: "",
            password: "",
            passwordError: "",
            mobilePhoneNumber: "",
            mobilePhoneNumberError: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            showPassword: false,
            loading: true,
            isPosStatement: false,
            isAccounting: false,
            isReporting: false,
            isJustDebit: false,
            isJustStatement: false,
            isJustWebUser: false,
            isExcelAndPdfExport: false,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeNameSurname = this.onChangeNameSurname.bind(this);
        this.onChangeEmailAddress = this.onChangeEmailAddress.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeMobilePhoneNumber = this.onChangeMobilePhoneNumber.bind(this);
        this.onChangeCompany = this.onChangeCompany.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangePosStatement = this.onChangePosStatement.bind(this);
        this.onChangeAccounting = this.onChangeAccounting.bind(this);
        this.onChangeReporting = this.onChangeReporting.bind(this);
        this.onChangeJustDebit = this.onChangeJustDebit.bind(this);
        this.onChangeJustStatement = this.onChangeJustStatement.bind(this);
        this.onChangeJustWebUser = this.onChangeJustWebUser.bind(this);
        this.onChangeExcelAndPdfExport = this.onChangeExcelAndPdfExport.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.userId) this.getEditableUser();
        else this.getCompanies()

        this.form = React.createRef();
    }

    private async getEditableUser() {
        const { data, isSuccess, errorCode, errorMessage } = await userService.getById(this.props.match.params.userId);

        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {

            await this.getCompanies();
            const state = {
                ...data,
                companyId: this.state.companyList.find((item: any) => item.id === data.companyId),
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });

        }
    }

    /** EVENT LISTENERS */

    private onChangeNameSurname(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ nameSurname: e.target.value, nameSurnameError: nameSurnameValidator(e.currentTarget.value) });
    }

    private onChangeEmailAddress(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ emailAddress: e.target.value, emailAddressError: emailAddressValidator(e.currentTarget.value) });
    }

    private onChangePassword(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ password: e.target.value, passwordError: passwordValidator(e.currentTarget.value) });
    }

    private onChangeMobilePhoneNumber(e: ChangeEvent<HTMLInputElement>) {
        if (Number(e.target.value) || e.target.value == "") {
            this.setState({ mobilePhoneNumber: Number(e.target.value), mobilePhoneNumberError: mobilePhoneNumberValidator(e.currentTarget.value) });
        }
    }

    private onChangeCompany(companyId: any) {
        this.setState({ companyId, companyError: companyValidator(companyId) });
    }

    handleClickShowPassword() {
        this.setState(state => ({ showPassword: !this.state.showPassword }));
    };

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    private onChangePosStatement(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isPosStatement: e.target.checked });
    }

    private onChangeAccounting(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isAccounting: e.target.checked });
    }

    private onChangeReporting(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isReporting: e.target.checked });
    }

    private onChangeJustDebit(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isJustDebit: e.target.checked });
    }

    private onChangeJustStatement(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isJustStatement: e.target.checked });
    }

    private onChangeJustWebUser(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isJustWebUser: e.target.checked });
    }

    private onChangeExcelAndPdfExport(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isExcelAndPdfExport: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(USER);
    }

    private async getCompanies(useDefault: boolean = true) {
        const { data } = await locationService.getCompaniesHQ();

        const state = {
            companyId: useDefault ? 0 : this.state.companyId.id,            
            companyList: data.companies,
            loading: false
        };

        this.setState(state);

    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (            
            this.state.errorMessage !== "" ? <Redirect to={{ pathname: USER, state: { errorMessage: this.state.errorMessage } }} /> : null
        );
    }

    private formValidation() {
        const { companyId, nameSurname, emailAddress, password, mobilePhoneNumber } = this.state;

        //Submit Validation
        if (
            companyValidator(companyId) !== "" ||
            nameSurnameValidator(nameSurname) !== "" ||
            emailAddressValidator(emailAddress) !== "" ||
            /*passwordValidator(password) !== "" || kaan*/
            mobilePhoneNumberValidator(String(mobilePhoneNumber)) !== ""
        ) {

            this.setState({
                companyError: companyValidator(companyId),
                nameSurnameError: nameSurnameValidator(nameSurname),
                emailAddressError: emailAddressValidator(emailAddress),
                passwordError: passwordValidator(password),
                mobilePhoneNumberError: mobilePhoneNumberValidator(String(mobilePhoneNumber))
            });
            return false;
        }

        return true;
    }

    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });

        const { nameSurname, emailAddress, password, mobilePhoneNumber, companyId, isPosStatement, isAccounting, isReporting, isJustDebit, isJustStatement, isJustWebUser, isExcelAndPdfExport, isActive, isEdit } = this.state;
        
        const model = {
            nameSurname: sqlInjectValidator(nameSurname),
            emailAddress: sqlInjectValidator(emailAddress),
            password,
            mobilePhoneNumber: mobilePhoneNumber,
            companyId: sqlInjectValidator(String(companyId.id)),
            isPosStatement,
            isAccounting,
            isReporting,
            isJustDebit,
            isJustStatement,
            isJustWebUser,
            isExcelAndPdfExport,
            isActive
        };        
        
        if (this.formValidation()) {
            if (isEdit) await this.editUser(model);
            else await this.createUser(model);
            
        }
    }

    private async createUser(model: any) {
        const data = await userService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editUser(model: any) {
        //console.log(model)
        const data = await userService.update({ ...model, id: this.props.match.params.userId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });        
    }

    private renderNameSurname() {
        const { nameSurname, nameSurnameError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="nameSurname"
                    label="Kullanıcı Adı"
                    placeholder="Kullanıcı adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeNameSurname}
                    value={nameSurname}
                    name="nameSurname"
                    autoComplete="nameSurname"
                    helperText={nameSurnameError}
                />
            </FormControl>
        );
    }

    private renderEmailAddress() {
        const { emailAddress, emailAddressError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    type="email"
                    id="emailAddress"
                    label="Eposta Adresi"
                    placeholder="Eposta adresi yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeEmailAddress}
                    value={emailAddress}
                    name="emailAddress"
                    autoComplete="emailAddress"
                    helperText={emailAddressError}
                />
            </FormControl>
        );
    }

    private renderPassword() {
        const { password, passwordError } = this.state;

        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="password"
                    name="password"
                    autoComplete="password"
                    value={password}
                    onChange={this.onChangePassword}
                    label="Şifre"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="********"
                    margin="normal"
                    variant="outlined"
                    helperText={passwordError}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Şifreyi göster"
                                    onClick={this.handleClickShowPassword}
                                    style={{ 'border': 'none', 'opacity': .2 }}
                                >
                                    {this.state.showPassword ? <Icon className="far fa-eye"></Icon> : <Icon className="far fa-eye-slash"></Icon>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
        );
    }

    private renderMobilePhoneNumber() {
        const { mobilePhoneNumber, mobilePhoneNumberError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="mobilePhoneNumber"
                    label="Telefon Numarası"
                    placeholder="Telefon numarası yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeMobilePhoneNumber}
                    value={mobilePhoneNumber != "" ? mobilePhoneNumber : ""}
                    name="mobilePhoneNumber"
                    autoComplete="mobilePhoneNumber"
                    helperText={mobilePhoneNumberError}
                />
            </FormControl>
        );
    }

    private renderCompanies() {
        const { companyId, companyList, companyError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" style={{ width: '98%' }} className="input inputSelect">
                <CustomSelect labelName="companyName" value={companyId} valueKey="id" options={companyList} onChange={this.onChangeCompany} placeholder="Firma *"></CustomSelect>
                <span className="errorMessage">{companyError}</span>
            </FormControl >
        );
    }

    renderAccessPermission() {
        const { isPosStatement, isAccounting, isReporting, isJustDebit, isJustStatement, isJustWebUser, isExcelAndPdfExport } = this.state;

        return (
            <div style={{ width: '100%', marginLeft: 10 }}>

                <FormControlLabel
                    control={
                        <Checkbox
                            value={isPosStatement}
                            color="primary"
                            onChange={this.onChangePosStatement}
                            checked={isPosStatement}
                        />
                    }
                    className="checkbox"
                    label="Pos Hareketleri"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            value={isExcelAndPdfExport}
                            color="primary"
                            onChange={this.onChangeExcelAndPdfExport}
                            checked={isExcelAndPdfExport}
                        />
                    }
                    className="checkbox"
                    label="Excel ve PDF Export"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            value={isJustDebit}
                            color="primary"
                            onChange={this.onChangeJustDebit}
                            checked={isJustDebit}
                        />
                    }
                    className="checkbox"
                    label="Sadece Borç Görebilir"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            value={isJustStatement}
                            color="primary"
                            onChange={this.onChangeJustStatement}
                            checked={isJustStatement}
                        />
                    }
                    className="checkbox"
                    label="Bakiye Göremez"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            value={isJustWebUser}
                            color="primary"
                            onChange={this.onChangeJustWebUser}
                            checked={isJustWebUser}
                        />
                    }
                    className="checkbox"
                    label="Mobil Uygulama Kullanamaz"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            value={isAccounting}
                            color="primary"
                            onChange={this.onChangeAccounting}
                            checked={isAccounting}
                        />
                    }
                    className="checkbox"
                    label="Muhasebeleştirme"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            value={isReporting}
                            color="primary"
                            onChange={this.onChangeReporting}
                            checked={isReporting}
                        />
                    }
                    className="checkbox"
                    label="Raporlama"
                />                
            </div>
        )
    }


    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
    if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: USER, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">
                                    
                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Kullanıcı {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan kullanıcı bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda kullanıcı ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Kullanıcı {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderNameSurname()}
                                    {this.renderEmailAddress()}
                                    {this.state.isEdit ? null : this.renderPassword()}
                                    {this.renderMobilePhoneNumber()}
                                    {this.renderCompanies()}
                                    {this.renderAccessPermission()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(UserForm);
