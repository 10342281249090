import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Wrapper from '../layout/Wrapper'
import {HOME} from '../../routes/paths'

interface INotFoundState {
}

interface INotFoundProps {
    errorCode: string;
    errorMessage: string;
}

export default class NotFound extends Component {
    public readonly state: INotFoundState;
    public readonly props: INotFoundProps;

    constructor(props: any) {
        super(props);
        this.props = props;

        this.state = {
        }

    }

    render() {
        return (
            <Wrapper>
                <Grid container className="middle-text">
                    <Grid xs={12}>
                        <Typography variant="h1" style={{ fontSize: 148, color: 'gray', marginTop: 40 }}>404</Typography>
                        <Typography variant="subheading">Aradığınız sayfa bulunamadı!</Typography>
                        <Button>Kokpit'e Dön</Button>
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
}
