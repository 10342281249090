//City

export const cityNameValidator = (cityName: string): string => {
    if (cityName === "") return 'Şehir adı alanını boş geçemezsiniz!'
    return '';
}

export const countryValidator = (countryId: number): string => {
    if (countryId === 0) return 'Ülke seçiniz!'
    return '';
}