import XHRService from "./xhr.service";

export default interface IPartnerRequest {
    id?: number;
    partnerName: string,
    contactPerson: string,
    emailAddress: string,
    mobilePhoneNumber: string,
    countryId: number,
    districtId: number,
    cityId: number,
    address: string,
    description: string,
    userId: number,
    isActive: boolean,
}


interface IPartner {
    id: number,
    partnerName: string,
    contactPerson: string,
    emailAddress: string,
    mobilePhoneNumber: string,
    countryId: number,
    districtId: number,
    cityId: number,
    address: string,
    description: string,
    userId: number,
    isActive: boolean;
}

interface IPartnerResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IPartner
}

interface IPartnerListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        partners: IPartner[]
    }
}

export default class PartnerService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IPartnerResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Partner/CreatePartner", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IPartnerRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Partner/UpdatePartner", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<IPartnerResponse> {
        const { isSuccess }: any = await this.XHRService.post("/Partner/DeletePartner", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            searchText,            
            "countryId": 0,
            "cityId": 0,
            "districtId": 0,
            "userId": 0,
            isActive,
            "pagingParameters": {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/Partner/GetPartners", model).catch(() => ({ isSuccess: false }));
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/Partner/GetPartnerById?id=${id}`).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/Partner/GetPartnersExcel", model);
    }



}
