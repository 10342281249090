import XHRService from "./xhr.service";

export default interface IBankAccountRequest {
    id?: number,
    companyId: number,
    bankId: number,
    currencyId: number,
    isActive: boolean,
    searchText: string
}


interface IBankAccount {
    id: number,
    companyId: number,
    bankId: number,
    currencyId: number,
    isActive: boolean,
    searchText: string
}

interface IBankAccountResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IBankAccount
}

interface IBankAccountListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        bankAccounts: IBankAccount[]
    }
}

export default class BankAccountService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IBankAccountResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/BankAccount/CreateBankAccount", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorMessage, errorCode };
        return data;
    }

    public async update(model: IBankAccountRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/BankAccount/UpdateBankAccount", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<IBankAccountResponse> {
        const { isSuccess }: any = await this.XHRService.post("/BankAccount/DeleteBankAccount", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, clientType: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean, isValorAccount: boolean): Promise<any> {

        const model = {
            isValorAccount,
            searchText,    
            clientType,                    
            "bankId": 0,
            "companyId": 0,            
            "currencyId": 0,
            isActive,
            "pagingParameters": {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/BankAccount/GetBankAccounts", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/BankAccount/GetBankAccountsExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/BankAccount/GetBankAccountById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
