import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { bankCredentialService, locationService } from '../../service';
import { BANKCREDENTIAL } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import Error from '../NotFound/NotFound'

import { bankValidator, customerCodeValidator, userNameValidator, passwordCodeValidator, companyValidator } from '../../utils/bankcredential.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IBankCredentialFormState {
    bankList: [],
    bankId: any,
    bankError: string,
    customerCode: string,
    customerCodeError: string,
    userName: string,
    userNameError: string,
    password: string,
    passwordError: string,
    companyCode: string,
    serviceId: string,
    environment: string,
    serviceCompanyName: string,
    companyKey: string,
    companyList: [],
    companyId: any,
    companyError: string,
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string,   
    isDisableCustomerCode: boolean, 
    isDisableUserName: boolean, 
    isDisablePassword: boolean,
    isDisableCompanyCode: boolean,
    isDisableServiceId: boolean,
    isDisableEnvironment: boolean,
    isDisableServiceCompanyName: boolean,
    isDisableCompanyKey: boolean,
}

export interface IBankCredentialFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class BankCredentialForm extends Component {
    public readonly state: IBankCredentialFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            bankList: [],
            bankId: 0,
            bankError: "",
            customerCode: "",
            customerCodeError: "",
            userName: "",
            userNameError: "",
            password: "",
            passwordError: "",
            companyCode: "",
            serviceId: "",
            environment: "",
            serviceCompanyName: "",
            companyKey: "",
            companyList: [],
            companyId: 0,
            companyError: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: "",   
            isDisableCustomerCode: false, 
            isDisableUserName: false, 
            isDisablePassword: false,         
            isDisableCompanyCode: false,
            isDisableServiceId: false,
            isDisableEnvironment: false,
            isDisableServiceCompanyName: false,
            isDisableCompanyKey: false,
        };

        this.onChangeBank = this.onChangeBank.bind(this);
        this.onChangeCustomerCode = this.onChangeCustomerCode.bind(this);
        this.onChangeUserName = this.onChangeUserName.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeCompanyCode = this.onChangeCompanyCode.bind(this);
        this.onChangeServiceId = this.onChangeServiceId.bind(this);
        this.onChangeEnvironment = this.onChangeEnvironment.bind(this);
        this.onChangeServiceCompanyName = this.onChangeServiceCompanyName.bind(this);
        this.onChangeCompanyKey = this.onChangeCompanyKey.bind(this);
        this.onChangeCompany = this.onChangeCompany.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.bankCredentialId) this.getEditableBankCredential();
        else { this.getBanks(); this.getCompanies(); }

        this.form = React.createRef();
    }

    private async getEditableBankCredential() {
        const { data, isSuccess, errorCode, errorMessage } = await bankCredentialService.getById(this.props.match.params.bankCredentialId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getBanks();
            await this.getCompanies();
            const state = {
                ...data,
                bankId: this.state.bankList.find((item: any) => item.id === data.bankId),
                companyId: this.state.companyList.find((item: any) => item.id === data.companyId),                
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });
            
            /*this.setState({isDisableCustomerCode: (
                this.state.bankId.id === 28) ? true : false});*/

            this.setState({isDisableUserName: (
                this.state.bankId.id === 11 ||
                this.state.bankId.id === 14 ||
                this.state.bankId.id === 15 ||
                this.state.bankId.id === 17) ? true : false});
                
            this.setState({isDisablePassword: (
                this.state.bankId.id === 11 ||
                this.state.bankId.id === 15 ||
                this.state.bankId.id === 5 ||
                this.state.bankId.id === 17) ? true : false});
    
            this.setState({isDisableCompanyCode: (
                this.state.bankId.id === 1 || 
                this.state.bankId.id === 2 || 
                this.state.bankId.id === 4 || 
                this.state.bankId.id === 8 || 
                this.state.bankId.id === 9 || 
                this.state.bankId.id === 10 || 
                this.state.bankId.id === 12 || 
                this.state.bankId.id === 13 || 
                this.state.bankId.id === 14 ||
                this.state.bankId.id === 15 || 
                this.state.bankId.id === 16 || 
                this.state.bankId.id === 17 || 
                this.state.bankId.id === 18 || 
                this.state.bankId.id === 19 ||
                this.state.bankId.id === 20 ||
                this.state.bankId.id === 23 ||
                this.state.bankId.id === 27 ||
                this.state.bankId.id === 28) ? true : false});
                
                this.setState({isDisableServiceId: (
                this.state.bankId.id === 1 || 
                this.state.bankId.id === 2 || 
                this.state.bankId.id === 3 ||
                this.state.bankId.id === 4 || 
                this.state.bankId.id === 5 ||
                this.state.bankId.id === 6 ||
                this.state.bankId.id === 7 ||
                this.state.bankId.id === 8 || 
                this.state.bankId.id === 9 || 
                this.state.bankId.id === 11 ||
                this.state.bankId.id === 13 ||
                this.state.bankId.id === 14 ||
                this.state.bankId.id === 15 || 
                this.state.bankId.id === 16 || 
                this.state.bankId.id === 17 || 
                this.state.bankId.id === 18 || 
                this.state.bankId.id === 19 ||
                this.state.bankId.id === 20 ||
                this.state.bankId.id === 21 ||
                this.state.bankId.id === 22 ||
                this.state.bankId.id === 23 ||
                this.state.bankId.id === 27 ||
                this.state.bankId.id === 28) ? true : false});
                
                this.setState({isDisableEnvironment: (
                this.state.bankId.id === 1 || 
                this.state.bankId.id === 2 || 
                this.state.bankId.id === 3 ||
                this.state.bankId.id === 4 || 
                this.state.bankId.id === 5 ||
                this.state.bankId.id === 6 ||
                this.state.bankId.id === 7 ||
                this.state.bankId.id === 8 || 
                this.state.bankId.id === 9 || 
                this.state.bankId.id === 10 || 
                this.state.bankId.id === 11 ||
                this.state.bankId.id === 12 || 
                this.state.bankId.id === 13 ||
                this.state.bankId.id === 14 ||
                this.state.bankId.id === 15 || 
                this.state.bankId.id === 16 || 
                this.state.bankId.id === 17 || 
                this.state.bankId.id === 18 || 
                this.state.bankId.id === 20 ||
                this.state.bankId.id === 21 ||
                this.state.bankId.id === 22 ||
                this.state.bankId.id === 23 ||
                this.state.bankId.id === 27 ||
                this.state.bankId.id === 28) ? true : false});
                
                this.setState({isDisableServiceCompanyName: (
                this.state.bankId.id === 1 || 
                this.state.bankId.id === 2 || 
                this.state.bankId.id === 3 ||
                this.state.bankId.id === 4 || 
                this.state.bankId.id === 5 ||
                this.state.bankId.id === 6 ||
                this.state.bankId.id === 7 ||
                this.state.bankId.id === 8 || 
                this.state.bankId.id === 9 || 
                this.state.bankId.id === 10 || 
                this.state.bankId.id === 11 ||
                this.state.bankId.id === 12 || 
                this.state.bankId.id === 13 ||
                this.state.bankId.id === 14 ||
                this.state.bankId.id === 15 || 
                this.state.bankId.id === 16 || 
                this.state.bankId.id === 17 || 
                this.state.bankId.id === 18 || 
                this.state.bankId.id === 19 || 
                this.state.bankId.id === 20 ||
                this.state.bankId.id === 21 ||
                this.state.bankId.id === 22 ||
                this.state.bankId.id === 23 ||
                this.state.bankId.id === 27 ||
                this.state.bankId.id === 28) ? true : false});
                
                this.setState({isDisableCompanyKey: (
                this.state.bankId.id === 1 || 
                this.state.bankId.id === 2 || 
                this.state.bankId.id === 3 ||
                this.state.bankId.id === 4 || 
                this.state.bankId.id === 5 ||
                this.state.bankId.id === 6 ||
                this.state.bankId.id === 7 ||
                this.state.bankId.id === 8 || 
                this.state.bankId.id === 9 || 
                this.state.bankId.id === 10 ||
                this.state.bankId.id === 11 ||
                this.state.bankId.id === 13 ||
                this.state.bankId.id === 14 ||
                this.state.bankId.id === 16 ||
                this.state.bankId.id === 18 || 
                this.state.bankId.id === 19 || 
                this.state.bankId.id === 20 ||
                this.state.bankId.id === 21 ||
                this.state.bankId.id === 22 ||
                this.state.bankId.id === 23 ||
                this.state.bankId.id === 27 ||
                this.state.bankId.id === 28) ? true : false});  
        }
    }

    /** EVENT LISTENERS */

    private onChangeBank(bankId: any) {
        this.setState({ bankId, bankError: bankValidator(bankId) });
        /*this.setState({isDisableCustomerCode: (
            bankId.id === 28) ? true : false});*/

        this.setState({isDisableUserName: (
            bankId.id === 11 || 
            bankId.id === 14 ||
            bankId.id === 15 ||
            bankId.id === 17) ? true : false});
            
        this.setState({isDisablePassword: (
            bankId.id === 5 ||
            bankId.id === 11 || 
            bankId.id === 15 ||
            bankId.id === 17) ? true : false});

        this.setState({isDisableCompanyCode: (
            bankId.id === 1 || 
            bankId.id === 2 || 
            bankId.id === 4 || 
            bankId.id === 8 || 
            bankId.id === 9 || 
            bankId.id === 10 || 
            bankId.id === 12 || 
            bankId.id === 13 || 
            bankId.id === 14 ||
            bankId.id === 15 || 
            bankId.id === 16 || 
            bankId.id === 17 || 
            bankId.id === 18 || 
            bankId.id === 19 ||
            bankId.id === 20 ||
            bankId.id === 23 ||
            bankId.id === 27 ||
            bankId.id === 28) ? true : false});
            
            this.setState({isDisableServiceId: (
            bankId.id === 1 || 
            bankId.id === 2 || 
            bankId.id === 3 ||
            bankId.id === 4 || 
            bankId.id === 5 ||
            bankId.id === 6 ||
            bankId.id === 7 ||
            bankId.id === 8 || 
            bankId.id === 9 || 
            bankId.id === 11 || 
            bankId.id === 13 ||
            bankId.id === 14 ||
            bankId.id === 15 || 
            bankId.id === 16 || 
            bankId.id === 17 || 
            bankId.id === 18 || 
            bankId.id === 19 ||
            bankId.id === 20 ||
            bankId.id === 21 ||
            bankId.id === 22 ||
            bankId.id === 23 ||
            bankId.id === 27 ||
            bankId.id === 28) ? true : false});
            
            this.setState({isDisableEnvironment: (
            bankId.id === 1 || 
            bankId.id === 2 || 
            bankId.id === 3 ||
            bankId.id === 4 || 
            bankId.id === 5 ||
            bankId.id === 6 ||
            bankId.id === 7 ||
            bankId.id === 8 || 
            bankId.id === 9 || 
            bankId.id === 10 || 
            bankId.id === 11 || 
            bankId.id === 12 || 
            bankId.id === 13 ||
            bankId.id === 14 ||
            bankId.id === 15 || 
            bankId.id === 16 || 
            bankId.id === 17 || 
            bankId.id === 18 || 
            bankId.id === 20 ||
            bankId.id === 21 ||
            bankId.id === 22 ||
            bankId.id === 23 ||
            bankId.id === 27 ||
            bankId.id === 28) ? true : false});
            
            this.setState({isDisableServiceCompanyName: (
            bankId.id === 1 || 
            bankId.id === 2 || 
            bankId.id === 3 ||
            bankId.id === 4 || 
            bankId.id === 5 ||
            bankId.id === 6 ||
            bankId.id === 7 ||
            bankId.id === 8 || 
            bankId.id === 9 || 
            bankId.id === 10 || 
            bankId.id === 11 || 
            bankId.id === 12 || 
            bankId.id === 13 ||
            bankId.id === 14 ||
            bankId.id === 15 || 
            bankId.id === 16 || 
            bankId.id === 17 || 
            bankId.id === 18 || 
            bankId.id === 19 || 
            bankId.id === 20 ||
            bankId.id === 21 ||
            bankId.id === 22 ||
            bankId.id === 23 ||
            bankId.id === 27 ||
            bankId.id === 28) ? true : false});
            
            this.setState({isDisableCompanyKey: (
            bankId.id === 1 || 
            bankId.id === 2 || 
            bankId.id === 3 ||
            bankId.id === 4 || 
            bankId.id === 5 ||
            bankId.id === 6 ||
            bankId.id === 7 ||
            bankId.id === 8 || 
            bankId.id === 9 || 
            bankId.id === 10 ||
            bankId.id === 11 || 
            bankId.id === 13 ||
            bankId.id === 14 ||
            bankId.id === 16 ||
            bankId.id === 18 || 
            bankId.id === 19 || 
            bankId.id === 20 ||
            bankId.id === 21 ||
            bankId.id === 22 ||
            bankId.id === 23 ||
            bankId.id === 27 ||
            bankId.id === 28) ? true : false});      
    }

    private onChangeCustomerCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ customerCode: e.target.value, customerCodeError: customerCodeValidator(e.currentTarget.value) });
    }

    private onChangeUserName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ userName: e.target.value, userNameError: userNameValidator(e.currentTarget.value) });
    }

    private onChangePassword(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ password: e.target.value, passwordError: "" });
    {/*this.setState({ password: e.target.value, passwordError: passwordCodeValidator(e.currentTarget.value) });*/}
    }

    private onChangeCompanyCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ companyCode: e.target.value });
    }

    private onChangeServiceId(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ serviceId: e.target.value });
    }

    private onChangeEnvironment(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ environment: e.target.value });
    }

    private onChangeServiceCompanyName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ serviceCompanyName: e.target.value });
    }

    private onChangeCompanyKey(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ companyKey: e.target.value });
    }

    private onChangeCompany(companyId: any) {
        this.setState({ companyId, companyError: companyValidator(companyId) });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(BANKCREDENTIAL);
    }

    private async getBanks() {
        const { data } = await locationService.getBanks();

        const state = {
            bankId: this.state.bankId === 0 ? 0 : this.state.bankId.id,
            bankList: data.banks
        };

        this.setState(state);

    }



    private async getCompanies() {
        const { data } = await locationService.getCompanies();

        const state = {
            companyId: this.state.companyId === 0 ? 0 : this.state.companyId.id,
            companyList: data.companies,
            loading: false
        };

        this.setState(state);

    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            this.state.errorMessage !== "" ? <Redirect to={{ pathname: BANKCREDENTIAL, state: { errorMessage: this.state.errorMessage } }} /> : null
        );
    }

    private formValidation() {
        const { bankId, customerCode, userName, password, companyId } = this.state;

        if (
            customerCodeValidator(customerCode) !== "" ||
            companyValidator(companyId) !== "" ||
            bankValidator(bankId) !== ""

        ) {

            this.setState({
                customerCodeError: customerCodeValidator(customerCode),
                companyError: companyValidator(companyId),
                bankError: bankValidator(bankId)
            });
            return false;
        }

        return true;
    }

    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { bankId, customerCode, userName, password, companyCode, serviceId, environment, serviceCompanyName, companyKey, companyId, isActive, isEdit } = this.state;

        if (this.formValidation()) {  
        const model = {
                bankId: bankId.id,
                customerCode: sqlInjectValidator(customerCode),
                userName: sqlInjectValidator(userName),
                password,
                companyCode: sqlInjectValidator(companyCode),
                serviceId: sqlInjectValidator(serviceId),
                environment: sqlInjectValidator(environment),
                serviceCompanyName: sqlInjectValidator(serviceCompanyName),
                companyKey: sqlInjectValidator(companyKey),
                companyId: companyId.id,
                isActive
            };

            if (isEdit) await this.editBankCredential(model);
            else await this.createBankCredential(model);
        }
    }

    private async createBankCredential(model: any) {
        const data = await bankCredentialService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editBankCredential(model: any) {
        const data = await bankCredentialService.update({ ...model, id: this.props.match.params.bankCredentialId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }


    private renderBanks() {
        const { bankId, bankList, bankError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="bankName" value={bankId} valueKey="id" options={bankList} onChange={this.onChangeBank} placeholder="Banka *"></CustomSelect>
                <span className="errorMessage">{bankError}</span>
            </FormControl>
        );
    }

    private renderCustomerCode() {
        const { customerCode, customerCodeError, isDisableCustomerCode } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="customerCode"
                    label={isDisableCustomerCode ? "Gerekli Değil" : "Müşteri Numarası"}
                    placeholder="Müşteri Numarası yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCustomerCode}
                    value={customerCode}
                    name="customerCode"
                    autoComplete="customerCode"
                    helperText={customerCodeError}
                    disabled={isDisableCustomerCode ? true : false}
                />
            </FormControl>
        );
    }

    private renderUserName() {
        const { userName, userNameError, isDisableUserName } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="userName"
                    label={isDisableUserName ? "Gerekli Değil" : "Kullanıcı Adı"}
                    placeholder="Kullanıcı adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeUserName}
                    value={userName}
                    name="userName"
                    autoComplete="userName"
                    helperText={userNameError}
                    disabled={isDisableUserName ? true : false}
                />
            </FormControl>
        );
    }

    private renderPassword() {
        const { password, passwordError, isDisablePassword } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="password"
                    label={isDisablePassword ? "Gerekli Değil" : "Şifre"}
                    placeholder="Şifre kodu yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangePassword}
                    value={password}
                    helperText={passwordError}
                    name="password"
                    autoComplete="password"
                    disabled={isDisablePassword ? true : false}
                />
            </FormControl>
        );
    }

    private renderCompanyCode() {
        const { companyCode, isDisableCompanyCode } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="companyCode"
                    label={isDisableCompanyCode ? "Gerekli Değil" : "Firma Kodu"}
                    placeholder="Firma kodu yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCompanyCode}
                    value={companyCode}
                    name="companyCode"
                    autoComplete="companyCode"
                    disabled={isDisableCompanyCode ? true : false}
                />
            </FormControl>
        );
    }

    private renderServiceId() {
        const { serviceId, isDisableServiceId } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="serviceId"
                    label={isDisableServiceId ? "Gerekli Değil" : "ServiceId"}
                    placeholder="serviceId yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeServiceId}
                    value={serviceId}
                    name="serviceId"
                    autoComplete="serviceId"
                    disabled={isDisableServiceId ? true : false}
                />
            </FormControl>
        );
    }

    private renderEnvironment() {
        const { environment, isDisableEnvironment } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="environment"
                    label={isDisableEnvironment ? "Gerekli Değil" : "Environment"}
                    placeholder="environment yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeEnvironment}
                    value={environment}
                    name="environment"
                    autoComplete="environment"
                    disabled={isDisableEnvironment ? true : false}
                />
            </FormControl>
        );
    }

    private renderServiceCompanyName() {
        const { serviceCompanyName, isDisableServiceCompanyName } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="serviceCompanyName"
                    label={isDisableServiceCompanyName ? "Gerekli Değil" : "Service Company Name"}
                    placeholder="serviceCompanyName yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeServiceCompanyName}
                    value={serviceCompanyName}
                    name="serviceCompanyName"
                    autoComplete="serviceCompanyName"
                    disabled={isDisableServiceCompanyName ? true : false}
                />
            </FormControl>
        );
    }

    private renderCompanyKey() {
        const { companyKey, isDisableCompanyKey } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="companyKey"
                    label={isDisableCompanyKey ? "Gerekli Değil" : "Company Key"}
                    placeholder="companyKey yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCompanyKey}
                    value={companyKey}
                    name="companyKey"
                    autoComplete="companyKey"
                    disabled={isDisableCompanyKey ? true : false}
                />
            </FormControl>
        );
    }


    private renderCompanies() {
        const { companyId, companyList, companyError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" required className="input inputSelect">
                <CustomSelect labelName="companyName" value={companyId} valueKey="id" options={companyList} onChange={this.onChangeCompany} placeholder="Firma *"></CustomSelect>
                <span className="errorMessage">{companyError}</span>
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: BANKCREDENTIAL, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Erişim Bilgileri {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan erişim bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda erişim bilgileri ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Erişim Bilgileri {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderBanks()}
                                    {this.renderCustomerCode()}
                                    {this.renderUserName()}
                                    {this.renderPassword()}
                                    {this.renderCompanyCode()}
                                    {this.renderServiceId()}
                                    {this.renderEnvironment()}
                                    {this.renderServiceCompanyName()}
                                    {this.renderCompanyKey()}
                                    {this.renderCompanies()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(BankCredentialForm);
