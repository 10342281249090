import React, { Component, ReactNode } from 'react'
import { Switch, Route, HashRouter, Redirect } from 'react-router-dom'
import ProtectedRoute from './protected'
import {
  LOGIN,
  REGISTER,
  REMEMBER,
  HOME,
  DOCUMENTATION,
  ACCOUNT,
  ACCOUNTBALANCEHISTORY,
  ACCOUNTSTATEMENT,
  BANK_STATEMENT,
  ACCOUNT_DETAIL,
  POSSTATEMENT,
  VALORDATE,
  COMPANY,
  ADD_NEW_COMPANY,
  EDIT_COMPANY,
  BANK,
  ADD_NEW_BANK,
  EDIT_BANK,
  BANKBRANCH,
  ADD_NEW_BANKBRANCH,
  EDIT_BANKBRANCH,
  BANKACCOUNT,
  ADD_NEW_BANKACCOUNT,
  EDIT_BANKACCOUNT,
  BANKCREDENTIAL,
  ADD_NEW_BANKCREDENTIAL,
  EDIT_BANKCREDENTIAL,
  BANKFTPCREDENTIAL,
  ADD_NEW_BANKFTPCREDENTIAL,
  EDIT_BANKFTPCREDENTIAL,
  COUNTRY,
  ADD_NEW_COUNTRY,
  EDIT_COUNTRY,
  CITY,
  ADD_NEW_CITY,
  EDIT_CITY,
  CURRENCY,
  ADD_NEW_CURRENCY,
  EDIT_CURRENCY,
  DISTRICT,
  ADD_NEW_DISTRICT,
  EDIT_DISTRICT,
  USER,
  ADD_NEW_USER,
  ADD_MULTI_USER,
  EDIT_USER,
  PROFILE,
  PASSWORD_RESET,
  PASSWORD_UPDATE,
  PERMISSION,
  ADD_NEW_PERMISSION,
  EDIT_PERMISSION,
  ROLE,
  ADD_NEW_ROLE,
  EDIT_ROLE,
  LANGUAGE,
  ADD_NEW_LANGUAGE,
  EDIT_LANGUAGE,
  RESOURCETYPE,
  ADD_NEW_RESOURCETYPE,
  EDIT_RESOURCETYPE,
  RESOURCE,
  ADD_NEW_RESOURCE,
  EDIT_RESOURCE,
  ACCOUNT_STATEMENT,
  REPORTS,
  PAYMENT,
  ADD_NEW_PAYMENT,
  BANKACCOUNTUSER,
  ADD_NEW_BANKACCOUNTUSER,
  EDIT_BANKACCOUNTUSER,
  COMPANYUSER,
  ADD_NEW_COMPANYUSER,
  EDIT_COMPANYUSER,
  USERPERMISSION,
  ADD_NEW_USERPERMISSION,
  EDIT_USERPERMISSION,
  ROLEPERMISSION,
  ADD_NEW_ROLEPERMISSION,
  EDIT_ROLEPERMISSION,
  NOTIFICATION,
  ADD_NEW_NOTIFICATION,
  EDIT_NOTIFICATION,
  INVOICE,
  ADD_NEW_INVOICE,
  EDIT_INVOICE,
  PARTNER,
  ADD_NEW_PARTNER,
  EDIT_PARTNER,
  NOTFOUND,
  EDIT_CONSENT_AGREEMENT,
  EDIT_CONSENT_RENEWAL,
  EDIT_CONSENT_CANCEL,
  BALANCEANALYSIS,
} from './paths'

//VIEWS
import Login from '../components/login/Login'
import Remember from '../components/remember/Remember'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Register from '../components/register/Register'
import Company from '../components/company/Company'
import Cockpit from '../components/cockpit/Cockpit'
import Documentation from '../components/documentation/Documentation'
import CompanyForm from '../components/company/CompanyForm'
import ConsentAgreementForm from '../components/company/ConsentAgreementForm'
import ConsentRenewalForm from '../components/company/ConsentRenewalForm'
import ConsentCancelForm from '../components/company/ConsentCancelForm'
import AccountStatement from '../components/accountStatement/AccountStatement'
import BankStatement from '../components/accountStatement/AccountStatement'
import PosStatement from '../components/posStatement/PosStatement'
import ValorDate from '../components/posValorDate/PosStatementValorDate'
import NotFound from '../components/NotFound/NotFound'

import Account from '../components/account/Account'
import AccountDetail from '../components/account/AccountDetail'

import Bank from '../components/bank/Bank'
import BankForm from '../components/bank/BankForm'

import BankBranch from '../components/bankBranch/BankBranch'
import BankBranchForm from '../components/bankBranch/BankBranchForm'

import BankAccount from '../components/bankAccount/BankAccount'
import BankAccountForm from '../components/bankAccount/BankAccountForm'

import BankCredential from '../components/bankCredential/BankCredential'
import BankCredentialForm from '../components/bankCredential/BankCredentialForm'

import BankFTPCredential from '../components/bankFTPCredential/BankFTPCredential'
import BankFTPCredentialForm from '../components/bankFTPCredential/BankFTPCredentialForm'

import Country from '../components/country/Country'
import CountryForm from '../components/country/CountryForm'

import City from '../components/city/City'
import CityForm from '../components/city/CityForm'

import District from '../components/district/District'
import DistrictForm from '../components/district/DistrictForm'

import Currency from '../components/currency/Currency'
import CurrencyForm from '../components/currency/CurrencyForm'

import User from '../components/user/User'
import UserForm from '../components/user/UserForm'
import UserMultiForm from '../components/multipleUser/MultipleUser'
import Profile from '../components/user/Profile'
import PasswordReset from '../components/user/PasswordReset'
import PasswordUpdate from '../components/user/PasswordUpdate'

import Permission from '../components/permission/Permission'
import PermissionForm from '../components/permission/PermissionForm'

import Partner from '../components/partner/Partner'
import PartnerForm from '../components/partner/PartnerForm'

import Role from '../components/role/Role'
import RoleForm from '../components/role/RoleForm'

import Language from '../components/language/Language'
import LanguageForm from '../components/language/LanguageForm'

import ResourceType from '../components/resourceType/ResourceType'
import ResourceTypeForm from '../components/resourceType/ResourceTypeForm'

import Resource from '../components/resource/Resource'
import ResourceForm from '../components/resource/ResourceForm'

import Reports from '../components/reports/Reports'
import BalanceAnalysis from '../components/reports/BalanceAnalysis'

import Payment from '../components/payment/Payment'
import PaymentForm from '../components/payment/PaymentForm'

import BankAccountUser from '../components/bankAccountUser/BankAccountUser'
import BankAccountUserForm from '../components/bankAccountUser/BankAccountUserForm'

import CompanyUser from '../components/companyUser/CompanyUser'
import CompanyUserForm from '../components/companyUser/CompanyUserForm'

import UserPermission from '../components/userPermission/UserPermission'
import UserPermissionForm from '../components/userPermission/UserPermissionForm'

import RolePermission from '../components/rolePermission/RolePermission'
import RolePermissionForm from '../components/rolePermission/RolePermissionForm'

import Notification from '../components/notification/Notification'
import NotificationForm from '../components/notification/NotificationForm'

import Invoice from '../components/invoice/Invoice'
import InvoiceForm from '../components/invoice/InvoiceForm'
import AccountBalanceHistory from '../components/accountbalancehistory/AccountBalanceHistory'

export default class Routes extends Component {
  private sidebarStatus(location: any) {
    return (
      !location.pathname.includes('/login') &&
      !location.pathname.includes('/register') &&
      !location.pathname.includes('/remember')
    )
  }

  private renderStatics(): ReactNode {
    return (
      <Route
        render={({ location }) => {
          if (this.sidebarStatus(location)) {
            return (
              <>
                <Sidebar />
                <Navbar />
              </>
            )
          }
        }}
      />
    )
  }

  private renderProtecteds(): ReactNode {
    return (
      <>
        <ProtectedRoute exact path={HOME} component={Cockpit} />

        <ProtectedRoute exact path={REPORTS} component={Reports} />

        <ProtectedRoute
          exact
          path={BALANCEANALYSIS}
          component={BalanceAnalysis}
        />

        <ProtectedRoute exact path={POSSTATEMENT} component={PosStatement} />

        <ProtectedRoute
          exact
          path={ACCOUNTBALANCEHISTORY}
          component={AccountBalanceHistory}
        />

        <ProtectedRoute exact path={VALORDATE} component={ValorDate} />

        <ProtectedRoute
          exact
          path={ACCOUNT_STATEMENT(':accountId?')}
          component={AccountStatement}
        />

        <ProtectedRoute
          exact
          path={BANK_STATEMENT(':bankId?', ':currencyId?')}
          component={BankStatement}
        />

        <ProtectedRoute exact path={DOCUMENTATION} component={Documentation} />

        <ProtectedRoute exact path={ACCOUNT} component={Account} />
        <ProtectedRoute
          exact
          path={ACCOUNT_DETAIL(':currencyId')}
          component={AccountDetail}
        />

        <ProtectedRoute exact path={COMPANY} component={Company} />
        <ProtectedRoute exact path={ADD_NEW_COMPANY} component={CompanyForm} />
        <ProtectedRoute
          exact
          path={EDIT_COMPANY(':companyId')}
          component={CompanyForm}
        />

        <ProtectedRoute
          exact
          path={EDIT_CONSENT_AGREEMENT(':companyId')}
          component={ConsentAgreementForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_CONSENT_RENEWAL(':companyId')}
          component={ConsentRenewalForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_CONSENT_CANCEL(':companyId')}
          component={ConsentCancelForm}
        />

        <ProtectedRoute exact path={BANK} component={Bank} />
        <ProtectedRoute exact path={ADD_NEW_BANK} component={BankForm} />
        <ProtectedRoute
          exact
          path={EDIT_BANK(':bankId')}
          component={BankForm}
        />

        <ProtectedRoute exact path={BANKBRANCH} component={BankBranch} />
        <ProtectedRoute
          exact
          path={ADD_NEW_BANKBRANCH}
          component={BankBranchForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_BANKBRANCH(':bankBranchId')}
          component={BankBranchForm}
        />

        <ProtectedRoute exact path={BANKACCOUNT} component={BankAccount} />
        <ProtectedRoute
          exact
          path={ADD_NEW_BANKACCOUNT}
          component={BankAccountForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_BANKACCOUNT(':bankAccountId')}
          component={BankAccountForm}
        />

        <ProtectedRoute
          exact
          path={BANKCREDENTIAL}
          component={BankCredential}
        />
        <ProtectedRoute
          exact
          path={ADD_NEW_BANKCREDENTIAL}
          component={BankCredentialForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_BANKCREDENTIAL(':bankCredentialId')}
          component={BankCredentialForm}
        />

        <ProtectedRoute
          exact
          path={BANKFTPCREDENTIAL}
          component={BankFTPCredential}
        />
        <ProtectedRoute
          exact
          path={ADD_NEW_BANKFTPCREDENTIAL}
          component={BankFTPCredentialForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_BANKFTPCREDENTIAL(':bankFTPCredentialId')}
          component={BankFTPCredentialForm}
        />

        <ProtectedRoute exact path={COUNTRY} component={Country} />
        <ProtectedRoute exact path={ADD_NEW_COUNTRY} component={CountryForm} />
        <ProtectedRoute
          exact
          path={EDIT_COUNTRY(':countryId')}
          component={CountryForm}
        />

        <ProtectedRoute exact path={CITY} component={City} />
        <ProtectedRoute exact path={ADD_NEW_CITY} component={CityForm} />
        <ProtectedRoute
          exact
          path={EDIT_CITY(':cityId')}
          component={CityForm}
        />

        <ProtectedRoute exact path={DISTRICT} component={District} />
        <ProtectedRoute
          exact
          path={ADD_NEW_DISTRICT}
          component={DistrictForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_DISTRICT(':districtId')}
          component={DistrictForm}
        />

        <ProtectedRoute exact path={CURRENCY} component={Currency} />
        <ProtectedRoute
          exact
          path={ADD_NEW_CURRENCY}
          component={CurrencyForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_CURRENCY(':currencyId')}
          component={CurrencyForm}
        />

        <ProtectedRoute exact path={USER} component={User} />
        <ProtectedRoute exact path={ADD_NEW_USER} component={UserForm} />
        <ProtectedRoute exact path={ADD_MULTI_USER} component={UserMultiForm} />
        <ProtectedRoute
          exact
          path={EDIT_USER(':userId')}
          component={UserForm}
        />
        <ProtectedRoute exact path={PROFILE} component={Profile} />
        <ProtectedRoute exact path={PASSWORD_RESET} component={PasswordReset} />
        <ProtectedRoute
          exact
          path={PASSWORD_UPDATE(':userId')}
          component={PasswordUpdate}
        />

        <ProtectedRoute exact path={PERMISSION} component={Permission} />
        <ProtectedRoute
          exact
          path={ADD_NEW_PERMISSION}
          component={PermissionForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_PERMISSION(':permissionId')}
          component={PermissionForm}
        />

        <ProtectedRoute exact path={PARTNER} component={Partner} />
        <ProtectedRoute exact path={ADD_NEW_PARTNER} component={PartnerForm} />
        <ProtectedRoute
          exact
          path={EDIT_PARTNER(':partnerId')}
          component={PartnerForm}
        />

        <ProtectedRoute exact path={ROLE} component={Role} />
        <ProtectedRoute exact path={ADD_NEW_ROLE} component={RoleForm} />
        <ProtectedRoute
          exact
          path={EDIT_ROLE(':roleId')}
          component={RoleForm}
        />

        <ProtectedRoute exact path={LANGUAGE} component={Language} />
        <ProtectedRoute
          exact
          path={ADD_NEW_LANGUAGE}
          component={LanguageForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_LANGUAGE(':languageId')}
          component={LanguageForm}
        />

        <ProtectedRoute exact path={RESOURCETYPE} component={ResourceType} />
        <ProtectedRoute
          exact
          path={ADD_NEW_RESOURCETYPE}
          component={ResourceTypeForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_RESOURCETYPE(':resourceTypeId')}
          component={ResourceTypeForm}
        />

        <ProtectedRoute exact path={RESOURCE} component={Resource} />
        <ProtectedRoute
          exact
          path={ADD_NEW_RESOURCE}
          component={ResourceForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_RESOURCE(':resourceId')}
          component={ResourceForm}
        />

        <ProtectedRoute exact path={PAYMENT} component={Payment} />
        <ProtectedRoute exact path={ADD_NEW_PAYMENT} component={PaymentForm} />

        <ProtectedRoute
          exact
          path={BANKACCOUNTUSER}
          component={BankAccountUser}
        />
        <ProtectedRoute
          exact
          path={ADD_NEW_BANKACCOUNTUSER}
          component={BankAccountUserForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_BANKACCOUNTUSER(':bankAccountUserId')}
          component={BankAccountUserForm}
        />

        <ProtectedRoute exact path={COMPANYUSER} component={CompanyUser} />
        <ProtectedRoute
          exact
          path={ADD_NEW_COMPANYUSER}
          component={CompanyUserForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_COMPANYUSER(':companyUserId')}
          component={CompanyUserForm}
        />

        <ProtectedRoute
          exact
          path={USERPERMISSION}
          component={UserPermission}
        />
        <ProtectedRoute
          exact
          path={ADD_NEW_USERPERMISSION}
          component={UserPermissionForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_USERPERMISSION(':userPermissionId')}
          component={UserPermissionForm}
        />

        <ProtectedRoute
          exact
          path={ROLEPERMISSION}
          component={RolePermission}
        />
        <ProtectedRoute
          exact
          path={ADD_NEW_ROLEPERMISSION}
          component={RolePermissionForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_ROLEPERMISSION(':rolePermissionId')}
          component={RolePermissionForm}
        />

        <ProtectedRoute exact path={INVOICE} component={Invoice} />
        <ProtectedRoute exact path={ADD_NEW_INVOICE} component={InvoiceForm} />
        <ProtectedRoute
          exact
          path={EDIT_INVOICE(':invoiceId')}
          component={InvoiceForm}
        />

        <ProtectedRoute exact path={NOTIFICATION} component={Notification} />
        <ProtectedRoute
          exact
          path={ADD_NEW_NOTIFICATION}
          component={NotificationForm}
        />
        <ProtectedRoute
          exact
          path={EDIT_NOTIFICATION(':notificationId')}
          component={NotificationForm}
        />
      </>
    )
  }

  private renderDefaultView() {
    return <Route exact path='/' render={() => <Redirect to={HOME} />} />
  }

  render() {
    return (
      <HashRouter>
        {this.renderStatics()}
        {this.renderProtecteds()}
        {this.renderDefaultView()}
        <Switch>
          <Route exact path={LOGIN} component={Login} />
          <Route exact path={REGISTER} component={Register} />
          <Route exact path={REMEMBER} component={Remember} />
        </Switch>
      </HashRouter>
    )
  }
}
