import React, { Component } from 'react'
import Wrapper from '../layout/Wrapper'
import Grid from '@material-ui/core/Grid'
import AccountStatements from './AccountStatements'
import AccountBalances from './AccountBalances'
import ArrowBack from '@material-ui/icons/ArrowBack'
// import memoize from 'lodash.memoize'

import PieChart, {
  Series as PieSeries,
  Label as PieLabel,
  Connector as PieConnector,
  Size as PieSize,
  Export as PieExport,
  Margin as PieMargin,
  Legend as PieLegend,
  Tooltip as PieTooltip,
  Title as PieTitle,
  Subtitle as PieSubtitle,
  HoverStyle as PieHoverStyle,
} from 'devextreme-react/pie-chart'

import {
  Chart,
  Series,
  Legend,
  ValueAxis,
  Tooltip,
  SeriesTemplate,
  CommonSeriesSettings,
} from 'devextreme-react/chart'

import { CurrencyId, priceFormatter } from '../../helpers/priceFormatter'
import { accountBalancesService, posStatementsService } from '../../service'
import { Button, CircularProgress } from '@material-ui/core'
import { ArgumentAxis } from 'devextreme-react/polar-chart'

interface PointInfo {
  argumentText: string
  value: number
  percentText: string
}

interface AccountBalancesByBank {
  balance: number
  currencyId: number
  bankName: string
}

interface BalanceTotal {
  balance: number
  currencyId: number
  currencyCode: string
  currencyName: string
}

interface AccountBalanceByCompany {
  balance: number
  companyId: number
  companyName: string
  currencyId: number
  currencyCode: string
  currencyName: string
}

interface AccountBalance {
  balance: number
  bankId: number
  bankName: string
  bankAccountName: string
  bankBranchCode: string
  bankAccountNo: string
  iban: string
  updateDateTime: string
}

interface ValorDate {
  companyName: string
  bankName: string
  isyeri: string
  iban: string
  netTutar: number
  currencyId: CurrencyId
  valorTarihi: string
}

interface MainDrillDownData {
  level: number
  content: any[]
  argumentField: string
  valueField: string
  currencyId: CurrencyId
}

interface ValorDrillDownData {
  level: number
  content: ValorDate[]
  argumentField: string
  valueField: string
  companyName: string
}

interface State {
  loading: boolean
  banks: AccountBalancesByBank[]
  balanceTotals: BalanceTotal[]
  accountBalances: AccountBalance[]
  companies: AccountBalanceByCompany[]
  mainDrillDownLoading: boolean
  valorDrillDownLoading: boolean
  valorDates: ValorDate[]
  mainDrillDownData: MainDrillDownData
  // 4 tane seviye var o nedenle ilk üç tanesi için önceki data'larını saklamalıyız.
  mainDrillDownPreviousData: {
    0: MainDrillDownData | null
    1: MainDrillDownData | null
    2: MainDrillDownData | null
  }
  valorDrillDownData: ValorDrillDownData
  valorDrillDownPreviousData: {
    0: ValorDrillDownData | null
    1: ValorDrillDownData | null
  }
}

interface Props {
  history: any
  match: any
}

const mainDrillDownColors = ['#BCEAD5', '#B8E8FC', '#BCCEF8', '#9ED2C6']

const classes: { [key: string]: React.CSSProperties } = {
  centeredLoading: {
    display: 'grid',
    height: '100%',
    placeItems: 'center',
  },
  card: {
    border: '1px solid #ccc',
    borderRadius: '8px',
  },
  pieChart: {
    paddingBottom: '50px',
    height: '450px',
  },
  drillChart: {
    marginTop: '-32px',
    height: '420px',
  },
  drillChartContainer: {
    height: '450px',
    padding: '16px 24px 0',
  },
  buttonInvisible: {
    opacity: 0,
    pointerEvents: 'none',
  },
  iconButton: {
    display: 'flex',
    gap: '12px',
  },
}

export default class Cockpit extends React.Component {
  public props: Props
  public state: State

  constructor(props: any) {
    super(props)

    this.props = props

    this.state = {
      loading: false,
      banks: [],
      balanceTotals: [],
      companies: [],
      accountBalances: [],
      valorDates: [],
      mainDrillDownLoading: false,
      valorDrillDownLoading: false,
      mainDrillDownData: {
        level: 0,
        content: [],
        argumentField: 'currencyCode',
        valueField: 'balance',
        currencyId: 1,
      },
      mainDrillDownPreviousData: { 0: null, 1: null, 2: null },
      valorDrillDownData: {
        level: 0,
        content: [],
        argumentField: 'companyName',
        valueField: 'netTutar',
        companyName: '',
      },
      valorDrillDownPreviousData: { 0: null, 1: null },
    }

    this.getBanks = this.getBanks.bind(this)
    this.getBalanceTotals = this.getBalanceTotals.bind(this)
    this.getCompanies = this.getCompanies.bind(this)
    this.getAccountBalances = this.getAccountBalances.bind(this)
    this.getValorDates = this.getValorDates.bind(this)
    this.concatenateSameFields = this.concatenateSameFields.bind(this)
    this.renderDoughnutChart = this.renderDoughnutChart.bind(this)
    this.renderMainDrillDownChart = this.renderMainDrillDownChart.bind(this)
    this.renderValorDrillDownChart = this.renderValorDrillDownChart.bind(this)
  }

  componentWillMount(): void {
    this.getBanks()
    this.getBalanceTotals()
    this.getValorDates()
  }

  // concatenateSameFields = memoize(
  //   (list: any[], sameFieldName: string, balanceFieldName: string) => {
  //     let trimmedList: any[] = []
  //     let loopedFieldValues: any[] = []

  //     console.log('isRendered')

  //     for (let i = 0; i < list.length; i++) {
  //       const currentSameFieldValue = list[i][sameFieldName]
  //       const currentBalanceFieldValue = list[i][balanceFieldName]

  //       if (loopedFieldValues.includes(currentSameFieldValue)) {
  //         trimmedList = trimmedList.map((item) => {
  //           if (item[sameFieldName] === currentSameFieldValue) {
  //             return {
  //               ...item,
  //               [balanceFieldName]:
  //                 item[balanceFieldName] + currentBalanceFieldValue,
  //             }
  //           } else {
  //             return item
  //           }
  //         })
  //       } else {
  //         loopedFieldValues.push(currentSameFieldValue)
  //         trimmedList.push(list[i])
  //       }
  //     }

  //     return trimmedList
  //   },
  //   (...args) =>
  //     `concatenateSameFields_${JSON.stringify(args[0])}_${args[1]}_${args[2]}`
  // )

  concatenateSameFields(
    list: any[],
    sameFieldName: string,
    balanceFieldName: string
  ) {
    let trimmedList: any[] = []
    let loopedFieldValues: any[] = []

    for (let i = 0; i < list.length; i++) {
      const currentSameFieldValue = list[i][sameFieldName]
      const currentBalanceFieldValue = list[i][balanceFieldName]

      if (loopedFieldValues.includes(currentSameFieldValue)) {
        trimmedList = trimmedList.map((item) => {
          if (item[sameFieldName] === currentSameFieldValue) {
            return {
              ...item,
              [balanceFieldName]:
                item[balanceFieldName] + currentBalanceFieldValue,
            }
          } else {
            return item
          }
        })
      } else {
        loopedFieldValues.push(currentSameFieldValue)
        trimmedList.push(list[i])
      }
    }

    return trimmedList
  }

  async getBanks() {
    this.setState({ loading: true })
    const response = await accountBalancesService.getAccountBalanceTotalsByBank(
      0
    )
    if (response.isSuccess) {
      this.setState({
        banks: response.data.accountBalanceTotalByBankItem,
        loading: false,
      })
    }
  }

  async getBalanceTotals() {
    this.setState({ loading: true })
    const response = await accountBalancesService.getTotals()

    if (response.isSuccess) {
      const balanceTotals = response.data.accountBalanceTotalItems

      this.setState({
        mainDrillDownData: {
          ...this.state.mainDrillDownData,
          content: balanceTotals,
        },
        balanceTotals,
        loading: false,
      })
    }
  }

  async getCompanies(currencyId: number) {
    const response =
      await accountBalancesService.getAccountBalanceTotalsByCompany(currencyId)
    if (response.isSuccess)
      this.setState({
        companies: response.data.accountBalanceTotalByCompanyItem,
      })
    return response.data.accountBalanceTotalByCompanyItem
  }

  async getAccountBalances(currencyId: number, companyId: number) {
    const response = await accountBalancesService.getAccountBalancesByCompanyId(
      currencyId,
      companyId
    )

    if (response.isSuccess) {
      const accountBalances = response.data.accountBalances as AccountBalance[]

      this.setState({
        accountBalances,
      })

      // let trimmedAccountBalances: AccountBalance[] = []
      // let loopedBankIds: number[] = []

      // for (let i = 0; i < accountBalances.length; i++) {
      //   const currentBankId = accountBalances[i].bankId
      //   const currentBalance = accountBalances[i].balance

      //   if (loopedBankIds.includes(currentBankId)) {
      //     trimmedAccountBalances.map((item) => {
      //       const { balance, bankId } = item
      //       if (bankId === currentBankId) {
      //         return { ...item, balance: balance + currentBalance }
      //       }
      //     })
      //   } else {
      //     loopedBankIds.push(currentBankId)
      //     trimmedAccountBalances.push(accountBalances[i])
      //   }
      // }

      // return trimmedAccountBalances

      return this.concatenateSameFields(
        accountBalances,
        'bankId',
        'balance'
      ) as AccountBalance[]
    }
  }

  async getValorDates() {
    this.setState({ loading: true })

    const model = {
      searchText: '',
      pagingParameters: {
        rowsPerPage: 999,
        pageNumber: 0,
        orderBy: 0,
      },
    }

    const response = await posStatementsService.valorDate(model)

    if (response.isSuccess) {
      const valorDates = response.data
        .posStatementTotalByValorDateItem as ValorDate[]

      this.setState({
        valorDates,
        loading: false,
      })

      const trimmedValorDates = this.concatenateSameFields(
        valorDates,
        'companyName',
        'netTutar'
      )

      this.setState({
        valorDates,
        loading: false,
        valorDrillDownData: {
          ...this.state.valorDrillDownData,
          content: trimmedValorDates,
        },
      })
    }
  }

  renderDoughnutChart(currencyId: CurrencyId, banks: AccountBalancesByBank[]) {
    const customizeTooltip = (pointInfo: PointInfo) => {
      const { argumentText, value } = pointInfo

      return {
        html: `
          <div style="text-align:center;">
            <div style="font-weight:700;margin-bottom:4px">${argumentText}</div>
            <div>${priceFormatter(value, currencyId)}</div>
          </div>
        `,
        fontColor: '#222',
      }
    }

    const onPointClick = (e: any) => {
      e.target.select()
    }

    return (
      <PieChart
        style={classes.pieChart}
        // id='chart'
        type='doughnut'
        title='Hesap Detay Raporu'
        palette={
          currencyId === 1
            ? 'Soft Blue'
            : currencyId === 2
            ? 'Soft Pastel'
            : 'Soft'
        }
        dataSource={banks}
        onPointClick={onPointClick}
        resolveLabelOverlapping='shift'
      >
        <PieSeries argumentField='bankName' valueField='balance'>
          <PieLabel
            visible={true}
            position='columns'
            customizeText={(pointInfo: PointInfo) =>
              priceFormatter(pointInfo.value, currencyId)
            }
          >
            <PieConnector visible={true} width={1} />
          </PieLabel>
          <PieHoverStyle color='#408AF8' />
        </PieSeries>
        <PieExport enabled={false} />
        <PieLegend
          visible={false}
          // @ts-ignore
          // margin={0}
          // horizontalAlignment='right'
          // verticalAlignment='top'
        />
        <PieSize width={'100%'} height={'100%'} />
        <PieTooltip
          enabled={true}
          zIndex={10}
          customizeTooltip={customizeTooltip}
        />
      </PieChart>
    )
  }

  renderMainDrillDownChart() {
    const { level, argumentField, valueField, content, currencyId } =
      this.state.mainDrillDownData

    //console.log('mainDrillDownData', this.state.mainDrillDownData)

    const customizePoint = () => {
      return {
        color: mainDrillDownColors[level],
        hoverStyle:
          level === 3
            ? {
                hatching: 'none',
              }
            : {},
      }
    }

    const onPointClick = async (e: any) => {
      //console.log(e)

      if (level === 0) {
        const { currencyId } = e.target.data

        this.setState({
          mainDrillDownLoading: true,
        })

        const companies = await this.getCompanies(currencyId)

        //console.log()

        this.setState({
          mainDrillDownLoading: false,
          mainDrillDownPreviousData: {
            ...this.state.mainDrillDownPreviousData,
            0: this.state.mainDrillDownData,
          },
          mainDrillDownData: {
            ...this.state.mainDrillDownData,
            level: 1,
            argumentField: 'companyName',
            content: companies,
            currencyId,
          },
        })
      }

      if (level === 1) {
        const { companyId } = e.target.data

        this.setState({
          mainDrillDownLoading: true,
        })

        const accountBalances = await this.getAccountBalances(
          currencyId,
          companyId
        )

        this.setState({
          mainDrillDownLoading: false,
          mainDrillDownPreviousData: {
            ...this.state.mainDrillDownPreviousData,
            1: this.state.mainDrillDownData,
          },
          mainDrillDownData: {
            ...this.state.mainDrillDownData,
            level: 2,
            argumentField: 'bankName',
            content: accountBalances,
          },
        })
      }

      if (level === 2) {
        const { accountBalances } = this.state

        this.setState({
          mainDrillDownLoading: true,
        })

        const { bankId } = e.target.data

        const currentBalances = accountBalances.filter(
          (item) => item.bankId === bankId
        )

        this.setState({
          mainDrillDownLoading: false,
          mainDrillDownPreviousData: {
            ...this.state.mainDrillDownPreviousData,
            2: this.state.mainDrillDownData,
          },
          mainDrillDownData: {
            ...this.state.mainDrillDownData,
            level: 3,
            argumentField: 'bankAccountName',
            content: currentBalances,
          },
        })
      }
    }

    const customizeTooltip = (pointInfo: PointInfo) => {
      const { argumentText, value } = pointInfo

      //console.log(pointInfo)

      const { accountBalances } = this.state

      if (level === 3) {
        const currentBalance = accountBalances.find(
          ({ bankAccountName }) => bankAccountName === argumentText
        )!

        const { bankAccountNo, bankBranchCode, balance, iban, updateDateTime } =
          currentBalance

        return {
          html: `
            <div style="text-align:center;font-weight:700;">
              <div style="margin-bottom:10px;">
                <div style="margin-bottom:5px;">${bankBranchCode} / ${bankAccountNo}</div>
                <div>${iban}</div>
              </div>
              <div style="font-weight:700;margin-bottom:12px;">${priceFormatter(
                balance,
                currencyId
              )}</div>
              <div>${updateDateTime}</div>
            </div>
          `,
        }
      }

      return {
        html: `
          <div style="font-weight:700;margin-bottom:4px;text-align:center;">${
            level === 0
              ? priceFormatter(value, 1, true)
              : priceFormatter(value, currencyId)
          }</div>
          </div>
        `,
        fontColor: '#222',
      }
    }

    return (
      <div style={classes.drillChartContainer}>
        {this.state.mainDrillDownLoading ? (
          <div style={classes.centeredLoading}>
            <CircularProgress className='progress' />
          </div>
        ) : (
          <>
            <Button
              style={level === 0 ? classes.buttonInvisible : {}}
              variant='contained'
              color='default'
              onClick={() => {
                this.setState({
                  mainDrillDownData: (
                    this.state.mainDrillDownPreviousData as {
                      [key: number]: MainDrillDownData
                    }
                  )[level - 1],
                })
              }}
            >
              <ArrowBack />
              <span style={{ marginLeft: '6px' }}>Geri Git</span>
            </Button>
            <Chart
              style={classes.drillChart}
              // id='chart'
              title='The Most Populated Countries by Continents'
              palette='Soft Blue'
              // @ts-ignore
              customizePoint={customizePoint}
              onPointClick={onPointClick}
              className={level !== 3 ? 'pointer-on-bars' : ''}
              dataSource={content}
            >
              <CommonSeriesSettings
                type='bar'
                argumentField={argumentField}
                valueField={valueField}
                ignoreEmptyPoints={true}
                barPadding={0.4}
              />
              <SeriesTemplate nameField={argumentField} />
              <ArgumentAxis
                label={{
                  // yatayda belirli bir sayıdan fazla değer olduğunda x axis'teki label değerlerinden bazıları gözükmüyor, onun önüne geçmek için none yapılıyor.
                  overlappingBehavior: 'none',
                  font: { size: 12, color: '#333' },
                }}
              />
              <ValueAxis showZero={false} />
              <Legend visible={false} />
              <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
            </Chart>
          </>
        )}
      </div>
    )
  }

  renderValorDrillDownChart() {
    const { level, argumentField, valueField, content } =
      this.state.valorDrillDownData

    //console.log('valorDrillDownData', this.state.valorDrillDownData)

    const customizePoint = () => {
      return {
        hoverStyle:
          level === 2
            ? {
                hatching: 'none',
              }
            : {},
      }
    }

    const onPointClick = async (e: any) => {
      //console.log(e)

      const targetData = e.target.data

      const { valorDates } = this.state

      if (level === 0) {
        const currentValorDates = valorDates.filter(
          ({ companyName }) => companyName === targetData.companyName
        )

        // burada da seçilen bankaya ait bankalar birden fazlaysa birleştiriliyor.
        const trimmedValorDates = this.concatenateSameFields(
          currentValorDates,
          'bankName',
          'netTutar'
        )

        this.setState({
          valorDrillDownPreviousData: {
            ...this.state.valorDrillDownPreviousData,
            0: this.state.valorDrillDownData,
          },
          valorDrillDownData: {
            ...this.state.valorDrillDownData,
            level: 1,
            argumentField: 'bankName',
            content: trimmedValorDates,
            companyName: targetData.companyName,
          },
        })
      }

      if (level === 1) {
        const { companyName } = this.state.valorDrillDownData

        const currentValorDates = valorDates.filter(
          ({ companyName, bankName }) =>
            companyName === companyName && targetData.bankName
        )

        // burada da seçilen bankaya ait bankalar birden fazlaysa birleştiriliyor.
        const trimmedValorDates = this.concatenateSameFields(
          currentValorDates,
          'isyeri',
          'netTutar'
        )

        this.setState({
          valorDrillDownPreviousData: {
            ...this.state.valorDrillDownPreviousData,
            1: this.state.valorDrillDownData,
          },
          valorDrillDownData: {
            ...this.state.valorDrillDownData,
            level: 2,
            argumentField: 'isyeri',
            content: trimmedValorDates,
            companyName: targetData.companyName,
          },
        })
      }
    }

    const customizeTooltip = (pointInfo: PointInfo) => {
      const { argumentText, value } = pointInfo

      //console.log(pointInfo)

      return {
        html: `
          <div style="font-weight:700;margin-bottom:4px;text-align:center;">${priceFormatter(
            value
          )}</div>
          </div>
        `,
        fontColor: '#222',
      }
    }

    return (
      <div style={classes.drillChartContainer}>
        <Button
          style={level === 0 ? classes.buttonInvisible : {}}
          variant='contained'
          color='default'
          onClick={() => {
            this.setState({
              valorDrillDownData: (
                this.state.valorDrillDownPreviousData as {
                  [key: number]: ValorDrillDownData
                }
              )[level - 1],
            })
          }}
        >
          <ArrowBack />
          <span style={{ marginLeft: '6px' }}>Geri Git</span>
        </Button>
        <Chart
          style={classes.drillChart}
          // id='chart'
          title='The Most Populated Countries by Continents'
          palette='Soft Pastel'
          // @ts-ignore
          customizePoint={customizePoint}
          onPointClick={onPointClick}
          className={level !== 3 ? 'pointer-on-bars' : ''}
          dataSource={content}
        >
          <CommonSeriesSettings
            type='bar'
            argumentField={argumentField}
            valueField={valueField}
            ignoreEmptyPoints={true}
            barPadding={0.5}
          />
          <SeriesTemplate nameField={argumentField} />
          <ArgumentAxis
            label={{
              // yatayda belirli bir sayıdan fazla değer olduğunda x axis'teki label değerlerinden bazıları gözükmüyor, onun önüne geçmek için none yapılıyor.
              overlappingBehavior: 'none',
              font: { size: 12, color: '#333' },
            }}
          />
          <ValueAxis showZero={false} />
          <Legend visible={false} />
          <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
        </Chart>
      </div>
    )
  }

  render() {
    const { banks } = this.state

    const banksWithTRYCurrency = banks.filter(
      ({ currencyId }) => currencyId === 1
    )
    const banksWithGBPCurrency = banks.filter(
      ({ currencyId }) => currencyId === 2
    )
    const banksWithUSDCurrency = banks.filter(
      ({ currencyId }) => currencyId === 3
    )
    const banksWithEURCurrency = banks.filter(
      ({ currencyId }) => currencyId === 4
    )

    return (
      <Wrapper>
        {this.state.loading ? (
          <div style={classes.centeredLoading}>
            <CircularProgress className='progress' />
          </div>
        ) : (
          <>
            <AccountBalances />
            <Grid container spacing={24}>
              <Grid item xs={12} style={{ marginTop: 12, marginBottom: 24 }}>
                <div style={classes.card}>
                  {this.renderMainDrillDownChart()}
                </div>
              </Grid>
              {this.state.valorDates.length > 0 && (
                <Grid item xs={12} style={{ marginTop: 12, marginBottom: 24 }}>
                  <div style={classes.card}>
                    {this.renderValorDrillDownChart()}
                  </div>
                </Grid>
              )}
              {banksWithTRYCurrency.length > 0 && (
                <Grid item xs={6} style={{ marginBottom: 32 }}>
                  <div style={classes.card}>
                    {this.renderDoughnutChart(1, banksWithTRYCurrency)}
                  </div>
                </Grid>
              )}
              {banksWithGBPCurrency.length > 0 && (
                <Grid item xs={6} style={{ marginBottom: 32 }}>
                  <div style={classes.card}>
                    {this.renderDoughnutChart(2, banksWithGBPCurrency)}
                  </div>
                </Grid>
              )}
              {banksWithUSDCurrency.length > 0 && (
                <Grid item xs={6} style={{ marginBottom: 32 }}>
                  <div style={classes.card}>
                    {this.renderDoughnutChart(3, banksWithUSDCurrency)}
                  </div>
                </Grid>
              )}
              {banksWithEURCurrency.length > 0 && (
                <Grid item xs={6} style={{ marginBottom: 32 }}>
                  <div style={classes.card}>
                    {this.renderDoughnutChart(4, banksWithEURCurrency)}
                  </div>
                </Grid>
              )}
            </Grid>
          </>
        )}

        <Grid container id='Content'>
          <Grid item xs={12}>
            <AccountStatements />
          </Grid>
        </Grid>
      </Wrapper>
    )
  }
}
