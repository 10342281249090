//Resource

export const resourceKeyValidator = (resourceKey: string): string => {
    if (resourceKey === "") return 'Kaynak anahtarı alanını boş geçemezsiniz!'
    return '';
}

export const valueValidator = (value: string): string => {
    if (value === "") return 'Kaynak Değeri alanını boş geçemezsiniz!'
    return '';
}

export const resourceTypeValidator = (resourceTypeId: number): string => {
    if (resourceTypeId === 0) return 'Kaynak tipi seçiniz!'
    return '';
}

export const languageValidator = (languageId: number): string => {
    if (languageId === 0) return 'Dil seçiniz!'
    return '';
}