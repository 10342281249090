import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { resourceService, locationService } from '../../service';
import { RESOURCE } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import Error from '../NotFound/NotFound'


import { resourceKeyValidator, resourceTypeValidator, languageValidator, valueValidator } from '../../utils/resource.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IResourceFormState {
    resourceTypeList: [],
    resourceTypeId: any,
    resourceTypeError: string,
    languageList: [],
    languageId: any,
    languageError: string,
    resourceKey: string,
    resourceKeyError: string,
    value: string,
    valueError: string,
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface IResourceFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class ResourceForm extends Component {
    public readonly state: IResourceFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            resourceTypeList: [],
            resourceTypeId: 0,
            resourceTypeError: "",
            languageList: [],
            languageId: 0,
            languageError: "",
            resourceKey: "",
            resourceKeyError: "",
            value: "",
            valueError: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeResourceKey = this.onChangeResourceKey.bind(this);
        this.onChangeResourceValue = this.onChangeResourceValue.bind(this);
        this.onChangeResourceType = this.onChangeResourceType.bind(this);
        this.onChangeResourceLanguage = this.onChangeResourceLanguage.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.resourceId) this.getEditableResource();
        else {
            this.getResourceTypes();
            this.getLanguages();
            this.setState({ loading: false });
        }

        this.form = React.createRef();
    }

    private async getEditableResource() {
        const { data, isSuccess, errorCode, errorMessage } = await resourceService.getById(this.props.match.params.resourceId);

        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getResourceTypes();
            await this.getLanguages();
            const state = {
                ...data,
                resourceTypeId: this.state.resourceTypeList.find((item: any) => item.id === data.resourceTypeId),
                languageId: this.state.languageList.find((item: any) => item.id === data.languageId),
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });
        }
    }

    /** EVENT LISTENERS */

    private async cancel() {
        this.props.history.push(RESOURCE);
    }

    private onChangeResourceKey(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ resourceKey: e.target.value, resourceKeyError: resourceKeyValidator(e.currentTarget.value) });
    }

    private onChangeResourceValue(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ value: e.target.value, valueError: valueValidator(e.currentTarget.value) });
    }

    private onChangeResourceType(resourceTypeId: any) {
        this.setState({ resourceTypeId, resourceTypeError: resourceTypeValidator(resourceTypeId) });
    }

    private onChangeResourceLanguage(languageId: any) {
        this.setState({ languageId, languageError: languageValidator(languageId) });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async getResourceTypes() {
        const { data } = await locationService.getResourceTypes();

        const state = {
            resourceTypeId: this.state.resourceTypeId === 0 ? 0 : this.state.resourceTypeId.id,
            resourceTypeList: data.resourceTypes
        };

        this.setState(state);

    }

    private async getLanguages() {
        const { data } = await locationService.getLanguages();

        const state = {
            languageId: this.state.languageId === 0 ? 0 : this.state.languageId.id,
            languageList: data.languages
        };

        this.setState(state);

    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { resourceKey, resourceTypeId, languageId, value } = this.state;

        //Submit Validation
        if (
            resourceKeyValidator(resourceKey) !== "" ||
            resourceTypeValidator(resourceTypeId) !== "" ||
            languageValidator(languageId) !== "" ||
            valueValidator(value) !== ""

        ) {

            this.setState({
                resourceKeyError: resourceKeyValidator(resourceKey),
                resourceTypeError: resourceTypeValidator(resourceTypeId),
                languageError: languageValidator(languageId),
                valueError: valueValidator(value)
            });
            return false;
        }

        return true;
    }


    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { resourceTypeId, languageId, resourceKey, value, isActive, isEdit } = this.state;

        if (this.formValidation()) {

            const model = {
                resourceTypeId: sqlInjectValidator(String(resourceTypeId.id)),
                languageId: sqlInjectValidator(String(languageId.id)),
                resourceKey: sqlInjectValidator(resourceKey),
                value: sqlInjectValidator(value),
                isActive
            };

            if (isEdit) await this.editResource(model);
            else await this.createResource(model);
        }
    }

    private async createResource(model: any) {
        const data = await resourceService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editResource(model: any) {
        const data = await resourceService.update({ ...model, id: this.props.match.params.resourceId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }



    private renderResourceKey() {
        const { resourceKey, resourceKeyError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="resourceKey"
                    label="Kaynak Anahtarı"
                    placeholder="Kaynak anahtarı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeResourceKey}
                    value={resourceKey}
                    name="resourceKey"
                    autoComplete="resourceKey"
                    helperText={resourceKeyError}
                />
            </FormControl>
        );
    }



    private renderResourceValue() {
        const { value, valueError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="value"
                    label="Kaynak Değeri"
                    placeholder="Kaynak değeri yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeResourceValue}
                    value={value}
                    name="value"
                    autoComplete="value"
                    helperText={valueError}
                />
            </FormControl>
        );
    }


    private renderResources() {
        const { resourceTypeId, resourceTypeList, resourceTypeError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="resourceTypeName" value={resourceTypeId} valueKey="id" options={resourceTypeList} onChange={this.onChangeResourceType} placeholder="Kaynak Tipi *"></CustomSelect>
                <span className="errorMessage">{resourceTypeError}</span>
            </FormControl>
        );
    }


    private renderLanguages() {
        const { languageId, languageList, languageError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="languageName" value={languageId} valueKey="id" options={languageList} onChange={this.onChangeResourceLanguage} placeholder="Dil *"></CustomSelect>
                <span className="errorMessage">{languageError}</span>
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }


    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: RESOURCE, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Kaynak {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan kaynak bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda kaynak ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Kaynak {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderResourceKey()}
                                    {this.renderResourceValue()}
                                    {this.renderResources()}
                                    {this.renderLanguages()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(ResourceForm);
