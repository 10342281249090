import React, { Component } from 'react'
import Wrapper from '../layout/Wrapper';
import Grid from '@material-ui/core/Grid';
import PosStatementList from './PosStatementList';
import { Typography } from '@material-ui/core';

export default class PosStatement extends Component {
    public props: any;

    render() {
        return (
            <Wrapper>
                <Grid container id="Breadcrumb">
                    <Grid xs={12}>
                        <ul>
                            <li>Pos Hareketleri</li>
                            <li>Finans</li>
                            <li>Pos Hareketleri</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container id="Content">
                    <Grid xs={12}>
                        <PosStatementList accountId={this.props.match.params.accountId} />
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
}
