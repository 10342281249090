//User

function emailValidation(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}


export const nameSurnameValidator = (nameSurname: string): string => {
    if (nameSurname === "") return 'Kullanıcı adı alanını boş geçemezsiniz!'
    return '';
}

export const emailAddressValidator = (emailAddress: string): string => {
    if (emailAddress === "") return 'Mail alanını boş geçemezsiniz!'
    else if (emailAddress.length < 6) return 'Kullanici adi en az 6 karakter olabilir!'
    else if (!emailValidation(emailAddress)) return 'Geçersiz bir E-posta Adresi girdiniz!'
    return '';
}

export const passwordValidator = (password: string): string => {
    if (password === "") return 'Şifre alanını boş geçemezsiniz!'
    else if (password.length < 8 || password.length > 16) return 'Sifre en az 8 en fazla 16 karakter olabilir.'
    return '';
}

export const mobilePhoneNumberValidator = (mobilePhoneNumber: string): string => {
    if (mobilePhoneNumber === "") return 'Cep telefonu alanını boş geçemezsiniz!'
    else if (mobilePhoneNumber.length < 10 || mobilePhoneNumber.length > 10) return 'Telefon numarası 10 karakter olmalıdır, başında 0 kullanmayın!'
    return '';
}

export const companyValidator = (companyId: number): string => {
    if (companyId === 0) return 'Firma seçiniz!'
    return '';
}