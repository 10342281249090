import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'

import Paper from "@material-ui/core/Paper"
import Grid from '@material-ui/core/Grid'
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'

import ConfirmDialog from '../layout/Confirm'

import { ADD_NEW_CURRENCY, EDIT_CURRENCY } from '../../routes/paths'

import { currencyService, userService, cookieService, resourceService } from '../../service'


interface ICurrencyListState {
    list: [],
    loading: boolean;
    totalCount: number;
    searchTotalCount: number;
    isActive: boolean;
    resources: any;

    //Paging
    sortType: string;
    searchText: string;
    currentPage: number;
    selectedRow: number;
    orderBy: number;

    //Confirm
    confirmStatus: boolean;
    deleteConfirm: boolean;
    confirmMessage: string;
    confirmTitle: string;
    confirmIcon: string;
    confirmType: string;

    //Permission
    userId: number;
    access: string;
    permissionList: any;
    addNewCurrency: boolean;
    addNewButton: boolean;
    updateButton: boolean;
    deleteButton: boolean;
    isExcelAndPdfExport: boolean;
}

export default class CurrencyList extends Component {
    public readonly props: any;
    public readonly state: ICurrencyListState;
    private deleteConfirmId: number | null;

    public readonly LANGUAGE_NAME = 'LANGUAGE_TOKEN';

    constructor(props: any) {
        super(props);
        this.props = props;
        this.deleteConfirmId = null;
        this.state = {
            list: [],
            loading: true,
            totalCount: 0,
            searchTotalCount: 0,
            isActive: true,
            resources: [],

            //Paging
            sortType: "asc",
            searchText: "",
            currentPage: 0,
            selectedRow: 25,
            orderBy: 0,

            //Confirm
            confirmStatus: false,
            deleteConfirm: false,
            confirmMessage: "Dil silmek istediginize emin misiniz?",
            confirmTitle: "Dil Sil",
            confirmIcon: "far fa-trash-alt",
            confirmType: "yes/no",

            //Permission
            userId: 0,
            access: "",
            permissionList: [],
            updateButton: false,
            deleteButton: false,
            isExcelAndPdfExport: false,
            addNewCurrency: false,
            addNewButton: false,
        }

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
        this.onConfirmApprove = this.onConfirmApprove.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.permissionNot = this.permissionNot.bind(this);
        this.getExportExcel = this.getExportExcel.bind(this);
        this.addNewCurrency = this.addNewCurrency.bind(this);

    }

    componentWillMount() {
        this.getPermissionControl();
    }

    private async getPermissionControl() {
        const { data } = await userService.permission();
        this.setState({ permissionList: data.unauthorizedPermissions, isExcelAndPdfExport: data.isExcelAndPdfExport, userId: data.userId });
        this.getCurrencies("", this.state.currentPage, this.state.selectedRow, this.state.orderBy, this.state.isActive);
        this.renderAccessProcess();

        this.state.permissionList.indexOf('Currency.CreateCurrency') == -1 ? this.setState({ addNewButton: true }) : this.setState({ access: "close" })

        this.state.userId === 21 ? this.setState({ access: "popup" }) : null

        this.getResources();
    }

    private async getResources() {
        const resourceLanguage: any = cookieService.resourceItem(this.LANGUAGE_NAME);
        const { data } = await resourceService.language(resourceLanguage);
        var resources: any = {};
        data.resources.forEach((item: any) => {
            resources[item.resourceKey] = item.value;
        });

        this.setState({ resources: resources });
    }


    private async getCurrencies(searchText: string, pageNumber: number, rowPerPage: number, orderBy: number = this.state.orderBy, isActive: boolean) {
        const { data } = await currencyService.get(searchText, pageNumber, rowPerPage, orderBy, isActive);
        this.setState({ list: data.currencies, loading: false, totalCount: data.totalCount, searchTotalCount: data.searchTotalCount })
    }

    private async getExportExcel() {
        return await currencyService.download(this.state.searchText, this.state.isActive);
    }

    private permissionNot() {
        this.setState({ confirmMessage: "Bu işlem için erişim yetkiniz bulunmuyor!", confirmTitle: "Erişim Uyarısı", confirmIcon: "fas fa-lock", confirmType: "ok", confirmStatus: true });
    }

    public async removeCurrency() {
        if (this.deleteConfirmId) {
            await currencyService.delete(this.deleteConfirmId);
            this.setState({ list: this.state.list.filter((currency: any) => currency.id !== this.deleteConfirmId) });
            this.getCurrencies(this.state.searchText, this.state.currentPage, this.state.selectedRow, this.state.orderBy, this.state.isActive);
        }
    }

    private addNewCurrency() {
        const { access } = this.state;
        if (access === "popup") {
            this.setState({ confirmStatus: true });
        } else if (access === "close") {
            this.setState({ addNewCurrency: false });
        } else {
            this.setState({ addNewCurrency: true });
        }
    }

    private renderAccessProcess() {
        const { permissionList, userId } = this.state;

        if ((permissionList.indexOf('Currency.UpdateCurrency') != -1) && (permissionList.indexOf('Currency.DeleteCurrency') != -1)) {
            this.setState({ access: "close" });
        } else if (userId === 21) {
            this.setState({ access: "popup" });
        } else {
            if (permissionList.indexOf('Currency.UpdateCurrency') == -1) {
                this.setState({ updateButton: true });
            }
            if (permissionList.indexOf('Currency.DeleteCurrency') == -1) {
                this.setState({ deleteButton: true });
            }
            this.setState({ access: "show" });
        }

    }

    private handleRemoveCurrency(id: number) {
        this.deleteConfirmId = id;
        this.setState({ confirmStatus: true });
    }

    private handleChangePage(_: any, page: number) {
        this.setState({ currentPage: page });
        this.getCurrencies(this.state.searchText, page, this.state.selectedRow, this.state.orderBy, this.state.isActive);
    }

    private handleSort(orderBy: number) {
        this.setState({ orderBy });
        this.getCurrencies(this.state.searchText, this.state.currentPage, this.state.selectedRow, orderBy, this.state.isActive);
    }

    private handleSearch({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        this.setState({ searchText: value });
        this.getCurrencies(value, this.state.currentPage, this.state.selectedRow, this.state.orderBy, this.state.isActive);
    }

    private onConfirmClose() {
        this.setState({ confirmStatus: false });
        this.deleteConfirmId = null;
    }

    private onConfirmApprove() {
        this.setState({ confirmStatus: false });
        this.removeCurrency();
    }

    private handleRowsPerPageChange({ target: { value } }: any) {
        const selectedRow = Number(value);
        this.setState({ selectedRow });
        this.getCurrencies(this.state.searchText, this.state.currentPage, selectedRow, this.state.orderBy, this.state.isActive);
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
        this.getCurrencies(this.state.searchText, this.state.currentPage, this.state.selectedRow, this.state.orderBy, e.target.checked);
    }

    private renderPagination() {
        const { searchTotalCount, selectedRow, currentPage } = this.state;
        let pageCount = 1;
        let pages = [];
        searchTotalCount <= selectedRow ? pageCount = 1 : pageCount = (searchTotalCount / selectedRow)

        for (let i = 1; i <= Math.ceil(pageCount); i++) {
            if (currentPage + 1 < i + 3 && currentPage + 1 > i - 3) {
                pages[i - 1] = i;
            }
        }

        const listItems = pages.map((page) =>
            <>
                {currentPage + 1 === page ? <a onClick={() => this.handleChangePage(null, page - 1)} style={{ color: '#408af8' }}>{page}</a> : <a onClick={() => this.handleChangePage(null, page - 1)}>{page}</a>}
            </>
        );

        return listItems;
    }

    private renderDownloadButton() {
        return (
            <Button
                variant="contained"
                color="primary"
                className="actionsButton"
                fullWidth
                type="submit">
                {this.state.resources.MenuOperationmenu1} <Icon className="fas fa-ellipsis-v"></Icon>
                <ul className="actions">
                    <h6>{this.state.resources.MenuOperationmenu1_1}</h6>
                    <li onClick={() => this.getExportExcel()}><Icon className="far fa-file-excel"></Icon> {this.state.resources.MenuOperationmenu1_2}</li>
                </ul>
            </Button>
        );
    }

    private renderAddButton() {
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={this.state.access === "popup" ? this.permissionNot : this.addNewCurrency}
                fullWidth
                style={{ float: 'right' }}
                type="submit"
                id="loginButton">
                {this.state.resources.Button12} +
                </Button>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select activeStatus" : "select allStatus"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Tümü"} className="isActiveLabel" />
        )
    }

    private renderRowHeaders() {
        const { orderBy, permissionList } = this.state;
        return (
            <TableRow style={{ backgroundColor: '#fdfdfd' }}>
                <TableCell onClick={() => this.handleSort(orderBy === 1 ? 2 : 1)}>
                    <TableSortLabel active={orderBy === 1 || orderBy === 2} direction={orderBy === 1 ? "desc" : "asc"}>
                        Para Birimi Adı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 3 ? 4 : 3)}>
                    <TableSortLabel active={orderBy === 3 || orderBy === 4} direction={orderBy === 3 ? "desc" : "asc"}>
                        Para Birimi Kodu
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 9 ? 10 : 9)} className="statusArea">
                    <TableSortLabel active={orderBy === 9 || orderBy === 10} direction={orderBy === 9 ? "desc" : "asc"}>
                        Durum
                    </TableSortLabel>
                </TableCell>
                {((permissionList.indexOf('Currency.UpdateCurrency') != -1) && (permissionList.indexOf('Currency.DeleteCurrency') != -1)) ? null :
                    <TableCell className="statusButton"></TableCell>
                }
            </TableRow >
        );
    }


    render() {
        const { addNewCurrency, addNewButton, list, loading, currentPage, searchText, totalCount, searchTotalCount, confirmStatus, selectedRow, access, updateButton, deleteButton, isExcelAndPdfExport, resources } = this.state;
        return (
            <Paper>
                <ConfirmDialog open={confirmStatus} icon={this.state.confirmIcon} confirm={this.state.confirmType} text={this.state.confirmMessage} header={this.state.confirmTitle} onClose={this.onConfirmClose} onApprove={this.onConfirmApprove} />

                {addNewCurrency && <Redirect to={ADD_NEW_CURRENCY} />}
                <Grid container>
                    <Grid xs={4} className="contentHeader">
                        <Typography variant="h2">{resources.MenuLeftmenu1_8_4} {resources.LabelList}</Typography>
                    </Grid>
                    <Grid xs={8} className="contentHeader">
                        {isExcelAndPdfExport ? this.renderDownloadButton() : null}
                        {addNewButton ? this.renderAddButton() : null}
                        {this.renderStatus()}
                    </Grid>
                    <Grid xs={9}>

                        <TablePagination
                            id="TopPagination"
                            rowsPerPageOptions={[25, 50, 100, 250]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={selectedRow}
                            onChangeRowsPerPage={this.handleRowsPerPageChange}
                            page={currentPage}
                            labelDisplayedRows={() => false}
                            labelRowsPerPage={resources.LabelViewData}
                            onChangePage={() => false}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <FormControl margin="none" fullWidth className="input searchBox" required>
                            <TextField
                                id="search"
                                placeholder={resources.InputPlaceholderSearch}
                                margin="normal"
                                variant="outlined"
                                onChange={this.handleSearch}
                                value={searchText}
                                name="search"
                                autoComplete="search"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {loading && <CircularProgress className="progress" />}
                <div style={{ minWidth: '100%', overflowX: 'auto' }}>
                    <Table style={{ width: '100%' }}>
                        <TableHead>
                            {this.renderRowHeaders()}
                        </TableHead>
                        <TableBody>
                            {list.map((currency: any) => (
                                <TableRow key={currency.id}>
                                    <TableCell scope="row" style={{ width: 200 }}>
                                        {currency.currencyName}
                                    </TableCell>
                                    <TableCell>{currency.currencyCode}</TableCell>
                                    <TableCell className="statusArea">
                                        <Typography className={currency.isActive ? "isActive active" : "isActive passive"}></Typography>
                                    </TableCell>
                                    {access === "close" ? null :
                                        <TableCell className="statusButton">
                                            {access === "popup" ?
                                                <>
                                                    <Icon onClick={() => this.permissionNot()} className="far fa-edit"></Icon>
                                                    <Icon onClick={() => this.permissionNot()} className="far fa-trash-alt"></Icon>
                                                </>
                                                : null}
                                            {access === "show" ?
                                                <>
                                                    {updateButton ?
                                                        <Link to={EDIT_CURRENCY(currency.id)}><Icon className="far fa-edit"></Icon></Link>
                                                        : null}
                                                    {deleteButton ?
                                                        <Icon onClick={() => this.handleRemoveCurrency(currency.id)} className="far fa-trash-alt"></Icon>
                                                        : null}
                                                </>
                                                : null}
                                        </TableCell>
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    id="Pagination"
                    rowsPerPageOptions={[0]}
                    component="div"
                    count={searchTotalCount}
                    rowsPerPage={selectedRow}
                    onChangeRowsPerPage={this.handleRowsPerPageChange}
                    page={currentPage}
                    labelDisplayedRows={({ from, to, count }) => `${count} ${resources.LabelPagination2} ${from} - ${to} ${resources.LabelPagination3}.`}
                    labelRowsPerPage="Görüntülenen Kayıt"
                    backIconButtonProps={{
                        'aria-label': 'Önceki Sayfa',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Sonraki Sayfa',
                    }}
                    onChangePage={this.handleChangePage}
                />
                <span className="total">{"(" + totalCount + " " + resources.LabelPagination1 + ")"}</span>
                <div id="PageList">
                    {currentPage > 0 ? <a onClick={() => this.handleChangePage(null, this.state.currentPage - 1)}>{resources.LabelPrevious}</a> : null}
                    {currentPage > 3 ? <><a onClick={() => this.handleChangePage(null, 0)}>1</a><a >...</a></> : null}

                    {loading === false ? this.renderPagination() : null}

                    {(currentPage + 1) < (Math.floor((searchTotalCount / selectedRow)) - 2) ? <a >...</a> : null}

                    {(currentPage) + 1 < (Math.ceil((searchTotalCount / selectedRow)) - 2) ? <a onClick={() => this.handleChangePage(null, Math.ceil((searchTotalCount / selectedRow) - 1))}>{Math.ceil((searchTotalCount / selectedRow))}</a> : null}
                    {(currentPage + 1) < (Math.ceil(searchTotalCount / selectedRow)) ? <a onClick={() => this.handleChangePage(null, this.state.currentPage + 1)}> {resources.LabelNext}</a> : null}
                </div>
            </Paper>
        )
    }
}


