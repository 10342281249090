import React, { Component } from 'react'
import RememberForm from './RememberForm';

export default class Remember extends Component {
    render() {
        return (
            <RememberForm />
        )
    }
}
