import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';
import moment from 'moment';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { invoiceService, locationService } from '../../service';
import { INVOICE } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import Error from '../NotFound/NotFound'


import { invoiceNameValidator, companyValidator, createDateTimeValidator, dueDateTimeValidator } from '../../utils/invoice.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IInvoiceFormState {
    companyList: any,
    companyId: any,
    companyError: string,
    invoiceName: string,
    invoiceNameError: string,
    createDateTime: string,
    createDateTimeError: string,
    dueDateTime: string,
    dueDateTimeError: string,
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface IInvoiceFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class InvoiceForm extends Component {
    public readonly state: IInvoiceFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            companyList: [],
            companyId: 0,
            companyError: "",
            invoiceName: "",
            invoiceNameError: "",
            createDateTime: "0000-00-00",
            createDateTimeError: "",
            dueDateTime: "0000-00-00",
            dueDateTimeError: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeCompany = this.onChangeCompany.bind(this);
        this.onChangeInvoiceName = this.onChangeInvoiceName.bind(this);
        this.onChangeCreateDateTime = this.onChangeCreateDateTime.bind(this);
        this.onChangeDueDateTime = this.onChangeDueDateTime.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        this.getCompanies();
    }

    componentDidMount() {
        if (this.props.match.params.invoiceId) this.getEditableInvoice();
        else this.setState({ loading: false });

        this.form = React.createRef();
    }




    private async getEditableInvoice() {
        const { data, isSuccess, errorCode, errorMessage } = await invoiceService.getById(this.props.match.params.invoiceId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {

            const state = {
                ...data,
                isEdit: true,
                createDateTime: moment(data.createDateTime).format("YYYY-MM-DD"),
                dueDateTime: moment(data.dueDateTime).format("YYYY-MM-DD"),
                companyId: this.state.companyList.find((item: any) => item.id === data.companyId)
            };
            if (isSuccess) this.setState({ ...state });
        }
    }

    /** EVENT LISTENERS */

    private onChangeCompany(companyId: any) {
        this.setState({ companyId, companyError: companyValidator(companyId) });
    }

    private onChangeInvoiceName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ invoiceName: e.target.value, invoiceNameError: invoiceNameValidator(e.currentTarget.value) });
    }

    private onChangeCreateDateTime(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ createDateTime: e.target.value });
    }

    private onChangeDueDateTime(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ dueDateTime: e.target.value });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async getCompanies(useDefault: boolean = true) {
        const { data } = await locationService.getAllCompanies();

        const state = {
            companyId: useDefault ? 0 : this.state.companyId.id,
            companyList: data.companies,
            loading: false
        };

        this.setState(state);

    }

    private async cancel() {
        this.props.history.push(INVOICE);
    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { invoiceName, companyId, createDateTime, dueDateTime } = this.state;

        //Submit Validation
        if (
            companyValidator(companyId) !== "" ||
            invoiceNameValidator(invoiceName) !== "" ||
            createDateTimeValidator(createDateTime) ||
            dueDateTimeValidator(dueDateTime)
        ) {

            this.setState({
                companyError: companyValidator(companyId),
                invoiceNameError: invoiceNameValidator(invoiceName),
                createDateTimeError: createDateTimeValidator(createDateTime),
                dueDateTimeError: dueDateTimeValidator(dueDateTime)
            });
            return false;
        }

        return true;
    }


    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { companyId, invoiceName, createDateTime, dueDateTime, isActive, isEdit } = this.state;

        if (this.formValidation()) {

            const model = {
                companyId: sqlInjectValidator(String(companyId.id)),
                invoiceName: sqlInjectValidator(invoiceName),
                createDateTime: sqlInjectValidator(createDateTime),
                dueDateTime: sqlInjectValidator(dueDateTime),
                isActive
            };

            if (isEdit) await this.editInvoice(model);
            else await this.createInvoice(model);
        }
    }

    private async createInvoice(model: any) {
        const data = await invoiceService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editInvoice(model: any) {
        const data = await invoiceService.update({ ...model, id: this.props.match.params.invoiceId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }



    private renderCompanies() {
        const { companyId, companyList, companyError } = this.state;
        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="companyName" value={companyId} valueKey="id" options={companyList} onChange={this.onChangeCompany} placeholder="Firma *"></CustomSelect>
                <span className="errorMessage">{companyError}</span>
            </FormControl >
        );
    }

    private renderInvoiceName() {
        const { invoiceName, invoiceNameError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="invoiceName"
                    label="Fatura Adı"
                    placeholder="Fatura adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeInvoiceName}
                    value={invoiceName}
                    name="invoiceName"
                    autoComplete="invoiceName"
                    helperText={invoiceNameError}
                />
            </FormControl>
        );
    }

    private renderCreateDateTime() {
        const { createDateTime, createDateTimeError } = this.state;

        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="createDateTime"
                    name="startDateTime"
                    label="Fatura Tarihi"
                    type="date"
                    margin="normal"
                    onChange={this.onChangeCreateDateTime}
                    value={createDateTime === "0000-00-00" ? "" : createDateTime}
                    variant="outlined"
                    helperText={createDateTimeError}
                    placeholder="Fatura tarihi yazınız"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </FormControl>
        );
    }

    private renderDueDateTime() {
        const { dueDateTime, dueDateTimeError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="dueDateTime"
                    name="dueDateTime"
                    label="Bitiş Tarihi"
                    type="date"
                    margin="normal"
                    onChange={this.onChangeDueDateTime}
                    value={dueDateTime === "0000-00-00" ? "" : dueDateTime}
                    variant="outlined"
                    helperText={dueDateTimeError}
                    placeholder="Bitiş tarihi yazınız"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: INVOICE, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">

                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Fatura {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan fatura bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda fatura ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">

                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Fatura {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderInvoiceName()}
                                    {this.renderCompanies()}
                                    {this.renderCreateDateTime()}
                                    {this.renderDueDateTime()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper >

        )
    }

}



export default withStyles(styles)(InvoiceForm);
