import React, { Component, ChangeEvent } from 'react'
import { NavLink } from 'react-router-dom'
import { Redirect } from 'react-router'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'

import { HOME } from '../../routes/paths'

import { emailValidator } from '../../utils/remember.validators'


export interface ILoginRememberFormState {
    emailAddress: string;
    emailError: string;
}

export interface ILoginRememberFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class LoginRememberForm extends Component {
    public readonly state: ILoginRememberFormState;
    public readonly props: ILoginRememberFormProps;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            emailAddress: "",
            emailError: ""
        };

        this.onEnterEmail = this.onEnterEmail.bind(this);
    }

    private onEnterEmail(e: ChangeEvent<HTMLInputElement>) {
        super.setState({ emailAddress: e.currentTarget.value, emailError: emailValidator(e.currentTarget.value) } as ILoginRememberFormState);
    }

    private renderEmail() {
        const { emailAddress, emailError } = this.state;


        return (
            <FormControl margin="none" className="input" required fullWidth error={!!emailError}>
                <TextField
                    id="emailAddress"
                    label="E-posta Adresi"
                    placeholder="sales@finstant.co"
                    margin="none"
                    variant="outlined"
                    onChange={this.onEnterEmail}
                    value={emailAddress}
                    name="emailAddress"
                    autoComplete="emailAddress"
                    helperText={emailError}
                />
            </FormControl>
        );
    }



    public render() {
        const { classes } = this.props;

        return (
            <>
                <Grid container spacing={0} alignItems="center" justify="center" id="Login">
                    <Grid item xs={3} className="Sidebar">
                        <img src="img/sidebarBackground.png" className="topBullets" />
                        <img src="img/sidebarBackground.png" className="bottomBullets" />
                        <img src="img/logo.png" className="logo" />
                        <div className="content">
                            <h2>Banka Hesap Yönetimi</h2>
                            <p>Tüm bankalardaki bakiyelerinizi, hesap ve pos hareketlerinizi tek bir uygulamadan anlık olarak görüntülemek hiç bu kadar kolay olmamıştı.</p>                                
                        </div>
                        <div className="footer">
                            {/*<NavLink to={"/"} className="language">
                                <img src="img/tr.png" />
                                <ul className="menu">
                                    <li className="en"><img src="img/en.png" /> English</li>
                                    <li className="tr"><img src="img/tr.png" /> Türkçe</li>
                                </ul>
                            </NavLink>
                            <NavLink to={"/"}>Yardım</NavLink>
                            <NavLink to={"/"}>İletişim</NavLink>*/}
                        </div>
                    </Grid>

                    <Grid item xs={9} id="LoginForm">
                        <Paper className="Form Remember">
                            <h2>Şifremi Unuttum</h2>
                            <p>E-posta adresinizi girerek şifre sıfırlama talebinde bulunabilirsiniz.</p>
                            {this.renderEmail()}
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                fullWidth
                            >
                                ŞİFREMİ SIFIRLA
                            </Button>

                            <p><NavLink to={"/login"}>Giriş ekranına dön</NavLink></p>
                        </Paper>

                        <p className="copyright">Finstant &copy; 2021</p>
                    </Grid>
                </Grid>
            </>
        )
    }

}



export default withStyles(styles)(LoginRememberForm);
