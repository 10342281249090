import React, { Component } from 'react'
import Wrapper from '../layout/Wrapper';
import Grid from '@material-ui/core/Grid';
import AccountBalances from './AccountBalances';
import AccountDetail from './AccountDetail';

export default class Account extends Component {

    render() {
        return (
            <Wrapper>
            <AccountBalances />
                <Grid container id="Content">
                    <Grid xs={12}>
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
}
