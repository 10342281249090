//BankCredential

export const customerCodeValidator = (customerCode: string): string => {
    if (customerCode === "") return 'Müşteri kodu alanını boş geçemezsiniz!'
    return '';
}

export const companyCodeValidator = (companyCode: string): string => {
    if (companyCode === "") return 'Firma kodu alanını boş geçemezsiniz!'
    return '';
}

export const userNameValidator = (userName: string): string => {
    if (userName === "") return 'Kullanıcı adı alanını boş geçemezsiniz!'
    return '';
}

export const serviceIdValidator = (serviceId: string): string => {
    if (serviceId === "") return 'ServiceId alanını boş geçemezsiniz!'
    return '';
}

export const environmentValidator = (environment: string): string => {
    if (environment === "") return 'Environment alanını boş geçemezsiniz!'
    return '';
}

export const serviceCompanyNameValidator = (serviceCompanyName: string): string => {
    if (serviceCompanyName === "") return 'ServiceCompanyName alanını boş geçemezsiniz!'
    return '';
}

export const companyKeyValidator = (companyKey: string): string => {
    if (companyKey === "") return 'CompanyKey alanını boş geçemezsiniz!'
    return '';
}

export const passwordCodeValidator = (password: string): string => {
    if (password === "") return 'Şifre Kodu alanını boş geçemezsiniz!'
    return '';
}

export const bankValidator = (bankId: number): string => {
    if (bankId === 0) return 'Banka seçiniz!'
    return '';
}

export const companyValidator = (companyId: number): string => {
    if (companyId === 0) return 'Firma seçiniz!'
    return '';
}
