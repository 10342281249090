import XHRService from "./xhr.service";

export default interface IUserPermissionRequest {
    id?: number,
    userId: number,
    companyId: number,
    pessimissionId: number,
    isActive: boolean,
    searchText: string
}


interface IUserPermission {
    id: number,
    userId: number,
    companyId: number,
    permissionId: number,
    isActive: boolean,
    searchText: string
}

interface IUserPermissionResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IUserPermission
}

interface IUserPermissionListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        userPermissions: IUserPermission[]
    }
}

export default class UserPermissionService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IUserPermissionResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/UserPermission/CreateUserPermission", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IUserPermissionRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/UserPermission/UpdateUserPermission", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<IUserPermissionResponse> {
        const { isSuccess }: any = await this.XHRService.post("/UserPermission/DeleteUserPermission", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            searchText,
            companyId: 0,
            permissionId: 0,
            userId: 0,
            isActive,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/UserPermission/GetUserPermissions", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/UserPermission/GetUserPermissionsExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/UserPermission/GetUserPermissionById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
