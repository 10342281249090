//Permission

export const permissionKeyValidator = (permissionKey: string): string => {
    if (permissionKey === "") return 'İzin anahtarı alanını boş geçemezsiniz!'
    return '';
}

export const permissionDescriptionValidator = (permissionDescription: string): string => {
    if (permissionDescription === "") return 'İzin açıklaması alanını boş geçemezsiniz!'
    return '';
}
