import React, { Component } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import Wrapper from '../layout/Wrapper';
//import PaymentForm from './PaymentForm';
import PaymentList from './PaymentList';

import { ADD_NEW_PAYMENT } from '../../routes/paths';

interface IPaymentState {
    addNewPayment: boolean
}

export default class Payment extends Component {
    public readonly state: IPaymentState;
    constructor(props: any) {
        super(props);

        this.state = {
            addNewPayment: false
        }

        this.addNewPayment = this.addNewPayment.bind(this);
    }


    private addNewPayment() {
        this.setState({ addNewPayment: true });
    }

    render() {
        const { addNewPayment } = this.state;
        return (
            <Wrapper>
                {addNewPayment && <Redirect to={ADD_NEW_PAYMENT} />}
                <Grid container id="Breadcrumb">
                    <Grid xs={12}>
                        <ul>
                            <li>Ödeme</li>
                            <li>Finans</li>
                            <li>Ödeme</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container id="Content">
                    <Grid xs={12}>
                        <PaymentList />
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
}
