import React, { Component } from 'react';
import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

export interface IAlertProps{
    type: any;
    message: string;
}

export interface IAlertState{
    isOpen: boolean;
}

export default class Alert extends Component {
    public readonly props: IAlertProps;
    public readonly state: IAlertState;

    constructor(props: any){
        super(props);

        this.props = props;

        this.state = {
            isOpen: true
        }
    }

    render() {
        const { type, message } = this.props;
        const { isOpen } = this.state;

        return (
            <Snackbar message={message} timeoutMs={10000} className={type === "success" ? "messageBoxSuccess" : "messageBoxError"} />
        )
    }
}
