import XHRService from "./xhr.service";

export default interface IResourceRequest {
    id?: number;
    resourceTypeId: number;
    languageId: number;
    resourceKey: string;
    value: string;
    isActive: boolean;
}


interface IResource {
    id: number;
    resourceTypeId: number;
    languageId: number;
    resourceKey: string;
    value: string;
    isActive: boolean;
}

interface IResourceResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IResource
}

interface IResourceListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        resources: IResource[]
    }
}

export default class ResourceService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IResourceResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Resource/CreateResource", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IResourceRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Resource/UpdateResource", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<IResourceResponse> {
        const { isSuccess }: any = await this.XHRService.post("/Resource/DeleteResource", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            resourceTypeId: 0,
            languageId: 0,
            isActive,
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/Resource/GetResources", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/Resource/GetResourcesExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/Resource/GetResourceById?id=${id}`).catch(() => ({ isSuccess: false }));
    }

    public async language(languageCode: string): Promise<any> {

        const model = {
            languageCode,
            isActive: true,
            searchText: null,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.post("/Resource/GetResources", model).catch(() => ({ isSuccess: false }));
    }
}
