import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Typography from "@material-ui/core/Typography"
import { accountStatementsService } from '../../service';

interface IAccountStatementsState {
    list: [],
    loading: boolean;
}

export default class AccountStatements extends Component {
    public readonly state: IAccountStatementsState;
    private timeout = 600000;
    private interval: any;

    constructor(props: any) {
        super(props);

        this.state = {
            list: [],
            loading: false,
        }

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    private async init() {
        this.setState({ loading: true });
        await this.getData();
        this.interval = setInterval(this.getData, this.timeout);
    }

    private async getData() {
        // this.setState({ loading: true });
        const { data } = await accountStatementsService.cockpit("", "", 0, "", "", 30, 0, 0);
        this.setState({ list: data.accountStatements, loading: false })
    }

    private renderAmountConvert(amount: string) {        
        if (amount === "null") return;

        if (amount == "0") {
            return "0,00";
        }              

        let p = "";
        let isaretli = ""

        let sayi = amount.split(".");
        let net = sayi[0];
        let ondalik = sayi[1];

        net.indexOf("-") != -1 ? isaretli = "-" : null
        net = net.replace("-", "");

        ondalik == undefined ? ondalik = "00" : null
        ondalik.length == 1 ? ondalik += "0" : null

        let tersSayi = ((net.split("")).reverse()).join("");

        for (let i = 0; i < tersSayi.length; i++) {
            if (i % 3 == 0 && i != 0) p += "."
            p += tersSayi.substr(i, 1);
        }


        return isaretli + (((p.split("")).reverse()).join("")) + "," + ondalik;
    }

    private renderTable() {
        const { list } = this.state;
        return <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Tarih</TableCell>
                    <TableCell>Banka</TableCell>
                    <TableCell>Açıklama</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>Tutar</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>Döviz</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>Bakiye</TableCell>
                    <TableCell style={{ width: 50, textAlign: 'center' }}>Tip</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((company: any) => (
                    <TableRow key={company.id}>
                        <TableCell scope="row">
                            {company.transactionDate}
                        </TableCell>
                        <TableCell>{company.bankName}</TableCell>
                        <TableCell>{company.description.trim()}</TableCell>
                        <TableCell style={{ textAlign: 'right', minWidth: 100 }}>{this.renderAmountConvert(String(company.amount))}</TableCell>
                        <TableCell>{company.currencyCode}</TableCell>
                        <TableCell style={{ textAlign: 'right', minWidth: 100 }}>{String(company.balance)}</TableCell>
                        <TableCell style={{ width: 50 }}>
                            <Typography style={{ marginRight: 0, width: 23, height: 23 }} className={company.amount < 0 ? "isActive passive" : "isActive active"}>{company.amount < 0 ? "A" : "B"}</Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    }

    render() {
        return (
            <Paper>
                {this.state.loading ? <CircularProgress className="progress" /> : this.renderTable()}
            </Paper>
        )
    }
}
