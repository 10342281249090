import React, { Component } from 'react'
import { Link } from "react-router-dom"

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Avatar from '@material-ui/core/Avatar'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../layout/Wrapper'

import { userService, accountStatementsService, invoiceService } from '../../service'
import { EDIT_USER, ADD_NEW_PAYMENT, PASSWORD_RESET } from '../../routes/paths'


export interface IUserFormState {
    nameSurname: string,
    emailAddress: string,
    mobilePhoneNumber: number,
    isActive: boolean,
    isSuccess: boolean,
    loading: boolean,
    userId: number,
    totalAccountStatementCount: number,
    list: any,
    totalCount: number,
    searchTotalCount: number
}

export interface IUserFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class UserForm extends Component {
    public readonly state: IUserFormState;
    public readonly props: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            nameSurname: "",
            emailAddress: "",
            mobilePhoneNumber: 0,
            isActive: true,
            isSuccess: false,
            loading: true,
            userId: 0,
            totalAccountStatementCount: 0,
            list: [],
            totalCount: 0,
            searchTotalCount: 0
        };

    }

    componentWillMount() {
        this.getPermissionControl();
    }

    private async getPermissionControl() {
        const { data } = await userService.permission();
        this.setState({ userId: data.userId });
        this.getShowUser();
        this.accountStatementCount();
        this.getInvoices();
    }


    private async getInvoices() {
        const { data } = await invoiceService.get("", 0, 20, 0, true);
        this.setState({ list: data.invoices, loading: false, totalCount: data.totalCount, searchTotalCount: data.searchTotalCount })
    }

    private async accountStatementCount() {
        const { data } = await accountStatementsService.accountStatementCount();
        this.setState({ totalAccountStatementCount: data.totalCount });
    }


    private async getShowUser() {
        const { data, isSuccess } = await userService.getById(this.state.userId);

        const state = {
            ...data
        }
        if (isSuccess) this.setState({ ...state, loading: false });
    }



    private renderRowHeaders() {
        return (
            <TableRow style={{ backgroundColor: '#fdfdfd' }}>
                <TableCell>
                    <TableSortLabel>
                        Fatura Adı
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                        Fatura Tarihi
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                        Bitiş Tarihi
                    </TableSortLabel>
                </TableCell>
                <TableCell className="statusArea">
                    <TableSortLabel>
                        Durum
                    </TableSortLabel>
                </TableCell>
                <TableCell className="statusButton"></TableCell>
            </TableRow >
        );
    }


    public render() {
        const { loading, nameSurname, emailAddress, mobilePhoneNumber, userId, totalAccountStatementCount, list } = this.state;
        return (
            <Wrapper>

                <Grid container id="Breadcrumb">
                    <Grid item xs={12}>
                        <ul>
                            <li>Profil</li>
                            <li>Finstant</li>
                            <li>Profil</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container id="Form" className="profileContent">

                    {loading && <CircularProgress className="progress" />}
                    {!loading ?
                        <>
                            <Grid item xs={3} className="sidebar" style={{ background: 'none', padding: 0 }}>
                                <div style={{ padding: '40px 60px' }}>
                                    <Avatar alt="Kaan Verdioğlu" src="img/avatar_user_big.png" style={{ width: '100%', height: 'auto' }} className="Avatar" />
                                    <Typography variant="h5">{nameSurname}</Typography>
                                    <Typography variant="subtitle2" style={{ color: '#9aafcd', fontSize: 12, marginBottom: 0, marginTop: 20 }}>Toplam {totalAccountStatementCount} hesap hareketiniz bulunmaktadır.</Typography>
                                </div>
                                <hr />
                                <ul>
                                    <li><a href=""><Icon className="fas fa-mobile-alt"></Icon> {mobilePhoneNumber}</a></li>
                                    <li><a href=""><Icon className="far fa-envelope"></Icon> {emailAddress}</a></li>
                                </ul>

                                {/*<Link to={EDIT_USER(userId)} className="detail">Bilgileri Düzenle</Link>*/}

                                <ul className="profileContentMenu">
                                    <li><Link to={PASSWORD_RESET}><Icon className="fas fa-key"></Icon>ŞİFREMİ DEĞİŞTİR</Link></li>
                                    {/*<li><Link to={""}><Icon className="far fa-life-ring"></Icon> YARDIM</Link></li>*/}
                                </ul>
                            </Grid>
                            <Grid item xs={9} id="Content" style={{ padding: 0 }}>
                                <Grid container>
                                    <Grid xs={12}>
                                        <Typography variant="h2" style={{ margin: 20, fontSize: 16, textAlign: 'left' }}>Faturalar</Typography>
                                        <Table>
                                            <TableHead>
                                                {this.renderRowHeaders()}
                                            </TableHead>
                                            <TableBody>
                                                {list.map((invoice: any) => (
                                                    <TableRow key={invoice.id}>

                                                        <TableCell scope="row">{invoice.invoiceName}</TableCell>
                                                        <TableCell>{invoice.startDateTime}</TableCell>
                                                        <TableCell>{invoice.dueDateTime}</TableCell>
                                                        <TableCell className="statusArea">
                                                            <Typography className={invoice.isActive ? "isActive active" : "isActive passive"}></Typography>
                                                        </TableCell>
                                                        <TableCell className="statusButton">
                                                            <a href={"https://www.finstant.co/Invoice/" + invoice.invoiceName + ".pdf"} target="_blank"><Icon className="fas fa-file-pdf" style={{ fontSize: 22 }}></Icon></a>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                        : null}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(UserForm);
