import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { bankBranchService, locationService } from '../../service';
import { BANKBRANCH } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import Error from '../NotFound/NotFound'

import { bankBranchCodeValidator, bankBranchNameValidator, bankValidator, countryValidator, cityValidator, districtValidator, addressValidator, phoneNumberValidator } from '../../utils/bankbranch.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IBankBranchFormState {
    bankBranchName: string,
    bankBranchNameError: string,
    bankBranchCode: string,
    bankBranchCodeError: string,
    bankList: [],
    bankId: any,
    bankError: string,
    countryList: [],
    countryId: any,
    countryError: string,
    cityList: [],
    cityId: any,
    cityError: string,
    districtList: [],
    districtId: any,
    districtError: string,
    address: string,
    addressError: string,
    phoneNumber: string,
    phoneNumberError: string,
    description: string,
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface IBankBranchFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class BankBranchForm extends Component {
    public readonly state: IBankBranchFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            bankBranchName: "",
            bankBranchNameError: "",
            bankBranchCode: "",
            bankBranchCodeError: "",
            bankList: [],
            bankId: 0,
            bankError: "",
            countryList: [],
            countryId: 0,
            countryError: "",
            cityList: [],
            cityId: 0,
            cityError: "",
            districtList: [],
            districtId: 0,
            districtError: "",
            address: "",
            addressError: "",
            phoneNumber: "",
            phoneNumberError: "",
            description: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeBankBranchName = this.onChangeBankBranchName.bind(this);
        this.onChangeBankBranchCode = this.onChangeBankBranchCode.bind(this);
        this.onChangeBank = this.onChangeBank.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeDistrict = this.onChangeDistrict.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.bankBranchId) this.getEditableBankBranch();
        else { this.getCountries(); this.getBanks(); }

        this.form = React.createRef();
    }

    private async getEditableBankBranch() {
        const { data, isSuccess, errorCode, errorMessage } = await bankBranchService.getById(this.props.match.params.bankBranchId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getCountries();
            await this.getBanks();

            const state = {
                ...data,
                bankId: this.state.bankList.find((item: any) => item.id === data.bankId),
                countryId: this.state.countryList.find((item: any) => item.id === data.countryId),
                cityId: this.state.cityList.find((item: any) => item.id === data.cityId),
                districtId: this.state.districtList.find((item: any) => item.id === data.districtId),
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state });
            await this.getDistricts(state.countryId.id, state.cityId.id);

            this.setState({ districtId: this.state.districtList.find((item: any) => item.id === data.districtId), loading: false })
        }
    }

    /** EVENT LISTENERS */

    private onChangeBankBranchName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ bankBranchName: e.target.value, bankBranchNameError: bankBranchNameValidator(e.currentTarget.value) });
    }

    private onChangeBankBranchCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ bankBranchCode: e.target.value, bankBranchCodeError: bankBranchCodeValidator(e.currentTarget.value) });
    }

    private onChangeBank(bankId: any) {
        this.setState({ bankId });
    }

    private onChangeCountry(countryId: any) {
        this.setState({ countryId, countryError: countryValidator(countryId) });
        this.getCities(countryId.id);
    }

    private onChangeCity(cityId: any) {
        this.setState({ cityId, cityError: cityValidator(cityId) });
        this.getDistricts(this.state.countryId.id, cityId.id);
    }

    private onChangeDistrict(districtId: any) {
        this.setState({ districtId, districtError: districtValidator(districtId) });
    }

    private onChangeAddress(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ address: e.target.value, addressError: addressValidator(e.currentTarget.value) });
    }

    private onChangePhoneNumber(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ phoneNumber: e.target.value, phoneNumberError: phoneNumberValidator(e.currentTarget.value) });
    }

    private onChangeDescription(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ description: e.target.value });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(BANKBRANCH);
    }

    private async getBanks() {
        const { data } = await locationService.getBanks();

        const state = {
            bankId: this.state.bankId === 0 ? 0 : this.state.bankId.id,
            bankList: data.banks
        };

        this.setState(state);

    }

    private async getCountries() {
        const { data } = await locationService.getCountries();

        const state = {
            countryId: this.state.countryId === 0 ? 0 : this.state.countryId.id,
            countryList: data.countries
        };

        this.setState(state);
        await this.getCities(state.countryId);
    }

    private async getCities(countryId: number) {
        const { data } = await locationService.getCities(countryId);

        const state = {
            cityList: data.cities,
            cityId: this.state.cityId === 0 ? 0 : this.state.cityId.id
        };

        this.setState(state);
        await this.getDistricts(countryId, state.cityId);
    }

    private async getDistricts(countryId: number, cityId: number) {
        const { data } = await locationService.getDistricts(countryId, cityId);

        const state = {
            districtList: data.districts,
            districtId: this.state.districtId === 0 ? 0 : this.state.districtId.id,
            loading: false
        }

        this.setState(state);
    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { bankBranchCode, bankBranchName, bankId, countryId, cityId, districtId, address, phoneNumber } = this.state;

        if (
            bankBranchCodeValidator(bankBranchCode) !== "" ||
            bankBranchNameValidator(bankBranchName) !== "" ||
            addressValidator(address) !== "" ||
            phoneNumberValidator(phoneNumber) !== "" ||
            countryValidator(countryId) !== "" ||
            bankValidator(bankId) !== "" ||
            cityValidator(cityId) !== "" ||
            districtValidator(districtId) !== ""

        ) {

            this.setState({
                bankBranchCodeError: bankBranchCodeValidator(bankBranchCode),
                bankBranchNameError: bankBranchNameValidator(bankBranchName),
                addressError: addressValidator(address),
                phoneNumberError: phoneNumberValidator(phoneNumber),
                countryError: countryValidator(countryId),
                bankError: bankValidator(bankId),
                cityError: cityValidator(cityId),
                districtError: districtValidator(districtId)
            });
            return false;
        }

        return true;
    }

    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { bankBranchName, bankBranchCode, bankId, countryId, cityId, districtId, address, phoneNumber, description, isActive, isEdit } = this.state;

        if (this.formValidation()) {

            const model = {
                bankBranchName: sqlInjectValidator(bankBranchName),
                bankBranchCode: sqlInjectValidator(bankBranchCode),
                bankId: sqlInjectValidator(String(bankId.id)),
                countryId: sqlInjectValidator(String(countryId.id)),
                cityId: sqlInjectValidator(String(cityId.id)),
                districtId: sqlInjectValidator(String(districtId.id)),
                address: sqlInjectValidator(address),
                phoneNumber: sqlInjectValidator(phoneNumber),
                description: sqlInjectValidator(description),
                isActive
            };

            if (isEdit) await this.editBankBranch(model);
            else await this.createBankBranch(model);
        }
    }

    private async createBankBranch(model: any) {
        const data = await bankBranchService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editBankBranch(model: any) {
        const data = await bankBranchService.update({ ...model, id: this.props.match.params.bankBranchId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private renderBankBranchName() {
        const { bankBranchName, bankBranchNameError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="bankBranchName"
                    label="Şube Adı"
                    placeholder="Şube adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeBankBranchName}
                    value={bankBranchName}
                    name="bankBranchName"
                    autoComplete="bankBranchName"
                    helperText={bankBranchNameError}
                />
            </FormControl>
        );
    }

    private renderBankBranchCode() {
        const { bankBranchCode, bankBranchCodeError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="bankBranchCode"
                    label="Şube Kodu"
                    placeholder="Şube kodu yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeBankBranchCode}
                    value={bankBranchCode}
                    name="bankBranchCode"
                    autoComplete="bankBranchCode"
                    helperText={bankBranchCodeError}
                />
            </FormControl>
        );
    }


    private renderBanks() {
        const { bankId, bankList, bankError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="bankName" value={bankId} valueKey="id" options={bankList} onChange={this.onChangeBank} placeholder="Banka *"></CustomSelect>
                <span className="errorMessage">{bankError}</span>
            </FormControl>
        );
    }

    private renderCountries() {
        const { countryId, countryList, countryError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="countryName" value={countryId} valueKey="id" options={countryList} onChange={this.onChangeCountry} placeholder="Ülke *"></CustomSelect>
                <span className="errorMessage">{countryError}</span>
            </FormControl >
        );
    }

    private renderCities() {
        const { cityId, cityList, cityError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="cityName" value={cityId} valueKey="id" options={cityList} onChange={this.onChangeCity} placeholder="Şehir *"></CustomSelect>
                <span className="errorMessage">{cityError}</span>
            </FormControl>
        );
    }

    private renderDistricts() {
        const { districtId, districtList, districtError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="districtName" value={districtId} valueKey="id" options={districtList} onChange={this.onChangeDistrict} placeholder="İlçe *"></CustomSelect>
                <span className="errorMessage">{districtError}</span>
            </FormControl>
        );
    }

    private renderPhoneNumber() {
        const { phoneNumber, phoneNumberError } = this.state;


        return (
            <FormControl margin="none" style={{ width: '98%', height: 'auto' }} className="input">
                <TextField
                    id="phoneNumber"
                    label="Telefon"
                    placeholder="Telefon yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangePhoneNumber}
                    value={phoneNumber}
                    name="phoneNumber"
                    autoComplete="phoneNumber"
                    helperText={phoneNumberError}
                />
            </FormControl>
        );
    }

    private renderAddress() {
        const { address, addressError } = this.state;


        return (
            <FormControl margin="none" style={{ width: '98%', height: 'auto' }} className="input">
                <TextField
                    id="address"
                    label="Adres"
                    placeholder="Adres yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeAddress}
                    value={address}
                    name="address"
                    autoComplete="address"
                    helperText={addressError}
                    multiline
                    rows={6}
                    className="multiInput"
                />
            </FormControl>
        );
    }

    private renderDescription() {
        const { description } = this.state;


        return (
            <FormControl margin="none" style={{ width: '98%', height: 'auto' }} className="input">
                <TextField
                    id="description"
                    label="Açıklama"
                    placeholder="Açıklama yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeDescription}
                    value={description}
                    name="description"
                    autoComplete="description"
                    className="multiInput"
                    multiline
                    rows={6}
                />
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: BANKBRANCH, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar" style={{ backgroundPositionY: '12%' }}>
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Şube {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan şube bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda şube ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Şube {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderBankBranchName()}
                                    {this.renderBankBranchCode()}
                                    {this.renderBanks()}
                                    {this.renderCountries()}
                                    {this.renderCities()}
                                    {this.renderDistricts()}
                                    {this.renderPhoneNumber()}
                                    {this.renderAddress()}
                                    {this.renderDescription()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(BankBranchForm);
