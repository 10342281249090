//BankFTPCredential


export const userNameValidator = (userName: string): string => {
    if (userName === "") return 'Kullanıcı adı alanını boş geçemezsiniz!'
    return '';
}

export const passwordCodeValidator = (password: string): string => {
    if (password === "") return 'Şifre Kodu alanını boş geçemezsiniz!'
    return '';
}

export const bankValidator = (bankId: number): string => {
    if (bankId === 0) return 'Banka seçiniz!'
    return '';
}

export const bankAccountValidator = (bankAccountId: number): string => {
    if (bankAccountId === 0) return 'Hesap seçiniz!'
    return '';
}

export const companyValidator = (companyId: number): string => {
    if (companyId === 0) return 'Firma seçiniz!'
    return '';
}

export const posMerchantCodeValidator = (posMerchantCode: string): string => {
    if (posMerchantCode === "") return 'Üye İşyeri Numarası alanını boş geçemezsiniz!'
    return '';
}
