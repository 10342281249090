import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { accountBalancesService } from '../../service';
import { withStyles } from '@material-ui/core/';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface IAccountBalancesState {
    list: [],
    itemCount: number,
    loading: boolean
}

const style = (theme: any) => ({
    wrapper: {
        display: "flex",
        alignItems: "top",
        justifyContent: "space-between"
    },
    content: {
        display: "flex",
        justifyContent: "space-between"
    },
    text: {
        color: '#6e9bc7'
    },
    paper: {
        marginBottom: "30px"
    }
})

class AccountBalances extends Component {
    public readonly state: IAccountBalancesState;
    public readonly props: any;

    private interval: any;

    private timeout = 600000;
    constructor(props: any) {
        super(props);

        this.state = {
            list: [],
            itemCount: 1,
            loading: false
        }

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    private async init() {
        this.setState({ loading: true });
        await this.getData();
        this.interval = setInterval(this.getData, this.timeout);
    }

    private async getData() {
        const { data } = await accountBalancesService.getTotals();
        this.setState({ list: data.accountBalanceTotalItems, itemCount: data.accountBalanceTotalItems.length, loading: false });
    }

    private renderAmountConvert(amount: string) {
        if (amount == "0") {
            return "0,00";
        }

        let p = "";
        let isaretli = ""

        let sayi = amount.split(".");
        let net = sayi[0];
        let ondalik = sayi[1];

        net.indexOf("-") != -1 ? isaretli = "-" : null
        net = net.replace("-", "");

        ondalik == undefined ? ondalik = "00" : null
        ondalik.length == 1 ? ondalik += "0" : null

        let tersSayi = ((net.split("")).reverse()).join("");

        for (let i = 0; i < tersSayi.length; i++) {
            if (i % 3 == 0 && i != 0) p += "."
            p += tersSayi.substr(i, 1);
        }


        return isaretli + (((p.split("")).reverse()).join("")) + "," + ondalik;
    }

    private renderCards() {
        const { list } = this.state;
        const { classes } = this.props;
        return list.map((balance: any) => (
            <Card key={balance.currencyId} className={"CardColors"} id="Card">
                <CardContent className={classes.content}>
                    <div>
                        <Typography variant="h5" component="h2" className={classes.text}>{balance.currencyCode}</Typography>
                        <Typography color="textSecondary" className={classes.text}>{balance.currencyName} </Typography>
                    </div>
                    <Typography variant="h5" component="h2" className={classes.text}>{this.renderAmountConvert(String(balance.balance))}</Typography>
                </CardContent>
            </Card>
        ))
    }

    private renderCarousel() {
        const { itemCount } = this.state;

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: itemCount > 5 ? 5 : itemCount,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: itemCount > 3 ? 3 : itemCount,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: itemCount > 1 ? 2 : 1,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
            },
        };

        return (
            <Carousel
                responsive={responsive}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
            >
                {this.renderCards()}
            </Carousel>
        );
    }

    render() {
        const { classes } = this.props;
        return this.state.itemCount > 0 ? (
            <div className={classes.paper}>
                {this.state.loading ? <CircularProgress className="progress" /> : <div className={classes.wrapper} id="BalanceWrapper">{this.renderCarousel()}</div>}
            </div>
        ) : null
    }
}


export default withStyles(style)(AccountBalances);
