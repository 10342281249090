//Company

export const companyNameValidator = (companyName: string): string => {
  if (companyName === '') return 'Şirket adı alanını boş geçemezsiniz!'
  else if (companyName.length < 6)
    return 'Şirket adı en az 6 karakter olabilir!'
  return ''
}

export const userLimitedValidator = (userLimit: number): string => {
  if (userLimit < 1) return "Kullanıcı limiti 0'dan büyük olmalıdır!"
  return ''
}

export const vatNumberValidator = (vatNumber: string): string => {
  if (vatNumber === '') return 'Vergi Numarası alanını boş geçemezsiniz!'
  else if (vatNumber.length < 10)
    return 'Vergi Numarası en az 10 karakter olabilir!'
  return ''
}

export const expireDateValidator = (expireDate: string): string => {
  if (expireDate === '0000-00-00') return 'Lütfen bir tarih seçiniz!'
  return ''
}

export const ipAddressValidator = (ipAddress: string): string => {
  if (ipAddress.length < 9 || ipAddress.length > 15)
    return 'Geçerli bir IP adresi giriniz!'
  return ''
}

export const employeeValidator = (employeeValue: string): string => {
  if (employeeValue === '') return 'Çalışan sayısı seçmelisiniz!'
  return ''
}

export const countryValidator = (countryId: number): string => {
  if (countryId === 0) return 'Ülke seçiniz!'
  return ''
}

export const cityValidator = (cityId: number): string => {
  if (cityId === 0) return 'Şehir seçiniz!'
  return ''
}

export const districtValidator = (districtId: number): string => {
  if (districtId === 0) return 'İlçe seçiniz!'
  return ''
}

export const addressValidator = (address: string): string => {
  if (address === '') return 'Adres alanını boş geçemezsiniz!'
  else if (address.length < 15) return 'Adres alanı en az 15 karakter olabilir!'
  return ''
}

/*export const partnerValidator = (partnerId: number): string => {
    if (partnerId === 0) return 'İş Ortağı seçiniz!'
    return '';
}*/

export const hhsValidator = (value: string): string => {
  if (value === '') return 'HHS alanını boş geçemezsiniz!'
  return ''
}

export const consentDateValidator = (value: string): string => {
  if (value === '') return 'Tarihi alanını boş geçemezsiniz!'
  return ''
}
