import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Wrapper from '../layout/Wrapper'
import BankBranchList from './BankBranchList'

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { cookieService, resourceService } from '../../service';

interface IBankBranchState {
    resources: any
}
interface IBankBranchProps {
    location: any,
}

export default class BankBranch extends Component {
    public readonly state: IBankBranchState;
    public readonly props: IBankBranchProps;

    public readonly LANGUAGE_NAME = 'LANGUAGE_TOKEN';

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            resources: []
        }

    }

    componentWillMount() {
        this.getResources()
    }


    private async getResources() {
        const resourceLanguage: any = cookieService.resourceItem(this.LANGUAGE_NAME);
        const { data } = await resourceService.language(resourceLanguage);
        var resources: any = {};
        data.resources.forEach((item: any) => {
            resources[item.resourceKey] = item.value;
        });

        this.setState({ resources: resources, loading: false });
    }

    private renderMessage(errorMessage: string) {
        return (
            <Snackbar message={errorMessage} timeoutMs={5000} className="messageBoxSuccess" />
        );
    }

    render() {
        const { resources } = this.state;
        return (
            <Wrapper>
                <Grid container id="Breadcrumb">
                    <Grid xs={12}>
                        <ul>
                            <li>{resources.MenuLeftmenu1_8_2}</li>
                            <li>{resources.MenuLeftmenu2_2}</li>
                            <li>{resources.MenuLeftmenu1_8}</li>
                            <li>{resources.MenuLeftmenu1_8_2}</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container id="Content">
                    <Grid xs={12}>
                        <BankBranchList />
                    </Grid>
                </Grid>
                {this.props.location.state !== undefined ? this.renderMessage(this.props.location.state.errorMessage) : null}
            </Wrapper>
        )
    }
}
