import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

import {
  COMPANY,
  COCKPIT,
  ACCOUNT,
  ACCOUNTBALANCEHISTORY,
  REPORTS,
  ACCOUNTSTATEMENT,
  POSSTATEMENT,
  BANK,
  BANKBRANCH,
  BANKACCOUNT,
  BANKCREDENTIAL,
  BANKFTPCREDENTIAL,
  COUNTRY,
  CITY,
  DISTRICT,
  CURRENCY,
  USER,
  PERMISSION,
  ROLE,
  LANGUAGE,
  RESOURCETYPE,
  RESOURCE,
  BANKACCOUNTUSER,
  COMPANYUSER,
  USERPERMISSION,
  ROLEPERMISSION,
  NOTIFICATION,
  INVOICE,
  PARTNER,
  ADD_NEW_BANK,
  PROFILE,
  ADD_NEW_BANKACCOUNT,
  ADD_NEW_BANKBRANCH,
  ADD_NEW_CURRENCY,
  ADD_NEW_PERMISSION,
  ADD_NEW_USER,
  ADD_NEW_ROLE,
  ADD_NEW_COMPANY,
  ADD_NEW_BANKCREDENTIAL,
  ADD_NEW_BANKFTPCREDENTIAL,
  PAYMENT,
  ADD_NEW_COMPANYUSER,
  ADD_NEW_USERPERMISSION,
  ADD_NEW_BANKACCOUNTUSER,
  ADD_NEW_ROLEPERMISSION,
  ADD_NEW_NOTIFICATION,
  ADD_NEW_INVOICE,
  VALORDATE,
  ADD_MULTI_USER,
  ADD_NEW_PARTNER,
  BALANCEANALYSIS,
} from '../routes/paths'

import { userService, resourceService, cookieService } from '../service'

import '../styles/style.css'

const drawerWidth = 240

export interface ISidebarState {
  open: boolean
  submenuCompany: boolean
  submenuUser: boolean
  submenuBank: boolean
  submenuDefinition: boolean
  submenuReporting: boolean
  sidebarDown: boolean
  confirmStatus: boolean
  permissionList: any
  isPosStatement: boolean
  isAccounting: boolean
  isReporting: boolean
  isJustStatement: boolean
  isExcelAndPdfExport: boolean
  userId: number
  addMenu: boolean
  resources: any
  loading: boolean
}

export interface ISidebarProps {
  classes: any
  drawerOpen: boolean
}

const styles: any = (theme: any) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 20px',
    ...theme.mixins.toolbar,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    'border-right': 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  sidebarOpen: {
    marginLeft: '240px !important',
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sidebarClose: {
    marginLeft: '73px !important',
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  NavbarLeft: {
    marginLeft: 50,
  },
  subheader: {
    textTransform: 'uppercase',
    padding: '0 10px',
    position: 'static',
  },
  hide: {
    display: 'none !important',
  },
  show: {
    display: 'block !important',
  },
  drawerButtonOpen: {
    marginLeft: '180px !important',
  },
  drawerButtonClose: {
    marginLeft: '5px !important',
  },
})

const companyView = (props: any) => <Link to={COMPANY} {...props} />
const bankView = (props: any) => <Link to={BANK} {...props} />
const bankBranchView = (props: any) => <Link to={BANKBRANCH} {...props} />
const bankAccountView = (props: any) => <Link to={BANKACCOUNT} {...props} />
const bankCredentialView = (props: any) => (
  <Link to={BANKCREDENTIAL} {...props} />
)
const bankFTPCredentialView = (props: any) => (
  <Link to={BANKFTPCREDENTIAL} {...props} />
)
const countryView = (props: any) => <Link to={COUNTRY} {...props} />
const cityView = (props: any) => <Link to={CITY} {...props} />
const districtView = (props: any) => <Link to={DISTRICT} {...props} />
const currencyView = (props: any) => <Link to={CURRENCY} {...props} />
const cockpitView = (props: any) => <Link to={COCKPIT} {...props} />
const accountView = (props: any) => <Link to={ACCOUNT} {...props} />
const balanceHistoryView = (props: any) => (
  <Link to={ACCOUNTBALANCEHISTORY} {...props} />
)
const accountStatementView = (props: any) => (
  <Link to={ACCOUNTSTATEMENT} {...props} />
)
const posStatementView = (props: any) => <Link to={POSSTATEMENT} {...props} />
const valorDateView = (props: any) => <Link to={VALORDATE} {...props} />
const balanceAnalysisView = (props: any) => (
  <Link to={BALANCEANALYSIS} {...props} />
)
const userView = (props: any) => <Link to={USER} {...props} />
const multiUserView = (props: any) => <Link to={ADD_MULTI_USER} {...props} />
const permissionView = (props: any) => <Link to={PERMISSION} {...props} />
const roleView = (props: any) => <Link to={ROLE} {...props} />
const languageView = (props: any) => <Link to={LANGUAGE} {...props} />
const resoruceTypeView = (props: any) => <Link to={RESOURCETYPE} {...props} />
const resoruceView = (props: any) => <Link to={RESOURCE} {...props} />
const reportsView = (props: any) => <Link to={REPORTS} {...props} />
const bankAccountUserView = (props: any) => (
  <Link to={BANKACCOUNTUSER} {...props} />
)
const companyUserView = (props: any) => <Link to={COMPANYUSER} {...props} />
const userPermissionView = (props: any) => (
  <Link to={USERPERMISSION} {...props} />
)
const rolePermissionView = (props: any) => (
  <Link to={ROLEPERMISSION} {...props} />
)
const notificationView = (props: any) => <Link to={NOTIFICATION} {...props} />
const invoiceView = (props: any) => <Link to={INVOICE} {...props} />
const partnerView = (props: any) => <Link to={PARTNER} {...props} />

class Sidebar extends Component {
  public readonly state: ISidebarState
  public readonly props: ISidebarProps

  constructor(props: any) {
    super(props)

    this.props = props

    this.state = {
      open: true,
      submenuCompany: false,
      submenuUser: false,
      submenuBank: false,
      submenuDefinition: false,
      submenuReporting: false,
      sidebarDown: false,
      confirmStatus: false,
      permissionList: [],
      isPosStatement: false,
      isAccounting: false,
      isReporting: false,
      isJustStatement: false,
      isExcelAndPdfExport: false,
      userId: 0,
      addMenu: false,
      resources: [],
      loading: true,
    }

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleSubmenuCompanyClick = this.handleSubmenuCompanyClick.bind(this)
    this.handleSubmenuUserClick = this.handleSubmenuUserClick.bind(this)
    this.handleSubmenuBankClick = this.handleSubmenuBankClick.bind(this)
    this.handleSubmenuDefinitionClick =
      this.handleSubmenuDefinitionClick.bind(this)
    this.handleSubmenuReportingClick =
      this.handleSubmenuReportingClick.bind(this)

    this.onConfirmApprove = this.onConfirmApprove.bind(this)
    this.onConfirmClose = this.onConfirmClose.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  public readonly LANGUAGE_NAME = 'LANGUAGE_TOKEN'

  componentWillMount() {
    this.getPermisisons()
  }

  private async getPermisisons() {
    const { data } = await userService.permission()
    /*console.log(data)*/
    this.setState({
      permissionList: data.unauthorizedPermissions,
      userId: data.userId,
      isPosStatement: data.isPosStatement,
      isAccounting: data.isAccounting,
      isReporting: data.isReporting,
      isJustStatement: data.isJustStatement,
      isExcelAndPdfExport: data.isExcelAndPdfExport,
    })

    await this.getResources()
  }

  private async getResources() {
    const resourceLanguage: any = cookieService.resourceItem(this.LANGUAGE_NAME)
    const { data } = await resourceService.language(resourceLanguage)
    var resources: any = {}
    data.resources.forEach((item: any) => {
      resources[item.resourceKey] = item.value
    })

    this.setState({ resources: resources, loading: false })
  }

  handleDrawerOpen() {
    this.setState({ open: !this.state.open })
  }

  addMenuClose() {
    this.setState({ addMenu: !this.state.addMenu })
  }

  handleClick() {
    this.setState((state) => ({ sidebarDown: !this.state.sidebarDown }))
  }

  handleSubmenuCompanyClick() {
    this.setState({
      submenuCompany: !this.state.submenuCompany,
      submenuUser: false,
      submenuBank: false,
      submenuDefinition: false,
      submenuReporting: false,
    })
  }

  handleSubmenuUserClick() {
    this.setState({
      submenuUser: !this.state.submenuUser,
      submenuCompany: false,
      submenuBank: false,
      submenuDefinition: false,
      submenuReporting: false,
    })
  }

  handleSubmenuBankClick() {
    this.setState({
      submenuBank: !this.state.submenuBank,
      submenuUser: false,
      submenuCompany: false,
      submenuDefinition: false,
      submenuReporting: false,
    })
  }

  handleSubmenuDefinitionClick() {
    this.setState({
      submenuDefinition: !this.state.submenuDefinition,
      submenuUser: false,
      submenuCompany: false,
      submenuBank: false,
      submenuReporting: false,
    })
  }

  handleSubmenuReportingClick() {
    this.setState({
      submenuReporting: !this.state.submenuReporting,
      submenuDefinition: false,
      submenuUser: false,
      submenuCompany: false,
      submenuBank: false,
    })
  }

  private onConfirmClose() {
    this.setState({ confirmStatus: false })
  }

  private onConfirmApprove() {
    this.setState({ confirmStatus: false })
  }

  private handleClose(page?: string) {
    if (page === 'Company' && this.state.open) {
      this.setState({
        submenuCompany: true,
        submenuUser: false,
        submenuBank: false,
        submenuDefinition: false,
        submenuReporting: false,
      })
    } else if (page === 'User' && this.state.open) {
      this.setState({
        submenuCompany: false,
        submenuUser: true,
        submenuBank: false,
        submenuDefinition: false,
        submenuReporting: false,
      })
    } else if (page === 'Bank' && this.state.open) {
      this.setState({
        submenuCompany: false,
        submenuUser: false,
        submenuBank: true,
        submenuDefinition: false,
        submenuReporting: false,
      })
    } else if (page === 'Definition' && this.state.open) {
      this.setState({
        submenuCompany: false,
        submenuUser: false,
        submenuBank: false,
        submenuDefinition: true,
        submenuReporting: false,
      })
    } else if (page === 'Reporting' && this.state.open) {
      this.setState({
        submenuCompany: false,
        submenuUser: false,
        submenuBank: false,
        submenuDefinition: false,
        submenuReporting: true,
      })
    } else {
      this.setState({
        submenuCompany: false,
        submenuUser: false,
        submenuBank: false,
        submenuDefinition: false,
        submenuReporting: false,
      })
    }
  }

  render() {
    const { classes } = this.props
    const {
      permissionList,
      isPosStatement,
      isAccounting,
      isReporting,
      isJustStatement,
      resources,
    } = this.state

    return (
      <div className={classes.root}>
        <Drawer
          id='Sidebar'
          variant='permanent'
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          {
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={() =>
                this.setState({
                  open: !this.state.open,
                  submenuCompany: false,
                  submenuUser: false,
                  submenuBank: false,
                  submenuDefinition: false,
                })
              }
              className={classNames(classes.drawerButton, {
                [classes.drawerButtonOpen]: this.state.open,
                [classes.drawerButtonClose]: !this.state.open,
              })}
            >
              <img
                src='img/navbarLeft.png'
                className={classNames({ [classes.hide]: this.state.open })}
              />
              <img
                src='img/navbarRight.png'
                className={classNames({ [classes.hide]: !this.state.open })}
              />
            </IconButton>
          }

          {this.state.userId !== 21 ? (
            permissionList.indexOf('TopMenu.PaymentOperations') != -1 ? null : (
              <Button
                variant='contained'
                color='primary'
                id='quickMenu'
                onClick={() => this.addMenuClose()}
                className={classNames({
                  [classes.sidebarOpen]: this.state.open,
                  [classes.sidebarClose]: !this.state.open,
                })}
              >
                <span
                  style={{
                    color: '#fff',
                    fontWeight: 'bold',
                    marginRight: 10,
                    fontSize: 12,
                  }}
                >
                  {resources.MenuTopMenu1} +
                </span>
                <ul
                  className={classNames({
                    [classes.hide]: !this.state.addMenu,
                  })}
                >
                  {permissionList.indexOf('Bank.CreateBank') != -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_BANK}>
                        <Icon className='fas fa-university'></Icon>
                        <br />
                        {resources.MenuTopMenu1_1}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf('BankBranch.CreateBankBranch') !=
                  -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_BANKBRANCH}>
                        <Icon className='fas fa-sitemap'></Icon>
                        <br />
                        {resources.MenuTopMenu1_2}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf('BankAccount.CreateBankAccount') !=
                  -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_BANKACCOUNT}>
                        <Icon className='fas fa-wallet'></Icon>
                        <br />
                        {resources.MenuTopMenu1_3}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf('Currency.CreateCurrency') !=
                  -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_CURRENCY}>
                        <Icon className='fas fa-money-bill'></Icon>
                        <br />
                        {resources.MenuTopMenu1_4}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf(
                    'BankCredential.CreateBankCredential'
                  ) != -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_BANKCREDENTIAL}>
                        <Icon className='fas fa-passport'></Icon>
                        <br />
                        {resources.MenuTopMenu1_5}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf(
                    'BankFTPCredential.CreateBankFTPCredential'
                  ) != -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_BANKFTPCREDENTIAL}>
                        <Icon className='fas fa-passport'></Icon>
                        <br />
                        {resources.MenuTopMenu1_15}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf('Company.CreateCompany') !=
                  -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_COMPANY}>
                        <Icon className='fas fa-building'></Icon>
                        <br />
                        {resources.MenuTopMenu1_6}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf('User.CreateUser') != -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_USER}>
                        <Icon className='fas fa-user'></Icon>
                        <br />
                        {resources.MenuTopMenu1_7}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf('Role.CreateRole') != -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_ROLE}>
                        <Icon className='fas fa-user-tag'></Icon>
                        <br />
                        {resources.MenuTopMenu1_8}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf('Permission.CreatePermission') !=
                  -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_PERMISSION}>
                        <Icon className='fas fa-hat-wizard'></Icon>
                        <br />
                        {resources.MenuTopMenu1_9}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf(
                    'RolePermission.CreateRolePermission'
                  ) != -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_ROLEPERMISSION}>
                        <Icon className='fas fa-user-shield'></Icon>
                        <br />
                        {resources.MenuTopMenu1_10}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf('CompanyUser.CreateCompanyUser') !=
                  -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_COMPANYUSER}>
                        <Icon className='fas fa-user-check'></Icon>
                        <br />
                        {resources.MenuTopMenu1_11}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf(
                    'UserPermission.CreateUserPermission'
                  ) != -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_USERPERMISSION}>
                        <Icon className='fas fa-user-lock'></Icon>
                        <br />
                        {resources.MenuTopMenu1_12}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf(
                    'BankAccountUser.CreateBankAccountUser'
                  ) != -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_BANKACCOUNTUSER}>
                        <Icon className='fas fa-users'></Icon>
                        <br />
                        {resources.MenuTopMenu1_13}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf('Notification.CreateNotification') !=
                  -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_NOTIFICATION}>
                        <Icon className='fas fa-bell'></Icon>
                        <br />
                        {resources.MenuTopMenu1_14}
                      </Link>
                    </li>
                  )}
                  {permissionList.indexOf('Partner.CreatePartner') !=
                  -1 ? null : (
                    <li>
                      <Link to={ADD_NEW_PARTNER}>
                        <Icon className='fas fa-hat-wizard'></Icon>
                        <br />
                        {resources.MenuTopMenu1_16}
                      </Link>
                    </li>
                  )}
                </ul>
              </Button>
            )
          ) : null}

          <div className={classes.toolbar} id='header'>
            <img
              src='img/logo.png'
              className={classNames('logo', {
                [classes.hide]: !this.state.open,
              })}
            />
          </div>
          <Divider />
          {this.state.loading ? (
            <CircularProgress className='progress' />
          ) : (
            <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
              <List>
                <ListSubheader component='div' className={classes.subheader}>
                  {resources.MenuLeftmenuHeader1}
                </ListSubheader>

                <ListItem
                  component={cockpitView}
                  onClick={() => this.handleClose()}
                >
                  <ListItemIcon>
                    <Icon className='fas fa-chart-pie'></Icon>
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuItem}
                    inset
                    primary={resources.MenuLeftmenu1_1}
                  />
                </ListItem>

                {isJustStatement ? null : (
                  <>
                    <ListItem
                      component={accountView}
                      onClick={() => this.handleClose()}
                    >
                      <ListItemIcon>
                        <Icon className='fas fa-wallet'></Icon>
                      </ListItemIcon>
                      <ListItemText
                        className={classes.menuItem}
                        inset
                        primary={resources.MenuLeftmenu1_2}
                      />
                    </ListItem>

                    <ListItem
                      component={balanceHistoryView}
                      onClick={() => this.handleClose()}
                    >
                      <ListItemIcon>
                        <Icon className='fas fa-wallet'></Icon>
                      </ListItemIcon>
                      <ListItemText
                        className={classes.menuItem}
                        inset
                        primary={resources.MenuLeftmenu1_2_1}
                      />
                    </ListItem>
                  </>
                )}

                <ListItem
                  component={accountStatementView}
                  onClick={() => this.handleClose()}
                >
                  <ListItemIcon>
                    <Icon className='fas fa-list-alt'></Icon>
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuItem}
                    inset
                    primary={resources.MenuLeftmenu1_3}
                  />
                </ListItem>
                {!isPosStatement ? null : (
                  <>
                    <ListItem
                      component={posStatementView}
                      onClick={() => this.handleClose()}
                    >
                      <ListItemIcon>
                        <Icon className='fas fa-credit-card'></Icon>
                      </ListItemIcon>
                      <ListItemText
                        className={classes.menuItem}
                        inset
                        primary={resources.MenuLeftmenu1_10}
                      />
                    </ListItem>
                  </>
                )}

                {!isAccounting ? null : (
                  <>
                    <ListItem onClick={() => this.handleClose()}>
                      <ListItemIcon>
                        <Icon className='fas fa-paper-plane'></Icon>
                      </ListItemIcon>
                      <ListItemText
                        className={classes.menuItem}
                        inset
                        primary={resources.MenuLeftmenu1_4}
                      />
                    </ListItem>
                  </>
                )}

                {!isReporting ? null : (
                  <>
                    <ListItem button onClick={this.handleSubmenuReportingClick}>
                      <ListItemIcon>
                        <Icon className='fas fa-chart-bar'></Icon>
                      </ListItemIcon>
                      <ListItemText
                        className={classes.menuItem}
                        inset
                        primary={resources.MenuLeftmenu1_5}
                      />
                      {this.state.submenuReporting ? '<' : '>'}
                    </ListItem>
                    <Collapse
                      in={this.state.submenuReporting}
                      timeout='auto'
                      unmountOnExit
                      className={classNames({ sidebarMenu: !this.state.open })}
                    >
                      <List disablePadding>
                        <ListItem
                          component={reportsView}
                          onClick={() => this.handleClose('Reporting')}
                        >
                          <ListItemText
                            inset
                            primary={resources.MenuLeftmenu1_5_1}
                          />
                        </ListItem>
                        <ListItem
                          component={valorDateView}
                          onClick={() => this.handleClose('Reporting')}
                        >
                          <ListItemText
                            inset
                            primary={resources.MenuLeftmenu1_5_2}
                          />
                        </ListItem>
                        <ListItem
                          component={balanceAnalysisView}
                          onClick={() => this.handleClose('Reporting')}
                        >
                          <ListItemText
                            inset
                            primary='Bakiye Analizi'
                            // primary={resources.MenuLeftmenu1_5_3}
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </>
                )}
              </List>

              {permissionList.indexOf('Administration.Label') != -1 ? null : (
                <>
                  <Divider />
                  <List>
                    <ListSubheader
                      component='div'
                      className={classes.subheader}
                    >
                      {resources.MenuLeftmenuHeader2}
                    </ListSubheader>
                    {permissionList.indexOf(
                      'Administration.CompanyOperations'
                    ) != -1 ? null : (
                      <>
                        <ListItem
                          button
                          onClick={this.handleSubmenuCompanyClick}
                        >
                          <ListItemIcon>
                            <Icon className='fas fa-building'></Icon>
                          </ListItemIcon>
                          <ListItemText
                            className={classes.menuItem}
                            inset
                            primary={resources.MenuLeftmenu1_6}
                          />
                          {this.state.submenuCompany ? '<' : '>'}
                        </ListItem>
                        <Collapse
                          in={this.state.submenuCompany}
                          timeout='auto'
                          unmountOnExit
                          className={classNames({
                            sidebarMenu: !this.state.open,
                          })}
                        >
                          <ListSubheader
                            component='div'
                            className={classNames({
                              [classes.hide]: this.state.open,
                              sidebarHeader: !this.state.open,
                            })}
                          >
                            Firma İşlemleri
                          </ListSubheader>
                          <List disablePadding>
                            {permissionList.indexOf(
                              'Company.GetCompanies.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={companyView}
                                onClick={() => this.handleClose('Company')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_6_1}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'CompanyUser.GetCompanyUsers.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={companyUserView}
                                onClick={() => this.handleClose('Company')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_6_2}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'Partner.GetPartners.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={partnerView}
                                onClick={() => this.handleClose('Company')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_6_7}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'Invoice.GetInvoices.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={invoiceView}
                                onClick={() => this.handleClose('Company')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_6_3}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'Country.GetCountries.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={countryView}
                                onClick={() => this.handleClose('Company')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_6_4}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf('City.GetCities.Menu') !=
                            -1 ? null : (
                              <ListItem
                                component={cityView}
                                onClick={() => this.handleClose('Company')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_6_5}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'District.GetDistricts.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={districtView}
                                onClick={() => this.handleClose('Company')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_6_6}
                                />
                              </ListItem>
                            )}
                          </List>
                        </Collapse>
                      </>
                    )}

                    {permissionList.indexOf('Administration.UserOperations') !=
                    -1 ? null : (
                      <>
                        <ListItem button onClick={this.handleSubmenuUserClick}>
                          <ListItemIcon>
                            <Icon className='fas fa-users'></Icon>
                          </ListItemIcon>
                          <ListItemText
                            className={classes.menuItem}
                            inset
                            primary={resources.MenuLeftmenu1_7}
                          />
                          {this.state.submenuUser ? '<' : '>'}
                        </ListItem>
                        <Collapse
                          in={this.state.submenuUser}
                          timeout='auto'
                          unmountOnExit
                          className={classNames({
                            sidebarMenu: !this.state.open,
                          })}
                        >
                          <ListSubheader
                            component='div'
                            className={classNames({
                              [classes.hide]: this.state.open,
                              sidebarHeader: !this.state.open,
                            })}
                          >
                            Kullanıcı İşlemleri
                          </ListSubheader>
                          <List disablePadding>
                            {permissionList.indexOf('User.GetUsers.Menu') !=
                            -1 ? null : (
                              <ListItem
                                component={userView}
                                onClick={() => this.handleClose('User')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_7_1}
                                />
                              </ListItem>
                            )}

                            {/*permissionList.indexOf('UserPermission.GetUserPermissions.Menu') != -1 ? null :
                            <ListItem component={userPermissionView} onClick={() => this.handleClose("User")}>
                              <ListItemText inset primary={resources.MenuLeftmenu1_7_4} />
                            </ListItem>
                            kaan yorum
                        */}

                            {/*{permissionList.indexOf('User.CreateMultipleUser.Menu') != -1 ? null :
                            <ListItem component={multiUserView} onClick={() => this.handleClose("User")}>
                              <ListItemText inset primary={resources.MenuLeftmenu1_7_6} />
                            </ListItem>
                          }*/}
                            {permissionList.indexOf('Role.GetRoles.Menu') !=
                            -1 ? null : (
                              <ListItem
                                component={roleView}
                                onClick={() => this.handleClose('User')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_7_2}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'RolePermission.GetRolePermissions.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={rolePermissionView}
                                onClick={() => this.handleClose('User')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_7_3}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'Permission.GetPermissions.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={permissionView}
                                onClick={() => this.handleClose('User')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_7_5}
                                />
                              </ListItem>
                            )}
                          </List>
                        </Collapse>
                      </>
                    )}

                    {permissionList.indexOf('Administration.BankOperations') !=
                    -1 ? null : (
                      <>
                        <ListItem button onClick={this.handleSubmenuBankClick}>
                          <ListItemIcon>
                            <Icon className='fas fa-university'></Icon>
                          </ListItemIcon>
                          <ListItemText
                            className={classes.menuItem}
                            inset
                            primary={resources.MenuLeftmenu1_8}
                          />
                          {this.state.submenuBank ? '<' : '>'}
                        </ListItem>
                        <Collapse
                          in={this.state.submenuBank}
                          timeout='auto'
                          unmountOnExit
                          className={classNames({
                            sidebarMenu: !this.state.open,
                          })}
                        >
                          <ListSubheader
                            component='div'
                            className={classNames({
                              [classes.hide]: this.state.open,
                              sidebarHeader: !this.state.open,
                            })}
                          >
                            Banka İşlemleri
                          </ListSubheader>
                          <List disablePadding>
                            {permissionList.indexOf('Bank.GetBanks.Menu') !=
                            -1 ? null : (
                              <ListItem
                                component={bankView}
                                onClick={() => this.handleClose('Bank')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_8_1}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'BankBranch.GetBankBranches.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={bankBranchView}
                                onClick={() => this.handleClose('Bank')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_8_2}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'BankAccount.GetBankAccounts.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={bankAccountView}
                                onClick={() => this.handleClose('Bank')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_8_3}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'BankAccountUser.GetBankAccountUsers.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={bankAccountUserView}
                                onClick={() => this.handleClose('Bank')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_8_6}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'BankCredential.GetBankCredentials.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={bankCredentialView}
                                onClick={() => this.handleClose('Bank')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_8_5}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'BankFTPCredential.GetBankFTPCredentials.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={bankFTPCredentialView}
                                onClick={() => this.handleClose('Bank')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_8_8}
                                />
                              </ListItem>
                            )}
                            {/*
                          {permissionList.indexOf('Notification.GetNotifications.Menu') != -1 ? null :
                            <ListItem component={notificationView} onClick={() => this.handleClose("Bank")}>
                              <ListItemText inset primary={resources.MenuLeftmenu1_8_7} />
                            </ListItem>
                          }*/}
                            {permissionList.indexOf(
                              'Currency.GetCurrencies.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={currencyView}
                                onClick={() => this.handleClose('Bank')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_8_4}
                                />
                              </ListItem>
                            )}
                          </List>
                        </Collapse>
                      </>
                    )}

                    {permissionList.indexOf(
                      'Administration.DefinitionOperations'
                    ) != -1 ? null : (
                      <>
                        <ListItem
                          button
                          onClick={this.handleSubmenuDefinitionClick}
                        >
                          <ListItemIcon>
                            <Icon className='fas fa-cogs'></Icon>
                          </ListItemIcon>
                          <ListItemText
                            className={classes.menuItem}
                            inset
                            primary={resources.MenuLeftmenu1_9}
                          />
                          {this.state.submenuDefinition ? '<' : '>'}
                        </ListItem>
                        <Collapse
                          in={this.state.submenuDefinition}
                          timeout='auto'
                          unmountOnExit
                          className={classNames({
                            sidebarMenu: !this.state.open,
                          })}
                        >
                          <ListSubheader
                            component='div'
                            className={classNames({
                              [classes.hide]: this.state.open,
                              sidebarHeader: !this.state.open,
                            })}
                          >
                            Tanım İşlemleri
                          </ListSubheader>
                          <List disablePadding>
                            {permissionList.indexOf(
                              'Language.GetLanguages.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={languageView}
                                onClick={() => this.handleClose('Definition')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_9_1}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'ResourceType.GetResourceTypes.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={resoruceTypeView}
                                onClick={() => this.handleClose('Definition')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_9_2}
                                />
                              </ListItem>
                            )}
                            {permissionList.indexOf(
                              'Resource.GetResources.Menu'
                            ) != -1 ? null : (
                              <ListItem
                                component={resoruceView}
                                onClick={() => this.handleClose('Definition')}
                              >
                                <ListItemText
                                  inset
                                  primary={resources.MenuLeftmenu1_9_3}
                                />
                              </ListItem>
                            )}
                          </List>
                        </Collapse>
                      </>
                    )}
                  </List>
                </>
              )}
            </div>
          )}
        </Drawer>
        <div className={classes.appBarSpacer} />
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Sidebar)
