import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import Wrapper from '../layout/Wrapper'
import moment from 'moment'
import 'moment/locale/tr'

import { Snackbar } from '@material/react-snackbar'
import '@material/react-snackbar/dist/snackbar.css'

import { COMPANY } from '../../routes/paths'
import CustomSelect from '../layout/CustomSelect'

import {
  employeeValidator,
  hhsValidator,
  consentDateValidator,
} from '../../utils/company.validators'
import { Divider, FormControlLabel, Tooltip } from '@material-ui/core'

moment.locale('tr')

const dummyAccountData = {
  HHS1: {
    accountList: [
      {
        account: 'Vadesiz TL Hesap 1',
        iban: 'TR08 0006 0000 0000 0000 0000 11',
      },
      {
        account: 'Vadesiz TL Hesap 2',
        iban: 'TR08 0006 0000 0000 0000 0000 12',
      },
    ],
    consentStartDate: '2023-01-21',
    consentEndDate: '2023-06-11',
    status: 'Aktif',
  },
  HHS2: {
    accountList: [
      {
        account: 'Vadesiz TL Hesap 2',
        iban: 'TR08 0006 0000 0000 0000 0000 21',
      },
      {
        account: 'Vadesiz TL Hesap 3',
        iban: 'TR08 0006 0000 0000 0000 0000 22',
      },
    ],
    consentStartDate: '2023-02-20',
    consentEndDate: '2023-08-10',
    status: 'Aktif',
  },
  HHS3: {
    accountList: [
      {
        account: 'Vadesiz TL Hesap 1',
        iban: 'TR08 0006 0000 0000 0000 0000 41',
      },
      {
        account: 'Vadesiz TL Hesap 3',
        iban: 'TR08 0006 0000 0000 0000 0000 52',
      },
    ],
    consentStartDate: '2023-04-10',
    consentEndDate: '2023-08-21',
    status: 'Aktif',
  },
}

const sharedKnowledges = [
  { name: 'Temel Hesap Bilgisi', info: 'Açıklama metni buraya yazılacak...' },
  { name: 'Detaylı Hesap Bilgisi', info: 'Açıklama metni buraya yazılacak...' },
  {
    name: 'Temel Hesap Hareketleri',
    info: 'Açıklama metni buraya yazılacak...',
  },
  {
    name: 'Detaylı Hesap Hareketleri',
    info: 'Açıklama metni buraya yazılacak...',
  },
  { name: 'Hesap Bakiyesi', info: 'Açıklama metni buraya yazılacak...' },
]

// maksimum date olarak 6 ay sonrasından iki gün öncesini seçiyoruz.
const d = new Date()
d.setMonth(d.getMonth() + 6)
d.setDate(d.getDate() - 2)
const maxDate = d.toLocaleDateString('en-CA')

type HHSNames = 'HHS1' | 'HHS2' | 'HHS3'

export interface ICompanyFormState {
  loading: boolean
  isSuccess: string
  errorCode: string
  errorMessage: string
  process: 'initial' | 'informing' | 'cancel' | 'canceled'
  hhsList: any
  hhsName: 'HHS1' | 'HHS2' | 'HHS3' | ''
  hhsError: string
}

export interface ICompanyFormProps {
  classes: any
}

export default class CompanyForm extends Component {
  public readonly state: ICompanyFormState
  public readonly props: any

  constructor(props: any) {
    super(props)

    this.props = props

    this.state = {
      loading: false,
      isSuccess: '',
      errorCode: '',
      errorMessage: '',
      process: 'initial',
      hhsList: [
        {
          name: 'HHS1',
        },
        {
          name: 'HHS2',
        },
        {
          name: 'HHS3',
        },
      ],
      hhsName: '',
      hhsError: '',
    }

    this.cancel = this.cancel.bind(this)
    this.grantPermission = this.grantPermission.bind(this)
    this.onChangeHHS = this.onChangeHHS.bind(this)
  }

  componentWillMount() {
    this.props.match.params.companyId
  }

  /** VIEW RENDER */
  async cancel() {
    this.props.history.push(COMPANY)
  }

  formValidation() {
    const { hhsName } = this.state

    if (hhsValidator(hhsName) !== '') {
      this.setState({
        hhsError: hhsValidator(hhsName),
      })
      return false
    }

    return true
  }

  async grantPermission() {
    // this.setState({ isSuccess: '', errorCode: '', errorMessage: '' })

    // Burada backend'e istek atılıp, success true gelirse popup açtırılacak.

    if (this.formValidation()) {
      console.log('granted permission')
      this.setState({ process: 'granted' })
    }
  }

  renderMessage() {
    return (
      <Snackbar
        message={
          this.state.errorMessage +
          ' (Error Code : ' +
          this.state.errorCode +
          ')'
        }
        timeoutMs={5000}
        className='messageBoxError'
      />
    )
  }

  // Burada iptal edilecek izin isteği backend'e gönderilecek.
  cancelPermission() {
    this.setState({
      process: 'cancelled',
    })
  }

  onChangeHHS(val: any) {
    if (val) {
      this.setState({
        hhsName: val.name,
        hhsError: employeeValidator(val.name),
      })
    }
  }

  renderHHS() {
    const { hhsList, hhsName, hhsError } = this.state
    return (
      <FormControl
        margin='none'
        variant='outlined'
        className='input inputSelect'
      >
        <CustomSelect
          labelName='name'
          valueKey='name'
          // value={this.state.hhsName}
          options={hhsList}
          onChange={this.onChangeHHS}
          placeholder={'HHS Seç'}
        ></CustomSelect>
        <span className='errorMessage'>{hhsError}</span>
      </FormControl>
    )
  }

  renderSharedKnowledge() {
    return (
      <div style={{ display: 'grid', gap: '14px' }}>
        {sharedKnowledges.map(({ name, info }) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <div>
                <Checkbox checked disabled value={name} />
                <span style={{ fontSize: 13.5 }}>{name}</span>
              </div>
              <Tooltip
                title={<p style={{ color: '#fff' }}>{info}</p>}
                placement='top'
              >
                <Icon className='fas fa-info-circle'></Icon>
              </Tooltip>
            </div>
          )
        })}
      </div>
    )
  }

  renderInitial() {
    const { hhsName } = this.state

    const renderContent = () => {
      if (hhsName) {
        const { accountList, consentStartDate, consentEndDate, status } =
          dummyAccountData[hhsName]

        return (
          <>
            <p style={{ marginBottom: '24px' }}>
              Sectiğiniz HHS'de rıza verilen hesaplar aşağıda listelenmiştir:
            </p>
            <Divider />
            <div style={{ marginTop: '24px' }}>
              <p style={{ fontWeight: 500, fontSize: '16px' }}>
                {hhsName} A.Ş.
              </p>
              {accountList.map(({ account, iban }, i) => (
                <div key={i}>
                  <p style={{ fontWeight: 500, fontSize: '14px' }}>
                    Hesap: {account}
                  </p>
                  <p style={{ fontWeight: 500, fontSize: '14px' }}>{iban}</p>
                </div>
                // Bu izin başlangıç ve bitiş tarihleri seçilen HHS'ye göre değişecek.
              ))}
              <div style={{ fontWeight: 500, fontSize: '14.5px' }}>
                İzin tarihi: {moment(consentStartDate).format('DD/MM/YYYY')}
              </div>
              <div style={{ fontWeight: 500, fontSize: '14.5px' }}>
                İzin bitiş tarihi: {moment(consentEndDate).format('DD/MM/YYYY')}
              </div>
              <div style={{ fontWeight: 500, fontSize: '14.5px' }}>
                Durum: {status}
              </div>
            </div>
          </>
        )
      } else {
        return null
      }
    }

    return (
      <>
        <Typography variant='h2'>HBHS 1</Typography>
        <p>
          Hesap bilgisi erişim izni verdiğiniz hesapların HHS'sini listeden
          seçebilirsiniz:
        </p>
        {this.renderHHS()}
        {renderContent()}
        {/* Buttons */}
        <Grid item xs={12}>
          <Button
            type='submit'
            id='createButton'
            onClick={() => {
              if (this.formValidation()) {
                this.setState({ process: 'informing' })
              }
            }}
          >
            İZİN YÖNET
          </Button>
          <Button onClick={this.cancel}>VAZGEÇ</Button>
        </Grid>
      </>
    )
  }

  renderInforming() {
    const hhsName = this.state.hhsName as HHSNames

    const { accountList } = dummyAccountData[hhsName]

    return (
      <div>
        <Typography variant='h2'>HBHS 1</Typography>
        <p style={{ fontSize: '15px' }}>
          Sizlere hesap bilgisi hizmeti sunabilmemiz için seçtiğiniz HHS'deki
          hesaplara ait aşağıdaki bilgilere erişmemiz gerektiğini hatırlatmak
          isteriz.
        </p>
        <div style={{ margin: '24px 0 24px' }}>
          <p style={{ fontWeight: 500, fontSize: '16px' }}>{hhsName} A.Ş.</p>
          {accountList.map(({ account, iban }, i) => (
            <div key={i}>
              <p style={{ fontWeight: 500, fontSize: '14px' }}>
                Hesap: {account}
              </p>
              <p style={{ fontWeight: 500, fontSize: '14px' }}>{iban}</p>
            </div>
          ))}
        </div>
        <Divider />
        <div style={{ margin: '24px 0 20px' }}>
          <p
            style={{ fontWeight: 500, marginBottom: '16px', fontSize: '14px' }}
          >
            Seçtiğiniz HHS'ye ait bilgi erişimi iptal edeceğimiz izinler:
          </p>
          {this.renderSharedKnowledge()}
        </div>
        <Divider />
        <p style={{ marginTop: '24px', fontSize: '14.5px', fontWeight: 500 }}>
          Hesap Bilgisi Erişim izninizi iptal etmek istediğinize emin misiniz?
        </p>
        <p style={{ fontSize: '14.5px', fontWeight: 500 }}></p>
        <Grid item xs={12}>
          <Button
            type='submit'
            id='createButton'
            onClick={() =>
              this.setState({
                process: 'cancel',
              })
            }
          >
            İPTAL ET
          </Button>
          <Button
            onClick={() =>
              this.setState({
                process: 'initial',
                hhsName: '',
              })
            }
          >
            VAZGEÇ
          </Button>
        </Grid>
      </div>
    )
  }

  renderCancel() {
    const hhsName = this.state.hhsName as HHSNames

    const { accountList } = dummyAccountData[hhsName]

    return (
      <div>
        <Typography variant='h2'>HBHS 1</Typography>
        <p style={{ fontSize: '15px', marginBottom: '24px' }}>
          İzniniz iptal edilecektir!
        </p>
        <Divider />
        <div style={{ margin: '24px 0 24px' }}>
          <p style={{ fontWeight: 500, fontSize: '16px' }}>{hhsName} A.Ş.</p>
          {accountList.map(({ account, iban }, i) => (
            <div key={i}>
              <p style={{ fontWeight: 500, fontSize: '14px' }}>
                Hesap: {account}
              </p>
              <p style={{ fontWeight: 500, fontSize: '14px' }}>{iban}</p>
            </div>
          ))}
        </div>
        <Divider />
        <p style={{ marginTop: '24px', fontSize: '14.5px', fontWeight: 500 }}>
          Hesap Bilgisi Erişim izninizi iptal etmek istediğinize emin misiniz?
        </p>
        <p style={{ fontSize: '14.5px', fontWeight: 500 }}></p>
        <Grid item xs={12}>
          <Button
            type='submit'
            id='createButton'
            onClick={() => this.cancelPermission()}
          >
            İPTAL ET
          </Button>
          <Button
            onClick={() =>
              this.setState({
                process: 'informing',
              })
            }
          >
            GERİ DÖN
          </Button>
        </Grid>
      </div>
    )
  }

  renderCanceled() {
    const hhsName = this.state.hhsName as HHSNames

    const { accountList } = dummyAccountData[hhsName]

    return (
      <div>
        <Typography variant='h2'>HBHS 1</Typography>
        <p style={{ fontSize: '15px', marginBottom: '16px' }}>
          İzniniz iptal edildi!
        </p>
        <p style={{ fontSize: '15px', marginBottom: '24px' }}>
          Daha önce izin verdiğiniz aşağıdaki hesap bilgilerine erişim izniniz
          iptal edildi.
        </p>
        <Divider />
        <div style={{ margin: '24px 0 24px' }}>
          <p style={{ fontWeight: 500, fontSize: '16px' }}>{hhsName} A.Ş.</p>
          {accountList.map(({ account, iban }, i) => (
            <div key={i}>
              <p style={{ fontWeight: 500, fontSize: '14px' }}>
                Hesap: {account}
              </p>
              <p style={{ fontWeight: 500, fontSize: '14px' }}>{iban}</p>
            </div>
          ))}
        </div>
        <Divider />
        <p style={{ marginTop: '24px', fontSize: '14.5px', fontWeight: 500 }}>
          Diğer hesaplarla ilgili erişim izinlerinizi yönetmek için tıklayınız.
        </p>
        <p style={{ fontSize: '14.5px', fontWeight: 500 }}></p>
        <Grid item xs={12}>
          <Button onClick={() => this.cancel()}>HESAP BİLGİLERİM</Button>
        </Grid>
      </div>
    )
  }

  render() {
    const { loading, isSuccess, process } = this.state
    if (isSuccess === 'error') this.renderMessage()

    return (
      <Wrapper>
        <Grid container id='Form'>
          <Grid item xs={12} md={3} className='sidebar'>
            <Icon className='fas fa-edit'></Icon>
            {/* <Icon className='fas fa-plus'></Icon> */}
            <Typography variant='h3'>HBHS Üzerinden Rıza İptal</Typography>
            <p>
              Bu ekrandan daha önce bize temin etmiş olduğunuz hesap bilgilerin
              iptalini gerçekleştirebilirsiniz.
            </p>
          </Grid>
          <Grid item xs={12} md={9} className='form'>
            {loading ? (
              <CircularProgress className='progress' />
            ) : (
              <form onSubmit={(e) => e.preventDefault()}>
                {process === 'initial'
                  ? this.renderInitial()
                  : process === 'informing'
                  ? this.renderInforming()
                  : process === 'cancel'
                  ? this.renderCancel()
                  : this.renderCanceled()}
              </form>
            )}
          </Grid>
        </Grid>
      </Wrapper>
    )
  }
}
