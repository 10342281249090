import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import { Redirect } from 'react-router'
import classNames from 'classnames'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'

import { userService, cookieService, languageService, resourceService } from '../service'
import ConfirmDialog from './layout/Confirm';

import '../styles/style.css'
import { DOCUMENTATION, PROFILE, PASSWORD_RESET, PAYMENT, LOGIN } from '../routes/paths'

export interface INavbarState {
    open: boolean;
    sidebarDown: boolean;
    confirmStatus: boolean;
    permissionList: any;
    nameSurname: string;
    confirmMessage: string;
    confirmTitle: string;
    confirmIcon: string;
    confirmType: string;
    userId: number;
    logout: boolean;
    languages: any;
    resources: any;
}

const drawerWidth = 240;

const styles: any = (theme: any) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: '100%',
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    //width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarDrawerClose: {
    marginLeft: 73,
    //width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    'border-right': 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 20px',
    ...theme.mixins.toolbar,
  },
  navbar: {
    justifyContent: 'flex-end',
  },
});


class Navbar extends Component {
  public readonly state: INavbarState;
  public readonly props: any;

  public readonly LANGUAGE_NAME = 'LANGUAGE_TOKEN';

  constructor(props: any) {
    super(props);

    this.props = props;

    this.state = {
      open: true,
      sidebarDown: false,
      confirmStatus: false,
      permissionList: [],
      nameSurname: "",
      confirmMessage: "Firmayi silmek istediginize emin misiniz?",
      confirmTitle: "Firma Sil",
      confirmIcon: "far fa-trash-alt",
      confirmType: "yes/no",
      userId: 0,
      logout: false,
      languages: [],
      resources: []
    };


    this.logout = this.logout.bind(this);

    this.onConfirmApprove = this.onConfirmApprove.bind(this);
    this.onConfirmClose = this.onConfirmClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDemo = this.handleDemo.bind(this);
    this.permissionNot = this.permissionNot.bind(this);

  }

  componentWillMount() {
    this.getPermisisons();
  }

  private async getPermisisons() {
    const { data } = await userService.permission();
    this.setState({ permissionList: data.unauthorizedPermissions, nameSurname: data.nameSurname, userId: data.userId });

    this.getResources();
    this.getLanguages();
  }

  private async getLanguages() {
    const { data } = await languageService.get("", 0, 10, 0, true);
    this.setState({ languages: data.languages });
  }

  private async getResources() {
    const resourceLanguage: any = cookieService.resourceItem(this.LANGUAGE_NAME);
    const { data } = await resourceService.language(resourceLanguage);
    var resources: any = {};
    data.resources.forEach((item: any) => {
      resources[item.resourceKey] = item.value;
    });

    this.setState({ resources: resources, loading: false });
  }


  private async logout() {
    const response = await userService.logout();
    this.setState({ logout: true });
  }


  handleDrawerOpen() {
    this.setState({ open: !this.state.open });
  };

  private handleDemo() {
    this.setState({ confirmStatus: true });
  };

  private onConfirmClose() {
    this.setState({ confirmStatus: false });
  };

  private onConfirmApprove() {
    this.setState({ confirmStatus: false });
  };


  private permissionNot() {
    this.setState({ confirmMessage: "Bu işlem için erişim yetkiniz bulunmuyor!", confirmTitle: "Erişim Uyarısı", confirmIcon: "fas fa-lock", confirmType: "ok", confirmStatus: true });
  }

  private onChangeLanguage(languageCode: string) {
    cookieService.setItem(this.LANGUAGE_NAME, languageCode);
    window.location.reload();
  }



  private redirectToHome() {
    if (this.state.logout) return <Redirect to={LOGIN} />
  }

  render() {
    const { classes } = this.props;
    const { confirmStatus, permissionList, userId, resources } = this.state;

    return (

      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: this.state.open,
          [classes.appBarDrawerClose]: !this.state.open,
        })}
      >
        {this.redirectToHome()}
        <Toolbar id="Appbar" disableGutters={!this.state.open}>
          <IconButton
            onClick={this.handleDrawerOpen}
            className={classNames(classes.menuButton, {
              [classes.hide]: this.state.open,
            })}
          >
            <img src="img/navbarRight.png" />
          </IconButton>
          <ConfirmDialog open={confirmStatus} icon={this.state.confirmIcon} confirm={this.state.confirmType} text={this.state.confirmMessage} header={this.state.confirmTitle} onClose={this.onConfirmClose} onApprove={this.onConfirmApprove} />

          <Grid container className={classes.navbar}>

            <IconButton color="secondary" id="Notification">
              <Badge badgeContent={0} showZero={false}>
                <Icon className="far fa-bell"></Icon>
              </Badge>
              <div className="notifications">
                <Icon className="far fa-bell-slash"></Icon>
                Bildirim Yok!
                        </div>
            </IconButton>

            <div className="Language">
              <Badge badgeContent={0} showZero={false}>
                <img src={`img/${cookieService.resourceItem(this.LANGUAGE_NAME)}.png`} />
              </Badge>
              <ul className="menu">
                {this.state.languages.map((item: any) => (
                  <li key={item.id} onClick={() => this.onChangeLanguage(item.languageCode)}><img src={`img/${item.languageCode}.png`} /> {item.languageName}</li>
                ))}
              </ul>
            </div>
            <div className="Profile">
              <span>{this.state.nameSurname}  <Avatar alt="Kaan Verdioğlu" src="img/avatar_user.png" className="Avatar" /></span>
              <ul className="profileMenu">
                {userId === 21 ? null :
                  <>
                    <h6 > {resources.MenuTopMenu41}</h6>
                    <li><NavLink to={PROFILE}><Icon className="fas fa-user"></Icon> {resources.MenuTopMenu42}</NavLink></li>
                    <li><NavLink to={PASSWORD_RESET}><Icon className="fas fa-lock"></Icon> {resources.MenuTopMenu43}</NavLink></li>
                    {/* <li><NavLink to={PAYMENT}><Icon className="fas fa-credit-card"></Icon> {resources.MenuTopMenu44}</NavLink></li>
                    <li><NavLink to={DOCUMENTATION}><Icon className="fas fa-folder"></Icon> {resources.MenuTopMenu46}</NavLink></li>*/}
                  </>}

                <li className="logout" onClick={this.logout}>{resources.MenuTopMenu45}</li>
              </ul>
            </div>

          </Grid>
        </Toolbar>

      </AppBar >
    )
  }
}

export default withStyles(styles, { withTheme: true })(Navbar);