import XHRService from "./xhr.service";

export default interface ILanguageRequest {
    id?: number;
    languageName: string;
    languageCode: string;
    searchText: string;
    isActive: boolean;
}


interface ILanguage {
    id: number;
    languageName: string;
    languageCode: string;
    searchText: string;
    isActive: boolean;
}

interface ILanguageResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: ILanguage
}

interface ILanguageListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        languages: ILanguage[]
    }
}

export default class LanguageService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<ILanguageResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Language/CreateLanguage", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: ILanguageRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Language/UpdateLanguage", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<ILanguageResponse> {
        const { isSuccess }: any = await this.XHRService.post("/Language/DeleteLanguage", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/Language/GetLanguages", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/Language/GetLanguagesExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/Language/GetLanguageById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
