import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { permissionService } from '../../service';
import { PERMISSION } from '../../routes/paths';

import Error from '../NotFound/NotFound'


import { permissionDescriptionValidator, permissionKeyValidator } from '../../utils/permission.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IPermissiponFormState {
    permissionKey: string;
    permissionKeyError: string;
    permissionDescription: string;
    permissionDescriptionError: string;
    isActive: boolean;
    isSuccess: string,
    isEdit: boolean;
    loading: boolean;
    errorCode: string;
    errorMessage: string;
}

export interface IPermissionFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class PermissionForm extends Component {
    public readonly state: IPermissiponFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            permissionKey: "",
            permissionKeyError: "",
            permissionDescription: "",
            permissionDescriptionError: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangePermissionKey = this.onChangePermissionKey.bind(this);
        this.onChangePermissionDescription = this.onChangePermissionDescription.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }


    componentWillMount() {
        if (this.props.match.params.permissionId) this.getEditablePermission();
        else this.setState({ loading: false });

        this.form = React.createRef();
    }

    private async getEditablePermission() {
        const { data, isSuccess, errorCode, errorMessage } = await permissionService.getById(this.props.match.params.permissionId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            const state = {
                ...data,
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });
        }
    }

    /** EVENT LISTENERS */

    private onChangePermissionKey(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ permissionKey: e.target.value, permissionKeyError: permissionKeyValidator(e.currentTarget.value) });
    }

    private onChangePermissionDescription(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ permissionDescription: e.target.value, permissionDescriptionError: permissionDescriptionValidator(e.currentTarget.value) });
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(PERMISSION);
    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const { permissionDescription, permissionKey } = this.state;

        //Submit Validation
        if (
            permissionDescriptionValidator(permissionDescription) !== "" ||
            permissionKeyValidator(permissionKey) !== ""

        ) {

            this.setState({
                permissionDescriptionError: permissionDescriptionValidator(permissionDescription),
                permissionKeyError: permissionKeyValidator(permissionKey)
            });
            return false;
        }

        return true;
    }


    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { permissionKey, permissionDescription, isActive, isEdit } = this.state;

        const model = {
            permissionKey: sqlInjectValidator(permissionKey),
            permissionDescription: sqlInjectValidator(permissionDescription),
            isActive
        };

        if (this.formValidation()) {
            if (isEdit) await this.editPermission(model);
            else await this.createPermission(model);
        }
    }

    private async createPermission(model: any) {
        const data = await permissionService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editPermission(model: any) {
        const data = await permissionService.update({ ...model, id: this.props.match.params.permissionId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private renderPermissionKey() {
        const { permissionKey, permissionKeyError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="permissionKey"
                    label="İzin Anahtarı"
                    placeholder="İzin anahtarı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangePermissionKey}
                    value={permissionKey}
                    name="permissionKey"
                    autoComplete="permissionKey"
                    helperText={permissionKeyError}
                />
            </FormControl>
        );
    }

    private renderPermissionDescription() {
        const { permissionDescription, permissionDescriptionError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="permissionDescription"
                    label="İzin Açıklaması"
                    placeholder="İzin açıklaması yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangePermissionDescription}
                    value={permissionDescription}
                    name="permissionDescription"
                    autoComplete="permissionDescription"
                    helperText={permissionDescriptionError}
                />
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage, isEdit } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: PERMISSION, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">İzin {isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {isEdit ? "Bu ekrandan izin bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda izin ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">İzin {isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderPermissionKey()}
                                    {this.renderPermissionDescription()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(PermissionForm);
