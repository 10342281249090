
//BankAccount

export const bankAccountNameValidator = (bankAccountName: string): string => {
    if (bankAccountName === "") return 'Hesap adı alanını boş geçemezsiniz!'
    return '';
}

export const ibanValidator = (iban: string): string => {
    if (iban === "") return 'Iban alanını boş geçemezsiniz!'
    return '';
}

export const bankAccountCodeValidator = (bankAccountCode: string): string => {
    if (bankAccountCode === "") return 'Hesap kodu alanını boş geçemezsiniz!'
    return '';
}

export const bankBranchCodeValidator = (bankBranchCode: string): string => {
    if (bankBranchCode === "") return 'Şube Kodu alanını boş geçemezsiniz!'
    return '';
}

export const companyValidator = (companyId: number): string => {
    if (companyId === 0) return 'Firma seçiniz!'
    return '';
}

export const bankValidator = (bankId: number): string => {
    if (bankId === 0) return 'Banka seçiniz!'
    return '';
}

export const currencyValidator = (currencyId: number): string => {
    if (currencyId === 0) return 'Para Birimi seçiniz!'
    return '';
}

