import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router'
import classNames from 'classnames'

import Paper from '@material-ui/core/Paper/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableFooter from "@material-ui/core/TableFooter"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Tabs from '@material-ui/core/Tabs/Tabs'
import Tab from '@material-ui/core/Tab/Tab'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import Checkbox from '@material-ui/core/Checkbox'
import Icon from '@material-ui/core/Icon'

import { withStyles } from '@material-ui/core/styles'

import { PAYMENT } from '../../routes/paths';

import Wrapper from '../layout/Wrapper';


export interface IPaymentFormState {

    //Form
    nameSurname: string,
    cardNumber: string,
    experationDate: string,
    cvc: string,
    automaticPay: boolean,
    discountCode: string,

    isSuccess: boolean,

    //Product
    product: string,
    yearPrice: number,
    monthPrice: number,
    discount: number,

    //Package
    productId: number,
    payType: boolean,
    planId: number,
    tabValue: number,
    packages: any,
}

export interface IPaymentFormProps {
    classes: any,
    packageName: string,
    price: number
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    },
    card: {
        marginRight: "10px",
        width: "100%",
        backgroundColor: "#fff",
    }
});

class PaymentForm extends Component {
    public readonly state: IPaymentFormState;
    public readonly props: IPaymentFormProps;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {

            //Form
            nameSurname: "",
            cardNumber: "",
            experationDate: "",
            cvc: "",
            automaticPay: false,
            discountCode: "",

            isSuccess: false,

            //Product
            productId: 0,
            product: "",
            yearPrice: 0,
            monthPrice: 0,
            discount: 0,

            //Package
            payType: true,
            planId: 1,
            tabValue: 0,
            packages: [
                {
                    "id": 2,
                    "planId": 1,
                    "packageName": "STARTER",
                    "year": 2500,
                    "month": 250
                },
                {
                    "id": 3,
                    "planId": 1,
                    "packageName": "PROFESSIONAL",
                    "year": 4000,
                    "month": 400
                },
                {
                    "id": 4,
                    "planId": 1,
                    "packageName": "BUSINESS",
                    "year": 7000,
                    "month": 700
                },
                {
                    "id": 5,
                    "planId": 1,
                    "packageName": "ENTERPRISE",
                    "year": 11000,
                    "month": 1100
                },
                {
                    "id": 6,
                    "planId": 2,
                    "packageName": "STARTER",
                    "year": 15000,
                    "month": 1500
                },
                {
                    "id": 7,
                    "planId": 2,
                    "packageName": "PROFESSIONAL",
                    "year": 25000,
                    "month": 2500
                },
                {
                    "id": 8,
                    "planId": 2,
                    "packageName": "BUSINESS",
                    "year": 35000,
                    "month": 3500
                },
                {
                    "id": 9,
                    "planId": 2,
                    "packageName": "ENTERPRISE",
                    "year": 40000,
                    "month": 4000
                },
            ],
        };

        this.onChangePayType = this.onChangePayType.bind(this);
        this.onChangeDiscountCode = this.onChangeDiscountCode.bind(this);
        this.onChangeCardNumber = this.onChangeCardNumber.bind(this);
        this.onChangeNameSurname = this.onChangeNameSurname.bind(this);
        this.onChangeExperationDate = this.onChangeExperationDate.bind(this);
        this.onChangeCVC = this.onChangeCVC.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);

    }

    componentWillMount() {

    }

    /** EVENT LISTENERS */

    private onChangePayType(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ payType: e.target.checked });
    }

    private onChangeDiscountCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ discountCode: e.target.value });
    }

    private onChangeCardNumber(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ cardNumber: e.target.value });
    }

    private onChangeNameSurname(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ nameSurname: e.target.value });
    }

    private onChangeExperationDate(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ experationDate: e.target.value });
    }

    private onChangeCVC(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ cvc: e.target.value });
    }

    private handleTabChange(_: any, tabValue: any) {
        this.setState({ tabValue });
    }






    private renderPayType() {
        const { payType } = this.state;

        return (<>
            <label className={payType ? "" : "activePayType"}>AYLIK</label>
            <FormControlLabel control={
                <Switch value="isActive" className={payType ? "" : ""} onChange={this.onChangePayType} checked={payType} />
            }
                label={""} style={{ marginRight: 0, marginLeft: 0 }} />
            <label className={payType ? "activePayType" : ""}>YILLIK</label>
        </>)
    }


    private renderPlans() {
        const { tabValue } = this.state;
        if (tabValue === 0) return this.renderPackages(1);
        else if (tabValue === 1) return this.renderPackages(2);
    }


    private renderPackages(planId: number) {
        const { packages, payType, productId } = this.state;

        return (


            packages.map((pack: any) => (
                (planId == pack.planId) ?
                    pack.id == 1 ?
                        <Card className={(pack.id == productId) ? "package activePlan" : "package"} style={{ float: 'none', margin:'10px auto' }}>
                            <Typography variant="h2">{pack.packageName}</Typography>
                            <Icon className="far fa-user"></Icon>
                            <Typography variant="h3">{payType ? pack.year : pack.month}<span> +kdv</span> </Typography>
                            <Button onClick={() => this.addPackage(pack.id)} className={classNames({ "activePlan": pack.id == productId })}>Planı Seç</Button>
                        </Card>
                        :
                        <Card className={(pack.id == productId) ? "package activePlan" : "package"}>
                            <Typography variant="h2">{pack.packageName}</Typography>
                            <Icon className="far fa-user"></Icon>
                            <Typography variant="h3">{payType ? pack.year : pack.month}<span> +kdv</span> </Typography>
                            <Button onClick={() => this.addPackage(pack.id)} className={classNames({ "activePlan": pack.id == productId })}>Planı Seç</Button>
                        </Card>
                    : null
            ))

        );
    }


    private renderDiscountCode() {
        const { discountCode } = this.state;
        return (
            <FormControl margin="none" required className="input">
                <TextField
                    id="discountCode"
                    label="İndirim Kodunuz"
                    placeholder="İndirim kodu yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeDiscountCode}
                    value={discountCode}
                    name="discountCode"
                    autoComplete="discountCode"
                />
            </FormControl>
        );
    }

    private renderCardNumber() {
        const { cardNumber } = this.state;
        return (
            <FormControl margin="none" required className="input">
                <TextField
                    id="cardNumber"
                    label="Kart Numarası"
                    placeholder="*** **** **** ****"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCardNumber}
                    value={cardNumber}
                    name="cardNumber"
                    autoComplete="cardNumber"
                />
            </FormControl>
        );
    }

    private renderNameSurname() {
        const { nameSurname } = this.state;
        return (
            <FormControl margin="none" required className="input">
                <TextField
                    id="nameSurname"
                    label="Kart Üzerindeki İsim"
                    placeholder="Kart üzerindeki ismi yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeNameSurname}
                    value={nameSurname}
                    name="nameSurname"
                    autoComplete="nameSurname"
                />
            </FormControl>
        );
    }

    private renderExperationDate() {
        const { experationDate } = this.state;
        return (
            <FormControl margin="none" required className="input x50">
                <TextField
                    id="experationDate"
                    label="Son Kullanma Tarihi"
                    placeholder="GG/YY"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeExperationDate}
                    value={experationDate}
                    name="experationDate"
                    autoComplete="experationDate"
                />
            </FormControl>
        );
    }

    private renderCVC() {
        const { cvc } = this.state;
        return (
            <FormControl margin="none" required className="input x50" style={{ marginLeft: '4%' }}>
                <TextField
                    id="cvc"
                    label="CVC"
                    placeholder="***"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCVC}
                    value={cvc}
                    name="cvc"
                    autoComplete="cvc"
                />
            </FormControl>
        );
    }

    private renderAutomaticPay() {
        const { automaticPay } = this.state;

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        id="automaticPay"
                        name="automaticPay"
                        value="automaticPay"
                        color="primary"
                    />
                }
                label="Otomatik ödeme alınmasını istiyorum."
                className="checkbox"
            />
        )
    }


    private async addPackage(id: number) {
        const { packages } = this.state;
        packages.map((pack: any) => (
            (pack.id == id) ? this.setState({ product: pack.packageName, yearPrice: pack.year, monthPrice: pack.month, productId: pack.id }) : null
        ))
    }





    public render() {
        const { payType, product, yearPrice, monthPrice, tabValue } = this.state;

        return (
            <Wrapper>
                {this.state.isSuccess && <Redirect to={PAYMENT} />}
                <Grid container id="Breadcrumb">
                    <Grid xs={12}>
                        <ul>
                            <li>Ödeme İşlemleri</li>
                            <li>Finans</li>
                            <li>Ödeme İşlemleri</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container className="middle-text">
                    <Grid xs={12}>
                        <Typography variant="h2">Fiyatlandırma planını seç tek adımda ödemeni yap.
                        </Typography>
                        <Typography variant="subheading">İşinizin boyutuna uygun olan üyelik planına sürpriz ücretler olmaksızın aylık veya yıllık olarak üye olabilirsiniz.</Typography>
                        {this.renderPayType()}
                    </Grid>
                </Grid>
                <Paper id="Packages" style={{ backgroundColor: '#fff', height: 360 }}>
                    <Tabs
                        value={tabValue}
                        onChange={this.handleTabChange}
                        centered
                        className="tab"
                        indicatorColor="none"
                    >
                        <Tab label="Silver" />
                        <Tab label="Gold" />
                    </Tabs>

                    {this.renderPlans()}
                </Paper>
                <Grid container id="Order">
                    <Grid xs={1}></Grid>
                    <Grid xs={5} id="Sepet">
                        {this.renderDiscountCode()}
                        <Button>Uygula</Button>
                        <hr />
                        <Typography variant="h4">Sipariş Özeti</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{product} <span>({payType ? "yıllık" : "aylık"})</span></TableCell>
                                    <TableCell>{payType ? yearPrice : monthPrice} TL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>İndirim</TableCell>
                                    <TableCell>0 TL</TableCell>
                                </TableRow>
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>Toplam Tutar</TableCell>
                                    <TableCell>{payType ? yearPrice : monthPrice} TL</TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Grid>
                    <Grid xs={5} id="Payment">
                        <Typography variant="h4">Kredi Kart Bilgileri</Typography>
                        {this.renderCardNumber()}
                        {this.renderNameSurname()}
                        {this.renderExperationDate()}
                        {this.renderCVC()}
                        {this.renderAutomaticPay()}
                        <Button>Hemen Ödeme Yap</Button>
                    </Grid>
                    <Grid xs={1}></Grid>
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(PaymentForm);
