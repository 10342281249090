import XHRService from "./xhr.service";

export default interface IPermissionRequest {
    id?: number;
    permissionKey: string;
    permissionDescription: string;
    isActive: boolean;
}


interface IPermission {
    id: number;
    permissionKey: string;
    permissionDescription: string;
    isActive: boolean;
}

interface IPermissionResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IPermission
}

interface IPermissionListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        permissions: IPermission[]
    }
}

export default class PermissionService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IPermissionResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Permission/CreatePermission", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IPermissionRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Permission/UpdatePermission", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<IPermissionResponse> {
        const { isSuccess }: any = await this.XHRService.post("/Permission/DeletePermission", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/Permission/GetPermissions", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/Permission/GetPermissionsExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/Permission/GetPermissionById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
