
import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = (theme: any) => ({
    root: {
        flexGrow: 1,
        height: 250
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 13,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 13,
    },
    paper: {
        position: 'absolute',
        zIndex: 9,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
        border: '1px solid #e6f0fe'
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});

function NoOptionsMessage(props: any) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            Kayıt bulunamadı!
            {console.log(props)}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }: { inputRef: any }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props: any) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props: any) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props: any) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props: any) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props: any) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props: any) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props: any) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

interface IntegrationReactSelectProps {
    placeholder: string;
    onChange: (val: any) => void;
    options: [];
    labelName: string;
    valueKey: string;
    theme: any,
    classes: any;
    value?: any;
    disabled?: boolean;
}

class IntegrationReactSelect extends React.Component {
    public props: IntegrationReactSelectProps;
    public state: any;

    constructor(props: IntegrationReactSelectProps) {
        super(props);
        this.props = props;

        this.state = {
            single: this.props.value ? this.props.value : null,
            multi: null,
        };
    }

    handleChange = (name: any) => (value: any) => {
        this.props.onChange(value);
        this.setState({
            [name]: value,
        });
    };

    render() {
        const { classes, theme, placeholder, labelName, valueKey, options, disabled } = this.props;

        const selectStyles = {
            input: (base: any) => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };

        return (
            <div className={classes.root}>
                <NoSsr>
                    <Select
                        required
                        variant="outlined"
                        classes={classes}
                        styles={selectStyles}
                        options={options}
                        components={components}
                        isDisabled={disabled}
                        getOptionLabel={(option: any) => option[labelName]}
                        getOptionValue={(option: any) => option[valueKey]}
                        value={this.props.value ? this.props.value : this.state.single}
                        onChange={this.handleChange('single')}
                        placeholder={placeholder}
                        isClearable
                        className="searchSelect"
                    />
                </NoSsr>
            </div>
        );
    }
}

export default withStyles(styles as any, { withTheme: true })(IntegrationReactSelect);