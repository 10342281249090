import React, { Component } from 'react'
import Wrapper from '../layout/Wrapper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';

interface IDocumentationState {
    tab: number;
}


const style = (theme: any) => ({
    wrapper: {
        display: "flex",
    },
    card: {
        margin: "10px",
        width: "20%",
    },
    content: {
        display: "flex",
        justifyContent: "space-between"
    }
})



class Documentation extends Component {
    public readonly state: IDocumentationState;
    public readonly props: any;


    constructor(props: any) {
        super(props);
        this.props = props;

        this.state = {
            tab: 0
        }

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    private handleTabChange(_: any, tab: number) {
        this.setState({ tab });
    }

    private onOpen($link: string) {
        window.open($link, '_blank');
    }

    private renderAllDocuments() {
        const { classes } = this.props;

        return (
            <div className={classes.wrapper} id="Documentation">
                <Card key={1} className={classes.card} id="card" onClick={() => this.onOpen('http://www.finstant.co/downloads/FinstantClient.msi')}>
                    <CardContent className={classes.content}>
                        <div>
                            <Icon className="far fa-file-archive" style={{ color: '#445978' }}></Icon>
                            <Typography variant="subheading">indir</Typography>
                            <Typography variant="h2" component="h2">Finstant Client</Typography>
                        </div>
                    </CardContent>
                </Card>
                <Card key={2} className={classes.card} id="card" onClick={() => this.onOpen('https://finstant.co/Img/KVKK.pdf')}>
                    <CardContent className={classes.content}>
                        <div>
                            <Icon className="far fa-file-pdf"></Icon>
                            <Typography variant="subheading">indir</Typography>
                            <Typography variant="h2" component="h2">KVKK</Typography>
                        </div>
                    </CardContent>
                </Card>
                <Card key={3} className={classes.card} id="card" onClick={() => this.onOpen('https://finstant.co/Img/KVKK.pdf')}>
                    <CardContent className={classes.content}>
                        <div>
                            <Icon className="far fa-file-pdf"></Icon>
                            <Typography variant="subheading">indir</Typography>
                            <Typography variant="h2" component="h2">Hizmet Sözleşmesi</Typography>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );

    }


    private renderSetupDocuments() {
        const { classes } = this.props;

        return (
            <div className={classes.wrapper} id="Documentation">
                <Card key={4} className={classes.card} id="card" onClick={() => this.onOpen('http://www.finstant.co/downloads/FinstantClient.msi')}>
                    <CardContent className={classes.content}>
                        <div>
                            <Icon className="far fa-file-archive" style={{ color: '#445978' }}></Icon>
                            <Typography variant="subheading">indir</Typography>
                            <Typography variant="h2" component="h2">Finstant Client</Typography>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );

    }


    private renderUsingDocuments() {
        const { classes } = this.props;

        return (
            <div className={classes.wrapper} id="Documentation">
                <Card key={5} className={classes.card} id="card" onClick={() => this.onOpen('https://finstant.co/Img/KVKK.pdf')}>
                    <CardContent className={classes.content}>
                        <div>
                            <Icon className="far fa-file-pdf"></Icon>
                            <Typography variant="subheading">indir</Typography>
                            <Typography variant="h2" component="h2">KVKK</Typography>
                        </div>
                    </CardContent>
                </Card>
                <Card key={6} className={classes.card} id="card" onClick={() => this.onOpen('https://finstant.co/Img/KVKK.pdf')}>
                    <CardContent className={classes.content}>
                        <div>
                            <Icon className="far fa-file-pdf"></Icon>
                            <Typography variant="subheading">indir</Typography>
                            <Typography variant="h2" component="h2">Hizmet Sözleşmesi</Typography>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );

    }


    private renderBody() {
        const { tab } = this.state;

        if (tab === 0) return this.renderAllDocuments();
        else if (tab === 1) return this.renderSetupDocuments();
        else if (tab === 2) return this.renderUsingDocuments();
    }

    render() {

        return (
            <Wrapper>
                <Grid container id="Breadcrumb">
                    <Grid xs={12}>
                        <ul>
                            <li>Dosyalar</li>
                            <li>Anasayfa</li>
                            <li>Dosyalar</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={12}>
                        <Tabs
                            value={this.state.tab}
                            onChange={this.handleTabChange}
                            className="tab"
                            id="DocumentationTab"
                            indicatorColor="primary"
                        >
                            <Tab label="Tüm Dosyalar" />
                            <Tab label="Kurulumlar" />
                            <Tab label="Belgeler" />

                        </Tabs>
                        {this.renderBody()}
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
}


export default withStyles(style)(Documentation);