import React, { Component, ChangeEvent } from 'react'

import Paper from "@material-ui/core/Paper"
import Grid from '@material-ui/core/Grid'
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from "@material-ui/core/Typography"
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputLabel from "@material-ui/core/InputLabel"
import Icon from '@material-ui/core/Icon'

import CustomSelect from '../layout/CustomSelect'

import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { cookieService, resourceService, accountStatementsService, bankAccountService, userService } from '../../service'

interface IAccountStatementsState {
    list: [],
    account: any,
    accountList: [],
    loading: boolean,
    totalCount: number,
    searchTotalCount: number,
    bankId: number,
    currencyId: number,
    resources: any,

    //DatePicker
    startDate: any,
    endDate: any,
    focusedInput: any,

    //Paging
    sortType: string,
    searchText: string,
    clientType: string,
    iban: string,
    currentPage: number,
    selectedRow: number,
    orderBy: number,

    //Permission
    isExcelAndPdfExport: boolean,
}


export default class AccountStatements extends Component {
    public readonly state: IAccountStatementsState;
    public props: any;

    private timeout = 3000;
    private interval: any;

    public readonly LANGUAGE_NAME = 'LANGUAGE_TOKEN';

    constructor(props: any) {
        super(props);
        this.props = props;

        this.state = {
            list: [],
            account: [],
            accountList: [],
            loading: false,
            totalCount: 0,
            searchTotalCount: 0,
            bankId: 0,
            currencyId: 0,
            resources: [],

            //DatePicker
            //startDate: moment().startOf('month'),
            //endDate: moment().endOf('month'),
            startDate: moment().startOf('day'),
            //endDate: moment().endOf('day'),
            endDate: moment().add(1,'day'),
            
            focusedInput: null,

            //Paging
            selectedRow: 25,
            currentPage: 0,
            sortType: "asc",
            searchText: "",
            clientType: "WEB",
            iban: "",
            orderBy: 0,

            //Permission
            isExcelAndPdfExport: false,
        }

        this.onChangeAccount = this.onChangeAccount.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
        this.updateList = this.updateList.bind(this);
        this.getData = this.getData.bind(this);
        this.getExportExcel = this.getExportExcel.bind(this);
    }

    componentWillMount() {
        this.init();
    }

    private onChangeAccount(account: any) {
        this.setState({ account });
    }

    private async init() {
        this.setState({ loading: true });

        this.getPermissions();

        await Promise.all([
            this.getData(),
            this.getAccounts()
        ]);

        if (this.props.bankId) {
            this.setState({ bankId: this.props.bankId, currencyId: this.props.currencyId });
            this.getData(this.state.startDate, this.state.endDate, this.props.currencyId, this.props.bankId, this.state.account.id, this.state.searchText, this.state.clientType, this.state.iban, this.state.selectedRow, this.state.currentPage, this.state.orderBy);
        }

        if (this.props.accountId) {
            this.setState({ loading: true, account: this.state.accountList.find((account: any) => account.id === Number(this.props.accountId)) })

            this.getData(this.state.startDate, this.state.endDate, this.state.currencyId, this.state.bankId, this.props.accountId, this.state.searchText, this.state.clientType, this.state.iban, this.state.selectedRow, this.state.currentPage, this.state.orderBy);
        }
    }

    private async getPermissions() {
        const { data } = await userService.permission();
        this.setState({ isExcelAndPdfExport: data.isExcelAndPdfExport });

        this.getResources();
    }



    private async getResources() {
        const resourceLanguage: any = cookieService.resourceItem(this.LANGUAGE_NAME);
        const { data } = await resourceService.language(resourceLanguage);
        var resources: any = {};
        data.resources.forEach((item: any) => {
            resources[item.resourceKey] = item.value;
        });

        this.setState({ resources: resources });
    }

    private async getData(startDate?: any, endDate?: any, currencyId?: number, bankId?: number, id?: number, searchText?: string, clientType?: string, iban?: string, rowsPerPage?: number, pageNumber?: number, orderBy?: number) {
        const unixStartDate = startDate ? startDate.format('MM.DD.YYYY') : this.state.startDate.format('MM.DD.YYYY');
        const unixEndDate = endDate ? endDate.format('MM.DD.YYYY') : this.state.endDate.format('MM.DD.YYYY');
        const accountId = Number(id == undefined ? 0 : id);
        const search = String(searchText ? searchText : this.state.searchText);
        const client = String(clientType ? clientType : this.state.clientType);
        const IBAN = String(iban ? iban : this.state.iban);
        const selectedRow = Number(rowsPerPage ? rowsPerPage : this.state.selectedRow);
        const currentPage = Number(pageNumber ? pageNumber : this.state.currentPage);
        const order = Number(orderBy ? orderBy : this.state.orderBy);
        const { data } = await accountStatementsService.get(unixStartDate, unixEndDate, currencyId, bankId, accountId, search, client, IBAN, selectedRow, currentPage, order);
        this.setState({ list: data.accountStatements, loading: false, totalCount: data.totalCount, searchTotalCount: data.searchTotalCount });
    }

    private updateList() {
        this.setState({ loading: true, selectedRow: 25, currentPage: 0, orderBy: 0, totalCount: 0 });
        const accountId = this.state.account ? this.state.account.id : 0;
        this.getData(this.state.startDate, this.state.endDate, this.state.currencyId, this.state.bankId, accountId, this.state.searchText, this.state.clientType,this.state.iban, this.state.selectedRow, this.state.currentPage, this.state.orderBy);
    }

    private async getAccounts() {
        const { data } = await bankAccountService.get("", "WEB", 0, 999, 0, true, false);
        this.setState({ accountList: data.bankAccounts, })
    }

    private async getExportExcel() {
        const accountId = this.state.account.id | 0;
        return await accountStatementsService.download(this.state.startDate.format('MM.DD.YYYY'), this.state.endDate.format('MM.DD.YYYY'), this.state.currencyId, this.state.bankId, accountId, this.state.searchText, this.state.iban);
    }

    private renderAccounts() {
        const { accountList, account } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input">
                <InputLabel htmlFor="account-select">Hesap</InputLabel>
                <CustomSelect value={account} labelName="bankAccountName" valueKey="id" options={accountList} onChange={this.onChangeAccount} placeholder="Hesap Seçiniz"></CustomSelect>
            </FormControl >
        );
    }

    private renderDatePickers() {
        const { startDate, endDate } = this.state;
        return (
            <DateRangePicker
                startDate={startDate}
                startDateId="accountStatementStartDateId"
                endDate={endDate}
                endDateId="accountStatementEndDateId"
                onDatesChange={this.onDatesChange}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput })}
                startDatePlaceholderText="DD"
                endDatePlaceholderText="DD"
                isOutsideRange={() => false}
                displayFormat="DD.MM.YYYY"
                monthFormat="MMMM YYYY"
            />
        );
    }

    private renderDownloadButton() {
        return (
            <Button
                variant="contained"
                color="primary"
                className="actionsButton"
                fullWidth
                type="submit">
                EYLEMLER <Icon className="fas fa-ellipsis-v"></Icon>
                <ul className="actions">
                    <h6>HIZLI EYLEMLER</h6>
                    <li onClick={() => this.getExportExcel()}><Icon className="far fa-file-excel"></Icon> Excel'e aktar</li>
                </ul>
            </Button>
        );
    }

    private onDatesChange({ startDate, endDate }: any) {
        this.setState({ startDate, endDate });
    }

    private handleChangePage(_: any, page: number) {
        this.setState({ currentPage: page, loading: true });
        this.getData(this.state.startDate, this.state.endDate, this.state.currencyId, this.state.bankId, this.state.account.id, this.state.searchText, this.state.clientType, this.state.iban, this.state.selectedRow, page, this.state.orderBy);
    }

    private handleSort(orderBy: number) {
        this.setState({ orderBy, loading: true });
        this.getData(this.state.startDate, this.state.endDate, this.state.currencyId, this.state.bankId, this.state.account.id, this.state.searchText, this.state.clientType, this.state.iban, this.state.selectedRow, this.state.currentPage, orderBy);
    }

    private handleSearch({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        this.setState({ searchText: value });
        this.getData(this.state.startDate, this.state.endDate, this.state.currencyId, this.state.bankId, this.state.account.id, value, this.state.iban, this.state.clientType, this.state.selectedRow, this.state.currentPage, this.state.orderBy);
    }

    private handleRowsPerPageChange({ target: { value } }: any) {
        const selectedRow = Number(value);
        this.setState({ selectedRow, loading: true });
        this.getData(this.state.startDate, this.state.endDate, this.state.currencyId, this.state.bankId, this.state.account.id, this.state.searchText, this.state.clientType, this.state.iban, selectedRow, this.state.currentPage, this.state.orderBy);
    }

    private renderPagination() {
        const { searchTotalCount, selectedRow, currentPage } = this.state;
        let pageCount = 1;
        let pages = [];
        searchTotalCount <= selectedRow ? pageCount = 1 : pageCount = (searchTotalCount / selectedRow)

        for (let i = 1; i <= Math.ceil(pageCount); i++) {
            if (currentPage + 1 < i + 3 && currentPage + 1 > i - 3) {
                pages[i - 1] = i;
            }
        }

        const listItems = pages.map((page) =>
            <>
                {currentPage + 1 === page ? <a onClick={() => this.handleChangePage(null, page - 1)} style={{ color: '#408af8' }}>{page}</a> : <a onClick={() => this.handleChangePage(null, page - 1)}>{page}</a>}
            </>
        );

        return listItems;
    }

    private renderAmountConvert(amount: string) {
        if (amount === null) return;

        if (amount == "0") {
            return "0,00";
        }

        let p = "";
        let isaretli = ""

        let sayi = amount.split(".");
        let net = sayi[0];
        let ondalik = sayi[1];

        net.indexOf("-") != -1 ? isaretli = "-" : null
        net = net.replace("-", "");

        ondalik == undefined ? ondalik = "00" : null
        ondalik.length == 1 ? ondalik += "0" : null

        let tersSayi = ((net.split("")).reverse()).join("");

        for (let i = 0; i < tersSayi.length; i++) {
            if (i % 3 == 0 && i != 0) p += "."
            p += tersSayi.substr(i, 1);
        }


        return isaretli + (((p.split("")).reverse()).join("")) + "," + ondalik;
    }

    private renderRowHeaders() {
        const { orderBy } = this.state;
        return (
            <TableRow style={{ backgroundColor: '#fdfdfd' }}>
                <TableCell onClick={() => this.handleSort(orderBy === 1 ? 2 : 1)}>
                    <TableSortLabel active={orderBy === 1 || orderBy === 2} direction={orderBy === 1 ? "desc" : "asc"}>
                        Tarih
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 20 ? 21 : 20)}>
                    <TableSortLabel active={orderBy === 20 || orderBy === 21} direction={orderBy === 20 ? "desc" : "asc"}>
                        Firma Adı
                    </TableSortLabel>
                </TableCell>  
                <TableCell onClick={() => this.handleSort(orderBy === 15 ? 16 : 15)}>
                    <TableSortLabel active={orderBy === 15 || orderBy === 16} direction={orderBy === 15 ? "desc" : "asc"}>
                        Hesap Adı
                    </TableSortLabel>
                </TableCell>              
                <TableCell onClick={() => this.handleSort(orderBy === 5 ? 6 : 5)}>
                    <TableSortLabel active={orderBy === 5 || orderBy === 6} direction={orderBy === 5 ? "desc" : "asc"}>
                        Açıklama
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 7 ? 8 : 7)} style={{ textAlign: 'right' }}>
                    <TableSortLabel active={orderBy === 7 || orderBy === 8} direction={orderBy === 7 ? "desc" : "asc"}>
                        Tutar
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 11 ? 12 : 11)} style={{ textAlign: 'right' }}>
                    <TableSortLabel active={orderBy === 11 || orderBy === 12} direction={orderBy === 11 ? "desc" : "asc"}>
                        Döviz
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 11 ? 12 : 11)} style={{ textAlign: 'right' }}>
                    <TableSortLabel active={orderBy === 11 || orderBy === 12} direction={orderBy === 11 ? "desc" : "asc"}>
                        Bakiye
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 15 ? 16 : 15)}>
                    <TableSortLabel active={orderBy === 15 || orderBy === 16} direction={orderBy === 15 ? "desc" : "asc"}>
                        Gönderen Adı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 15 ? 16 : 15)}>
                    <TableSortLabel active={orderBy === 15 || orderBy === 16} direction={orderBy === 15 ? "desc" : "asc"}>
                        Gönderen VKN/TCKN
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 15 ? 16 : 15)}>
                    <TableSortLabel active={orderBy === 15 || orderBy === 16} direction={orderBy === 15 ? "desc" : "asc"}>
                        Gönderen IBAN
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 3 ? 4 : 3)}>
                    <TableSortLabel active={orderBy === 3 || orderBy === 4} direction={orderBy === 3 ? "desc" : "asc"}>
                        Banka
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 7 ? 8 : 7)} className="statusArea">
                    <TableSortLabel active={orderBy === 7 || orderBy === 8} direction={orderBy === 7 ? "desc" : "asc"} style={{ justifyContent: 'center' }}>
                        Tip
                    </TableSortLabel>
                </TableCell>
            </TableRow >
        );
    }

    private renderTable() {
        const { list, loading, isExcelAndPdfExport, currentPage, searchText, totalCount, searchTotalCount, selectedRow, resources } = this.state;

        return (

            <>
                <Grid container style={{ height: 80 }}>
                    <Grid xs={12}>
                        <Paper id="DatePicker">
                            {this.renderAccounts()}
                            {this.renderDatePickers()}
                            <Button className="button" onClick={this.updateList}>Tamam</Button>
                        </Paper>
                        {!isExcelAndPdfExport ? null :
                            <div className="contentHeader" style={{ border: 'none', marginTop: '-73px' }}>
                                {this.renderDownloadButton()}
                            </div>
                        }
                    </Grid>
                </Grid>

                <Grid container style={{ height: 60 }} className="topPagination">
                    <Grid xs={9}>
                        <TablePagination
                            id="TopPagination"
                            rowsPerPageOptions={[25, 50, 100, 250]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={selectedRow}
                            onChangeRowsPerPage={this.handleRowsPerPageChange}
                            page={currentPage}
                            labelDisplayedRows={() => false}
                            labelRowsPerPage={resources.LabelViewData}
                            onChangePage={() => false}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <FormControl margin="none" fullWidth className="input searchBox" required>
                            <TextField
                                id="search"
                                placeholder="Arama"
                                margin="normal"
                                variant="outlined"
                                onChange={this.handleSearch}
                                value={searchText}
                                name="search"
                                autoComplete="search"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ minWidth: '100%', overflowX: 'auto' }}>
                    <Table style={{ width: 2500 }}>
                        <TableHead>
                            {this.renderRowHeaders()}
                        </TableHead>
                        <TableBody>
                            {list.map((company: any) => (
                                <TableRow key={company.id}>
                                    <TableCell scope="row" style={{ minWidth: 80 }}>
                                        {company.transactionDate}
                                    </TableCell>                               
                                    <TableCell style={{ minWidth: 250 }}>{company.companyName}</TableCell>
                                    <TableCell style={{ minWidth: 250 }}>{company.bankAccountName}</TableCell>
                                    <TableCell>{company.description}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 100 }}>{this.renderAmountConvert(company.amount)}</TableCell>
                                    <TableCell style={{ minWidth: 60 }}>{company.currencyCode}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 100 }}>{company.balance}</TableCell>
                                    <TableCell style={{ minWidth: 250 }}>{company.senderName}</TableCell>
                                    <TableCell style={{ minWidth: 250 }}>{company.debtorVKN}</TableCell>                                    
                                    <TableCell style={{ minWidth: 250 }}>{company.senderIBAN}</TableCell>   
                                    <TableCell style={{ minWidth: 210 }}>{company.bankName}</TableCell>  
                                    <TableCell style={{ minWidth: 50 }} className="statusArea">
                                        <Typography style={{ marginRight: 20, width: 23, height: 23 }} className={company.amount < 0 ? "isActive passive" : "isActive active"}>{company.amount < 0 ? "A" : "B"}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    id="Pagination"
                    rowsPerPageOptions={[0]}
                    component="div"
                    count={searchTotalCount}
                    rowsPerPage={selectedRow}
                    onChangeRowsPerPage={this.handleRowsPerPageChange}
                    page={currentPage}
                    labelDisplayedRows={({ from, to, count }) => `${count} ${resources.LabelPagination2} ${from} - ${to} ${resources.LabelPagination3}.`}
                    labelRowsPerPage="Görüntülenen Kayıt"
                    backIconButtonProps={{
                        'aria-label': 'Önceki Sayfa',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Sonraki Sayfa',
                    }}
                    onChangePage={this.handleChangePage}
                />
                <span className="total">{"(" + totalCount + " " + resources.LabelPagination1 + ")"}</span>
                <div id="PageList">
                    {currentPage > 0 ? <a onClick={() => this.handleChangePage(null, this.state.currentPage - 1)}>{resources.LabelPrevious}</a> : null}
                    {currentPage > 3 ? <><a onClick={() => this.handleChangePage(null, 0)}>1</a><a >...</a></> : null}

                    {loading === false ? this.renderPagination() : null}

                    {(currentPage + 1) < (Math.floor((searchTotalCount / selectedRow)) - 2) ? <a >...</a> : null}

                    {(currentPage) + 1 < (Math.ceil((searchTotalCount / selectedRow)) - 2) ? <a onClick={() => this.handleChangePage(null, Math.ceil((searchTotalCount / selectedRow) - 1))}>{Math.ceil((searchTotalCount / selectedRow))}</a> : null}
                    {(currentPage + 1) < (Math.ceil(searchTotalCount / selectedRow)) ? <a onClick={() => this.handleChangePage(null, this.state.currentPage + 1)}> {resources.LabelNext}</a> : null}
                </div>
            </>
        )
    }

    render() {
        return (
            <Paper>
                {this.state.loading ? <CircularProgress className="progress" /> : this.renderTable()}
            </Paper>
        )
    }
}
