import XHRService from "./xhr.service";

export default interface ICountryRequest {
    id?: number;
    countryName: string;
    countryCode: string;
    countryPhoneCode: string;
    isActive: boolean;
}


interface ICountry {
    id: number;
    countryName: string;
    countryCode: string;
    countryPhoneCode: string;
    isActive: boolean;
}

interface ICountryResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: ICountry
}

interface ICountryListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        countries: ICountry[]
    }
}

export default class CountryService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<ICountryResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Country/CreateCountry", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: ICountryRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Country/UpdateCountry", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<ICountryResponse> {
        const { isSuccess }: any = await this.XHRService.post("/Country/DeleteCountry", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            searchText,
            countryName: null,
            countryCode: null,
            countryPhoneCode: null,
            isActive,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/Country/GetCountries", model).catch(() => ({ isSuccess: false }));
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/Country/GetCountryById?id=${id}`).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/Country/GetCountriesExcel", model);
    }
}
