import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Typography from "@material-ui/core/Typography";
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { companyUserService, locationService } from '../../service';
import { COMPANYUSER } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import Error from '../NotFound/NotFound'


import { companyValidator, userValidator } from '../../utils/companyuser.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface ICompanyUserFormState {
    companyList: [],
    companyId: any,
    companyError: string,
    userList: [],
    userId: any,
    userError: string,
    searchText: string,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface ICompanyUserFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class CompanyUserForm extends Component {
    public readonly state: ICompanyUserFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            companyList: [],
            companyId: 0,
            companyError: "",
            userList: [],
            userId: 0,
            userError: "",
            searchText: "",
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeCompany = this.onChangeCompany.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.companyUserId) this.getEditableCompanyUser();
        else { this.getCompanies(); this.getUsers(); this.setState({ loading: false }); }
        this.form = React.createRef();
    }

    private async getEditableCompanyUser() {
        const { data, isSuccess, errorCode, errorMessage } = await companyUserService.getById(this.props.match.params.companyUserId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getCompanies();
            await this.getUsers();
            const state = {
                ...data,
                companyId: this.state.companyList.find((item: any) => item.id === data.companyId),
                userId: this.state.userList.find((item: any) => item.id === data.userId),
                isEdit: true
            }
            if (isSuccess) this.setState({ ...state, loading: false });
        }
    }

    /** EVENT LISTENERS */

    private onChangeCompany(companyId: any) {
        this.setState({ companyId });
    }

    private onChangeUser(userId: any) {
        this.setState({ userId });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(COMPANYUSER);
    }



    private async getUsers(useDefault: boolean = true) {
        const { data } = await locationService.getUsers();

        const state = {
            userId: useDefault ? 0 : this.state.userId.id,
            userList: data.users
        };

        this.setState(state);
    }

    private async getCompanies(useDefault: boolean = true) {
        const { data } = await locationService.getCompanies();

        const state = {
            companyId: useDefault ? 0 : this.state.companyId.id,
            companyList: data.companies
        };

        this.setState(state);
    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (            
            this.state.errorMessage !== "" ? <Redirect to={{ pathname: COMPANYUSER, state: { errorMessage: this.state.errorMessage } }} /> : null
        );
    }

    private formValidation() {
        const { userId, companyId } = this.state;

        //Submit Validation
        if (
            userValidator(userId) !== "" ||
            companyValidator(companyId) !== ""

        ) {

            this.setState({
                userError: userValidator(userId),
                companyError: companyValidator(companyId)
            });
            return false;
        }

        return true;
    }


    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { companyId, userId, isEdit } = this.state;

        if (this.formValidation()) {

            const model = {
                companyId: sqlInjectValidator(String(companyId.id)),
                userId: sqlInjectValidator(String(userId.id))
            };

            if (isEdit) await this.editCompanyUser(model);
            else await this.createCompanyUser(model);
        }
    }

    private async createCompanyUser(model: any) {
        const data = await companyUserService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editCompanyUser(model: any) {
        const data = await companyUserService.update({ ...model, id: this.props.match.params.companyUserId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }



    private renderCompanies() {
        const { companyId, companyList, companyError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" required className="input inputSelect">
                <CustomSelect labelName="companyName" value={companyId} valueKey="id" options={companyList} onChange={this.onChangeCompany} placeholder="Firma *"></CustomSelect>
                <span className="errorMessage">{companyError}</span>
            </FormControl >
        );
    }


    private renderUsers() {
        const { userId, userList, userError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" required className="input inputSelect">
                <CustomSelect labelName="nameSurname" value={userId} valueKey="id" options={userList} onChange={this.onChangeUser} placeholder="Kullanıcı *"></CustomSelect>
                <span className="errorMessage">{userError}</span>
            </FormControl>
        );
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: COMPANYUSER, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Firma Kullanıcı {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan hesap kullanıcı bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda hesap kullanıcısı ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Firma Kullanıcı {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderCompanies()}
                                    {this.renderUsers()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(CompanyUserForm);
