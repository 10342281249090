import XHRService from "./xhr.service";

export default interface IInvoiceRequest {
    id?: number,
    invoiceName: string,
    startDateTime: string,
    dueDateTime: string,
    isActive: boolean
}


interface IInvoice {
    id: number,
    invoiceName: string,
    startDateTime: string,
    dueDateTime: string,
    isActive: boolean
}

interface IInvoiceResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IInvoice
}

interface IInvoiceListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        invoices: IInvoice[]
    }
}

export default class InvoiceService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IInvoiceResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Invoice/CreateInvoice", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IInvoiceRequest): Promise<any> {
        const { isSuccess, errorMessage, errorCode }: any = await this.XHRService.post("/Invoice/UpdateInvoice", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<IInvoiceResponse> {
        const { isSuccess }: any = await this.XHRService.post("/Invoice/DeleteInvoice", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            isActive,
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/Invoice/GetInvoices", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/Invoice/GetInvoicesExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/Invoice/GetInvoiceById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
