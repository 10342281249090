import React, { Component } from 'react';
import Routes from './routes';
import CssBaseline from '@material-ui/core/CssBaseline';

class App extends Component {
  render() {
    return (
      <div style={{ display: "flex" }} className="App">
        <CssBaseline />
        <Routes />
      </div>
    );
  }
}

export default App;