import React, { Component, ChangeEvent } from 'react'
import { NavLink, Redirect } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import CustomSelect from '../layout/CustomSelect';
import Alert from '../layout/Alert';
import { registerService, locationService, userService } from '../../service';

import {HOME} from "../../routes/paths";

import {validate} from "../../helpers/validators";

export interface IRegisterFormState {
    companyName: string;
    companyNameError: string | boolean;
    personal: any;
    personalValue: string;
    personalError: string | boolean;
    countryId: number;
    countryList: [];
    countryError: string | boolean;
    countryPhoneCode: number;
    phone: string;
    phoneError: string | boolean;
    nameSurname: string;
    nameSurnameError: string | boolean;
    email: string;
    emailError: string | boolean;
    password: string;
    passwordError: string | boolean;
    passwordAgain: string;
    passwordAgainError: string | boolean;
    discountCode: string;
    aggreement: boolean;
    aggreementError: string | boolean;
    clarificationText: boolean;
    clarificationTextError: string | boolean;
    explicitConsentText: boolean;
    showPassword: boolean;
    errorMessage: string;
    isToken: boolean;
    tokenParams: any;
    isLogin: boolean;        
    clientType: string;
    partnerCompanyCode: string;
}

export interface IRegisterFormProps {
    classes: any;
    params: string;
}


const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    },
});

class RegisterForm extends Component {
    public readonly state: IRegisterFormState;
    public readonly props: IRegisterFormProps;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            companyName: "",
            companyNameError: false,
            countryId: 0,
            countryList: [],
            countryError: false,
            countryPhoneCode: 0,
            phone: "",
            phoneError: false,
            nameSurname: "",
            nameSurnameError: false,
            email: "",
            emailError: false,
            password: "",
            passwordError: false,
            passwordAgain: "",
            passwordAgainError: false,
            discountCode: "",
            aggreement: false,
            aggreementError: false,
            clarificationText: false,
            clarificationTextError: false,
            explicitConsentText: false,
            showPassword: false,
            personalValue: "",
            personal: [
                {
                    "personalValue": "1-5 Kişi"
                },
                {
                    "personalValue": "6-10 Kişi"
                },
                {
                    "personalValue": "11-25 Kişi"
                },
                {
                    "personalValue": "26-50 Kişi"
                },
                {
                    "personalValue": "51-100 Kişi"
                },
                {
                    "personalValue": "100+ Kişi"
                }
            ],
            personalError: false,
            errorMessage: "",
            isToken: false,
            tokenParams: [],
            isLogin: false,        
            clientType: "WEB",
            partnerCompanyCode: "0",
        };

        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangePersonal = this.onChangePersonal.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeNameSurname = this.onChangeNameSurname.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassoword = this.onChangePassoword.bind(this);
        this.onChangePassowordAgain = this.onChangePassowordAgain.bind(this);
        this.onChangeDiscountCode = this.onChangeDiscountCode.bind(this);
        this.onChangeAggreement = this.onChangeAggreement.bind(this);
        this.onChangeClarificationText = this.onChangeClarificationText.bind(this);
        this.onChangeExplicitConsentText = this.onChangeExplicitConsentText.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.register = this.register.bind(this);
        this.complate = this.complate.bind(this);

    }

    componentWillMount() {
        this.getCountries();
    }

    handleChange = (event: any) => {
        this.setState({ [event.target]: event.target });
    };

    handleClickShowPassword() {
        this.setState(state => ({ showPassword: !this.state.showPassword }));
    };


    /** EVENT LISTENERS */

    private onChangeCompanyName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ 
            companyName: e.target.value,
            companyNameError: validate("Firma Adı", e.target.value, ["empty"])
         });
    }

    private onChangePersonal(val: any) {
        if (val) {
            this.setState({ 
                personalValue: val.personalValue,
                personalError: validate("Çalışan Sayısı", val.personalValue, ["empty"])
            });
        }
    }

    private onChangeCountry(val: any) {
        if (val) {
            const countryId = val;
            const countryPhoneCode = Number(val.countryPhoneCode);
            this.setState({ 
                countryId, 
                countryPhoneCode, 
                phone: countryPhoneCode,
                countryError: validate("Ülke", val.id, ["empty"] )
            });
        }
    }

    private onChangePhone(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ 
            phone: e.target.value,
            phoneError: validate("Telefon", e.target.value, ["empty", "phone"])
        });
    }

    private onChangeNameSurname(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ 
            nameSurname: e.target.value, 
            nameSurnameError: validate("Ad Soyad", e.target.value, ["empty"]) 
        });
    }

    private onChangeEmail(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ 
            email: e.target.value,
            emailError: validate("E-posta Adresi", e.target.value, ["empty", "email"])
         });
    }

    private onChangePassoword(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ 
            password: e.target.value,
            passwordError: validate("Şifre", e.target.value, ["empty", "password"])
        });
    }

    private onChangePassowordAgain(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ 
            passwordAgain: e.target.value,
            passwordAgainError: validate("Şifre Tekrar", e.target.value, ["empty", "password"])
        });
    }

    private onChangeDiscountCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ discountCode: e.target.value });
    }

    private onChangeAggreement(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ aggreement: e.target.checked });
        e.target.checked ? window.open("https://www.finstant.co/legal/Finstant_Kullanici_Sozlesmesi.pdf", "_blank") : null
    }

    private onChangeClarificationText(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ clarificationText: e.target.checked });
        e.target.checked ? window.open("https://www.finstant.co/legal/Finstant_KVKK_Aydinlatma_Metni.pdf", "_blank") : null
    }

    private onChangeExplicitConsentText(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ explicitConsentText: e.target.checked });
        e.target.checked ? window.open("https://finstant.co/Img/KVKK.pdf", "_blank") : null
    }

    /** VIEW RENDER */

    private async getCountries(useDefault: boolean = true) {
        const { data } = await locationService.getCountries();

        const state = {
            countryList: data.countries,
            countryId: useDefault ? data.countries[0].id : this.state.countryId
        };

        this.setState(state);
        
        this.getToken();
    }

    private async getToken(){
        const {params}  = this.props;

        const {data, isSuccess, errorCode, errorMessage} = await userService.getUserByToken(params);

        if(isSuccess){
            const country : any = await this.state.countryList.find((item: any) => item.id === data.countryId);

            await this.setState({
                isToken: isSuccess, 
                tokenParams: data,
                countryId: country,
                countryPhoneCode: country.countryPhoneCode,
                phone: country.countryPhoneCode,
            });
            
        }else{
            this.setState({errorMessage});
        }
        
    }


    private async register() {
        const { companyName, personal, countryId, phone, nameSurname, email, password, discountCode, aggreement } = this.state;

        const model = {
            companyName,
            personal,
            countryId,
            phone,
            nameSurname,
            email,
            password,
            discountCode,
            aggreement
        }
        
        this.createUser(model);

    }

    private async complate() {

        const { phone, phoneError, nameSurname, nameSurnameError, email, emailError, password, passwordError, passwordAgain, passwordAgainError, personalValue, personalError, aggreement, clarificationText, explicitConsentText } = this.state;

        if(aggreement === false){
            this.setState({aggreementError: "Kullanıcı sözleşmesini onaylamanız gerekiyor!"});
        }
        else { this.setState({aggreementError: false}); }

        if(clarificationText === false){
            this.setState({clarificationTextError: "Aydınlatma metnini onaylamanız gerekiyor!"});
        }
        else { this.setState({clarificationTextError: false}); }

        if(password !== passwordAgain){
            this.setState({passwordAgainError: "Girdiğiniz şifreler birbiri ile uyuşmuyor!"});
        }else{
            this.setState({
                passwordError: validate("Şifre", password, ["empty", "password"]),
                passwordAgainError: validate("Şifre Tekrar", passwordAgain, ["empty", "password"])
            });
        }

        this.setState({
            personalError: validate("Çalışan Kişi", personalValue, ["empty"]),
            phoneError: validate("Telefon", phone, ["empty", "phone"]),
            nameSurnameError: validate("Ad Soyad", nameSurname, ["empty"]),
            emailError: validate("E-posta Adresi", email, ["empty", "email"])
        });

        if(phoneError === false && nameSurnameError === false && emailError === false && passwordError === false && passwordAgainError === false && personalError === false && aggreement !== false && clarificationText !== false){

            const model = {
                mobilePhoneNumber: phone,
                nameSurname,
                emailAddress: email,
                password,
                employeeNumber: personalValue, 
                isKullaniciSozlesmesiAccepted: aggreement,
                isAydinlatmaMetniAccepted: clarificationText,
                isAcikRizaMetniAccepted: explicitConsentText,
                token: this.props.params
            }
    
            this.complateUser(model);
        }

    }

    private async createUser(model: any) {
        await registerService.create(model);
        this.setState({ isSuccess: true });
    }

    private async complateUser(model: any) {
        const {isSuccess, errorMessage} = await userService.registrationComplate(model);
        if(isSuccess){
            const {password, email, clientType, partnerCompanyCode} = this.state;
            const response = await userService.login({ password, emailAddress: email, clientType, partnerCompanyCode });
            if (response.isSuccess) this.setState({ isLogin: true });
        }else{
            this.setState({errorMessage});
        }
    }

    

    private redirectToHome() {
        if (this.state.isLogin) return <Redirect to={HOME} />
    }


    private renderCompanyName() {
        const { companyName, companyNameError, isToken, tokenParams } = this.state;
        return (
            <FormControl margin="none" required className="input">
                <TextField
                    id="companyName"
                    label="Şirket Adı"
                    placeholder="Şirket adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeCompanyName}
                    disabled={isToken ? true : false}
                    value={isToken ? tokenParams.companyName : companyName}
                    name="companyName"
                    autoComplete="companyName"
                    helperText={companyNameError}
                />
            </FormControl>
        );
    }


    private renderPersonal() {
        const { personal, personalValue, personalError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" required className="input inputSelect">
                <CustomSelect labelName="personalValue" valueKey="personalValue" options={personal} onChange={this.onChangePersonal} placeholder="Çalışan Sayısı"></CustomSelect>
                <span style={{textAlign:'center', fontSize: 9, marginTop: 5, color: '#9aafcd'}}>{personalError}</span>
            </FormControl>
        );
    }


    private renderCountry() {
        const { countryList, countryId, countryError, isToken, tokenParams } = this.state;

        return (
            <FormControl margin="none" variant="outlined" required className="input inputSelect">
                <CustomSelect labelName="countryName" disabled={isToken} value={countryId} valueKey="id" options={countryList} onChange={this.onChangeCountry} placeholder="Ülke"></CustomSelect>
                <span className="errorMessage">{countryError}</span>
            </FormControl >
        );
    }


    private renderPhone() {
        const { phone, phoneError, countryPhoneCode } = this.state;

        return (
            <FormControl margin="none" required className="input">
                <TextField
                    id="phone"
                    name="phone"
                    autoComplete="phone"
                    value={phone}
                    onChange={this.onChangePhone}
                    label="Telefon"
                    placeholder="Telefonu numaranızı yazınız"
                    margin="normal"
                    variant="outlined"
                    className="countryPhone"
                    helperText={phoneError}
                />
                { countryPhoneCode !== 0 ? <img src={`img/country/${countryPhoneCode}.png`} height="30" className="flag" /> : null}
            </FormControl>
        );
    }


    private renderNameSurname() {
        const { nameSurname, nameSurnameError } = this.state;

        return (
            <FormControl margin="none" required className="input">
                <TextField
                    id="nameSurname"
                    name="nameSurname"
                    autoComplete="nameSurname"
                    value={nameSurname}
                    onChange={this.onChangeNameSurname}
                    label="Ad Soyad"
                    placeholder="Ad Soyad yazınız"
                    margin="normal"
                    variant="outlined"
                    helperText={nameSurnameError}
                />
            </FormControl>
        );
    }


    private renderEmail() {
        const { email, emailError } = this.state;

        return (
            <FormControl margin="none" required className="input">
                <TextField
                    id="email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={this.onChangeEmail}
                    label="E-posta Adresi"
                    placeholder="Eposta adresinizi yazınız"
                    margin="normal"
                    variant="outlined"
                    helperText={emailError}
                />
            </FormControl>
        );
    }


    private renderPassword() {
        const { password, passwordError } = this.state;

        return (
            <FormControl margin="none" required className="input">
                <TextField
                    id="password"
                    name="password"
                    autoComplete="password"
                    value={password}
                    onChange={this.onChangePassoword}
                    label="Şifre"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="********"
                    margin="normal"
                    variant="outlined"
                    helperText={passwordError}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Şifreyi göster"
                                    onClick={this.handleClickShowPassword}
                                >
                                    {this.state.showPassword ? <Icon className="far fa-eye"></Icon> : <Icon className="far fa-eye-slash"></Icon>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
        );
    }


    private renderPasswordAgain() {
        const { passwordAgain, passwordAgainError } = this.state;

        return (
            <FormControl margin="none" required className="input">
                <TextField
                    id="passwordAgain"
                    name="passwordAgain"
                    autoComplete="passwordAgain"
                    value={passwordAgain}
                    onChange={this.onChangePassowordAgain}
                    label="Şifre Tekrar"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="Şifrenizi Tekrar Yazınız"
                    margin="normal"
                    variant="outlined"
                    helperText={passwordAgainError}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Şifreyi göster"
                                    onClick={this.handleClickShowPassword}
                                >
                                    {this.state.showPassword ? <Icon className="far fa-eye"></Icon> : <Icon className="far fa-eye-slash"></Icon>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
        );
    }


    private renderDiscountCode() {
        const { discountCode } = this.state;

        return (
            <FormControl margin="none" required className="input">
                <TextField
                    id="discountCode"
                    name="discountCode"
                    autoComplete="discountCode"
                    value={discountCode}
                    onChange={this.onChangeDiscountCode}
                    label="Varsa İndirim Kodunuz"
                    placeholder="********"
                    margin="normal"
                    variant="outlined"
                />
            </FormControl>
        );
    }


    private renderAggreement() {
        const { aggreement, aggreementError,  } = this.state;

        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="aggreement"
                            name="aggreement"
                            value={aggreement}
                            color="primary"
                            onChange={this.onChangeAggreement}
                        />
                    }
                    label="Kullanıcı sözleşmesini okudum, anladım ve onaylıyorum."
                    className="checkbox"
                />
                <span style={{float:'left', fontSize: 9, marginBottom: 20, color: '#9aafcd', width: '100%'}}>{aggreementError !== false ? aggreementError : null}</span>
            </>
        )
    }

    private renderClarificationText() {
        const { clarificationText, clarificationTextError } = this.state;

        return (
            <>
            <FormControlLabel
                control={
                    <Checkbox
                        id="clarificationText"
                        name="clarificationText"
                        value={clarificationText}
                        color="primary"
                        onChange={this.onChangeClarificationText}
                    />
                }
                label="Aydınlatma metnini okudum, anladım ve onaylıyorum."
                className="checkbox"
            />
            <span style={{float:'left', fontSize: 9, marginBottom: 20, color: '#9aafcd', width: '100%'}}>{clarificationTextError !== false ? clarificationTextError : null}</span>
            </>
        )
    }

    private renderExplicitConsentText() {
        const { explicitConsentText } = this.state;

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        id="explicitConsentText"
                        name="explicitConsentText"
                        value={explicitConsentText}
                        color="primary"
                        onChange={this.onChangeExplicitConsentText}
                    />
                }
                label="Açık rıza metnini okudum, anladım ve onaylıyorum."
                className="checkbox"
            />
        )
    }




    public render() {

        return (

            <Grid container spacing={0} alignItems="center" id="Login">
                <Grid item xs={3} className="Sidebar">

                    <img src="img/sidebarBackground.png" className="topBullets" />
                    <img src="img/sidebarBackground.png" className="bottomBullets" />

                    <img src="img/logo.png" className="logo" />

                    {this.redirectToHome()}
                    {this.state.errorMessage !== "" ? <Alert type="error" message={this.state.errorMessage} /> : null}

                    <div className="content">
                        <h2>Banka Hesap Yönetimi</h2>
                        <p>Tüm bankalardaki bakiyelerinizi, hesap ve pos hareketlerinizi tek bir uygulamadan anlık olarak görüntülemek hiç bu kadar kolay olmamıştı.</p>
                    </div>


                    <div className="footer">
                    {/*<NavLink to={"/"} className="language">
                                <img src="img/tr.png" />
                                <ul className="menu">
                                    <li className="en"><img src="img/en.png" /> English</li>
                                    <li className="tr"><img src="img/tr.png" /> Türkçe</li>
                                </ul>
                            </NavLink>
                        <NavLink to={"/"}>Yardım</NavLink>
                        <NavLink to={"/"}>İletişim</NavLink>*/}
                    </div>

                </Grid>
                <Grid item xs={9} id="LoginForm">
                    <Paper className="Form Register">
                        <h2>finstant'a kayıt ol</h2>
                        <p>Hesabınıza sadece bir adım uzaktasınız.</p>

                        {this.renderCompanyName()}
                        {this.renderPersonal()}
                        {this.renderCountry()}
                        {this.renderPhone()}
                        {this.renderNameSurname()}
                        {this.renderEmail()}
                        {this.renderPassword()}
                        {this.renderPasswordAgain()}
                        {this.renderDiscountCode()}
                        {this.renderAggreement()}
                        {this.state.isToken ? this.renderClarificationText() : null}

                        <Button variant="contained"
                            color="primary"
                            onClick={this.state.isToken ? this.complate : this.register}
                            id="registerButton"
                            disabled={!this.state.isToken}
                        >
                            {this.state.isToken ? "KAYDI TAMAMLA" : "KAYIT OL"}
                        </Button>

                        <p>Zaten bir hesabım var. <NavLink to={"/login"}>Giriş yapın</NavLink></p>
                    </Paper>

                    <p className="copyright">Finstant &copy; 2021</p>
                </Grid>
            </Grid>
        )
    }
}


export default withStyles(styles)(RegisterForm);