import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig, AxiosProxyConfig } from 'axios';
import { userService, cookieService } from '.';

export default class XHRService {

    private readonly service: AxiosInstance;
    private readonly downloadFileRequest: AxiosInstance;
    public readonly baseUrl = "https://api.finstant.co/api/";

    constructor() {

        const service = axios.create({
            baseURL: this.baseUrl,
            responseType: "json",
        });

        const downloadFileRequest = axios.create({
            baseURL: this.baseUrl,
            responseType: "blob",
        });


        service.interceptors.response.use(this.handleSuccess, this.handleError);
        service.interceptors.request.use(this.handleRequest);
        downloadFileRequest.interceptors.response.use(this.handleDownloadSuccess, this.handleError);
        downloadFileRequest.interceptors.request.use(this.handleRequest);


        this.service = service;
        this.downloadFileRequest = downloadFileRequest;
    }

    private handleSuccess(response: AxiosResponse<any>) {
        return response.data;

    }

    private handleDownloadSuccess(response: AxiosResponse<any>) {
        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        let fileName = String(response.config.url).split("/");
        a.download = fileName[4] + '.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        return response.data;
    }

    private handleRequest(config: AxiosRequestConfig) {
        config.headers["Authorization"] = userService.getToken();
        return config;
    }

    private handleError = (error: AxiosError) => {
        switch (error.response && error.response.status) {
            case 401:
                console.error("401")
                userService.logout();
                break;
            case 404:
                console.error("404")
                break;
            default:
                console.error("500")
                break;
        }
        return Promise.reject(error)
    }

    public async get(path: string) {
        return await this.service.get(path)
    }

    public async post(path: string, payload: any) {
        return await this.service.request({
            method: 'POST',
            url: path,
            data: payload,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    public async export(path: string, payload: any) {
        return await this.downloadFileRequest.request({
            method: 'POST',
            url: path,
            data: payload,
            headers: { 'Content-Type': 'application/json' },
        })
    }
}
