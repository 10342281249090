import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { bankFTPCredentialService, locationService } from '../../service';
import { BANKFTPCREDENTIAL } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import Error from '../NotFound/NotFound'

import { bankValidator,  bankAccountValidator, userNameValidator, passwordCodeValidator,  posMerchantCodeValidator, companyValidator } from '../../utils/bankftpcredential.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IBankFTPCredentialFormState {
    bankList: [],
    bankId: any,
    bankError: string,    
    bankAccountList: [],
    bankAccountId: any,
    bankAccountError: any,
    userName: string,
    userNameError: string,
    password: string,
    passwordError: string,
    posMerchantCode: string,
    posMerchantCodeError: string,
    companyList: [],
    companyId: any,
    companyError: string,
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface IBankFTPCredentialFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class BankFTPCredentialForm extends Component {
    public readonly state: IBankFTPCredentialFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            bankList: [],
            bankId: 0,
            bankError: "",
            bankAccountList: [],
            bankAccountId: 0,
            bankAccountError: "",
            userName: "",
            userNameError: "",
            password: "",
            passwordError: "",
            posMerchantCode: "",
            posMerchantCodeError: "",
            companyList: [],
            companyId: 0,
            companyError: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeBank = this.onChangeBank.bind(this);
        this.onChangeBankAccount = this.onChangeBankAccount.bind(this);
        this.onChangeUserName = this.onChangeUserName.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePosMerchantCode = this.onChangePosMerchantCode.bind(this);
        this.onChangeCompany = this.onChangeCompany.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.bankFTPCredentialId) this.getEditableBankFTPCredential();
        else { {/*this.getBanks();*/} this.getCompanies();}

        this.form = React.createRef();
    }

    private async getEditableBankFTPCredential() {
        const { data, isSuccess, errorCode, errorMessage } = await bankFTPCredentialService.getById(this.props.match.params.bankFTPCredentialId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getBanks();
            await this.getCompanies();
            const state = {
                ...data,
                bankId: this.state.bankList.find((item: any) => item.id === data.bankId),
                /*bankAccountId: this.state.bankAccountList.find((item: any) => item.id === data.bankAccountId),*/
                companyId: this.state.companyList.find((item: any) => item.id === data.companyId),
                isEdit: true
            }

            if (isSuccess) this.setState({ ...state});  

            await this.getBankAccounts(state.companyId.id, state.bankAccountId.id, true);
            this.setState({ bankAccountId: this.state.bankAccountList.find((item: any) => item.id === data.bankAccountId), loading: false })
            
            

        }
    }

    /** EVENT LISTENERS */

    private onChangeBank(bankId: any) {
        this.setState({ bankId, bankError: bankValidator(bankId), bankAccountId : 0 });
        //console.log(this.state.companyId.id)
        this.getBankAccounts(this.state.companyId.id, bankId.id, true);
    }

    private onChangeBankAccount(bankAccountId: any) {
        this.setState({ bankAccountId, bankAccountError: bankAccountValidator(bankAccountId)});
    }    

    private onChangeUserName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ userName: e.target.value, userNameError: userNameValidator(e.currentTarget.value) });
    }

    private onChangePassword(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ password: e.target.value, passwordError: passwordCodeValidator(e.currentTarget.value) });
    }   

    private onChangePosMerchantCode(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ posMerchantCode: e.target.value, posMerchantCodeError: posMerchantCodeValidator(e.currentTarget.value) });
    }

    private onChangeCompany(companyId: any) {
        this.setState({ companyId, companyError: companyValidator(companyId) })
        this.getBanks();;

    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(BANKFTPCREDENTIAL);
    }    

    private async getBanks(useDefault: boolean = true) {
        const { data } = await locationService.getBanks();

        const state = {
            bankId: useDefault ? 0 : this.state.bankId.id,
            bankList: data.banks,
            /*loading: false*/
        };

        this.setState(state);

        /*if (this.props.match.params.companyId) await this.getBankAccounts(state.bankId);*/
    {/*await this.getBankAccounts(state.bankId, useDefault);*/}
    }

    private async getBankAccounts(companyId: number, bankId: number, useDefault: boolean) {
        const { data } = await locationService.getBankAccountsCompanyBankDropDown(companyId, bankId);

        const state = {
            bankAccountList: data.bankAccounts,
            bankAccountId: useDefault ? 0 : this.state.bankAccountId.id,
            loading: false
        };

        this.setState(state);
    }

    private async getCompanies() {
        const { data } = await locationService.getCompanies();

        const state = {
            companyId: this.state.companyId === 0 ? 0 : this.state.companyId.id,
            companyList: data.companies,
            loading: false
        };

        this.setState(state);

    }

    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            this.state.errorMessage !== "" ? <Redirect to={{ pathname: BANKFTPCREDENTIAL, state: { errorMessage: this.state.errorMessage } }} /> : null
        );
    }

    private formValidation() {
        const { bankId, bankAccountId, userName, password, companyId } = this.state;

        if (
            bankAccountValidator(bankAccountId) !== "" ||
            userNameValidator(userName) !== "" ||
            passwordCodeValidator(password) !== "" ||
            companyValidator(companyId) !== "" ||
            bankValidator(bankId) !== ""

        ) {

            this.setState({
                bankAccountError: bankAccountValidator(bankAccountId),
                userNameError: userNameValidator(userName),
                passwordError: passwordCodeValidator(password),
                companyError: companyValidator(companyId),
                bankError: bankValidator(bankId)
            });
            return false;
        }

        return true;
    }

    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { bankId, bankAccountId, userName, password, posMerchantCode, companyId, isActive, isEdit } = this.state;

        if (this.formValidation()) {  
        const model = {
                bankId: bankId.id,
                bankAccountId: bankAccountId.id,
                userName: sqlInjectValidator(userName),
                password,
                posMerchantCode: sqlInjectValidator(posMerchantCode),
                companyId: companyId.id,
                isActive
            };

            if (isEdit) await this.editBankFTPCredential(model);
            else await this.createBankFTPCredential(model);
        }
    }

    private async createBankFTPCredential(model: any) {
        const data = await bankFTPCredentialService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editBankFTPCredential(model: any) {
        const data = await bankFTPCredentialService.update({ ...model, id: this.props.match.params.bankFTPCredentialId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }


    private renderBanks() {
        const { bankId, bankList, bankError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="bankName" value={bankId} valueKey="id" options={bankList} onChange={this.onChangeBank} placeholder="Banka *"></CustomSelect>
                <span className="errorMessage">{bankError}</span>
            </FormControl>
        );
    }

    private renderBankAccounts() {
        const { bankAccountId, bankAccountList, bankAccountError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="bankAccountName" value={bankAccountId} valueKey="id" options={bankAccountList} onChange={this.onChangeBankAccount} placeholder="Banka Hesabı *"></CustomSelect>
                <span className="errorMessage">{bankAccountError}</span>
            </FormControl>
        );
    }

    private renderPosMerchantCode() {
        const { posMerchantCode, posMerchantCodeError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="posMerchantCode"
                    label="Üye İşyeri No"
                    placeholder="Üye İşyeri No yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangePosMerchantCode}
                    value={posMerchantCode}
                    name="posMerchantCode"
                    autoComplete="posMerchantCode"
                    helperText={posMerchantCodeError}
                />
            </FormControl>
        );
    }

    private renderUserName() {
        const { userName, userNameError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="userName"
                    label="Kullanıcı Adı"
                    placeholder="Kullanıcı adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeUserName}
                    value={userName}
                    name="userName"
                    autoComplete="userName"
                    helperText={userNameError}
                />
            </FormControl>
        );
    }

    private renderPassword() {
        const { password, passwordError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="password"
                    label="Şifre Kodu"
                    placeholder="Şifre kodu yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangePassword}
                    value={password}
                    helperText={passwordError}
                    name="password"
                    autoComplete="password"
                />
            </FormControl>
        );
    }

    private renderCompanies() {
        const { companyId, companyList, companyError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" required className="input inputSelect">
                <CustomSelect labelName="companyName" value={companyId} valueKey="id" options={companyList} onChange={this.onChangeCompany} placeholder="Firma *"></CustomSelect>
                <span className="errorMessage">{companyError}</span>
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: BANKFTPCREDENTIAL, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">FTP Erişim Bilgileri {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan ftp erişim bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda ftp erişim bilgileri ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">FTP Erişim Bilgileri {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>                                    
                                    {this.renderCompanies()}
                                    {this.renderBanks()}
                                    {this.renderBankAccounts()}
                                    {this.renderPosMerchantCode()}
                                    {this.renderUserName()}
                                    {this.renderPassword()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(BankFTPCredentialForm);
