import React, { Component, ChangeEvent } from 'react'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid"
import Icon from "@material-ui/core/Icon"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableFooter from "@material-ui/core/TableFooter"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { cookieService, resourceService, posStatementsService, bankAccountService, userService } from '../../service';
import { POSSTATEMENT, VALORDATE } from '../../routes/paths'
import CustomSelect from '../layout/CustomSelect';

import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

interface IPosStatementsState {
    list: [],
    loading: boolean;
    focusedInput: any;
    totalCount: number;
    searchTotalCount: number;
    currentPage: number;
    sortType: string;
    searchText: string;
    clientType: string,
    selectedRow: number;
    orderBy: number;
    accountList: [];
    account: any;
    startDate: any;
    endDate: any;
    isExcelAndPdfExport: boolean;
    resources: any;
}



const valorDateView = (props: any) => <Link to={VALORDATE} {...props} />;


export default class PosStatements extends Component {
    public readonly state: IPosStatementsState;
    public props: any;

    private timeout = 3000;
    private interval: any;

    public readonly LANGUAGE_NAME = 'LANGUAGE_TOKEN';

    constructor(props: any) {
        super(props);
        this.props = props;

        this.state = {
            account: {},
            accountList: [],
            list: [],
            //startDate: moment().startOf('month'),
            //endDate: moment().endOf('month'),    
            startDate: moment().add(-7,'day'),
            endDate: moment().add(1,'day'),
            loading: false,
            focusedInput: null,
            totalCount: 0,
            searchTotalCount: 0,
            currentPage: 0,
            sortType: "asc",
            searchText: "",
            clientType: "WEB",
            selectedRow: 25,
            orderBy: 0,
            isExcelAndPdfExport: false,
            resources: []
        }

        this.getData = this.getData.bind(this);
        this.onChangeAccount = this.onChangeAccount.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.updateList = this.updateList.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
        this.getExportExcel = this.getExportExcel.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentWillMount() {
        this.getPermissions();
    }

    private async getPermissions() {
        const { data } = await userService.permission();
        this.setState({ isExcelAndPdfExport: data.isExcelAndPdfExport });

        this.getResources();
    }


    private async getResources() {
        const resourceLanguage: any = cookieService.resourceItem(this.LANGUAGE_NAME);
        const { data } = await resourceService.language(resourceLanguage);
        var resources: any = {};
        data.resources.forEach((item: any) => {
            resources[item.resourceKey] = item.value;
        });

        this.setState({ resources: resources });
    }

    private onChangeAccount(account: any) {
        this.setState({ account });
        //this.getData(undefined, undefined, account.id)
    }

    private updateList() {
        this.setState({ loading: true, selectedRow: 25, currentPage: 0, orderBy: 0, totalCount: 0 });
        const accountId = this.state.account ? this.state.account.id : 0;
        this.getData(undefined, undefined, accountId, this.state.searchText, this.state.clientType, this.state.selectedRow, this.state.currentPage, this.state.orderBy);
    }

    private async init() {
        this.setState({ loading: true });

        await Promise.all([this.getData(),
        this.getAccounts()])

        if (this.props.accountId) {
            this.setState({ account: this.state.accountList.find((account: any) => account.id === Number(this.props.accountId)) })
        }

    }

    private handleChangePage(_: any, page: number) {
        this.setState({ currentPage: page, loading: true });
        this.getData(this.state.startDate, this.state.endDate, this.state.account.id, this.state.searchText, this.state.clientType, this.state.selectedRow, page, this.state.orderBy);
    }

    private handleSort(orderBy: number) {
        this.setState({ orderBy, loading: true });
        this.getData(this.state.startDate, this.state.endDate, this.state.account.id, this.state.searchText, this.state.clientType, this.state.selectedRow, this.state.currentPage, orderBy);
    }

    private handleSearch({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        this.setState({ searchText: value });
        this.getData(this.state.startDate, this.state.endDate, this.state.account.id, value, this.state.clientType, this.state.selectedRow, this.state.currentPage, this.state.orderBy);
    }

    private handleRowsPerPageChange({ target: { value } }: any) {
        const selectedRow = Number(value);
        this.setState({ selectedRow, loading: true });
        this.getData(this.state.startDate, this.state.endDate, this.state.account.id, this.state.searchText,this.state.clientType,  selectedRow, this.state.currentPage, this.state.orderBy);
    }

    private async getData(startDate?: number, endDate?: number, id?: number, searchText?: string, clientType?: string, rowsPerPage?: number, pageNumber?: number, orderBy?: number) {
        const unixStartDate = startDate ? startDate : this.state.startDate.format('MM.DD.YYYY');
        const unixEndDate = endDate ? endDate : this.state.endDate.format('MM.DD.YYYY');
        const accountId = Number(id == undefined ? 0 : id);
        const search = String(searchText ? searchText : this.state.searchText);
        const client = String(clientType ? clientType : this.state.clientType);
        const selectedRow = Number(rowsPerPage ? rowsPerPage : this.state.selectedRow);
        const currentPage = Number(pageNumber ? pageNumber : this.state.currentPage);
        const order = Number(orderBy ? orderBy : this.state.orderBy);
        const { data } = await posStatementsService.get(unixStartDate, unixEndDate, accountId, search, client, selectedRow, currentPage, order);
        this.setState({ list: data.posStatements, loading: false, totalCount: data.totalCount, searchTotalCount: data.searchTotalCount })
    }

    private async getAccounts() {
        const { data } = await bankAccountService.get("", "WEB", 0, 999, 0, true, true);
        this.setState({ accountList: data.bankAccounts, })
    }

    private async getExportExcel() {
        return await posStatementsService.download(this.state.startDate, this.state.endDate, this.state.account.id, this.state.searchText, this.state.clientType);
    }

    private renderPagination() {
        const { searchTotalCount, selectedRow, currentPage } = this.state;
        let pageCount = 1;
        let pages = [];
        searchTotalCount <= selectedRow ? pageCount = 1 : pageCount = (searchTotalCount / selectedRow)

        for (let i = 1; i <= Math.ceil(pageCount); i++) {
            if (currentPage + 1 < i + 3 && currentPage + 1 > i - 3) {
                pages[i - 1] = i;
            }
        }

        const listItems = pages.map((page) =>
            <>
                {currentPage + 1 === page ? <a onClick={() => this.handleChangePage(null, page - 1)} style={{ color: '#408af8' }}>{page}</a> : <a onClick={() => this.handleChangePage(null, page - 1)}>{page}</a>}
            </>
        );

        return listItems;
    }

    private renderAccounts() {
        const { accountList, account } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input">
                <InputLabel htmlFor="account-select">Hesap</InputLabel>
                <CustomSelect value={this.props.accountId ? this.state.account : ""} labelName="description" valueKey="id" options={accountList} onChange={this.onChangeAccount} placeholder="Hesap Seçiniz"></CustomSelect>
            </FormControl >
        );
    }

    private onDatesChange({ startDate, endDate }: any) {
        this.setState({ startDate, endDate });
    }

    private renderRowHeaders() {
        const { orderBy } = this.state;
        return (
            <TableRow>
                <TableCell onClick={() => this.handleSort(orderBy === 1 ? 2 : 1)}>
                    <TableSortLabel active={orderBy === 1 || orderBy === 2} direction={orderBy === 1 ? "desc" : "asc"}>
                        İşlem Tarihi
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 3 ? 4 : 3)}>
                    <TableSortLabel active={orderBy === 3 || orderBy === 4} direction={orderBy === 4 ? "desc" : "asc"}>
                        Valor Tarihi
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 5 ? 6 : 5)}>
                    <TableSortLabel active={orderBy === 5 || orderBy === 6} direction={orderBy === 6 ? "desc" : "asc"}>
                        İşlem
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 7 ? 8 : 7)} style={{ textAlign: 'right' }}>
                    <TableSortLabel active={orderBy === 7 || orderBy === 8} direction={orderBy === 8 ? "desc" : "asc"}>
                        Net Tutar
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 9 ? 10 : 9)} style={{ textAlign: 'right' }}>
                    <TableSortLabel active={orderBy === 9 || orderBy === 10} direction={orderBy === 10 ? "desc" : "asc"}>
                        Brüt Tutar
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 11 ? 12 : 11)} style={{ textAlign: 'right' }}>
                    <TableSortLabel active={orderBy === 11 || orderBy === 12} direction={orderBy === 12 ? "desc" : "asc"}>
                        Komisyon
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 13 ? 14 : 13)}>
                    <TableSortLabel active={orderBy === 13 || orderBy === 14} direction={orderBy === 14 ? "desc" : "asc"}>
                        İşyeri
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 15 ? 16 : 15)}>
                    <TableSortLabel active={orderBy === 15 || orderBy === 16} direction={orderBy === 16 ? "desc" : "asc"}>
                        Şube
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 17 ? 18 : 17)}>
                    <TableSortLabel active={orderBy === 17 || orderBy === 18} direction={orderBy === 18 ? "desc" : "asc"}>
                        Hesap
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 19 ? 20 : 19)}>
                    <TableSortLabel active={orderBy === 19 || orderBy === 20} direction={orderBy === 20 ? "desc" : "asc"}>
                        Kart Numarası
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 21 ? 22 : 21)}>
                    <TableSortLabel active={orderBy === 21 || orderBy === 22} direction={orderBy === 22 ? "desc" : "asc"}>
                        Terminal
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 23 ? 24 : 23)}>
                    <TableSortLabel active={orderBy === 23 || orderBy === 24} direction={orderBy === 24 ? "desc" : "asc"}>
                        BatchN
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 25 ? 26 : 25)}>
                    <TableSortLabel active={orderBy === 25 || orderBy === 26} direction={orderBy === 26 ? "desc" : "asc"}>
                        Onay No
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 27 ? 28 : 27)}>
                    <TableSortLabel active={orderBy === 27 || orderBy === 28} direction={orderBy === 28 ? "desc" : "asc"}>
                        Ürün Sınıfı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 29 ? 30 : 29)}>
                    <TableSortLabel active={orderBy === 29 || orderBy === 30} direction={orderBy === 30 ? "desc" : "asc"}>
                        Kur
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 31 ? 32 : 31)} style={{ textAlign: 'right' }}>
                    <TableSortLabel active={orderBy === 31 || orderBy === 32} direction={orderBy === 32 ? "desc" : "asc"}>
                        Kur Bilgisi
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 33 ? 34 : 33)} style={{ textAlign: 'right' }}>
                    <TableSortLabel active={orderBy === 33 || orderBy === 34} direction={orderBy === 34 ? "desc" : "asc"}>
                        SM Kur Bilgisi
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 35 ? 36 : 35)}>
                    <TableSortLabel active={orderBy === 35 || orderBy === 36} direction={orderBy === 36 ? "desc" : "asc"}>
                        İşyeri Adı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 37 ? 38 : 37)}>
                    <TableSortLabel active={orderBy === 37 || orderBy === 38} direction={orderBy === 38 ? "desc" : "asc"}>
                        Kayıt Tarihi
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 39 ? 40 : 39)}>
                    <TableSortLabel active={orderBy === 39 || orderBy === 40} direction={orderBy === 40 ? "desc" : "asc"}>
                        Kayıt Saati
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 41 ? 42 : 41)}>
                    <TableSortLabel active={orderBy === 41 || orderBy === 42} direction={orderBy === 42 ? "desc" : "asc"}>
                        Firma
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 43 ? 44 : 43)}>
                    <TableSortLabel active={orderBy === 43 || orderBy === 44} direction={orderBy === 44 ? "desc" : "asc"}>
                        IBAN
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 45 ? 46 : 45)}>
                    <TableSortLabel active={orderBy === 45 || orderBy === 46} direction={orderBy === 46 ? "desc" : "asc"}>
                        RetRefNo
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 47 ? 48 : 47)}>
                    <TableSortLabel active={orderBy === 47 || orderBy === 48} direction={orderBy === 48 ? "desc" : "asc"}>
                        Sipariş No
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 49 ? 50 : 49)}>
                    <TableSortLabel active={orderBy === 49 || orderBy === 50} direction={orderBy === 50 ? "desc" : "asc"}>
                        Ödül Katkısı
                    </TableSortLabel>
                </TableCell>                
                <TableCell onClick={() => this.handleSort(orderBy === 53 ? 54 : 53)}>
                    <TableSortLabel active={orderBy === 53 || orderBy === 54} direction={orderBy === 54 ? "desc" : "asc"}>
                        Ekstra Ödül Katkısı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 55 ? 56 : 55)}>
                    <TableSortLabel active={orderBy === 55 || orderBy === 56} direction={orderBy === 56 ? "desc" : "asc"}>
                        SM Ödül Katkısı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 57 ? 58 : 57)}>
                    <TableSortLabel active={orderBy === 57 || orderBy === 58} direction={orderBy === 57 ? "desc" : "asc"}>
                        Servis Komisyonu
                    </TableSortLabel>
                </TableCell>                
                <TableCell onClick={() => this.handleSort(orderBy === 59 ? 60 : 59)}>
                    <TableSortLabel active={orderBy === 59 || orderBy === 60} direction={orderBy === 60 ? "desc" : "asc"}>
                        İşyeri Sosyal Katkısı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 61 ? 62 : 61)}>
                    <TableSortLabel active={orderBy === 61 || orderBy === 62} direction={orderBy === 62 ? "desc" : "asc"}>
                        Program Ücreti
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 63 ? 64 : 63)}>
                    <TableSortLabel active={orderBy === 63 || orderBy === 64} direction={orderBy === 64 ? "desc" : "asc"}>
                        Erken Ödeme Tutarı
                    </TableSortLabel>
                </TableCell>                
                <TableCell onClick={() => this.handleSort(orderBy === 65 ? 66 : 65)}>
                    <TableSortLabel active={orderBy === 65 || orderBy === 66} direction={orderBy === 66 ? "desc" : "asc"}>
                        Erken Bloke Komisyonu
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 67 ? 68 : 67)}>
                    <TableSortLabel active={orderBy === 67 || orderBy === 68} direction={orderBy === 68 ? "desc" : "asc"}>
                        İskonto Tutarı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 69 ? 70 : 69)}>
                    <TableSortLabel active={orderBy === 69 || orderBy === 70} direction={orderBy === 70 ? "desc" : "asc"}>
                        Taksit Sayısı
                    </TableSortLabel>
                </TableCell>                
                <TableCell onClick={() => this.handleSort(orderBy === 71 ? 72 : 71)}>
                    <TableSortLabel active={orderBy === 71 || orderBy === 72} direction={orderBy === 72 ? "desc" : "asc"}>
                        Taksit Sırası
                    </TableSortLabel>
                </TableCell>
                
                <TableCell onClick={() => this.handleSort(orderBy === 73 ? 74 : 73)}>
                    <TableSortLabel active={orderBy === 73 || orderBy === 74} direction={orderBy === 74 ? "desc" : "asc"}>
                        Banka Adı
                    </TableSortLabel>
                </TableCell>                
                <TableCell onClick={() => this.handleSort(orderBy === 75 ? 76 : 75)}>
                    <TableSortLabel active={orderBy === 75 || orderBy === 76} direction={orderBy === 76 ? "desc" : "asc"}>
                        Hesap Adı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 77 ? 78 : 77)}>
                    <TableSortLabel active={orderBy === 77 || orderBy === 78} direction={orderBy === 78 ? "desc" : "asc"}>
                        Firma Adı
                    </TableSortLabel>
                </TableCell>

            </TableRow >
        );
    }

    private renderTable() {
        const { list, isExcelAndPdfExport, sortType, startDate, endDate, currentPage, searchText, totalCount, searchTotalCount, selectedRow, resources, loading } = this.state;
        return (

            <>
                <Grid container>
                    <Grid xs={12}>
                        <Paper id="DatePicker">
                            {this.renderAccounts()}
                            <DateRangePicker
                                startDate={startDate}
                                startDateId="accountStatementStartDateId"
                                endDate={endDate}
                                endDateId="accountStatementEndDateId"
                                onDatesChange={this.onDatesChange}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                startDatePlaceholderText="DD"
                                endDatePlaceholderText="DD"
                                isOutsideRange={() => false}
                                displayFormat="DD.MM.YYYY"
                                monthFormat="MMMM YYYY"

                            />
                            <Button className="button" onClick={this.updateList}>Tamam</Button>
                        </Paper>
                        <div className="contentHeader" style={{ border: 'none', marginTop: '-73px' }}>
                            {!isExcelAndPdfExport ? null :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="actionsButton"
                                    style={{ float: 'right' }}
                                    fullWidth
                                    type="submit">
                                    EYLEMLER <Icon className="fas fa-ellipsis-v"></Icon>
                                    <ul className="actions">
                                        <h6>HIZLI EYLEMLER</h6>
                                        <li onClick={() => this.getExportExcel()}><Icon className="far fa-file-excel"></Icon> Excel'e aktar</li>
                                    </ul>
                                </Button>
                            }
                            <Button
                                variant="contained"
                                color="primary"
                                className="actionsButton"
                                component={valorDateView}
                                style={{ float: 'right', paddingLeft: 10, paddingRight: 10, minWidth: 140, backgroundColor: '#408af8', paddingBottom: 5 }}
                                type="submit">
                                <span style={{ color: '#fff', textTransform: 'uppercase' }}>{resources.MenuLeftmenu1_5_2}</span>
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <Grid container style={{ height: 80 }}>
                    <Grid xs={10}>
                        <TablePagination
                            id="TopPagination"
                            rowsPerPageOptions={[25, 50, 100, 250]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={selectedRow}
                            onChangeRowsPerPage={this.handleRowsPerPageChange}
                            page={currentPage}
                            labelDisplayedRows={() => false}
                            labelRowsPerPage={resources.LabelViewData}
                            onChangePage={() => false}
                            style={{ marginTop: 20 }}
                        />
                    </Grid>
                    <Grid xs={2}>
                        <FormControl margin="none" fullWidth className="input searchBox" required>
                            <TextField
                                id="search"
                                placeholder="Arama"
                                margin="normal"
                                variant="outlined"
                                onChange={this.handleSearch}
                                value={searchText}
                                name="search"
                                autoComplete="search"
                                style={{ marginTop: 40 }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ minWidth: '100%', overflowX: 'auto' }}>
                    <Table style={{ width: 6000 }}>
                        <TableHead>
                            {this.renderRowHeaders()}
                        </TableHead>
                        <TableBody>
                            {list.map((company: any) => (
                                <TableRow key={company.id}>
                                    <TableCell scope="row">{company.islemTarihi.substring(0, 10)}</TableCell>
                                    <TableCell>{company.valorTarihi.substring(0, 10)}</TableCell>
                                    <TableCell>{company.islem}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 100 }}>{company.netTutar}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 100 }}>{company.brutTutar}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 100 }}>{company.komisyon}</TableCell>
                                    <TableCell>{company.isyeri}</TableCell>
                                    <TableCell>{company.sube}</TableCell>
                                    <TableCell>{company.hesap}</TableCell>
                                    <TableCell>{company.kartNumarasi}</TableCell>
                                    <TableCell>{company.terminal}</TableCell>
                                    <TableCell>{company.batchn}</TableCell>
                                    <TableCell>{company.onayNo}</TableCell>
                                    <TableCell>{company.urunSinifi}</TableCell>
                                    <TableCell>{company.kur}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 100 }}>{company.kurBilgisi}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 100 }}>{company.smKurBilgisi}</TableCell>
                                    <TableCell>{company.isYeriAdi}</TableCell>
                                    <TableCell>{company.kayitTarihi.substring(0, 10)}</TableCell>
                                    <TableCell>{company.kayitSaati}</TableCell>
                                    <TableCell>{company.firma}</TableCell>
                                    <TableCell>{company.iban}</TableCell>
                                    <TableCell>{company.retRefNo}</TableCell>
                                    <TableCell>{company.siparisNo}</TableCell>                                    
                                    <TableCell style={{ textAlign: 'right', minWidth: 40 }}>{company.odulKatkisi}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 40 }}>{company.ekstraOdulKatki}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 40 }}>{company.smOdulKatki}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 40 }}>{company.servisKomisyonu}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 40 }}>{company.isyeriSosyalKatki}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 40 }}>{company.programUcreti}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 40 }}>{company.erkenOdemeTutari}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 40 }}>{company.erkenBlokeKomisyonu}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 40 }}>{company.iskontoTutari}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 40 }}>{company.taksitSayisi}</TableCell>
                                    <TableCell style={{ textAlign: 'right', minWidth: 40 }}>{company.taksitSirasi}</TableCell>
                                    <TableCell>{company.bankName}</TableCell>
                                    <TableCell>{company.bankAccountName}</TableCell>
                                    <TableCell style={{ minWidth: 250 }}>{company.companyName}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </div>
                <TablePagination
                    id="Pagination"
                    rowsPerPageOptions={[0]}
                    component="div"
                    count={searchTotalCount}
                    rowsPerPage={selectedRow}
                    onChangeRowsPerPage={this.handleRowsPerPageChange}
                    page={currentPage}
                    labelDisplayedRows={({ from, to, count }) => `${count} ${resources.LabelPagination2} ${from} - ${to} ${resources.LabelPagination3}.`}
                    labelRowsPerPage="Görüntülenen Kayıt"
                    backIconButtonProps={{
                        'aria-label': 'Önceki Sayfa',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Sonraki Sayfa',
                    }}
                    onChangePage={this.handleChangePage}
                />
                <span className="total">{"(" + totalCount + " " + resources.LabelPagination1 + ")"}</span>
                <div id="PageList">
                    {currentPage > 0 ? <a onClick={() => this.handleChangePage(null, this.state.currentPage - 1)}>{resources.LabelPrevious}</a> : null}
                    {currentPage > 3 ? <><a onClick={() => this.handleChangePage(null, 0)}>1</a><a >...</a></> : null}

                    {loading === false ? this.renderPagination() : null}

                    {(currentPage + 1) < (Math.floor((searchTotalCount / selectedRow)) - 2) ? <a >...</a> : null}

                    {(currentPage) + 1 < (Math.ceil((searchTotalCount / selectedRow)) - 2) ? <a onClick={() => this.handleChangePage(null, Math.ceil((searchTotalCount / selectedRow) - 1))}>{Math.ceil((searchTotalCount / selectedRow))}</a> : null}
                    {(currentPage + 1) < (Math.ceil(searchTotalCount / selectedRow)) ? <a onClick={() => this.handleChangePage(null, this.state.currentPage + 1)}> {resources.LabelNext}</a> : null}
                </div>
            </>
        )
    }

    render() {
        return (
            <Paper>
                {this.state.loading ? <CircularProgress className="progress" /> : this.renderTable()}
            </Paper>
        )
    }
}
