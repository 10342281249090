import XHRService from "./xhr.service";

export default interface ICurrencyRequest {
    id?: number;
    currencyName: string;
    currencyCode: string;
    searchText: string;
    isActive: boolean;
}


interface ICurrency {
    id: number;
    currencyName: string;
    currencyCode: string;
    searchText: string;
    isActive: boolean;
}

interface ICurrencyResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: ICurrency
}

interface ICurrencyListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        currencies: ICurrency[]
    }
}

export default class CurrencyService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<ICurrencyResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Currency/CreateCurrency", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage }
        return data;
    }

    public async update(model: ICurrencyRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Currency/UpdateCurrency", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<ICurrencyResponse> {
        const { isSuccess }: any = await this.XHRService.post("/Currency/DeleteCurrency", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            isActive,
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/Currency/GetCurrencies", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/Currency/GetCurrenciesExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/Currency/GetCurrencyById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
