import XHRService from "./xhr.service";

export default interface IRegisterRequest {
    companyId: number;
    nameSurname: string;
    emailAddress: string;
    password: string;
    mobilePhoneNumber: string;
    isActive: boolean;
}

interface IRegisterResponse {
    companyId: number;
    nameSurname: string;
    emailAddress: string;
    password: string;
    mobilePhoneNumber: string;
    isActive: boolean;
}

export default class RegisterService {
    private readonly XHRService: XHRService;


    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }


    public async create(model: any): Promise<IRegisterResponse> {
        const { isSuccess }: any = await this.XHRService.post("/Company/CreateCompany", model).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }



}
