import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/';

import { accountBalancesService } from '../../service';
import Paper from '@material-ui/core/Paper/Paper';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

import Chart, {
    Series,
    Label,
    Connector,
    Size,
    Export,
    Margin
} from 'devextreme-react/chart';

interface IBalanceChartProps {
    currencyId: number;
    name: string;
    classes: any;
}

interface IBalanceChartState {
    data: [];
    companyBalance: [];
    loading: boolean;
}

const style = (theme: any) => ({
    wrapper: {
        display: "flex",
        width: "100%"
    },
    chart: {
        marginRight: "10px",
        width: "100%",
        backgroundColor: "#fff"
    }
})

class BalanceChart extends Component {
    public props: IBalanceChartProps;
    public state: IBalanceChartState;

    constructor(props: IBalanceChartProps) {
        super(props);
        this.props = props;
        this.state = {
            data: [],
            companyBalance: [],
            loading: false
        }
    }

    componentDidMount() {
        this.getData();
    }

    private async getData() {
        this.setState({ loading: true });
        const { data } = await accountBalancesService.getAccountBalances(this.props.currencyId);
        const { companyBalance } = await accountBalancesService.getAccountBalanceTotalsByCompany(this.props.currencyId);
        this.setState({ data: data.accountBalances, loading: false });
    }

    private renderCharts() {
        const { data } = this.state;
        const { classes } = this.props;

        return (
            <>
                <div className={classes.chart}>
                    <Chart
                        id={'chart'}
                        dataSource={data}
                        palette={'Soft Blue'}
                        title={this.props.name}
                    >
                        <Series
                            argumentField={'bankName'}
                            valueField={'balance'}
                            type={'bar'}
                            name="Bakiye"
                        >
                            <Label visible={true}>
                                <Connector visible={true} width={1} />
                            </Label>
                        </Series>
                        <Margin top={-300} />
                        <Size width={'100%'} />
                        <Export enabled={false} />
                    </Chart >

                </div>
            </>);
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper>
                {this.state.loading ? <CircularProgress className="progress" /> : <div className={classes.wrapper}>{this.renderCharts()}</div>}
            </Paper>

        )
    }
}
export default withStyles(style)(BalanceChart);