import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';
import moment from 'moment';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { partnerService, locationService } from '../../service';
import { PARTNER } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import { partnerNameValidator, contactPersonValidator, emailAddressValidator, mobilePhoneNumberValidator, countryValidator, cityValidator, districtValidator, addressValidator, userValidator } from '../../utils/partner.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface IPartnerFormState {
    partnerName: string;
    partnerNameError: string;
    contactPerson: string;
    contactPersonError: string;
    emailAddress: string;
    emailAddressError: string; 
    mobilePhoneNumber: string;
    mobilePhoneNumberError: string;
    countryList: [],
    countryId: any,
    countryError: string,
    cityList: [],
    cityId: any,
    cityError: any,
    districtList: [],
    districtId: any,
    districtError: string, 
    address: string;
    addressError: string;
    description: string;
    userList: [],
    userId: any,
    userError: string,    
    isActive: boolean;      
    isEdit: boolean,
    loading: boolean,
    isSuccess: string,
    errorCode: string,
    errorMessage: string
}


const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});


export interface IPartnerFormProps {
    classes: any;
}

class PartnerForm extends Component {
    public readonly state: IPartnerFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            partnerName: "",
            partnerNameError: "",
            contactPerson: "",
            contactPersonError: "",
            emailAddress: "",
            emailAddressError: "",
            mobilePhoneNumber: "",
            mobilePhoneNumberError: "",
            countryList: [],
            countryId: 0,
            countryError: "",
            cityList: [],
            cityId: 0,
            cityError: "",
            districtList: [],
            districtId: 0,
            districtError: "", 
            address: "",
            addressError: "",
            description: "",
            userList: [],
            userId: 0,
            userError: "", 
            isActive: true,
            isEdit: false,
            loading: true,
            isSuccess: "",
            errorCode: "",
            errorMessage: ""
        };

        this.onChangePartnerName = this.onChangePartnerName.bind(this);
        this.onChangeContactPerson = this.onChangeContactPerson.bind(this);
        this.onChangeEmailAddress = this.onChangeEmailAddress.bind(this);
        this.onChangeMobilePhoneNumber = this.onChangeMobilePhoneNumber.bind(this);       
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeDistrict = this.onChangeDistrict.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.partnerId) this.getEditablePartner();
        else { this.getCountries(0); this.getUsers(); this.setState({ loading: false }); }

        this.form = React.createRef();
    }




    private async getEditablePartner() {
        const { data } = await partnerService.getById(this.props.match.params.partnerId);

        await this.getCountries(data.countryId);
        await this.getUsers();

        const state = {
            ...data,
            isEdit: true,
            countryId: this.state.countryList.find((item: any) => item.id === data.countryId),
            cityId: this.state.cityList.find((item: any) => item.id === data.cityId),
            userId: this.state.userList.find((item: any) => item.id === data.userId),
        };

        this.setState({ ...state, loading: false });

        await this.getDistricts(state.countryId.id, state.cityId.id);
        this.setState({ districtId: this.state.districtList.find((item: any) => item.id === data.districtId), loading: false })


    }

    /** EVENT LISTENERS */
   

    private onChangePartnerName(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ partnerName: e.currentTarget.value, partnerNameError: partnerNameValidator(e.currentTarget.value) });
    }

    private onChangeContactPerson(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ contactPerson: e.currentTarget.value, contactPersonError: contactPersonValidator(e.currentTarget.value) });
    }

    private onChangeEmailAddress(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ emailAddress: e.target.value, emailAddressError: emailAddressValidator(e.currentTarget.value) });
    }

    private onChangeMobilePhoneNumber(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ mobilePhoneNumber: e.currentTarget.value, mobilePhoneNumberError: mobilePhoneNumberValidator(e.currentTarget.value) });
    }

    private onChangeCountry(countryId: any) {
        this.setState({ countryId, countryError: countryValidator(countryId), cityId: 0, districtId: 0 });
        this.getCities(countryId.id);
    }

    private onChangeCity(cityId: any) {
        this.setState({ cityId, cityError: cityValidator(cityId), districtId: 0 });
        this.getDistricts(this.state.countryId.id, cityId.id);
    }

    private onChangeDistrict(districtId: any) {
        this.setState({ districtId, districtError: districtValidator(districtId) });
    }

    private onChangeAddress(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ address: e.currentTarget.value, addressError: addressValidator(e.currentTarget.value) });
    }

    private onChangeDescription(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ description: e.currentTarget.value});
    }

    private onChangeUser(userId: any) {
        this.setState({ userId, userError: userValidator(userId)});
    }    

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.currentTarget.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(PARTNER);
    }

    private async getCountries(countryId?: number) {
        const { data } = await locationService.getCountries();

        const state = {
            countryId: this.state.countryId === 0 ? countryId : this.state.countryId.id,
            countryList: data.countries,
            loading: false
        };

        this.setState(state);

        if (this.props.match.params.partnerId) await this.getCities(state.countryId);
    }

    private async getCities(countryId: number) {
        const { data } = await locationService.getCities(countryId);

        const state = {
            cityList: data.cities,
            cityId: this.state.cityId === 0 ? 0 : this.state.cityId.id
        };

        this.setState(state);

        if (this.props.match.params.partnerId) await this.getDistricts(countryId, state.cityId);
    }

    private async getDistricts(countryId: number, cityId: number) {
        const { data } = await locationService.getDistricts(countryId, cityId);

        const state = {
            districtList: data.districts,
            districtId: this.state.districtId === 0 ? 0 : this.state.districtId.id
        }

        this.setState(state);
    }

    private async getUsers(useDefault: boolean = true) {
        const { data } = await locationService.getUsersOfPartners();

        const state = {
            userId: useDefault ? 0 : this.state.userId.id,
            userList: data.users
        };

        this.setState(state);
    }
    
    private formValidation() {
        const { partnerName, contactPerson, emailAddress, mobilePhoneNumber, countryId, cityId, districtId, address, userId } = this.state;

        if (
            partnerNameValidator(partnerName) !== "" ||
            contactPersonValidator(contactPerson) !== "" ||
            emailAddressValidator(emailAddress) !== "" ||
            mobilePhoneNumberValidator(mobilePhoneNumber) !== "" ||
            countryValidator(countryId) !== "" ||
            cityValidator(cityId) !== "" ||
            districtValidator(districtId) !== "" ||
            addressValidator(address) !== "" ||
            userValidator(userId) !== ""
        ) {

            this.setState({
                partnerNameError: partnerNameValidator(partnerName),
                contactPersonError: contactPersonValidator(contactPerson),
                emailAddressError: emailAddressValidator(emailAddress),
                mobilePhoneNumberError: mobilePhoneNumberValidator(mobilePhoneNumber),
                countryError: countryValidator(countryId),
                cityError: cityValidator(cityId),
                districtError: districtValidator(districtId),
                addressError: addressValidator(address),
                userError: userValidator(userId)
            });
            return false;
        }

        return true;
    }

    private async saveChanges() {

        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });

        const { partnerName, contactPerson, emailAddress, mobilePhoneNumber, countryId, cityId, districtId, address, description, userId, isActive, isEdit } = this.state;


        if (this.formValidation()) {


            const model = {
                partnerName: sqlInjectValidator(partnerName),
                contactPerson: sqlInjectValidator(String(contactPerson)),
                emailAddress: sqlInjectValidator(emailAddress),
                mobilePhoneNumber: sqlInjectValidator(mobilePhoneNumber),
                countryId: sqlInjectValidator(String(countryId.id)),
                cityId: sqlInjectValidator(String(cityId.id)),
                districtId: sqlInjectValidator(String(districtId.id)),
                address: sqlInjectValidator(address),
                description: sqlInjectValidator(description),
                userId: sqlInjectValidator(String(userId.id)),
                isActive
            };



            if (isEdit) await this.editPartner(model);
            else await this.createPartner(model);
        }

    }

    private async createPartner(model: any) {
        const data = await partnerService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editPartner(model: any) {
        const data = await partnerService.update({ ...model, id: this.props.match.params.partnerId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private renderPartnerName() {
        const { partnerName, partnerNameError } = this.state;

        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="partnerName"
                    label="Şirket Adı"
                    placeholder="Şirket adı yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangePartnerName}
                    value={partnerName}
                    name="partnerName"
                    autoComplete="partnerName"
                    helperText={partnerNameError}
                />
            </FormControl>
        );
    }

    private renderContactPerson() {
        const { contactPerson, contactPersonError } = this.state;

        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="contactPerson"
                    label="İrtibat Kişisi"
                    placeholder="İrtibat Kişisi yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeContactPerson}
                    value={contactPerson}
                    name="contactPerson"
                    autoComplete="contactPerson"
                    helperText={contactPersonError}
                />
            </FormControl>
        );
    }

    private renderEmailAddress() {
        const { emailAddress, emailAddressError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    type="email"
                    id="emailAddress"
                    label="Eposta Adresi"
                    placeholder="Eposta adresi yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeEmailAddress}
                    value={emailAddress}
                    name="emailAddress"
                    autoComplete="emailAddress"
                    helperText={emailAddressError}
                />
            </FormControl>
        );
    }

    private renderMobilePhoneNumber() {
        const { mobilePhoneNumber, mobilePhoneNumberError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="mobilePhoneNumber"
                    label="Telefon Numarası"
                    placeholder="Telefon numarası yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeMobilePhoneNumber}
                    value={mobilePhoneNumber != "" ? mobilePhoneNumber : ""}
                    name="mobilePhoneNumber"
                    autoComplete="mobilePhoneNumber"
                    helperText={mobilePhoneNumberError}
                />
            </FormControl>
        );
    }
    
    private renderCountries() {
        const { countryId, countryList, countryError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="countryName" value={countryId} valueKey="id" options={countryList} onChange={this.onChangeCountry} placeholder="Ülke *"></CustomSelect>
                <span className="errorMessage">{countryError}</span>
            </FormControl >
        );
    }

    private renderCities() {
        const { cityId, cityList, cityError, countryId } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="cityName" value={cityId} valueKey="id" options={cityList} onChange={this.onChangeCity} placeholder="Şehir *"></CustomSelect>
                <span className="errorMessage">{cityError}</span>
            </FormControl>
        );
    }

    private renderDistricts() {
        const { districtId, districtList, districtError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="districtName" value={districtId} valueKey="id" options={districtList} onChange={this.onChangeDistrict} placeholder="İlçe *"></CustomSelect>
                <span className="errorMessage">{districtError}</span>
            </FormControl>
        );
    }

    private renderAddress() {
        const { address, addressError } = this.state;

        return (
            <FormControl margin="none" style={{ width: '98%', marginLeft: '1%' }}>
                <TextField
                    id="address"
                    label="Adres"
                    placeholder="Adres yazınız"
                    margin="normal"
                    variant="outlined"
                    multiline
                    onChange={this.onChangeAddress}
                    value={address}
                    name="address"
                    autoComplete="address"
                    rows={6}
                    helperText={addressError}
                    className="multiInput"
                />
            </FormControl>
        );
    }

    private renderDescription() {
        const { description } = this.state;


        return (
            <FormControl margin="none" style={{ width: '98%', marginLeft: '1%' }}>
                <TextField
                    id="description"
                    label="Açıklama"
                    placeholder="Açıklama yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeDescription}
                    value={description}
                    name="description"
                    autoComplete="description"
                    className="multiInput"
                />
            </FormControl>
        );
    }

    private renderUsers() {
        const { userId, userList, userError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" required className="input inputSelect">
                <CustomSelect labelName="nameSurname" value={userId} valueKey="id" options={userList} onChange={this.onChangeUser} placeholder="Kullanıcı *"></CustomSelect>
                <span className="errorMessage">{userError}</span>
            </FormControl>
        );
    }

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: PARTNER, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">

                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">İş Ortağı {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan İş Ortağı bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda İş Ortağı ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">

                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">İş Ortağı {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>
                                    {this.renderPartnerName()}
                                    {this.renderContactPerson()}
                                    {this.renderEmailAddress()}
                                    {this.renderMobilePhoneNumber()}
                                    {this.renderCountries()}
                                    {this.renderCities()}
                                    {this.renderDistricts()}
                                    {this.renderUsers()}
                                    {this.renderAddress()}
                                    {this.renderDescription()}
                                    
                                    {this.renderStatus()}

                                    <Button type="submit" id="createButton" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : null}
                </Grid>

            </Wrapper >

        )
    }

}



export default withStyles(styles)(PartnerForm);
