import XHRService from "./xhr.service";

export default class LocationService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async getPartners(search: any = null): Promise<any> {
        const model = {
            isActive: true,
            searchText: search,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0,
                orderBy: 0
            }
        }

        return await this.XHRService.post("/Partner/GetPartners", model).catch(() => ({ isSuccess: false }));

    }

    public async getCountries(search: any = ""): Promise<any> {
        const model = {
            isActive: true,
            searchText: search,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0,
                orderBy: 0
            }
        }

        return await this.XHRService.post("/Country/GetCountries", model).catch(() => ({ isSuccess: false }));

    }

    public async getCities(countryId: number, search: any = null): Promise<any> {
        const model = {
            countryId,
            isActive: true,
            searchText: search,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0,
                orderBy: 0
            }
        };

        return await this.XHRService.post("/City/GetCities", model).catch(() => ({ isSuccess: false }));
    }



    public async getDistricts(countryId: number, cityId: number, search: any = null): Promise<any> {
        const model = {
            countryId,
            cityId,
            isActive: true,
            searchText: search,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0,
                orderBy: 0
            }
        }

        return await this.XHRService.post("/District/GetDistricts", model).catch(() => ({ isSuccess: false }));
    }



    public async getCompanies(search: any = null): Promise<any> {
        const model = {
            companyName: search,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/Company/GetCompanies", model).catch(() => ({ isSuccess: false }));

    }

    public async getCompaniesHQ(search: any = null): Promise<any> {
        const model = {
            companyName: search,
            isHQ: true,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/Company/GetCompanies", model).catch(() => ({ isSuccess: false }));

    }

    public async getAllCompanies(search: any = null): Promise<any> {
        const model = {
            companyName: search,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/Company/GetAllCompanies", model).catch(() => ({ isSuccess: false }));

    }



    public async getCurrencies(search: any = null): Promise<any> {
        const model = {
            currencyName: search,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/Currency/GetCurrencies", model).catch(() => ({ isSuccess: false }));

    }


    public async getBankAccounts( search: any = null): Promise<any> {
        const model = {
            bankAccountName: search,                     
            "bankId": 0,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/BankAccount/GetBankAccounts", model).catch(() => ({ isSuccess: false }));

    }

    public async getBankAccountsDropDown(bankId: number, search: any = null): Promise<any> {
        const model = {
            bankId,
            bankAccountName: search,
            clientType: "WEB",
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/BankAccount/GetBankAccounts", model).catch(() => ({ isSuccess: false }));

    }

    public async getBankAccountsCompanyDropDown(companyId: number, search: any = null): Promise<any> {
        const model = {
            companyId,
            bankAccountName: search,
            clientType: "WEB",
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/BankAccount/GetBankAccounts", model).catch(() => ({ isSuccess: false }));

    }

    public async getBankAccountsCompanyBankDropDown(companyId: number, bankId: number, search: any = null): Promise<any> {
        const model = {
            companyId,
            bankId,
            bankAccountName: search,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/BankAccount/GetBankAccounts", model).catch(() => ({ isSuccess: false }));

    }


    public async getUsers(search: any = null): Promise<any> {
        const model = {
            companyId: 0,
            password: null,
            isActive: true,
            searchText: null,
            pagingParameters: {
                rowsPerPage: 9999,
                pageNumber: 0,
                orderBy: 0
            }
        }

        return await this.XHRService.post("/User/GetUsers", model).catch(() => ({ isSuccess: false }));

    }

    public async getUsersOfPartners(search: any = null): Promise<any> {
        const model = {
            companyId: 0,
            password: null,
            isActive: true,
            searchText: null,
            pagingParameters: {
                rowsPerPage: 9999,
                pageNumber: 0,
                orderBy: 0
            }
        }

        return await this.XHRService.post("/User/GetUsersOfPartners", model).catch(() => ({ isSuccess: false }));

    }

    public async getBanks(search: any = null): Promise<any> {
        const model = {
            bankName: search,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/Bank/GetBanks", model).catch(() => ({ isSuccess: false }));

    }

    public async getBankBranches(bankId: number, search: any = null): Promise<any> {
        const model = {
            bankId,
            isActive: true,
            searchText: null,
            pagingParameters: {
                rowsPerPage: 99999,
                pageNumber: 0
            }
        };

        return await this.XHRService.post("/BankBranch/GetBankBranches", model).catch(() => ({ isSuccess: false }));
    }




    /* Resources */
    public async getResourceTypes(search: any = null): Promise<any> {
        const model = {
            resourceTypeName: search,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/ResourceType/GetResourceTypes", model).catch(() => ({ isSuccess: false }));

    }


    public async getLanguages(search: any = null): Promise<any> {
        const model = {
            languageName: null,
            languageCode: null,
            searchText: search,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/Language/GetLanguages", model).catch(() => ({ isSuccess: false }));

    }





    public async getPermissions(search: any = null): Promise<any> {
        const model = {
            permissionName: search,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/Permission/GetPermissions", model).catch(() => ({ isSuccess: false }));

    }

    public async getRoles(search: any = null): Promise<any> {
        const model = {
            roleName: search,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 1000,
                pageNumber: 0
            }
        }

        return await this.XHRService.post("/Role/GetRoles", model).catch(() => ({ isSuccess: false }));

    }




}
