//BankBranch

export const bankBranchNameValidator = (bankBranchName: string): string => {
    if (bankBranchName === "") return 'Şube adı alanını boş geçemezsiniz!'
    return '';
}

export const bankBranchCodeValidator = (bankBranchCode: string): string => {
    if (bankBranchCode === "") return 'Şube Kodu alanını boş geçemezsiniz!'
    return '';
}

export const bankValidator = (bankId: number): string => {
    if (bankId === 0) return 'Banka seçiniz!'
    return '';
}

export const countryValidator = (countryId: number): string => {
    if (countryId === 0) return 'Ülke seçiniz!'
    return '';
}

export const cityValidator = (cityId: number): string => {
    if (cityId === 0) return 'Şehir seçiniz!'
    return '';
}

export const districtValidator = (districtId: number): string => {
    if (districtId === 0) return 'İlçe seçiniz!'
    return '';
}

export const addressValidator = (address: string): string => {
    if (address === "") return 'Adres alanını boş geçemezsiniz!'
    else if (address.length < 15) return 'Adres alanı en az 15 karakter olabilir!'
    return '';
}

export const phoneNumberValidator = (phoneNumber: string): string => {
    if (phoneNumber === "") return 'Telefon Numarası alanını boş geçemezsiniz!'
    return '';
}