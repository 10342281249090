import XHRService from "./xhr.service";

export default interface IBankCredentialRequest {
    id?: number,
    bankId: number,
    customerCode: string,
    userName: string,
    password: string,
    companyCode: string,
    companyId: number,
    isActive: boolean
}


interface IBankCredential {
    id: number,
    bankId: number,
    customerCode: string,
    userName: string,
    password: string,
    companyCode: string,
    companyId: number,
    isActive: boolean
}

interface IBankCredentialResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IBankCredential
}

interface IBankCredentialListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        bankCredentials: IBankCredential[]
    }
}

export default class BankCredentialService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IBankCredentialResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/BankCredential/CreateBankCredential", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IBankCredentialRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/BankCredential/UpdateBankCredential", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<IBankCredentialResponse> {
        const { isSuccess }: any = await this.XHRService.post("/BankCredential/DeleteBankCredential", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            bankId: 0,
            password: null,
            companyCode: null,
            companyId: 0,
            isActive,
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/BankCredential/GetBankCredentials", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/BankCredential/GetBankCredentialsExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/BankCredential/GetBankCredentialById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
