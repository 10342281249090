//District

export const districtNameValidator = (districtName: string): string => {
    if (districtName === "") return 'İlçe adı alanını boş geçemezsiniz!'
    return '';
}

export const countryValidator = (countryId: number): string => {
    if (countryId === 0) return 'Ülke seçiniz!'
    return '';
}

export const cityValidator = (cityId: number): string => {
    if (cityId === 0) return 'Şehir seçiniz!'
    return '';
}