import React, { Component, ChangeEvent, KeyboardEvent } from 'react'
import { NavLink } from 'react-router-dom'
import { Redirect } from 'react-router'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';


import { userService, cookieService } from '../../service'
import { HOME } from '../../routes/paths'

import ConfirmDialog from '../layout/Confirm';

import { emailValidator, passwordValidator } from '../../utils/login.validators'


export interface ILoginFormState {
    emailAddress: string;
    emailAddressError: string;
    password: string;
    passwordError: string;
    clientType: string;
    partnerCompanyCode: string;
    loginSuccess: boolean;
    showPassword: boolean;
    confirmStatus: boolean;
}

export interface ILoginFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class LoginForm extends Component {
    public readonly state: ILoginFormState;
    public readonly props: ILoginFormProps;
    private timeout = 5000;
    private interval: any;

    public readonly LANGUAGE_NAME = 'LANGUAGE_TOKEN';

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            emailAddress: "",
            emailAddressError: "",
            password: "",
            passwordError: "",            
            clientType: "WEB",            
            partnerCompanyCode: "0",
            loginSuccess: false,
            showPassword: false,
            confirmStatus: false
        };

        this.onEnter = this.onEnter.bind(this);
        this.onEnterEmail = this.onEnterEmail.bind(this);
        this.onEnterPassword = this.onEnterPassword.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);
        this.login = this.login.bind(this);
    }

    private onEnterEmail(e: ChangeEvent<HTMLInputElement>) {
        super.setState({ emailAddress: e.currentTarget.value, emailAddressError: emailValidator(e.currentTarget.value) } as ILoginFormState);
    }

    private onEnterPassword(e: ChangeEvent<HTMLInputElement>) {
        super.setState({ password: e.currentTarget.value, passwordError: passwordValidator(e.currentTarget.value) } as ILoginFormState);
    }

    private onEnter(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key == "Enter") this.login()
    }

    private formValidation() {
        const { emailAddress, password } = this.state;

        //Submit Validation
        if (emailValidator(emailAddress) !== "") {
            this.setState({ emailAddressError: emailValidator(emailAddress) });
            return false;
        }
        else if (passwordValidator(password) !== "") {
            this.setState({ passwordError: passwordValidator(password) });
            return false;
        }

        return true;
    }

    private async login() {
        const { password, emailAddress, clientType, partnerCompanyCode } = this.state;
        const response = await userService.login({ password, emailAddress, clientType, partnerCompanyCode });

        if (this.formValidation()) {
            if (response.isSuccess) this.redirect()
            else this.setState({ confirmStatus: true });
        }

    }

    private redirect() {
        this.setState({ loginSuccess: true });
    }

    handleClickShowPassword() {
        this.setState(state => ({ showPassword: !this.state.showPassword }));
    };

    private onConfirmClose() {
        this.setState({ confirmStatus: false });
    }


    private renderEmail() {
        const { emailAddress, emailAddressError } = this.state;


        return (
            <FormControl margin="none" className="input" required fullWidth error={!!emailAddressError}>
                <TextField
                    id="emailAddress"
                    label="E-posta Adresi"
                    placeholder="sales@finstant.co"
                    margin="none"
                    variant="outlined"
                    onKeyPress={this.onEnter}
                    onChange={this.onEnterEmail}
                    value={emailAddress}
                    name="emailAddress"
                    autoComplete="emailAddress"
                    helperText={emailAddressError}
                />
            </FormControl>
        );
    }

    private renderPassword() {
        const { password, passwordError } = this.state;

        return (
            <FormControl margin="none" className="input" required fullWidth error={!!passwordError}>
                <TextField
                    id="password"
                    label="Şifre"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="********"
                    margin="none"
                    variant="outlined"
                    onKeyPress={this.onEnter}
                    onChange={this.onEnterPassword}
                    value={password}
                    name="password"
                    autoComplete="current-password"
                    helperText={passwordError}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Şifreyi göster"
                                    onClick={this.handleClickShowPassword}
                                >
                                    {this.state.showPassword ? <Icon className="far fa-eye"></Icon> : <Icon className="far fa-eye-slash"></Icon>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
        );
    }

    private redirectToHome() {
        if (this.state.loginSuccess) return <Redirect to={HOME} />
    }


    public render() {
        const { classes } = this.props;
        const { confirmStatus } = this.state;

        return (
            <>
                <ConfirmDialog open={confirmStatus} icon="fas fa-lock" confirm="ok" text="Hatalı E-posta Adresi veya Şifre girdiniz!" header="Hatalı Giriş" onClose={this.onConfirmClose} onApprove={this.onConfirmClose} />

                <Grid container spacing={0} alignItems="center" justify="center" id="Login">
                    <Grid item xs={3} className="Sidebar">
                        <img src="img/sidebarBackground.png" className="topBullets" />
                        <img src="img/sidebarBackground.png" className="bottomBullets" />
                        <img src="img/logo.png" className="logo" />
                        <div className="content">
                            <ul>
                                <li>
                                    <h2>Banka Hesap Yönetimi</h2>
                                    <p>Tüm bankalardaki bakiyelerinizi, hesap ve pos hareketlerinizi tek bir uygulamadan anlık olarak görüntülemek hiç bu kadar kolay olmamıştı.</p>
                                </li>
                            </ul>

                        </div>
                        <div className="footer">
                            {/*<NavLink to={"/"} className="language">
                                <img src="img/tr.png" />
                                <ul className="menu">
                                    <li className="en" onClick={() => cookieService.setItem(this.LANGUAGE_NAME, "EN")}><img src="img/en.png" /> English</li>
                                    <li className="tr" onClick={() => cookieService.setItem(this.LANGUAGE_NAME, "TR")}><img src="img/tr.png" /> Türkçe</li>
                                </ul>
                            </NavLink>
                            <NavLink to={"/"}>Yardım</NavLink>
                            <NavLink to={"/"}>İletişim</NavLink>*/}
                        </div>
                    </Grid>
                    {this.redirectToHome()}

                    <Grid item xs={9} id="LoginForm">
                        <Paper className="Form">
                            <h2>finstant'a Hoş geldiniz</h2>
                            <p>Hesap bilgileriniz ile giriş yapınız.</p>
                            {this.renderEmail()}
                            {this.renderPassword()}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="remember"
                                        color="primary"
                                    />
                                }
                                className="checkbox"
                                label="Beni Hatırla"
                            />
                            <NavLink to={"/remember"}>Şifremi Unuttum</NavLink>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.login}
                                id="loginButton"
                                fullWidth
                                onKeyPress={this.login}
                                type="submit">
                                GİRİŞ
                            </Button>
                            <p>Hesabınız yok mu? <NavLink to={"/register"}>Hemen üye ol</NavLink></p>
                        </Paper>

                        <p className="copyright">Finstant &copy; 2021</p>
                    </Grid>
                </Grid>
            </>
        )
    }

}



export default withStyles(styles)(LoginForm);
