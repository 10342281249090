function isEmpty(str: string) {
    return (!str || 0 === str.length || "0" === str);
}

function emailValidation(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function passwordValidation(password: string, title: string){
    const symbol = '*|,:<>[]{}`;()@&$#%!+-"/';
        var SymbolControl = false;
        for (var i = 0; i < password.length; i++) {
            if (symbol.indexOf((password).charAt(i)) != -1) {
                SymbolControl = true;
            }
        }

        if ((password).search(/[a-z]/) < 0)
            return title + " en az bir Küçük harf içermelidir.";
        else if ((password).search(/[A-Z]/) < 0)
            return title + " en az bir Büyük harf içermelidir.";
        else if ((password).search(/[0-9]/) < 0)
            return title + " en az bir rakam içermelidir.";
        else if (SymbolControl === false)
            return title + " en az bir özel karakter içermelidir.";
        else return true;
}

export const validate = (name: string, content: string, validations: any): (string | boolean) => {

    if(validations.indexOf('empty') !== -1){

        if(isEmpty(content) || content==="0") return name + " alanını boş geçemezsiniz!";

    }

    if(validations.indexOf('email') !== -1){

        if(!emailValidation(content)) return "Geçersiz bir e-posta adresi girdiniz!";

    }

    if(validations.indexOf('password') !== -1){

        if(passwordValidation(content, name) !== true) return passwordValidation(content, name);

    }

    if(validations.indexOf('phone') !== -1){
        if(!content.match(/^\d{12}$/g)) return "Geçerli bir " + name + " giriniz!";
    }

    return false;
}
