import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';

interface IConfirmDialogProps {
    open: boolean;
    text: string;
    header: string;
    icon: string;
    confirm: string;
    onApprove: Function;
    onClose: Function
}

export default class ConfirmDialog extends PureComponent {
    public readonly props: IConfirmDialogProps;
    constructor(props: any) {
        super(props);
        this.props = props;
        this.onApprove = this.onApprove.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onApprove() {
        this.props.onApprove();
    };

    onClose() {
        this.props.onClose();
    };

    render() {
        const { text, header, open, icon, confirm } = this.props;
        return (
            <div>
                <Dialog
                    id="Dialog"
                    open={open}
                    onClose={this.onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"><Icon className={icon}></Icon> {header}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {confirm === "yes/no" ? <>
                            <Button onClick={this.onClose} color="primary">Hayır</Button>
                            <Button onClick={this.onApprove} color="primary" style={{ margin: '0px 10px' }} autoFocus>Evet</Button>
                        </> :
                            <Button onClick={this.onApprove} color="primary" autoFocus>Tamam</Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
