import XHRService from "./xhr.service";

export default interface ICompanyRequest {
    id?: number;
    companyName: string;    
    vatNumber: string;
    userLimit: number;
    expireDate: string;
    employeeNumber: number,
    ipAddress: string;
    address: string;
    partnerId: number,
    countryId: number,
    districtId: number,
    cityId: number,
    isActive: boolean;
}


interface ICompany {
    id: number;
    companyName: string;    
    vatNumber: string;
    userLimit: number;
    expireDate: string;
    employeeNumber: number,
    ipAddress: string;
    address: string;
    partnerId: number,
    countryId: number,
    districtId: number,
    cityId: number,
    isActive: boolean;
}

interface ICompanyResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: ICompany
}

interface ICompanyListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        companies: ICompany[]
    }
}

export default class CompanyService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<ICompanyResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Company/CreateCompany", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: ICompanyRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/Company/UpdateCompany", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<ICompanyResponse> {
        const { isSuccess }: any = await this.XHRService.post("/Company/DeleteCompany", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            searchText,
            "CompanyName": null,
            "userLimit": 0,
            "expireDate": "2019-04-15T18:34:28.888Z",
            "ipAddress": null,
            "partnerId": 0,
            "employeeNumber": null,
            "countryId": 0,
            "cityId": 0,
            "districtId": 0,
            "address": null,
            isActive,
            "pagingParameters": {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/Company/GetCompanies", model).catch(() => ({ isSuccess: false }));
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/Company/GetCompanyById?id=${id}`).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/Company/GetCompaniesExcel", model);
    }



}
