import XHRService from "./xhr.service";

export default class AccountStatementsService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async get(unixStartDate?: string, unixEndtDate?: string, currencyId?: number, bankId?: number, accountId?: number, searchText?: string, clientType?: string, iban?: string, rowsPerPage?: number, pageNumber?: number, orderBy?: number): Promise<any> {

        const model = {
            currencyId: accountId !== 0 ? 0 : currencyId,
            bankId: accountId !== 0 ? 0 : bankId,
            bankAccountId: accountId,
            startDate: unixStartDate,
            endDate: unixEndtDate,
            iban,
            description: null,
            clientType: "WEB",
            isActive: true,
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        //console.log(model);

        return await this.XHRService.post("/AccountStatement/GetAccountStatements", model).catch(() => ({ isSuccess: false }));

    }

    public async download(startDate?: string, endDate?: string, currencyId?: number, bankId?: number, accountId?: number, searchText?: string,iban?: string, ): Promise<any> {

        const model = {
            currencyId: accountId !== 0 ? 0 : currencyId,
            bankId: accountId !== 0 ? 0 : bankId,
            startDate,
            endDate,
            bankAccountId: accountId,
            searchText,
            iban,
            clientType: "WEB",
            isActive: true,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/AccountStatement/GetAccountStatementsExcel", model);
    }


    public async cockpit(startDate?: string, endDate?: string, accountId?: number, searchText?: string, iban?: string, rowsPerPage?: number, pageNumber?: number, orderBy?: number): Promise<any> {

        const model = {
            bankAccountId: accountId,
            startDate: null,
            endDate: null,
            description: null,
            isActive: true,
            clientType: "WEB",
            searchText,
            iban,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/AccountStatement/GetAccountStatements", model).catch(() => ({ isSuccess: false }));

    }

    public async bankAccounts(search: any = null): Promise<any> {
        const model = {
            bankId: 0,
            bankAccountId: 0,
            currencyId: 0,
            balance: 0,
            isActive: true,
            clientType: "WEB",
            searchText: null,
            pagingParameters: {
                rowsPerPage: 30,
                pageNumber: 0,
                orderBy: 0
            }
        }

        return await this.XHRService.post("/AccountBalance/GetAccountBalances", model).catch(() => ({ isSuccess: false }));

    }

    public async accountStatementCount(): Promise<any> {
        const model = {
            isActive: true,
            pagingParameters: {
                "rowsPerPage": 1
            }
        }

        return await this.XHRService.post("/AccountStatement/GetAccountStatementCount", model).catch(() => ({ isSuccess: false }));

    }
}
