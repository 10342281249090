import React, { Component, ChangeEvent } from 'react'
import { Link } from "react-router-dom"

import Paper from "@material-ui/core/Paper"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Button from '@material-ui/core/Button';

import { ADD_NEW_PAYMENT } from '../../routes/paths';


interface IPaymentListState {
    list: [],
    loading: boolean;
    totalCount: number;
    currentPage: number;
    sortType: string;
    searchText: string;
    selectedRow: number;
    orderBy: number;
}


const addPaymentView = (props: any) => <Link to={ADD_NEW_PAYMENT} {...props} />;


export default class PaymentList extends Component {
    public readonly props: any;
    public readonly state: IPaymentListState;
    private deleteConfirmId: number | null;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.deleteConfirmId = null;
        this.state = {
            list: [],
            loading: true,
            selectedRow: 25,
            totalCount: 0,
            currentPage: 0,
            sortType: "asc",
            searchText: "",
            orderBy: 0
        }

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
        this.AddPayment = this.AddPayment.bind(this);

    }

    componentWillMount() {
        this.getPayments("", this.state.currentPage, this.state.selectedRow);
    }

    private async getPayments(searchText: string, pageNumber: number, rowPerPage: number, orderBy: number = this.state.orderBy) {
        //const { data } = await paymentService.get(searchText, pageNumber, rowPerPage, orderBy);
        //this.setState({ list: data.payments, loading: false, totalCount: data.totalCount })
    }

    private async AddPayment() {
        this.props.history.push();
    }

    private handleChangePage(_: any, page: number) {
        this.setState({ currentPage: page });
        this.getPayments(this.state.searchText, page, this.state.selectedRow);
    }

    private handleSort(orderBy: number) {
        this.setState({ orderBy });
        this.getPayments(this.state.searchText, this.state.currentPage, this.state.selectedRow, orderBy);
    }

    private handleSearch({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        this.setState({ searchText: value });
        this.getPayments(value, this.state.currentPage, this.state.selectedRow);
    }

    private handleRowsPerPageChange({ target: { value } }: any) {
        const selectedRow = Number(value);
        this.setState({ selectedRow });
        this.getPayments(this.state.searchText, this.state.currentPage, selectedRow);
    }

    private renderRowHeaders() {
        const { orderBy } = this.state;
        return (
            <TableRow>
                <TableCell onClick={() => this.handleSort(orderBy === 1 ? 2 : 1)}>
                    <TableSortLabel active={orderBy === 1 || orderBy === 2} direction={orderBy === 1 ? "desc" : "asc"}>
                        Ödeme Adı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 3 ? 4 : 3)}>
                    <TableSortLabel active={orderBy === 3 || orderBy === 4} direction={orderBy === 3 ? "desc" : "asc"}>
                        Kuruluş Sayısı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 5 ? 6 : 5)} style={{ textAlign: 'right' }}>
                    <TableSortLabel active={orderBy === 5 || orderBy === 6} direction={orderBy === 5 ? "desc" : "asc"}>
                        Üyelik Modeli
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 7 ? 8 : 7)} style={{ textAlign: 'right' }}>
                    <TableSortLabel active={orderBy === 7 || orderBy === 8} direction={orderBy === 7 ? "desc" : "asc"}>
                        Tutar
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 11 ? 12 : 11)} style={{ textAlign: 'right' }}>
                    <TableSortLabel active={orderBy === 11 || orderBy === 12} direction={orderBy === 11 ? "desc" : "asc"}>
                        Üyelik Bitiş
                    </TableSortLabel>
                </TableCell>
                <TableCell></TableCell>
            </TableRow >
        );
    }


    render() {
        return (
            <Paper>
                <Grid container id="Form" className="profileContent">
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} className="form" alignContent="center">
                        <Typography variant="h2">Henüz Faturanız Bulunmamaktadır!</Typography>
                        <Typography variant="subheading">Oluşturulmuş veya geçmiş bir faturanız henüz bulunmamaktadır!</Typography>
                        <Button component={addPaymentView}>Yeni Plan Ekle +</Button>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}


