import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { accountBalancesService } from '../../service'
import { withStyles } from '@material-ui/core/'
import Wrapper from '../layout/Wrapper'
import { ACCOUNTSTATEMENT, ACCOUNT_STATEMENT } from '../../routes/paths'
import { Link } from 'react-router-dom'

import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  Export,
  Margin,
  Legend,
  Tooltip,
  Title,
  Subtitle,
} from 'devextreme-react/pie-chart'
import { CurrencyId, priceFormatter } from '../../helpers/priceFormatter'

interface PointInfo {
  argumentText: string
  value: number
  percentText: string
}

interface AccountBalancesByBank {
  balance: number
  currencyId: number
  bankName: string
}

interface IAccountDetailState {
  list: []
  loading: boolean
  expanded: any
  totalBalance: []
  companyList: []
  banks: AccountBalancesByBank[]
}

interface IAccountDetailChartProps {
  currencyId: number
  name: string
  classes: any
  history: any
  match: any
}

const style = (theme: any) => ({
  wrapper: {
    alignItems: 'top',
    justifyContent: 'space-between',
  },
  cards: {
    display: 'flex',
  },
  card: {
    marginRight: '10px',
    width: '32%',
  },
  bankcard: {
    margin: '1%',
    width: '23%',
  },
  chart: {
    marginRight: '10px',
    width: '68%',
  },
  company: {
    width: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paper: {
    marginBottom: '30px',
  },
  accordion: {
    width: '99%',
    marginTop: 20,
  },
  accordionDetail: {
    display: 'flex',
    'flex-wrap': 'wrap',
  },
  totalAmount: {
    fontSize: 30,
    color: '#304057',
    fontWeight: 500,
  },
  labelTotalAmount: {
    marginTop: 15,
    fontSize: 13,
    marginLeft: 10,
    color: '#9aafcd',
  },
  heading: {
    fontSize: 14,
  },
})

class AccountDetail extends React.Component {
  public readonly state: IAccountDetailState
  public readonly props: IAccountDetailChartProps

  constructor(props: IAccountDetailChartProps) {
    super(props)

    this.props = props

    this.state = {
      list: [],
      companyList: [],
      loading: false,
      expanded: '',
      totalBalance: [],
      banks: [],
    }

    this.getTotalBalance = this.getTotalBalance.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.detail = this.detail.bind(this)
  }

  private handleChange(expanded: any) {
    this.setState({
      expanded,
    })
  }

  private async detail() {
    this.props.history.push(ACCOUNTSTATEMENT)
  }

  getCurrencyId() {
    return Number(this.props.match.params.currencyId) as CurrencyId
  }

  componentWillMount() {
    if (this.getCurrencyId()) {
      this.getTotalBalance()
      this.getAccountBalanceTotal()
      this.getCompanies()
      this.renderCompanyBalances()
      this.getBanks()
    }
  }

  private generateBalanceList(list: []) {
    const balanceList: object[] = []
    list.forEach((current: any) => {
      const index = balanceList.findIndex(
        (item: any) => item.companyId === current.companyId
      )
      if (index === -1)
        balanceList.push({
          list: [current],
          companyName: current.companyName,
          companyId: current.companyId,
        })
      else (balanceList[index] as any).list.push(current)
    })

    return balanceList
  }

  private async getTotalBalance() {
    const { data } = await accountBalancesService.getAccountBalances(
      this.getCurrencyId()
    )
    const list = this.generateBalanceList(data.accountBalances)
    if (list && list.length > 0) {
      this.setState({
        list,
        loading: false,
        expanded: (list[0] as any).companyId,
      })
    }
  }

  private async getAccountBalanceTotal() {
    this.setState({ loading: true })
    const response = await accountBalancesService.getAccountBalanceTotals(
      this.getCurrencyId()
    )
    //const list = this.generateBalanceList(data.accountBalanceTotalItems)
    if (response.isSuccess)
      this.setState({
        totalBalance: response.data.accountBalanceTotalItems,
        loading: false,
      })
  }

  private async getCompanies() {
    this.setState({ loading: true })
    const response =
      await accountBalancesService.getAccountBalanceTotalsByCompany(
        this.getCurrencyId()
      )
    if (response.isSuccess)
      this.setState({
        companyList: response.data.accountBalanceTotalByCompanyItem,
        loading: false,
      })
  }

  private async getBanks() {
    this.setState({ loading: true })
    const response = await accountBalancesService.getAccountBalanceTotalsByBank(
      this.getCurrencyId()
    )
    if (response.isSuccess)
      this.setState({
        banks: response.data.accountBalanceTotalByBankItem,
        loading: false,
      })
  }

  private renderAmountConvert(amount: string) {
    if (amount == '0') {
      return '0,00'
    }

    let p = ''
    let isaretli = ''

    let sayi = amount.split('.')
    let net = sayi[0]
    let ondalik = sayi[1]

    net.indexOf('-') != -1 ? (isaretli = '-') : null
    net = net.replace('-', '')

    ondalik == undefined ? (ondalik = '00') : null
    ondalik.length == 1 ? (ondalik += '0') : null

    let tersSayi = net.split('').reverse().join('')

    for (let i = 0; i < tersSayi.length; i++) {
      if (i % 3 == 0 && i != 0) p += '.'
      p += tersSayi.substr(i, 1)
    }

    return isaretli + p.split('').reverse().join('') + ',' + ondalik
  }

  private renderChartReport() {
    const { banks } = this.state

    const customizeTooltip = (pointInfo: PointInfo) => {
      const { argumentText, value } = pointInfo

      //console.log(pointInfo)

      return {
        html: `
          <div style="text-align:center;">
            <div style="font-weight:700;margin-bottom:4px">${argumentText}</div>
            <div>${priceFormatter(value, this.getCurrencyId())}</div>
          </div>
        `,
        fontColor: '#222',
      }
    }

    return (
      <PieChart
        id={'chart'}
        dataSource={banks}
        palette='Soft Blue'
        title={'Hesap Detay Raporu'}
        resolveLabelOverlapping='shift'
      >
        <Series argumentField={'bankName'} valueField={'balance'}>
          <Label
            visible={true}
            position='columns'
            customizeText={(pointInfo: PointInfo) =>
              priceFormatter(pointInfo.value, this.getCurrencyId())
            }
          >
            <Connector visible={true} width={1} />
          </Label>
        </Series>
        <Margin bottom={-300} />
        <Size width={'100%'} height={'100%'} />
        <Export enabled={false} />
        <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
      </PieChart>
    )
  }

  private renderBanks(balance: any) {
    const { classes } = this.props
    return (
      <CardContent className={classes.content}>
        <div>
          <img src={`img/banks/${balance.bankId}.png`} height='30'></img>
          <Typography>
            {balance.bankBranchCode} / {balance.bankAccountNo}
            <br />
            {balance.iban}
          </Typography>
          <Typography variant='h6' component='h6'>
            <span>{balance.currencyCode}</span>{' '}
            {this.renderAmountConvert(String(balance.balance))}
          </Typography>
          <Typography>{balance.updateDateTime}</Typography>
          <Button>
            <Link to={`${ACCOUNT_STATEMENT(balance.bankAccountId)}/`}>
              Detaylar
            </Link>
          </Button>
        </div>
      </CardContent>
    )
  }

  private renderExpansionPanels() {
    const { expanded, list } = this.state
    const { classes } = this.props

    return list.map((balance: any) => (
      <ExpansionPanel
        expanded={expanded === balance.companyId}
        key={balance.companyId}
        onChange={() => this.handleChange(balance.companyId)}
      >
        <ExpansionPanelSummary expandIcon={'-'}>
          <Typography className={classes.heading}>
            {balance.companyName}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.accordionDetail}>
          {balance.list.map((bank: any) => (
            <Card key={bank.id} className={classes.bankcard} id={'Card'}>
              {this.renderBanks(bank)}
            </Card>
          ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ))
  }

  private renderCompanies() {
    const { classes } = this.props
    return this.state.totalBalance.map((company: any) => (
      <div style={{ display: 'flex' }}>
        <Typography variant='h4' component='h4' className={classes.totalAmount}>
          <span className='currencyCode'>{company.currencyCode}</span>{' '}
          {this.renderAmountConvert(String(company.balance))}
        </Typography>
        <Typography className={classes.labelTotalAmount}>
          Toplam Bakiye
        </Typography>
      </div>
    ))
  }

  private renderCompanyBalances() {
    const { companyList } = this.state
    if (Array.isArray(companyList) && companyList.length > 0)
      return companyList.map((company: any) => (
        <TableRow key={company.id} className={'companyBalance'}>
          <TableCell scope='row' style={{ fontSize: 12 }}>
            <Icon className='fas fa-square'></Icon> {company.companyName}
          </TableCell>
          <TableCell style={{ fontSize: 12 }}>
            {this.renderAmountConvert(String(company.balance))}{' '}
            {company.currencyCode}
          </TableCell>
        </TableRow>
      ))

    return (
      <Typography color='textSecondary' className='text'>
        Gösterilecek firma yok.
      </Typography>
    )
  }

  private renderCards() {
    const { totalBalance } = this.state
    const { classes } = this.props

    return (
      <>
        <div className={classes.cards} id='BalanceWrapper'>
          <Card className={classes.card} id='Card'>
            <CardContent className={classes.content}>
              <div>
                {this.renderCompanies()}
                {this.renderCompanyBalances()}
              </div>
            </CardContent>
          </Card>
          <Card className={classes.chart} id='Card'>
            <CardContent className={classes.content}>
              {this.renderChartReport()}
            </CardContent>
          </Card>
        </div>

        <div className={classes.accordion} id='Accordion'>
          <Typography variant='h5' component='h5'>
            Şirket Hesap Bilgileri
          </Typography>
          {this.renderExpansionPanels()}
        </div>
      </>
    )
  }

  render() {
    const { classes } = this.props
    return (
      <Wrapper>
        <div className={classes.paper}>
          {this.state.loading ? (
            <CircularProgress className='progress' />
          ) : (
            <div className={classes.wrapper}>{this.renderCards()}</div>
          )}
        </div>
      </Wrapper>
    )
  }
}

export default withStyles(style)(AccountDetail)
