import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Wrapper from '../layout/Wrapper';

import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

import { notificationService, locationService } from '../../service';
import { NOTIFICATION } from '../../routes/paths';
import CustomSelect from '../layout/CustomSelect';

import Error from '../NotFound/NotFound'

import { companyValidator, bankValidator,  bankAccountValidator, userValidator, descriptionValidator, senderIBANValidator, senderTaxNumberValidator } from '../../utils/notification.validators';
import { sqlInjectValidator } from '../../utils/sql.inject.validators';

export interface INotificationFormState {
    companyList: [],
    companyId: any,
    companyError: string,
    bankList: [],
    bankId: any,
    bankError: string,    
    bankAccountList: [],
    bankAccountId: any,
    bankAccountError: any,
    userList: [],
    userId: any,
    userError: string,
    description: string,
    descriptionError: string,
    senderIBAN: string,
    senderIBANError: string,
    senderTaxNumber: string,
    senderTaxNumberError: string,
    isActive: boolean,
    isSuccess: string,
    isEdit: boolean,
    loading: boolean,
    errorCode: string,
    errorMessage: string
}

export interface INotificationFormProps {
    classes: any
}

const styles = (theme: any) => ({
    main: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
});

class NotificationForm extends Component {
    public readonly state: INotificationFormState;
    public readonly props: any;
    private form: any;

    constructor(props: any) {
        super(props);

        this.props = props;

        this.state = {
            companyList: [],
            companyId: 0,
            companyError: "",
            bankList: [],
            bankId: 0,
            bankError: "",
            bankAccountList: [],
            bankAccountId: 0,
            bankAccountError: "",
            userList: [],
            userId: 0,
            userError: "",
            description: "",
            descriptionError: "",
            senderIBAN: "",
            senderIBANError: "",
            senderTaxNumber: "",
            senderTaxNumberError: "",
            isActive: true,
            isSuccess: "",
            isEdit: false,
            loading: true,
            errorCode: "",
            errorMessage: ""
        };

        this.onChangeCompany = this.onChangeCompany.bind(this);
        this.onChangeBank = this.onChangeBank.bind(this);
        this.onChangeBankAccount = this.onChangeBankAccount.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeSenderIBAN = this.onChangeSenderIBAN.bind(this);
        this.onChangeSenderTaxNumber = this.onChangeSenderTaxNumber.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.notificationId) this.getEditableNotification();
        else { {/*this.getBanks();*/} this.getCompanies(); this.getUsers();}

        this.form = React.createRef();
    }

    private async getEditableNotification() {
        const { data, isSuccess, errorCode, errorMessage } = await notificationService.getById(this.props.match.params.notificationId);
        if (errorCode !== null) {
            this.setState({ errorCode, errorMessage, loading: false });
        } else {
            await this.getBanks();
            await this.getCompanies();
            await this.getUsers();
            const state = {
                ...data,
                companyId: this.state.companyList.find((item: any) => item.id === data.companyId),
                bankId: this.state.bankList.find((item: any) => item.id === data.bankId),
                /*bankAccountId: this.state.bankAccountList.find((item: any) => item.id === data.bankAccountId),*/
                userId: this.state.userList.find((item: any) => item.id === data.userId),
                isEdit: true
            }

            if (isSuccess) this.setState({ ...state});  

            await this.getBankAccounts(state.companyId.id, state.bankAccountId.id, true);
            this.setState({ bankAccountId: this.state.bankAccountList.find((item: any) => item.id === data.bankAccountId), loading: false })                        
        }
    }

    /** EVENT LISTENERS */

    private onChangeCompany(companyId: any) {
        this.setState({ companyId, companyError: companyValidator(companyId) })
        this.getBanks();;
    }

    private onChangeBank(bankId: any) {
        this.setState({ bankId, bankError: bankValidator(bankId), bankAccountId : 0 });
        console.log(this.state.companyId.id)
        this.getBankAccounts(this.state.companyId.id, bankId.id, true);
    }

    private onChangeBankAccount(bankAccountId: any) {
        this.setState({ bankAccountId, bankAccountError: bankAccountValidator(bankAccountId)});
    }    

    private onChangeUser(userId: any) {
        this.setState({ userId, userError: userValidator(userId) });
    }

    private onChangeDescription(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ description: e.target.value, descriptionError: descriptionValidator(e.currentTarget.value) });
    }

    private onChangeSenderIBAN(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ senderIBAN: e.target.value, senderIBANError: senderIBANValidator(e.currentTarget.value) });
    }   

    private onChangeSenderTaxNumber(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ senderTaxNumber: e.target.value, senderTaxNumberError: senderTaxNumberValidator(e.currentTarget.value) });
    }
    
    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
    }

    /** VIEW RENDER */

    private async cancel() {
        this.props.history.push(NOTIFICATION);
    }    

    private async getCompanies() {
        const { data } = await locationService.getCompanies();

        const state = {
            companyId: this.state.companyId === 0 ? 0 : this.state.companyId.id,
            companyList: data.companies,
            loading: false
        };

        this.setState(state);

    }

    private async getBanks(useDefault: boolean = true) {
        const { data } = await locationService.getBanks();

        const state = {
            bankId: useDefault ? 0 : this.state.bankId.id,
            bankList: data.banks,
            /*loading: false*/
        };

        this.setState(state);

        /*if (this.props.match.params.companyId) await this.getBankAccounts(state.bankId);*/
    {/*await this.getBankAccounts(state.bankId, useDefault);*/}
    }

    private async getBankAccounts(companyId: number, bankId: number, useDefault: boolean) {
        const { data } = await locationService.getBankAccountsCompanyBankDropDown(companyId, bankId);

        const state = {
            bankAccountList: data.bankAccounts,
            bankAccountId: useDefault ? 0 : this.state.bankAccountId.id,
            loading: false
        };

        this.setState(state);
    }    

    private async getUsers() {
        const { data } = await locationService.getUsers();

        const state = {
            userId: this.state.userId === 0 ? 0 : this.state.userId.id,
            userList: data.users
        };

        this.setState(state);
    }
    
    private getErrorMessage() {
        const { errorCode, errorMessage } = this.state;
        return (
            errorCode !== "" ? <Error errorCode={errorCode} errorMessage={errorMessage} /> : null

        );
    }

    private formValidation() {
        const {companyId, bankId, bankAccountId, userId, description, senderIBAN, senderTaxNumber } = this.state;

        if (
            companyValidator(companyId) !== "" ||
            bankValidator(bankId) !== "" ||
            bankAccountValidator(bankAccountId) !== "" ||
            userValidator(userId) !== "" ||
            descriptionValidator(description) !== "" ||
            senderIBANValidator(senderIBAN) !== "" ||
            senderTaxNumberValidator(senderTaxNumber) !== ""

        ) {

            this.setState({
                companyError: companyValidator(companyId),
                bankError: bankValidator(bankId),
                bankAccountError: bankAccountValidator(bankAccountId),
                userError: userValidator(userId),
                descriptionError: descriptionValidator(description),                
                senderIBANError: senderIBANValidator(senderIBAN),
                senderTaxNumberError: senderTaxNumberValidator(senderTaxNumber)
            });
            return false;
        }

        return true;
    }

    private async saveChanges() {
        this.setState({ isSuccess: "", errorCode: "", errorMessage: "" });


        const { companyId, bankId, bankAccountId, userId, description, senderIBAN, senderTaxNumber, isActive, isEdit } = this.state;

        if (this.formValidation()) {  
        const model = {
                companyId: companyId.id,
                bankId: bankId.id,
                bankAccountId: bankAccountId.id,
                userId: userId.id,
                description: sqlInjectValidator(description),
                senderIBAN: sqlInjectValidator(senderIBAN),
                senderTaxNumber: sqlInjectValidator(senderTaxNumber),
                isActive
            };

            if (isEdit) await this.editNotification(model);
            else await this.createNotification(model);
        }
    }

    private async createNotification(model: any) {
        const data = await notificationService.create(model);
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }

    private async editNotification(model: any) {
        const data = await notificationService.update({ ...model, id: this.props.match.params.notificationId });
        this.setState({ isSuccess: data.isSuccess ? "success" : "error", errorCode: data.errorCode, errorMessage: data.errorMessage });
    }


    private renderCompanies() {
        const { companyId, companyList, companyError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" required className="input inputSelect">
                <CustomSelect labelName="companyName" value={companyId} valueKey="id" options={companyList} onChange={this.onChangeCompany} placeholder="Firma *"></CustomSelect>
                <span className="errorMessage">{companyError}</span>
            </FormControl>
        );
    }

    private renderBanks() {
        const { bankId, bankList, bankError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="bankName" value={bankId} valueKey="id" options={bankList} onChange={this.onChangeBank} placeholder="Banka *"></CustomSelect>
                <span className="errorMessage">{bankError}</span>
            </FormControl>
        );
    }

    private renderBankAccounts() {
        const { bankAccountId, bankAccountList, bankAccountError } = this.state;

        return (
            <FormControl margin="none" variant="outlined" className="input inputSelect">
                <CustomSelect labelName="bankAccountName" value={bankAccountId} valueKey="id" options={bankAccountList} onChange={this.onChangeBankAccount} placeholder="Banka Hesabı *"></CustomSelect>
                <span className="errorMessage">{bankAccountError}</span>
            </FormControl>
        );
    }

    private renderUsers() {
        const { userId, userList, userError } = this.state;

        return (
            <FormControl margin="none" variant="outlined"  className="input inputSelect">
                <CustomSelect labelName="nameSurname" value={userId} valueKey="id" options={userList} onChange={this.onChangeUser} placeholder="Kullanıcı *"></CustomSelect>
                <span className="errorMessage">{userError}</span>
            </FormControl>
        );
    }

    private renderDescription() {
        const { description, descriptionError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="description"
                    label="Açıklama"
                    placeholder="Açıklama yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeDescription}
                    value={description}
                    name="description"
                    autoComplete="description"
                    helperText={descriptionError}
                />
            </FormControl>
        );
    }

    private renderSenderIBAN() {
        const { senderIBAN, senderIBANError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="senderIBAN"
                    label="Gönderen IBAN"
                    placeholder="Gönderen IBAN yazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeSenderIBAN}
                    value={senderIBAN}
                    helperText={senderIBANError}
                    name="senderIBAN"
                    autoComplete="senderIBAN"
                />
            </FormControl>
        );
    }

    private renderSenderTaxNumber() {
        const { senderTaxNumber, senderTaxNumberError } = this.state;


        return (
            <FormControl margin="none" className="input">
                <TextField
                    id="senderTaxNumber"
                    label="Gönderen VKN/TCKN"
                    placeholder="Gönderen VKN/TCKNyazınız"
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChangeSenderTaxNumber}
                    value={senderTaxNumber}
                    helperText={senderTaxNumberError}
                    name="senderTaxNumber"
                    autoComplete="senderTaxNumber"
                />
            </FormControl>
        );
    }
    

    private renderStatus() {
        const { isActive } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select active" : "select passive"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? "Aktif" : "Pasif"} />
        )
    }

    private renderMessage() {
        return (
            <Snackbar message={this.state.errorMessage + " (Error Code : " + this.state.errorCode + ")"} timeoutMs={5000} className="messageBoxError" />
        );
    }

    public render() {
        const { loading, isSuccess, errorCode, errorMessage } = this.state;
        if (isSuccess === "error") this.renderMessage();

        return (
            <Wrapper>
                {isSuccess === "success" ? <Redirect to={{ pathname: NOTIFICATION, state: { errorMessage: errorMessage } }} /> : null}
                <Grid container id="Form">

                    {loading && <CircularProgress className="progress" />}
                    {!loading && errorCode === "" ?
                        <>
                            <Grid item xs={12} md={3} className="sidebar">
                                {this.state.isEdit ? <Icon className="fas fa-edit"></Icon> : <Icon className="fas fa-plus"></Icon>}
                                <Typography variant="h3">Bildirim {this.state.isEdit ? "Düzenle" : "Ekle"}</Typography>
                                <p>
                                    {this.state.isEdit ? "Bu ekrandan bildirim bilgilerini düzenleyebilirsiniz." : "Bu ekrandan sınırsız sayıda bildirim ekleme özgürlüğüne sahipsiniz."}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={9} className="form">
                                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                    <Typography variant="h2">Bildirim {this.state.isEdit ? "Düzenleme" : "Ekleme"} Formu</Typography>                                    
                                    {this.renderCompanies()}
                                    {this.renderBanks()}
                                    {this.renderBankAccounts()}
                                    {this.renderUsers()}
                                    {this.renderDescription()}
                                    {this.renderSenderIBAN()}
                                    {this.renderSenderTaxNumber()}
                                    {this.renderStatus()}

                                    <Button type="submit" onClick={this.saveChanges}>KAYDET</Button>
                                    <Button onClick={this.cancel}>VAZGEÇ</Button>
                                </form>
                            </Grid>
                        </>
                        : this.getErrorMessage()}
                </Grid>
            </Wrapper>

        )
    }

}



export default withStyles(styles)(NotificationForm);
