//UserPermission

export const userValidator = (userId: number): string => {
    if (userId === 0) return 'Kullanıcı seçiniz!'
    return '';
}

export const companyValidator = (companyId: number): string => {
    if (companyId === 0) return 'Firma seçiniz!'
    return '';
}

export const permissionValidator = (permissionId: number): string => {
    if (permissionId === 0) return 'İzin seçiniz!'
    return '';
}