import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Wrapper from '../layout/Wrapper';
import AccountStatementList from './AccountStatementList';

export default class AccountStatement extends Component {
    public props: any;

    render() {
        return (
            <Wrapper>
                <Grid container id="Breadcrumb">
                    <Grid xs={12}>
                        <ul>
                            <li>Hesap Hareketleri</li>
                            <li>Finans</li>
                            <li>Hesap Hareketleri</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container id="Content">
                    <Grid xs={12}>
                        {this.props.match.params.bankId ? <AccountStatementList bankId={this.props.match.params.bankId} currencyId={this.props.match.params.currencyId} /> : <AccountStatementList accountId={this.props.match.params.accountId} />}
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
}
