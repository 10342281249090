

import XHRService from "./xhr.service";

export default class PosStatementsService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async get(startDate?: number, endDate?: number, accountId?: number, searchText?: string, clientType?: string, rowsPerPage?: number, pageNumber?: number, orderBy?: number): Promise<any> {
        const model = {
            bankAccountId: accountId,
            startDate,
            endDate,
            clientType: "WEB",
            searchText,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        //console.log(model);

        return await this.XHRService.post("/PosStatement/GetPosStatements", model).catch(() => ({ isSuccess: false }));

    }

    public async download(startDate?: string, endDate?: string, accountId?: number, searchText?: string, clientType?: string): Promise<any> {

        const model = {
            startDate,
            endDate,
            accountId,
            clientType: "WEB",
            searchText,
            isActive: true,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/PosStatement/GetPosStatementsExcel", model);
    }

    public async bankAccounts(search: any = null): Promise<any> {
        const model = {
            bankId: 0,
            bankAccountId: 0,
            currencyId: 0,
            balance: 0,
            isActive: true,
            clientType: "WEB",
            searchText: null,
            pagingParameters: {
                rowsPerPage: 30,
                pageNumber: 0,
                orderBy: 0
            }
        }

        return await this.XHRService.post("/AccountBalance/GetAccountBalances", model).catch(() => ({ isSuccess: false }));

    }

    public async valorDate(models?: any): Promise<any> {
        const model = {
            userId: 0,
            clientType: "WEB",
            ...models
        }

        return await this.XHRService.post("/PosStatement/GetPosStatementTotalsByValorDate", model).catch(() => ({ isSuccess: false }));

    }


    public async valorDateDownload(models?: any): Promise<any> {
        const model = {
            userId: 0,
            clientType: "WEB",
            ...models
        }

        return await this.XHRService.export("/PosStatement/GetPosStatementTotalsByValorDateExcel", model);

    }
}
