import React, { Component, ChangeEvent } from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'

import Paper from "@material-ui/core/Paper"
import Grid from '@material-ui/core/Grid'
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from "@material-ui/core/Typography"
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'

import ConfirmDialog from '../layout/Confirm'

import { ADD_NEW_PARTNER, EDIT_PARTNER } from '../../routes/paths'

import { partnerService, userService, cookieService, resourceService } from '../../service'


interface IPartnerListState {
    list: [],
    loading: boolean;
    totalCount: number;
    searchTotalCount: number;
    isActive: boolean;
    resources: any;

    //Paging
    sortType: string;
    searchText: string;
    currentPage: number;
    selectedRow: number;
    orderBy: number;

    //Confirm
    confirmStatus: boolean;
    deleteConfirm: boolean;
    confirmMessage: string;
    confirmTitle: string;
    confirmIcon: string;
    confirmType: string;

    //Permission
    userId: number;
    access: string;
    permissionList: any;
    addNewPartner: boolean;
    addNewButton: boolean;
    updateButton: boolean;
    deleteButton: boolean;
    isExcelAndPdfExport: boolean;
}

export default class PartnerList extends Component {
    public readonly props: any;
    public readonly state: IPartnerListState;
    private deleteConfirmId: number | null;

    public readonly LANGUAGE_NAME = 'LANGUAGE_TOKEN';

    constructor(props: any) {
        super(props);
        this.props = props;
        this.deleteConfirmId = null;
        this.state = {
            list: [],
            loading: true,
            totalCount: 0,
            searchTotalCount: 0,
            isActive: true,
            resources: [],

            //Paging
            sortType: "asc",
            searchText: "",
            currentPage: 0,
            selectedRow: 25,
            orderBy: 0,

            //Confirm
            confirmStatus: false,
            deleteConfirm: false,
            confirmMessage: "İş Ortağı silmek istediginize emin misiniz?",
            confirmTitle: "İş Ortağı Sil",
            confirmIcon: "far fa-trash-alt",
            confirmType: "yes/no",

            //Permission
            userId: 0,
            access: "",
            permissionList: [],
            updateButton: false,
            deleteButton: false,
            isExcelAndPdfExport: false,
            addNewPartner: false,
            addNewButton: false,
        }

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
        this.onConfirmApprove = this.onConfirmApprove.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.permissionNot = this.permissionNot.bind(this);
        this.getExportExcel = this.getExportExcel.bind(this);
        this.addNewPartner = this.addNewPartner.bind(this);

    }

    componentWillMount() {
        this.getPermissionControl();
    }

    private async getPermissionControl() {
        const { data } = await userService.permission();
        this.setState({ permissionList: data.unauthorizedPermissions, isExcelAndPdfExport: data.isExcelAndPdfExport, userId: data.userId });
        this.getPartners("", this.state.currentPage, this.state.selectedRow, this.state.orderBy, this.state.isActive);
        this.renderAccessProcess();

        this.state.permissionList.indexOf('Partner.CreatePartner') == -1 ? this.setState({ addNewButton: true }) : this.setState({ access: "close" })

        this.state.userId === 21 ? this.setState({ access: "popup" }) : null

        this.getResources();
    }

    private async getResources() {
        const resourceLanguage: any = cookieService.resourceItem(this.LANGUAGE_NAME);
        const { data } = await resourceService.language(resourceLanguage);
        var resources: any = {};
        data.resources.forEach((item: any) => {
            resources[item.resourceKey] = item.value;
        });

        this.setState({ resources: resources });
    }

    private async getPartners(searchText: string, pageNumber: number, rowPerPage: number, orderBy: number = this.state.orderBy, isActive: boolean) {
        const { data } = await partnerService.get(searchText, pageNumber, rowPerPage, orderBy, isActive);
        this.setState({ list: data.partners, loading: false, totalCount: data.totalCount, searchTotalCount: data.searchTotalCount })
    }

    private async getExportExcel() {
        return await partnerService.download(this.state.searchText, this.state.isActive);
    }

    private permissionNot() {
        this.setState({ confirmMessage: "Bu işlem için erişim yetkiniz bulunmuyor!", confirmTitle: "Erişim Uyarısı", confirmIcon: "fas fa-lock", confirmType: "ok", confirmStatus: true });
    }

    public async removePartner() {
        if (this.deleteConfirmId) {
            await partnerService.delete(this.deleteConfirmId);
            this.setState({ list: this.state.list.filter((partner: any) => partner.id !== this.deleteConfirmId) })
            this.getPartners(this.state.searchText, this.state.currentPage, this.state.selectedRow, this.state.orderBy, this.state.isActive);
        }
    }

    private addNewPartner() {
        const { access } = this.state;
        if (access === "popup") {
            this.setState({ confirmStatus: true });
        } else if (access === "close") {
            this.setState({ addNewPartner: false });
        } else {
            this.setState({ addNewPartner: true });
        }
    }

    private renderAccessProcess() {
        const { permissionList, userId } = this.state;

        if ((permissionList.indexOf('Partner.UpdatePartner') != -1) && (permissionList.indexOf('Partner.DeletePartner') != -1)) {
            this.setState({ access: "close" });
        } else if (userId === 21) {
            this.setState({ access: "popup" });
        } else {
            if (permissionList.indexOf('Partner.UpdatePartner') == -1) {
                this.setState({ updateButton: true });
            }
            if (permissionList.indexOf('Partner.DeletePartner') == -1) {
                this.setState({ deleteButton: true });
            }
            this.setState({ access: "show" });
        }

    }

    private handleRemovePartner(id: number) {
        this.deleteConfirmId = id;
        this.setState({ confirmStatus: true });
    }

    private handleChangePage(_: any, page: number) {
        this.setState({ currentPage: page });
        this.getPartners(this.state.searchText, page, this.state.selectedRow, this.state.orderBy, this.state.isActive);
    }

    private handleSort(orderBy: number) {
        this.setState({ orderBy });
        this.getPartners(this.state.searchText, this.state.currentPage, this.state.selectedRow, orderBy, this.state.isActive);
    }

    private handleSearch({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        this.setState({ searchText: value });
        this.getPartners(value, this.state.currentPage, this.state.selectedRow, this.state.orderBy, this.state.isActive);
    }

    private onConfirmClose() {
        this.setState({ confirmStatus: false });
        this.deleteConfirmId = null;
    }

    private onConfirmApprove() {
        this.setState({ confirmStatus: false });
        this.removePartner();
    }

    private handleRowsPerPageChange({ target: { value } }: any) {
        const selectedRow = Number(value);
        this.setState({ selectedRow });
        this.getPartners(this.state.searchText, this.state.currentPage, selectedRow, this.state.orderBy, this.state.isActive);
    }

    private onChangeStatus(e: ChangeEvent<HTMLInputElement>) {
        this.setState({ isActive: e.target.checked });
        this.getPartners(this.state.searchText, this.state.currentPage, this.state.selectedRow, this.state.orderBy, e.target.checked);
    }

    private renderPagination() {
        const { searchTotalCount, selectedRow, currentPage } = this.state;
        let pageCount = 1;
        let pages = [];
        searchTotalCount <= selectedRow ? pageCount = 1 : pageCount = (searchTotalCount / selectedRow)

        for (let i = 1; i <= Math.ceil(pageCount); i++) {
            if (currentPage + 1 < i + 3 && currentPage + 1 > i - 3) {
                pages[i - 1] = i;
            }
        }

        const listItems = pages.map((page) =>
            <div key={page}>
                {currentPage + 1 === page ? <a onClick={() => this.handleChangePage(null, page - 1)} style={{ color: '#408af8' }}>{page}</a> : <a onClick={() => this.handleChangePage(null, page - 1)}>{page}</a>}
            </div>
        );

        return listItems;
    }

    private renderDownloadButton() {
        return (
            <Button
                variant="contained"
                color="primary"
                className="actionsButton"
                fullWidth
                style={{ textTransform: 'uppercase' }}
                type="submit">
                {this.state.resources.MenuOperationmenu1} <Icon className="fas fa-ellipsis-v"></Icon>
                <ul className="actions">
                    <h6>{this.state.resources.MenuOperationmenu1_1}</h6>
                    <li onClick={() => this.getExportExcel()}><Icon className="far fa-file-excel"></Icon> {this.state.resources.MenuOperationmenu1_2}</li>
                </ul>
            </Button>
        );
    }

    private renderAddButton() {
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={this.state.access === "popup" ? this.permissionNot : this.addNewPartner}
                fullWidth
                style={{ float: 'right', textTransform: 'uppercase' }}
                type="submit"
                id="loginButton">
                {this.state.resources.Button12} +
                </Button>
        );
    }

    private renderStatus() {
        const { isActive, resources } = this.state;

        return (
            <FormControlLabel control={
                <Switch value="isActive" className={isActive ? "select activeStatus" : "select allStatus"} onChange={this.onChangeStatus} checked={isActive} />
            }
                label={isActive ? resources.LabelStatus1 : resources.LabelStatus2} className="isActiveLabel" />
        )
    }

    private renderRowHeaders() {
        const { orderBy, permissionList, resources } = this.state;
        return (
            <TableRow style={{ backgroundColor: '#fdfdfd' }}>
                <TableCell onClick={() => this.handleSort(orderBy === 1 ? 2 : 1)}>
                    <TableSortLabel active={orderBy === 1 || orderBy === 2} direction={orderBy === 1 ? "desc" : "asc"}>
                        İş Ortağı Adı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 3 ? 4 : 3)}>
                    <TableSortLabel active={orderBy === 3 || orderBy === 4} direction={orderBy === 3 ? "desc" : "asc"}>
                        İrtibat Kişi
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 5 ? 6 : 5)}>
                    <TableSortLabel active={orderBy === 5 || orderBy === 6} direction={orderBy === 5 ? "desc" : "asc"}>
                        Email Adresi
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 7 ? 8 : 7)}>
                    <TableSortLabel active={orderBy === 7 || orderBy === 8} direction={orderBy === 7 ? "desc" : "asc"}>
                        Cep Telefonu
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 11 ? 12 : 11)}>
                    <TableSortLabel active={orderBy === 11 || orderBy === 12} direction={orderBy === 11 ? "desc" : "asc"}>
                        Ülke
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 13 ? 14 : 13)}>
                    <TableSortLabel active={orderBy === 13 || orderBy === 14} direction={orderBy === 13 ? "desc" : "asc"}>
                        Şehir
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 15 ? 16 : 15)}>
                    <TableSortLabel active={orderBy === 15 || orderBy === 16} direction={orderBy === 15 ? "desc" : "asc"}>
                        İlçe
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 17 ? 18 : 17)}>
                    <TableSortLabel active={orderBy === 17 || orderBy === 18} direction={orderBy === 17 ? "desc" : "asc"}>
                        Adres
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 19 ? 20 : 19)}>
                    <TableSortLabel active={orderBy === 19 || orderBy === 20} direction={orderBy === 19 ? "desc" : "asc"}>
                        Açıklama
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 21 ? 22 : 21)}>
                    <TableSortLabel active={orderBy === 21 || orderBy === 22} direction={orderBy === 21 ? "desc" : "asc"}>
                        Kullanıcı Adı
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => this.handleSort(orderBy === 9 ? 10 : 9)} className="statusArea">
                    <TableSortLabel active={orderBy === 9 || orderBy === 10} direction={orderBy === 9 ? "desc" : "asc"}>
                        {resources.ListLabelStatus}
                    </TableSortLabel>
                </TableCell>
                {((permissionList.indexOf('Partner.UpdatePartner') != -1) && (permissionList.indexOf('Partner.DeletePartner') != -1)) ? null :
                    <TableCell className="statusButton"></TableCell>
                }
            </TableRow >
        );
    }

    render() {
        const { addNewPartner, addNewButton, list, loading, currentPage, searchText, totalCount, searchTotalCount, confirmStatus, selectedRow, access, updateButton, deleteButton, isExcelAndPdfExport, resources } = this.state;
        return (
            <>
                <Paper>
                    <ConfirmDialog open={confirmStatus} icon={this.state.confirmIcon} confirm={this.state.confirmType} text={this.state.confirmMessage} header={this.state.confirmTitle} onClose={this.onConfirmClose} onApprove={this.onConfirmApprove} />

                    {addNewPartner && <Redirect to={ADD_NEW_PARTNER} />}
                    <Grid container>
                        <Grid item xs={4} className="contentHeader">
                            <Typography variant="h2">{resources.MenuLeftmenu1_6_7} {resources.LabelList}</Typography>
                        </Grid>
                        <Grid item xs={8} className="contentHeader">
                            {isExcelAndPdfExport ? this.renderDownloadButton() : null}
                            {addNewButton ? this.renderAddButton() : null}
                            {this.renderStatus()}
                        </Grid>
                        <Grid item xs={9}>
                            <TablePagination
                                id="TopPagination"
                                rowsPerPageOptions={[25, 50, 100, 250]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={selectedRow}
                                onChangeRowsPerPage={this.handleRowsPerPageChange}
                                page={currentPage}
                                labelDisplayedRows={() => false}
                                labelRowsPerPage={resources.LabelViewData}
                                onChangePage={() => false}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl margin="none" fullWidth className="input searchBox" required>
                                <TextField
                                    id="search"
                                    placeholder={resources.InputPlaceholderSearch}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={this.handleSearch}
                                    value={searchText}
                                    name="search"
                                    autoComplete="search"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    {loading && <CircularProgress className="progress" />}

                    <div style={{ minWidth: '100%', overflowX: 'auto' }}>
                        <Table style={{ width: 2000 }}>
                            <TableHead>
                                {this.renderRowHeaders()}
                            </TableHead>
                            <TableBody>
                                {list.map((partner: any) => (
                                    <TableRow key={partner.id}>
                                        <TableCell scope="row">{partner.partnerName}</TableCell>
                                        <TableCell>{partner.contactPerson}</TableCell>
                                        <TableCell>{partner.emailAddress}</TableCell>
                                        <TableCell>{partner.mobilePhoneNumber}</TableCell>
                                        <TableCell>{partner.countryName}</TableCell>
                                        <TableCell>{partner.cityName}</TableCell>
                                        <TableCell>{partner.districtName}</TableCell>
                                        <TableCell>{partner.address}</TableCell>
                                        <TableCell>{partner.description}</TableCell>
                                        <TableCell>{partner.nameSurname}</TableCell>
                                        <TableCell className="statusArea">
                                            <Typography className={partner.isActive ? "isActive active" : "isActive passive"}></Typography>
                                        </TableCell>
                                        {access === "close" ? null :
                                            <TableCell className="statusButton">
                                                {access === "popup" ?
                                                    <>
                                                        <Icon onClick={() => this.permissionNot()} className="far fa-edit"></Icon>
                                                        <Icon onClick={() => this.permissionNot()} className="far fa-trash-alt"></Icon>
                                                    </>
                                                    : null}
                                                {access === "show" ?
                                                    <>
                                                        {updateButton ?
                                                            <Link to={EDIT_PARTNER(partner.id)}><Icon className="far fa-edit"></Icon></Link>
                                                            : null}
                                                        {deleteButton ?
                                                            <Icon onClick={() => this.handleRemovePartner(partner.id)} className="far fa-trash-alt"></Icon>
                                                            : null}
                                                    </>
                                                    : null}
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        id="Pagination"
                        rowsPerPageOptions={[0]}
                        component="div"
                        count={searchTotalCount}
                        rowsPerPage={selectedRow}
                        onChangeRowsPerPage={this.handleRowsPerPageChange}
                        page={currentPage}
                        labelDisplayedRows={({ from, to, count }) => `${count} ${resources.LabelPagination2} ${from} - ${to} ${resources.LabelPagination3}.`}
                        labelRowsPerPage="Görüntülenen Kayıt"
                        backIconButtonProps={{
                            'aria-label': 'Önceki Sayfa',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Sonraki Sayfa',
                        }}
                        onChangePage={this.handleChangePage}
                    />
                    <span className="total">{"(" + totalCount + " " + resources.LabelPagination1 + ")"}</span>
                    <div id="PageList">
                        {currentPage > 0 ? <a onClick={() => this.handleChangePage(null, this.state.currentPage - 1)}>{resources.LabelPrevious}</a> : null}
                        {currentPage > 3 ? <><a onClick={() => this.handleChangePage(null, 0)}>1</a><a >...</a></> : null}

                        {loading === false ? this.renderPagination() : null}

                        {(currentPage + 1) < (Math.floor((searchTotalCount / selectedRow)) - 2) ? <a >...</a> : null}

                        {(currentPage) + 1 < (Math.ceil((searchTotalCount / selectedRow)) - 2) ? <a onClick={() => this.handleChangePage(null, Math.ceil((searchTotalCount / selectedRow) - 1))}>{Math.ceil((searchTotalCount / selectedRow))}</a> : null}
                        {(currentPage + 1) < (Math.ceil(searchTotalCount / selectedRow)) ? <a onClick={() => this.handleChangePage(null, this.state.currentPage + 1)}> {resources.LabelNext}</a> : null}
                    </div>
                </Paper >
            </>
        )
    }
}


