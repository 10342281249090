import XHRService from "./xhr.service";

export default interface IRolePermissionRequest {
    id?: number,
    roleId: number,
    pessimissionId: number,
    isActive: boolean,
    searchText: string
}


interface IRolePermission {
    id: number,
    roleId: number,
    permissionId: number,
    isActive: boolean,
    searchText: string
}

interface IRolePermissionResponse {
    isSuccess: boolean,
    errorCode: string,
    errorMessage: string,
    data: IRolePermission
}

interface IRolePermissionListResponse {
    isSuccess: boolean;
    data: {
        totalCount: number,
        rolePermissions: IRolePermission[]
    }
}

export default class RolePermissionService {
    private readonly XHRService: XHRService;

    constructor(xhrService: XHRService) {
        this.XHRService = xhrService;
    }

    public async create(model: any): Promise<IRolePermissionResponse> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/RolePermission/CreateRolePermission", model).catch(() => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async update(model: IRolePermissionRequest): Promise<any> {
        const { isSuccess, errorCode, errorMessage }: any = await this.XHRService.post("/RolePermission/UpdateRolePermission", model).catch((e) => ({ isSuccess: false }));
        const data: any = { isSuccess, errorCode, errorMessage };
        return data;
    }

    public async delete(id: number): Promise<IRolePermissionResponse> {
        const { isSuccess }: any = await this.XHRService.post("/RolePermission/DeleteRolePermission", { id }).catch(() => ({ isSuccess: false }));
        return isSuccess;
    }

    public async get(searchText: string, pageNumber: number, rowsPerPage: number, orderBy: number, isActive: boolean): Promise<any> {

        const model = {
            searchText,
            permissionId: 0,
            roleId: 0,
            isActive,
            pagingParameters: {
                rowsPerPage,
                pageNumber,
                orderBy
            }
        }

        return await this.XHRService.post("/RolePermission/GetRolePermissions", model).catch(() => ({ isSuccess: false }));
    }

    public async download(searchText?: string, isActive?: boolean): Promise<any> {

        const model = {
            searchText,
            isActive,
            pagingParameters: {
                rowsPerPage: 9999
            }
        }

        return await this.XHRService.export("/RolePermission/GetRolePermissionsExcel", model);
    }

    public async getById(id: number): Promise<any> {
        return await this.XHRService.get(`/RolePermission/GetRolePermissionById?id=${id}`).catch(() => ({ isSuccess: false }));
    }
}
