//Bank

export const bankNameValidator = (bankName: string): string => {
    if (bankName === "") return 'Banka adı alanını boş geçemezsiniz!'
    return '';
}

export const bankCodeValidator = (bankCode: string): string => {
    if (bankCode === "") return 'Banka kodu adı alanını boş geçemezsiniz!'
    return '';
}

export const countryValidator = (countryId: number): string => {
    if (countryId === 0) return 'Ülke seçiniz!'
    return '';
}

export const webSiteValidator = (webSite: string): string => {
    if (webSite === "") return 'Web sitesi alanını boş geçemezsiniz!'
    return '';
}
