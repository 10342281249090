import React, { Component } from 'react'
import Wrapper from '../layout/Wrapper';
import Grid from '@material-ui/core/Grid';
import AccountBalanceHistoryList from './AccountBalanceHistoryList';

export default class AccountBalanceHistory extends Component {    
    public props: any;

    render() {
        return (
            <Wrapper>
                <Grid container id="Breadcrumb">
                    <Grid xs={12}>
                        <ul>
                            <li>Bakiye Geçmişi</li>
                            <li>Finans</li>
                            <li>Bakiye Geçmişi</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container id="Content">
                    <Grid xs={12}>
                        <AccountBalanceHistoryList accountId={this.props.match.params.accountId} />
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
}
